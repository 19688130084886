import { combineReducers } from "redux";
import authReducer from "./authReducer";
import orderReducer from "./orderReducer";
import utilsReducer from "./utilsReducer";
import messageReducer from "./messageReducer";
import reportReducer from "./reportReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  order: orderReducer,
  utils: utilsReducer,
  message: messageReducer,
  report:reportReducer
});

export default rootReducer;
