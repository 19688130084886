export const authConstants = {
  OTP_REQUEST: "OTP_REQUEST",
  OTP_SUCCESS: "OTP_SUCCESS",
  OTP_FAILURE: "OTP_FAILURE",
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGOUT_REQUEST: "LOGOUT_REQUEST",
  LOGOUT_SUCESS: "LOGOUT_SUCESS",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",
  REGISTER_REQUEST: "REGISTER_REQUEST",
  REGISTER_SUCESS: "REGISTER_SUCESS",
  REGISTER_FAILURE: "REGISTER_FAILURE",
  UPDATE_USER_REQUEST: "UPDATE_USER_REQUEST",
  UPDATE_USER_SUCESS: "UPDATE_USER_SUCESS",
  UPDATE_USER_FAILURE: "UPDATE_USER_FAILURE",
  VERIFY_OTP_REQUEST: "VERIFY_OTP_REQUEST",
  VERIFY_OTP_SUCCESS: "VERIFY_OTP_SUCCESS",
  VERIFY_OTP_FAILURE: "VERIFY_OTP_FAILURE",
  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
};

export const completeProfileConstants = {
  ADD_LICENCE_REQUEST: "ADD_LICENCE_REQUEST",
  ADD_LICENCE_SUCCESS: "ADD_LICENCE_SUCCESS",
  ADD_LICENCE_FAILURE: "ADD_LICENCE_FAILURE",
  ADD_COMPANY_REQUEST: "ADD_COMPANY_REQUEST",
  ADD_COMPANY_SUCCESS: "ADD_COMPANY_SUCCESS",
  ADD_COMPANY_FAILURE: "ADD_COMPANY_FAILURE",
  ADD_PERSONAL_REQUEST: "ADD_PERSONAL_REQUEST",
  ADD_PERSONAL_SUCCESS: "ADD_PERSONAL_SUCCESS",
  ADD_PERSONAL_FAILURE: "ADD_PERSONAL_FAILURE",
  ADD_COVERAGE_AREA_REQUEST: "ADD_COVERAGE_AREA_REQUEST",
  ADD_COVERAGE_AREA_SUCCESS: "ADD_COVERAGE_AREA_SUCCESS",
  ADD_COVERAGE_AREA_FAILURE: "ADD_COVERAGE_AREA_FAILURE",
  ADD_DIGITAL_REQUEST: "ADD_DIGITAL_REQUEST",
  ADD_DIGITAL_SUCCESS: "ADD_DIGITAL_SUCCESS",
  ADD_DIGITAL_FAILURE: "ADD_DIGITAL_FAILURE",
  ADD_BILLING_REQUEST: "ADD_BILLING_REQUEST",
  ADD_BILLING_SUCCESS: "ADD_BILLING_SUCCESS",
  ADD_BILLING_FAILURE: "ADD_BILLING_FAILURE",
  ADD_DOCUMENT_REQUEST: "ADD_DOCUMENT_REQUEST",
  ADD_DOCUMENT_SUCCESS: "ADD_DOCUMENT_SUCCESS",
  ADD_DOCUMENT_FAILURE: "ADD_DOCUMENT_FAILURE",
  VERIFY_AGREEMENT_REQUEST: "VERIFY_AGREEMENT_REQUEST",
  VERIFY_AGREEMENT_SUCCESS: "VERIFY_AGREEMENT_SUCCESS",
  VERIFY_AGREEMENT_FAILURE: "VERIFY_AGREEMENT_FAILURE",
  OTP_VERIFICATION_REQUEST: "OTP_VERIFICAsTION_REQUEST",
  OTP_VERIFICATION_SUCCESS: "OTP_VERIFICATION_SUCCESS",
  OTP_VERIFICATION_FAILURE: "OTP_VERIFICATION_FAILURE",
  APPRAISER_QUALIFICATION_REQUEST: "APPRAISER_QUALIFICATION_REQUEST",
  APPRAISER_QUALIFICATION_SUCCESS: "APPRAISER_QUALIFICATION_SUCCESS",
  APPRAISER_QUALIFICATION_FAILURE: "APPRAISER_QUALIFICATION_FAILURE",
  MAKE_INSURANCE_REQUEST: "MAKE_INSURANCE_REQUEST",
  MAKE_INSURANCE_SUCCESS: "MAKE_INSURANCE_SUCCESS",
  MAKE_INSURANCE_FAILURE: "MAKE_INSURANCE_FAILURE",
  //GET Requests for complete profile
  GET_LICENCE_REQUEST: "GET_LICENCE_REQUEST",
  GET_LICENCE_SUCCESS: "GET_LICENCE_SUCCESS",
  GET_LICENCE_FAILURE: "GET_LICENCE_FAILURE",
  GET_COMPANY_REQUEST: "GET_COMPANY_REQUEST",
  GET_COMPANY_SUCCESS: "GET_COMPANY_SUCCESS",
  GET_COMPANY_FAILURE: "GET_COMPANY_FAILURE",
  GET_PERSONAL_REQUEST: "GET_PERSONAL_REQUEST",
  GET_PERSONAL_SUCCESS: "GET_PERSONAL_SUCCESS",
  GET_PERSONAL_FAILURE: "GET_PERSONAL_FAILURE",
  GET_COVERAGE_AREA_REQUEST: "GET_COVERAGE_AREA_REQUEST",
  GET_COVERAGE_AREA_SUCCESS: "GET_COVERAGE_AREA_SUCCESS",
  GET_COVERAGE_AREA_FAILURE: "GET_COVERAGE_AREA_FAILURE",
  GET_DIGITAL_REQUEST: "GET_DIGITAL_REQUEST",
  GET_DIGITAL_SUCCESS: "GET_DIGITAL_SUCCESS",
  GET_DIGITAL_FAILURE: "GET_DIGITAL_FAILURE",
  GET_BILLING_REQUEST: "GET_BILLING_REQUEST",
  GET_BILLING_SUCCESS: "GET_BILLING_SUCCESS",
  GET_BILLING_FAILURE: "GET_BILLING_FAILURE",
  GET_DOCUMENT_REQUEST: "GET_DOCUMENT_REQUEST",
  GET_DOCUMENT_SUCCESS: "GET_DOCUMENT_SUCCESS",
  GET_DOCUMENT_FAILURE: "GET_DOCUMENT_FAILURE",
  GET_AGREEMENT_REQUEST: "GET_AGREEMENT_REQUEST",
  GET_AGREEMENT_SUCCESS: "GET_AGREEMENT_SUCCESS",
  GET_AGREEMENT_FAILURE: "GET_AGREEMENT_FAILURE",
  GET_QUALIFICATION_REQUEST: "GET_QUALIFICATION_REQUEST",
  GET_QUALIFICATION_SUCCESS: "GET_QUALIFICATION_SUCCESS",
  GET_QUALIFICATION_FAILURE: "GET_QUALIFICATION_FAILURE",
  GET_INSURANCE_REQUEST: "GET_INSURANCE_REQUEST",
  GET_INSURANCE_SUCCESS: "GET_INSURANCE_SUCCESS",
  GET_INSURANCE_FAILURE: "GET_INSURANCE_FAILURE",
};

export const orderConstants = {
  NEW_ORDER_REQUEST: "NEW_ORDER_REQUEST",
  NEW_ORDER_SUCCESS: "NEW_ORDER_SUCCESS",
  NEW_ORDER_FAILURE: "NEW_ORDER_FAILURE",
  CREATE_ASSIGNED_ORDER_REQUEST: "CREATE_ASSIGNED_ORDER_REQUEST",
  CREATE_ASSIGNED_ORDER_SUCCESS: "CREATE_ASSIGNED_ORDER_SUCCESS",
  CREATE_ASSIGNED_ORDER_FAILURE: "CREATE_ASSIGNED_ORDER_FAILURE",
  ASSIGNED_ORDER_REQUEST: "ASSIGNED_ORDER_REQUEST",
  ASSIGNED_ORDER_SUCCESS: "ASSIGNED_ORDER_SUCCESS",
  ASSIGNED_ORDER_FAILURE: "ASSIGNED_ORDER_FAILURE",
  SCHEDULE_ORDER_REQUEST: "SCHEDULE_ORDER_REQUEST",
  SCHEDULE_ORDER_SUCCESS: "SCHEDULE_ORDER_SUCCESS",
  SCHEDULE_ORDER_FAILURE: "SCHEDULE_ORDER_FAILURE",
  GET_NEW_ORDER_REQUEST: "GET_NEW_ORDER_REQUEST",
  GET_NEW_ORDER_SUCCESS: "GET_NEW_ORDER_SUCCESS",
  GET_NEW_ORDER_FAILURE: "GET_NEW_ORDER_FAILURE",
  GET_SCHEDULED_ORDER_REQUEST: "GET_SCHEDULED_ORDER_REQUEST",
  GET_SCHEDULED_ORDER_SUCCESS: "GET_SCHEDULED_ORDER_SUCCESS",
  GET_SCHEDULED_ORDER_FAILURE: "GET_SCHEDULED_ORDER_FAILURE",
  COMPLETE_ORDER_REQUEST: "COMPLETE_ORDER_REQUEST",
  COMPLETE_ORDER_SUCCESS: "COMPLETE_ORDER_SUCCESS",
  COMPLETE_ORDER_FAILURE: "COMPLETE_ORDER_FAILURE",
  GET_COMPLETE_ORDER_REQUEST: "GET_COMPLETE_ORDER_REQUEST",
  GET_COMPLETE_ORDER_SUCCESS: "GET_COMPLETE_ORDER_SUCCESS",
  GET_COMPLETE_ORDER_FAILURE: "GET_COMPLETE_ORDER_FAILURE",
  GET_CANCELLED_ORDER_REQUEST: "GET_CANCELLED_ORDER_REQUEST",
  GET_CANCELLED_ORDER_SUCCESS: "GET_CANCELLED_ORDER_SUCCESS",
  GET_CANCELLED_ORDER_FAILURE: "GET_CANCELLED_ORDER_FAILURE",
  DECLINE_ORDER_REQUEST: "DECLINE_ORDER_REQUEST",
  DECLINE_ORDER_SUCCESS: "DECLINE_ORDER_SUCCESS",
  DECLINE_ORDER_FAILURE: "DECLINE_ORDER_FAILURE",
  COUNTER_OFFER_REQUEST: "COUNTER_OFFER_REQUEST",
  COUNTER_OFFER_SUCCESS: "COUNTER_OFFER_SUCCESS",
  COUNTER_OFFER_FAILURE: "COUNTER_OFFER_FAILURE",
  GET_ORDER_DETAILS_REQUEST: "GET_ORDER_DETAILS_REQUEST",
  GET_ORDER_DETAILS_SUCCESS: "GET_ORDER_DETAILS_SUCCESS",
  GET_ORDER_DETAILS_FAILURE: "GET_ORDER_DETAILS_FAILURE",
  APPROVE_ORDER_REQUEST: "APPROVE_ORDER_REQUEST",
  APPROVE_ORDER_SUCCESS: "APPROVE_ORDER_SUCCESS",
  APPROVE_ORDER_FAILURE: "APPROVE_ORDER_FAILURE",
  GET_ORDER_ACTIVITY_REQUEST: "GET_ORDER_ACTIVITY_REQUEST",
  GET_ORDER_ACTIVITY_SUCCESS: "GET_ORDER_ACTIVITY_SUCCESS",
  GET_ORDER_ACTIVITY_FAILURE: "GET_ORDER_ACTIVITY_FAILURE",
};

export const reportConstants = {
  GET_PROPERTY_DETAILS_REQUEST: "GET_PROPERTY_DETAILS_REQUEST",
  GET_PROPERTY_DETAILS_SUCCESS: "GET_PROPERTY_DETAILS_SUCCESS",
  GET_PROPERTY_DETAILS_FAILURE: "GET_PROPERTY_DETAILS_FAILURE",
  UPDATE_PROPERTY_DETAILS_REQUEST: "UPDATE_PROPERTY_DETAILS_REQUEST",
  UPDATE_PROPERTY_DETAILS_SUCCESS: "UPDATE_PROPERTY_DETAILS_SUCCESS",
  UPDATE_PROPERTY_DETAILS_FAILURE: "UPDATE_PROPERTY_DETAILS_FAILURE",
  GET_REPAIR_DETAILS_REQUEST: "GET_REPAIR_DETAILS_REQUEST",
  GET_REPAIR_DETAILS_SUCCESS: "GET_REPAIR_DETAILS_SUCCESS",
  GET_REPAIR_DETAILS_FAILURE: "GET_REPAIR_DETAILS_FAILURE",
  UPDATE_REPAIR_DETAILS_REQUEST: "UPDATE_REPAIR_DETAILS_REQUEST",
  UPDATE_REPAIR_DETAILS_SUCCESS: "UPDATE_REPAIR_DETAILS_SUCCESS",
  UPDATE_REPAIR_DETAILS_FAILURE: "UPDATE_REPAIR_DETAILS_FAILURE",
  GET_MARKET_TREND_REQUEST: "GET_MARKET_TREND_REQUEST",
  GET_MARKET_TREND_SUCCESS: "GET_MARKET_TREND_SUCCESS",
  GET_MARKET_TREND_FAILURE: "GET_MARKET_TREND_FAILURE",
  UPDATE_MARKET_TREND_REQUEST: "UPDATE_MARKET_TREND_REQUEST",
  UPDATE_MARKET_TREND_SUCCESS: "UPDATE_MARKET_TREND_SUCCESS",
  UPDATE_MARKET_TREND_FAILURE: "UPDATE_MARKET_TREND_FAILURE",
  GET_SKETCH_REQUEST: "GET_SKETCH_REQUEST",
  GET_SKETCH_SUCCESS: "GET_SKETCH_SUCCESS",
  GET_SKETCH_FAILURE: "GET_SKETCH_FAILURE",
  GET_COMMENT_REQUEST: "GET_COMMENT_REQUEST",
  GET_COMMENT_SUCCESS: "GET_COMMENT_SUCCESS",
  GET_COMMENT_FAILURE: "GET_COMMENT_FAILURE",
  GET_RECONCILIATION_REQUEST: "GET_RECONCILIATION_REQUEST",
  GET_RECONCILIATION_SUCCESS: "GET_RECONCILIATION_SUCCESS",
  GET_RECONCILIATION_FAILURE: "GET_RECONCILIATION_FAILURE",
  UPDATE_RECONCILIATION_REQUEST: "UPDATE_RECONCILIATION_REQUEST",
  UPDATE_RECONCILIATION_SUCCESS: "UPDATE_RECONCILIATION_SUCCESS",
  UPDATE_RECONCILIATION_FAILURE: "UPDATE_RECONCILIATION_FAILURE",
  SUBMIT_REPORT_REQUEST: "SUBMIT_REPORT_REQUEST",
  SUBMIT_REPORT_SUCCESS: "SUBMIT_REPORT_SUCCESS",
  SUBMIT_REPORT_FAILURE: "SUBMIT_REPORT_FAILURE",
  GET_REVISIONS_REQUEST: "GET_REVISIONS_REQUEST",
  GET_REVISIONS_SUCCESS: "GET_REVISIONS_SUCCESS",
  GET_REVISIONS_FAILURE: "GET_REVISIONS_FAILURE",
  GET_FILE_REQUEST: "GET_FILE_REQUEST",
  GET_FILE_SUCCESS: "GET_FILE_SUCCESS",
  GET_FILE_FAILURE: "GET_FILE_FAILURE",
  UPDATE_FILE_REQUEST: "UPDATE_FILE_REQUEST",
  UPDATE_FILE_SUCCESS: "UPDATE_FILE_SUCCESS",
  UPDATE_FILE_FAILURE: "UPDATE_FILE_FAILURE",
  DELETE_FILE_REQUEST: "DELETE_FILE_REQUEST",
  DELETE_FILE_SUCCESS: "DELETE_FILE_SUCCESS",
  DELETE_FILE_FAILURE: "DELETE_FILE_FAILURE",
};

export const messageConstants = {
  GET_SUPPORT_MESSAGE_REQUEST: "GET_SUPPORT_MESSAGE_REQUEST",
  GET_SUPPORT_MESSAGE_SUCCESS: "GET_SUPPORT_MESSAGE_SUCCESS",
  GET_SUPPORT_MESSAGE_FAILURE: "GET_SUPPORT_MESSAGE_FAILURE",
  CREATE_TICKET_REQUEST: "CREATE_TICKET_REQUEST",
  CREATE_TICKET_SUCCESS: "CREATE_TICKET_SUCCESS",
  CREATE_TICKET_FAILURE: "CREATE_TICKET_FAILURE",
  CREATE_NEW_MESSAGE_REQUEST: "CREATE_NEW_MESSAGE_REQUEST",
  CREATE_NEW_MESSAGE_SUCCESS: "CREATE_NEW_MESSAGE_SUCCESS",
  CREATE_NEW_MESSAGE_FAILURE: "CREATE_NEW_MESSAGE_FAILURE",
  GET_MESSAGE_REQUEST: "GET_MESSAGE_REQUEST",
  GET_MESSAGE_SUCCESS: "GET_MESSAGE_SUCCESS",
  GET_MESSAGE_FAILURE: "GET_MESSAGE_FAILURE",
  GET_ADMIN_MESSAGE_REQUEST: "GET_ADMIN_MESSAGE_REQUEST",
  GET_ADMIN_MESSAGE_SUCCESS: "GET_ADMIN_MESSAGE_SUCCESS",
  GET_ADMIN_MESSAGE_FAILURE: "GET_ADMIN_MESSAGE_FAILURE",
  CREATE_ADMIN_MESSAGE_REQUEST: "CREATE_ADMIN_MESSAGE_REQUEST",
  CREATE_ADMIN_MESSAGE_SUCCESS: "CREATE_ADMIN_MESSAGE_SUCCESS",
  CREATE_ADMIN_MESSAGE_FAILURE: "CREATE_ADMIN_MESSAGE_FAILURE",
  CREATE_ORDER_MESSAGE_REQUEST: "CREATE_ORDER_MESSAGE_REQUEST",
  CREATE_ORDER_MESSAGE_SUCCESS: "CREATE_ORDER_MESSAGE_SUCCESS",
  CREATE_ORDER_MESSAGE_FAILURE: "CREATE_ORDER_MESSAGE_FAILURE",
  GET_ORDER_MESSAGE_REQUEST: "GET_ORDER_MESSAGE_REQUEST",
  GET_ORDER_MESSAGE_SUCCESS: "GET_ORDER_MESSAGE_SUCCESS",
  GET_ORDER_MESSAGE_FAILURE: "GET_ORDER_MESSAGE_FAILURE",
  GET_SUPPORT_By_ID_REQUEST: "GET_SUPPORT_By_ID_REQUEST",
  GET_SUPPORT_By_ID_SUCCESS: "GET_SUPPORT_By_ID_SUCCESS",
  GET_SUPPORT_By_ID_FAILURE: "GET_SUPPORT_By_ID_FAILURE",
  GET_TICKET_REQUEST: "GET_TICKET_REQUEST",
  GET_TICKET_SUCCESS: "GET_TICKET_SUCCESS",
  GET_TICKET_FAILURE: "GET_TICKET_FAILURE",
  GET_MESSAGE_BY_TICKET_REQUEST: "GET_MESSAGE_BY_TICKET_REQUEST",
  GET_MESSAGE_BY_TICKET_SUCCESS: "GET_MESSAGE_BY_TICKET_SUCCESS",
  GET_MESSAGE_BY_TICKET_FAILURE: "GET_MESSAGE_BY_TICKET_FAILURE",
  POST_MESSAGE_BY_TICKET_REQUEST: "POST_MESSAGE_BY_TICKET_REQUEST",
  POST_MESSAGE_BY_TICKET_SUCCESS: "POST_MESSAGE_BY_TICKET_SUCCESS",
  POST_MESSAGE_BY_TICKET_SUCCESS: "POST_MESSAGE_BY_TICKET_FAILURE",
};

export const utilConstants = {
  GET_STATES_REQUEST: "GET_STATES_REQUEST",
  GET_STATES_SUCCESS: "GET_STATES_SUCCESS",
  GET_STATES_FAILURE: "GET_STATES_FAILURE",
  GET_BILLING_REQUEST: "GET_BILLING_REQUEST",
  GET_BILLING_SUCCESS: "GET_BILLING_SUCCESS",
  GET_BILLING_FAILURE: "GET_BILLING_FAILURE",
};
