import { authConstants, completeProfileConstants } from "../Actions/constants";

const initState = {
  authenticate: false,
  authenticating: false,
  loading: true,
  error: null,
  message: "",
  user: null,
  token: null,
  fetchedItems: [],
  profile: {
    license: {},
    company: {},
    personals: {},
    coverage_area: {},
    digital_signature: {},
    billings: {},
    documents: {},
    agreements: {},
    qualifications: {},
    insurance: {},
  },
};

export default (state = initState, action) => {
  switch (action.type) {
    case authConstants.OTP_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case authConstants.OTP_SUCCESS:
      state = {
        ...state,
        user: action.payload.user,
        role: action.payload.role,
        message: action.payload.message,
        authenticate: false,
        authenticating: false,
        loading: false,
      };
      break;
    case authConstants.OTP_SUCCESS:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;

    case authConstants.LOGIN_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case authConstants.LOGIN_SUCCESS:
      state = {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        message: action.payload.message,
        authenticate: true,
        authenticating: false,
        loading: false,
      };
      break;
    case authConstants.LOGIN_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;

    case authConstants.REGISTER_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case authConstants.REGISTER_SUCESS:
      state = {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        message: action.payload.message,
        authenticate: true,
        authenticating: false,
        loading: false,
      };
      break;
    case authConstants.REGISTER_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case authConstants.LOGOUT_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case authConstants.LOGOUT_SUCESS:
      state = {
        ...initState,
      };
      break;
    case authConstants.LOGOUT_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case completeProfileConstants.GET_LICENCE_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case completeProfileConstants.GET_LICENCE_SUCCESS:
      state = {
        ...state,
        profile: {
          ...state.profile,
          license: action.payload.data,
          fetchedItems: state?.fetchedItems?.includes("State License(s)")
            ? state.fetchedItems
            : state.fetchedItems.push(action.payload.fetchedItems),
        },
        loading: false,
      };
      break;

    case completeProfileConstants.GET_LICENCE_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case completeProfileConstants.GET_COMPANY_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case completeProfileConstants.GET_COMPANY_SUCCESS:
      state = {
        ...state,
        profile: {
          ...state.profile,
          company: action.payload.data,
          fetchedItems: state?.fetchedItems?.includes("Company Information")
            ? state.fetchedItems
            : state.fetchedItems.push(action.payload.fetchedItems),
        },
        loading: false,
      };
      break;
    case completeProfileConstants.GET_COMPANY_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case completeProfileConstants.GET_PERSONAL_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case completeProfileConstants.GET_PERSONAL_SUCCESS:
      state = {
        ...state,
        profile: {
          ...state.profile,
          personals: action.payload.data,
          fetchedItems: state?.fetchedItems?.includes("Personal Information")
            ? state.fetchedItems
            : state.fetchedItems.push(action.payload.fetchedItems),
        },
        loading: false,
      };
      break;
    case completeProfileConstants.GET_PERSONAL_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case completeProfileConstants.GET_COVERAGE_AREA_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case completeProfileConstants.GET_COVERAGE_AREA_SUCCESS:
      state = {
        ...state,
        profile: {
          ...state.profile,
          coverage_area: action.payload.data,
          fetchedItems: state?.fetchedItems?.includes("Coverage Area")
            ? state.fetchedItems
            : state.fetchedItems.push(action.payload.fetchedItems),
        },
        loading: false,
      };
      break;
    case completeProfileConstants.GET_COVERAGE_AREA_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case completeProfileConstants.GET_DIGITAL_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case completeProfileConstants.GET_DIGITAL_SUCCESS:
      state = {
        ...state,
        profile: {
          ...state.profile,
          digital_signature: action.payload.data,
          fetchedItems: state?.fetchedItems?.includes("Digital Signature")
            ? state.fetchedItems
            : state.fetchedItems.push(action.payload.fetchedItems),
        },
        loading: false,
      };
      break;
    case completeProfileConstants.GET_DIGITAL_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case completeProfileConstants.GET_BILLING_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;

    case completeProfileConstants.GET_BILLING_SUCCESS:
      state = {
        ...state,
        profile: {
          ...state.profile,
          billings: action.payload.data,
          fetchedItems: state?.fetchedItems?.includes("Billing")
            ? state.fetchedItems
            : state.fetchedItems.push(action.payload.fetchedItems),
        },
        loading: false,
      };
      break;
    case completeProfileConstants.GET_BILLING_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case completeProfileConstants.GET_DOCUMENT_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;

    case completeProfileConstants.GET_DOCUMENT_SUCCESS:
      state = {
        ...state,
        profile: {
          ...state.profile,
          documents: action.payload.data,
          fetchedItems: state?.fetchedItems?.includes("Document")
            ? state.fetchedItems
            : state.fetchedItems.push(action.payload.fetchedItems),
        },
        loading: false,
      };
      break;
    case completeProfileConstants.GET_DOCUMENT_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case completeProfileConstants.GET_AGREEMENT_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;

    case completeProfileConstants.GET_AGREEMENT_SUCCESS:
      state = {
        ...state,
        profile: {
          ...state.profile,
          agreements: action.payload.data,
        },
        loading: false,
      };
      break;
    case completeProfileConstants.GET_AGREEMENT_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case completeProfileConstants.GET_QUALIFICATION_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;

    case completeProfileConstants.GET_QUALIFICATION_SUCCESS:
      state = {
        ...state,
        profile: {
          ...state.profile,
          qualifications: action.payload.data,
          fetchedItems: state?.fetchedItems?.includes("Appraiser Qualification")
            ? state.fetchedItems
            : state.fetchedItems.push(action.payload.fetchedItems),
        },
        loading: false,
      };
      break;
    case completeProfileConstants.GET_QUALIFICATION_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;

    case completeProfileConstants.GET_INSURANCE_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;

    case completeProfileConstants.GET_INSURANCE_SUCCESS:
      state = {
        ...state,
        profile: {
          ...state.profile,
          insurance: action.payload.data,
          fetchedItems: state?.fetchedItems?.includes("E&o Insurance")
            ? state.fetchedItems
            : state.fetchedItems.push(action.payload.fetchedItems),
        },
        loading: false,
      };
      break;

    case completeProfileConstants.GET_INSURANCE_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;

    default:
  }
  return state;
};
