import React, { useState } from "react";
import { Box } from "@mui/material";
import Text from "./Core/Text";
import FileUploader from "./FileUploader";
import Button from "./Core/Button";

const DigitalSign = ({
  handleChange,
  data,
  handleNext,
  handleBack,
  isUpdate,
}) => {
  const [dataSign, setdataSign] = useState(null);
  return (
    <Box
      sx={{
        width: "66%",
        ml: isUpdate ? 0 : "auto",
        mr: "auto",
      }}
    >
      <Text
        variant="h1"
        style={{
          color: "#E6E6E6",
          fontSize: "48px",
        }}
      >
        Digital Signature
      </Text>
      <Text
        variant="body1"
        style={{
          font: "normal normal medium 14px/21px Poppins",
          color: "#656a72",
          fontSize: "13px",
          marginBottom: "2em",
        }}
      >
        Lorem Ipsum is simply dummy text of the printing and type setting
        industry.
      </Text>
      <FileUploader
        label=""
        type="signature"
        handleChange={(file) => {
          handleChange("signature", file);
          setdataSign({
            ...dataSign,
            signature: file[0],
          });
        }}
      />
      {dataSign?.signature && (
        <span
          style={{
            fontSize: 13,
            marginTop: 4,
          }}
        >
          {dataSign?.signature?.name}
        </span>
      )}
      <Text
        variant="body1"
        style={{
          color: "#E6E6E6",
          fontSize: "24px",
          marginTop: ".5em",
        }}
      >
        Or
      </Text>
      <FileUploader
        label=""
        type="file-uploader"
        handleChange={(file) => {
          handleChange("signature", file);
          setdataSign({
            ...dataSign,
            signaturefile: file[0],
          });
        }}
      />
      {dataSign?.signaturefile && (
        <span
          style={{
            fontSize: 13,
            marginTop: 4,
          }}
        >
          {dataSign?.signaturefile?.name}
        </span>
      )}
      <Box sx={{ display: "flex", flexDirection: "row", mt: "1.5em" }}>
        {handleBack && (
          <Button
            onClick={handleBack}
            isTransparent
            style={{
              width: "30%",
              paddingTop: ".2em",
              paddingBottom: ".2em",
              marginRight: ".5em",
            }}
          >
            Back
          </Button>
        )}
        {handleNext && (
          <Button
            onClick={handleNext}
            style={{
              width: "30%",
              paddingTop: ".1em",
              paddingBottom: ".1em",
            }}
          >
             {isUpdate ? "Udpate" : "Save"}  & Continue
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default DigitalSign;
