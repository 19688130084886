import React, { useEffect, useState } from "react";
import Template from "../../Template";
import Ellipse8 from "../../Assets/Images/Ellipse8.svg";
import { Box, CircularProgress } from "@mui/material";
import Text from "../../Components/Core/Text";
import {
  Calendar,
  ChatWritten,
  ClockCircle,
  CoinDollar,
  FileWrittenBent,
  HomeLocation,
  RoofHomeGraph,
  ShoppingBag,
} from "react-huge-icons/outline";
import Button from "../../Components/Core/Button";
import { useParams } from "react-router";
import ScheduleNow from "../../Components/ScheduleNow";
import CreateCounter from "../../Components/CreateCounter";
import Decline from "../../Components/Decline";
import MessageModal from "../../Components/MessageModal";
import { useDispatch, useSelector } from "react-redux";
import {
  OrderDetails,
  approveOrder,
  counterOffer,
  declineOrder,
  getNewOrders,
  getOrderActivity,
} from "../../Actions/order";
import moment from "moment";
import ActivityCard from "../../Components/ActivityCard";
import Prompt from "../../Components/Core/Prompt";

const OrderDetail = () => {
  const [showCounter, setshowCounter] = useState(false);
  const [showDecline, setshowDecline] = useState(false);
  const [showSchedule, setshowSchedule] = useState(false);
  const [showMessage, setshowMessage] = useState(false);
  const order = useSelector((state) => state.order);
  const [makePrompt, setmakePrompt] = useState({
    open: false,
    message: "",
    type: "error",
  });
  const dispatch = useDispatch();
  const params = useParams();
  const type = params.type;

  useEffect(() => {
    dispatch(OrderDetails(params.id));
    dispatch(getOrderActivity(params.id));
  }, []);

  const details = order?.orderDetails;

  const getButtonTypes = () => {
    switch (type) {
      case "new":
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "44%",
              m: "auto 0",
            }}
          >
            <Button
              onClick={onApprove}
              style={{
                width: "50%",
                height: "42px",
                backgroundColor: "#00C165",
                borderRadius: "11px",
                color: "#FFFFFF",
                fontSize: "12px",
                fontFamily: "Poppins",
                fontWeight: 600,
                margin: "auto .5em",
              }}
            >
              Approve
            </Button>
            <Button
              onClick={() => setshowDecline(true)}
              style={{
                width: "50%",
                height: "42px",
                backgroundColor: "transparent",
                borderRadius: "11px",
                border: " 1px solid #EE1616",
                color: "#EE1616",
                fontSize: "12px",
                fontFamily: "Poppins",
                fontWeight: 600,
                margin: "auto .5em",
              }}
            >
              Decline
            </Button>
          </Box>
        );
      case "assigned":
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "44%",
              m: "auto 0",
            }}
          >
            <Button
              style={{
                width: "180px",
                height: "42px",
                borderRadius: "11px",
                color: "#fff",
                fontSize: "12px",
                fontFamily: "Poppins",
                fontWeight: 600,
                margin: "auto .5em",
              }}
              onClick={() => setshowSchedule(true)}
            >
              Schedule Now
            </Button>
            <Button
              style={{
                width: "180px",
                height: "42px",
                borderRadius: "11px",
                color: "#fff",
                fontSize: "14px",
                margin: "0 auto",
                fontFamily: "Poppins",
                fontWeight: 600,
                display: "flex",
                flexDirection: "row",
                position: "relative",
              }}
              onClick={() => setshowMessage(true)}
            >
              <ChatWritten
                fontSize={18}
                style={{
                  position: "absolute",
                  left: "15%",
                  top: "25%",
                }}
              />
              <span style={{ marginLeft: "1.5em" }}>Message</span>
            </Button>
          </Box>
        );
      case "scheduled":
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "32%",
              m: "auto 0",
            }}
          >
            <Button
              style={{
                width: "240px",
                height: "42px",
                borderRadius: "11px",
                color: "#fff",
                fontSize: "12px",
                fontFamily: "Poppins",
                fontWeight: 600,
                margin: "auto .5em",
              }}
            >
              Complete Report
            </Button>
          </Box>
        );

      default:
        break;
    }
  };

  console.log(details);
  const handleMakePrompt = (message, type) => {
    setmakePrompt({ open: true, message, type });
    setTimeout(() => {
      setmakePrompt({ open: false, message: "", type: "" });
    }, 2000);
  };

  const onApprove = () => {
    dispatch(approveOrder(details._id))
      .then((result) => {
        handleMakePrompt("Order Approved", "success");
        dispatch(getNewOrders());
      })
      .catch((err) => {
        handleMakePrompt(err.response.data.error, "error");
      });
  };

  const onDecline = (reason) => {
    dispatch(declineOrder(details._id, { reason }))
      .then((result) => {
        setshowDecline(false);
        dispatch(getNewOrders());
        handleMakePrompt("Order Declined", "success");
      })
      .catch((err) => {
        setshowDecline(false);
        handleMakePrompt(err.response.data.error, "error");
      });
  };

  const onCreateCounter = (data) => {
    dispatch(counterOffer(details._id, data))
      .then((result) => {
        setshowCounter(false);
        handleMakePrompt("Counter Offer Created", "success");
      })
      .catch((err) => {
        setshowCounter(false);
        handleMakePrompt(err.response.data.error, "error");
      });
  };

  return (
    <Template
      showHeader
      headerProps={{
        showSearch: true,
        showNotification: true,
        showCash: true,
        showProfile: true,
        showCompleteProfile: true,
        showMessage: true,
      }}
      style={{
        paddingBottom: "2em",
      }}
      containerStyle={{
        backgroundImage: `url(${Ellipse8})`,
        height: "100vh",
      }}
    >
      {Object.keys(order?.orderDetails).length > 0 ? (
        <Box
          sx={{
            border: "1px solid #FFFFFF",
            background:
              "transparent linear-gradient(119deg, #FFFFFF66 0%, #FFFFFF24 100%) 0% 0% no-repeat padding-box",
            boxShadow: "15px 15px 50px #00000029",
            borderRadius: "24px",
            backgroundColor: "#F2F2F2",
            opacity: "1",
            padding: "3em",
            backdropFilter: "blur(50px)",
            WebkitBackdropFilter: "blur(50px)",
            width: "78%",
            margin: "auto",
            marginTop: "2em",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box sx={{ mr: "auto" }}>
              <Text
                style={{
                  color: "#000000",
                  font: "normal normal 600 26px/36px Poppins",
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
              >
                Order Detail
              </Text>
              <Text
                style={{
                  color: "#000000",
                  font: "normal normal normal 12px/26px Poppins",
                  opacity: "0.5",
                  marginTop: "-.5em",
                  fontSize: "14px",
                }}
              >
                Lorem Ipsum is simply dummy text of the printing and type
                setting industry.
              </Text>
            </Box>
            <Text
              style={{
                color: "#2C80FF",
                font: "normal normal 600 17px/26px Poppins",
                margin: "auto 0px",
                fontSize: "16px",
              }}
            >
              Back
            </Text>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "row", marginTop: "1em" }}>
            <Box
              sx={{
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                borderRadius: "16px",
                height: "auto",
                width: "47%",
                margin: "1em auto",
                padding: "1.6em",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    color: "#000000",
                    fontWeight: 600,
                    fontSize: "15px",
                    fontFamily: "Poppins",
                  }}
                >
                  {details.address} {details.zipCode}
                </Text>
                <Text
                  style={{
                    color: "#000000",
                    fontSize: "15px",
                    fontFamily: "Poppins",
                    marginLeft: ".7em",
                    marginTop: "4px",
                    marginRight: "auto",
                  }}
                >
                  {details.state}
                </Text>

                <Box
                  sx={{
                    width: "auto",
                    backgroundColor: "#000000 ",
                    borderRadius: "15px",
                    bottom: 10,
                    left: "13%",
                    color: "#FFFFFF",
                    padding: "7px 8px",
                    textAlign: "center",
                    justifyContent: "center",
                    opacity: "1",
                    display: "flex",
                  }}
                >
                  <span
                    style={{
                      fontSize: 11,
                      fontFamily: "Poppins",
                      fontWeight: 600,
                    }}
                  >
                    {details.property?.propertyType}
                  </span>
                </Box>
                {type === "completed" && (
                  <Box
                    sx={{
                      width: "auto",
                      backgroundColor: "#00C165",
                      borderRadius: "15px",
                      bottom: 10,
                      left: "13%",
                      color: "#FFFFFF",
                      padding: "7px 8px",
                      textAlign: "center",
                      justifyContent: "center",
                      opacity: "1",
                      display: "flex",
                      ml: ".5em",
                    }}
                  >
                    <span
                      style={{
                        fontSize: 11,
                        fontFamily: "Poppins",
                        fontWeight: 600,
                      }}
                    >
                      Completed
                    </span>
                  </Box>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  m: "1em 0",
                }}
              >
                <HomeLocation
                  color="#2593D6"
                  fontSize={20}
                  style={{
                    marginRight: "10px",
                    marginTop: "4px",
                    marginBottom: "auto",
                  }}
                />

                <Text
                  style={{
                    fontSize: "14px",
                    fontFamily: "Poppins",
                    color: "#000000",
                  }}
                >
                  Property ID: #{details.property?.propertyId}
                </Text>
                <ShoppingBag
                  color="#2593D6"
                  fontSize={20}
                  style={{
                    marginRight: "10px",
                    marginTop: "4px",
                    marginBottom: "auto",
                    marginLeft: "1.2em",
                  }}
                />

                <Text
                  style={{
                    fontSize: "14px",
                    fontFamily: "Poppins",
                    color: "#000000",
                  }}
                >
                  Order ID: #{details.orderId}
                </Text>
                <RoofHomeGraph
                  color="#2593D6"
                  fontSize={20}
                  style={{
                    marginRight: "10px",
                    marginTop: "4px",
                    marginBottom: "auto",
                    marginLeft: "1.2em",
                  }}
                />
                <Text
                  style={{
                    fontSize: "14px",
                    fontFamily: "Poppins",
                    color: "#000000",
                    marginRight: "1.2em",
                  }}
                >
                  {details.property?.propertyType}
                </Text>
                {type !== "new" && (
                  <CoinDollar
                    color="#2593D6"
                    fontSize={20}
                    style={{
                      marginRight: "10px",
                      marginTop: "8px",
                      marginBottom: "auto",
                    }}
                  />
                )}
                {type !== "new" && (
                  <Text
                    style={{
                      fontSize: "14px",
                      fontFamily: "Poppins",
                      color: "#000000",
                      marginTop: "8px",
                      marginRight: "1.2em",
                    }}
                  >
                    {details.totalCost}
                  </Text>
                )}
                <ClockCircle
                  color="#2593D6"
                  fontSize={20}
                  style={{
                    marginRight: "10px",
                    marginTop: "8px",
                    marginBottom: "auto",
                  }}
                />

                <Text
                  style={{
                    fontSize: "14px",
                    fontFamily: "Poppins",
                    color: "#000000",
                    marginTop: "8px",
                  }}
                >
                  {moment(details.dueDate).format("DD/MM/YY")}
                </Text>
                {type === "scheduled" && (
                  <Calendar
                    color="#2593D6"
                    fontSize={20}
                    style={{
                      marginRight: "10px",
                      marginTop: "8px",
                      marginBottom: "auto",
                      marginLeft: "1.2em",
                    }}
                  />
                )}
                {type === "scheduled" && (
                  <Text
                    style={{
                      fontSize: "14px",
                      fontFamily: "Poppins",
                      color: "#000000",
                      marginTop: "8px",
                    }}
                  >
                    Schedule Date:{" "}
                    {moment(details.scheduleDate).format("DD/MM/YY HH:mm")}
                  </Text>
                )}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  borderBottom: "1px solid lightgray",
                  pb: "1em",
                  mb: "1em",
                }}
              >
                <Box sx={{ width: "30%" }}>
                  <Text
                    style={{
                      fontSize: "13px",
                      fontFamily: "Poppins",
                      color: "#000000",
                      opacity: "0.5",
                      font: "normal normal normal 12px/18px Poppins",
                    }}
                  >
                    Property Type
                  </Text>
                  <Text
                    style={{
                      fontSize: "13.5px",
                      fontFamily: "Poppins",
                      color: "#000000",
                      marginTop: "8px",
                      fontWeight: 600,
                    }}
                  >
                    {details.property?.propertyType}
                  </Text>
                </Box>
                <Box sx={{ width: "40%", mr: "auto" }}>
                  <Text
                    style={{
                      fontSize: "13px",
                      fontFamily: "Poppins",
                      color: "#000000",
                      opacity: "0.5",
                      font: "normal normal normal 12px/18px Poppins",
                    }}
                  >
                    Purpose of the Appraisal
                  </Text>
                  <Text
                    style={{
                      fontSize: "13.5px",
                      fontFamily: "Poppins",
                      color: "#000000",
                      marginTop: "8px",
                      fontWeight: 600,
                    }}
                  >
                    {details.reasonForAppraisal}
                  </Text>
                </Box>

                {getButtonTypes()}
              </Box>
              <Text
                style={{
                  fontSize: "13.5px",
                  fontFamily: "Poppins",
                  color: "#000000",
                  marginTop: "8px",
                  fontWeight: 600,
                }}
              >
                Images
              </Text>
              {details?.property?.images.length > 0 && (
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  {details?.property?.images.map((IImg, index) => (
                    <Box
                      key={index}
                      sx={{
                        width: "148px",
                        height: "152px",
                        background: " #F9F9F9 0% 0% no-repeat padding-box",
                        borderRadius: "16px",
                        m: "1em .5em",
                        p: ".5em",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={IImg}
                        alt={"Image" + (index + 1)}
                        style={{
                          width: "100%",
                          height: "60%",
                          borderRadius: "16px",
                          objectFit: "cover",
                        }}
                      />
                      <Text
                        style={{
                          fontSize: "12px",
                          fontFamily: "Poppins",
                          color: "#000000",
                          marginTop: "8px",
                          fontWeight: 600,
                          textAlign: "center",
                        }}
                      >
                        {IImg}
                      </Text>
                      <Text
                        style={{
                          fontSize: "10.5px",
                          fontFamily: "Poppins",
                          color: "#000000",
                          opacity: "0.5",
                          marginTop: "-2px",
                          font: "normal normal normal 9px/18px Poppins",
                        }}
                      ></Text>
                    </Box>
                  ))}
                </Box>
              )}
              <Text
                style={{
                  fontSize: "13.5px",
                  fontFamily: "Poppins",
                  color: "#000000",
                  marginTop: "8px",
                  fontWeight: 600,
                }}
              >
                Documents
              </Text>
              {details?.property?.documents.length > 0 && (
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  {details?.property?.documents.map((IImg, index) => (
                    <Box
                      key={index}
                      sx={{
                        width: "148px",
                        height: "152px",
                        background: " #F9F9F9 0% 0% no-repeat padding-box",
                        borderRadius: "16px",
                        m: "1em .5em",
                        p: ".5em",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Box
                        sx={{
                          background: "#edf2f9 0% 0% no-repeat padding-box",
                          width: "100%",
                          height: "60%",
                          borderRadius: "16px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <FileWrittenBent
                          fontSize={40}
                          style={{
                            color: "#2c80ff",
                          }}
                        />
                      </Box>
                      <Text
                        style={{
                          fontSize: "12px",
                          fontFamily: "Poppins",
                          color: "#000000",
                          marginTop: "8px",
                          textAlign: "center",
                          fontWeight: 600,
                        }}
                      >
                        {IImg}
                      </Text>
                      <Text
                        style={{
                          fontSize: "10.5px",
                          fontFamily: "Poppins",
                          color: "#000000",
                          opacity: "0.5",
                          marginTop: "-2px",
                          font: "normal normal normal 9px/18px Poppins",
                        }}
                      >
                        98.04 KB
                      </Text>
                    </Box>
                  ))}
                </Box>
              )}
            </Box>

            <Box
              sx={{
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                borderRadius: "16px",
                height: "auto",
                width: "44%",
                margin: "1em 0 1em 1em",
                padding: "1.6em",
              }}
            >
              <Text
                style={{
                  fontSize: "13.5px",
                  fontFamily: "Poppins",
                  color: "#000000",
                  marginTop: "8px",
                  fontWeight: 600,
                }}
              >
                Activities
              </Text>
              {order.activities?.map((activity, index) => (
                <ActivityCard key={index} item={activity} />
              ))}
            </Box>
          </Box>
        </Box>
      ) : (
        <CircularProgress
          style={{ position: "absolute", top: "50%", left: "50%" }}
        />
      )}
      {showCounter && (
        <CreateCounter
          handleClose={() => setshowCounter(false)}
          onSubmit={(data) => onCreateCounter(data)}
        />
      )}
      {showDecline && (
        <Decline
          handleClose={() => setshowDecline(false)}
          onCreateCounter={() => setshowCounter(true)}
          onDecline={(reason) => {
            onDecline(reason);
          }}
        />
      )}
      {showMessage && (
        <MessageModal
          item={details}
          handleClose={() => setshowMessage(false)}
        />
      )}
      {showSchedule && (
        <ScheduleNow
          item={details}
          handleClose={() => setshowSchedule(false)}
        />
      )}

      {makePrompt.open && (
        <Prompt
          open={makePrompt.open}
          message={makePrompt.message}
          type={makePrompt.type}
          onClose={() => setmakePrompt({ ...makePrompt, open: false })}
        />
      )}
    </Template>
  );
};

export default OrderDetail;
