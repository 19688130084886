import React, { useState } from "react";
import { Box } from "@mui/material";
import Text from "./Core/Text";
import FileUploader from "./FileUploader";
import Button from "./Core/Button";

const Document = ({ handleChange, handleNext, handleBack, isUpdate }) => {
  const [documents, setdocuments] = useState(null);
  return (
    <Box
      sx={{
        width: "66%",
        ml: isUpdate ? 0 : "auto",
        mr: "auto",
      }}
    >
      <Text
        variant="h1"
        style={{
          color: "#E6E6E6",
          fontSize: "48px",
        }}
      >
        Documents
      </Text>
      <Text
        variant="body1"
        style={{
          font: "normal normal medium 14px/21px Poppins",
          color: "#656a72",
          fontSize: "13px",
        }}
      >
        Lorem Ipsum is simply dummy text of the printing and type setting
        industry.
      </Text>
      <Box
        sx={{
          mt: "1.8em",
          mb: "1.2em",
        }}
      >
        <FileUploader
          handleChange={(file) => {
            setdocuments({
              ...documents,
              insurance: file[0],
            });
            handleChange("insurance", file);
          }}
          label="Insurance"
          isOptional
        />
        {documents?.insurance && (
          <span
            style={{
              fontSize: 13,
              marginTop: 4,
            }}
          >
            {documents?.insurance?.name}
          </span>
        )}
      </Box>

      <Box
        sx={{
          mt: "1.2em",
          mb: "1.2em",
        }}
      >
        <FileUploader
          label="W9 "
          isOptional
          handleChange={(file) => {
            handleChange("w9", file);
            setdocuments({
              ...documents,
              w9: file[0],
            });
          }}
        />
        {documents?.w9 && (
          <span
            style={{
              fontSize: 13,
              marginTop: 4,
            }}
          >
            {documents?.w9?.name}
          </span>
        )}
      </Box>
      <Box
        sx={{
          mt: "1.2em",
          mb: "1.2em",
        }}
      >
        <FileUploader
          label="Resume"
          isOptional
          handleChange={(file) => {
            handleChange("resume", file);
            setdocuments({
              ...documents,
              resume: file[0],
            });
          }}
        />
        {documents?.resume && (
          <span
            style={{
              fontSize: 13,
              marginTop: 4,
            }}
          >
            {documents?.resume?.name}
          </span>
        )}
      </Box>
      <Box
        sx={{
          mt: "1.2em",
          mb: "1.2em",
        }}
      >
        <FileUploader
          label="Certifications & Licenses"
          isOptional
          handleChange={(file) => {
            handleChange("certificate", file);
            setdocuments({
              ...documents,
              certificate: file[0],
            });
          }}
        />
        {documents?.certificate && (
          <span
            style={{
              fontSize: 13,
              marginTop: 4,
            }}
          >
            {documents?.certificate?.name}
          </span>
        )}
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", mt: "1em" }}>
        {handleBack && (
          <Button
            onClick={handleBack}
            isTransparent
            style={{
              width: "30%",
              paddingTop: ".2em",
              paddingBottom: ".2em",
              marginRight: ".5em",
            }}
          >
            Back
          </Button>
        )}
        {handleNext && (
          <Button
            onClick={handleNext}
            style={{
              width: "30%",
              marginTop: ".1em",
            }}
          >
             {isUpdate ? "Udpate" : "Save"}  & Continue
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default Document;
