import React from "react";
import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputBase from "@mui/material/InputBase";
import { Box } from "@mui/material";

const BootstrapInput = styled(InputBase)(({ theme, isTransparent, style }) => ({
  background: isTransparent
    ? "transparent"
    : style.backgroundColor
    ? style.backgroundColor
    : "#F2F2F2",
  padding: "8px 14px",
  height: "40px",
  width: "100%",
  borderRadius: "11px",
  display: "flex",
  border: isTransparent ? "1px solid #CCCCCC" : "none",
  flexDirection: "row",
  "& .MuiInputBase-input": {
    fontSize: 14,
    font: "normal normal medium 14px Poppins",
    color: "rgba(101, 106, 114, 1)",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      '"Poppins"',
    ].join(","),
    "&:focus": {
      background: "rgba(242, 242, 242, 1)",
    },
  },
}));

const SelectInput = ({
  label,
  value,
  name,
  options,
  onChange,
  disabled,
  placeholder,
  style,
  defaultValue,
  isTransparent,
  error,
  isOptional = false,
  haveNone = false,
}) => {
  return (
    <FormControl sx={style ? style : { width: "100%" }} variant="standard">
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        {label && (
          <label
            style={{
              color: "#656A72",
              fontSize: "13px",
              marginBottom: ".4em",
              fontFamily: "Poppins",
            }}
          >
            {label}
          </label>
        )}
        {isOptional && (
          <label
            style={{
              color: "#B8B8B8",
              fontSize: "12px",
              marginBottom: ".4em",
              fontFamily: "Poppins",
              marginLeft: ".5em",
            }}
          >
            (Optional)
          </label>
        )}
      </Box>
      <Select
        name={name}
        disabled={disabled}
        labelId="demo-customized-select-label"
        id="demo-customized-select"
        placeholder={placeholder || "Select"}
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        error={error}
        style={{ width: "100%" }}
        input={<BootstrapInput isTransparent={isTransparent} style={style} />}
      >
        <MenuItem value="" disabled>
          {placeholder || "Select"}
        </MenuItem>
        {haveNone && (
          <MenuItem value="">
            <em>Select</em>
          </MenuItem>
        )}
        {options.map((option, i) => (
          <MenuItem
            key={i}
            onClick={() => onChange(option.value)}
            value={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {error && (
        <p
          style={{
            color: "orangered",
            fontSize: ".7em",
          }}
          className="error"
        >
          {error}
        </p>
      )}
    </FormControl>
  );
};

export default SelectInput;
