import { Box } from "@mui/material";
import React, { useRef, useState } from "react";
import { ReactSketchCanvas } from "react-sketch-canvas";
import Button from "./Core/Button";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { getFile, getSketch, getSketches, updateFile } from "../Actions/report";
import Prompt from "./Core/Prompt";

const styles = {
  borderRadius: "0.5rem",
};

const Sketch = () => {
  const [isLoading, setisLoading] = useState(false);
  const [makePrompt, setmakePrompt] = useState({
    open: false,
    message: "",
    type: "error",
  });
  const dispatch = useDispatch();
  const location = useLocation();
  const canvasRef = useRef(null);

  const handleSave = () => {
    setisLoading(true);
    canvasRef?.current
      ?.exportImage()
      .then((result) => {
        const form = new FormData();
        form.append("file", Base64ToFile(result, "sketch.png"));
        form.append("type", "sketch");
        dispatch(updateFile(location.state.id, "sketch", form))
          .then((result) => {
            setisLoading(false);
            dispatch(getSketches(location.state.id));
            setmakePrompt({
              open: true,
              message: "Sketch saved successfully",
              type: "success",
            });
          })
          .catch((err) => {
            setisLoading(false);
            setmakePrompt({
              open: true,
              message: err.response.data.error || "Some error occured",
              type: "error",
            });
          });
      })
      .catch((err) => {
        setisLoading(false);
        console.log(err.response.data.error, err.response.data);
        setmakePrompt({
          open: true,
          message: err.response.data.error || "Some error occured",
          type: "error",
        });
      });
  };

  function Base64ToFile(base64String, filename) {
    const mimeString = base64String.split(",")[0].split(":")[1].split(";")[0];
    const byteString = atob(base64String.split(",")[1]);

    const ab = new ArrayBuffer(byteString.length);

    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([ab], { type: mimeString });
    const file = new File([blob], filename, { type: mimeString });

    return file;
  }

  return (
    <Box>
      <ReactSketchCanvas
        style={styles}
        ref={canvasRef}
        width="100%"
        height="70vh"
        strokeWidth={2}
        strokeColor="#000000"
        backgroundImage="https://upload.wikimedia.org/wikipedia/commons/7/70/Graph_paper_scan_1600x1000_%286509259561%29.jpg"
      />
      <Button
        onClick={handleSave}
        isLoading={isLoading}
        style={{
          width: "140px",
          margin: "1em 0",
        }}
      >
        Save
      </Button>
      <Prompt
        open={makePrompt.open}
        message={makePrompt.message}
        type={makePrompt.type}
        handleClose={() =>
          setmakePrompt({ open: false, message: "", type: "error" })
        }
      />
    </Box>
  );
};

export default Sketch;
