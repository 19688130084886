import { Box } from "@mui/material";
import React, { useState } from "react";
import Text from "./Core/Text";
import Input from "./Core/Input";
import FileUploader from "./FileUploader";
import Button from "./Core/Button";
import Select from "./Core/Select";

const AppraiserQualification = ({
  handleNext,
  handleBack,
  handleChange,
  data,
  errors,
}) => {
  const [documents, setdocuments] = useState(null);

  return (
    <Box
      sx={{
        width: "66%",
        ml: "auto",
        mr: "auto",
      }}
    >
      <Text
        variant="h1"
        style={{
          color: "#E6E6E6",
          fontSize: "48px",
        }}
      >
        Registration Agreement
      </Text>
      <Text
        variant="body1"
        style={{
          font: "normal normal medium 14px/21px Poppins",
          color: "#656a72",
          fontSize: "12.5px",
        }}
      >
        Lorem Ipsum is simply dummy text of the printing and type setting
        industry.
      </Text>
      <Box sx={{ mt: "2em", display: "flex", flexDirection: "row" }}>
        <Input
          label="Years Experience"
          placeholder="Enter here"
          value={data.experience}
          onChange={(e) => handleChange("experience", e.target.value)}
          error={errors.experience || ""}
          style={{ width: "48%", marginRight: "10px" }}
        />
        <Input
          label="Designation"
          placeholder="Enter here"
          style={{ width: "48%", marginRight: "10px" }}
          value={data.designation}
          onChange={(e) => handleChange("designation", e.target.value)}
          error={errors.designation || ""}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          m: "1em 0",
        }}
      >
        <Box sx={{ width: "50%" }}>
          <Text
            style={{
              font: "normal normal 600 22px/33px Poppins",
              color: "#BFBFBF",
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            Commercial Approved
          </Text>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            <input
              type="radio"
              name="basement"
              value="Yes"
              style={{ margin: "7px 10px auto 0" }}
              onChange={(e) => handleChange("commercialApproved", "true")}
            />
            <label for="Yes">Yes</label>
            <input
              type="radio"
              name="basement"
              value="No"
              onChange={(e) => handleChange("commercialApproved", "false")}
              style={{ margin: "7px 10px auto 2em" }}
            />
            <label for="No">No</label>
          </Box>
        </Box>
        <Box sx={{ width: "50%" }}>
          <Text
            style={{
              font: "normal normal 600 22px/33px Poppins",
              color: "#BFBFBF",
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            FHA Approved
          </Text>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            <input
              type="radio"
              name="fhaApproved"
              value="Yes"
              onChange={(e) => handleChange("FHAApproved", "true")}
              style={{ margin: "7px 10px auto 0" }}
            />
            <label for="Yes">Yes</label>
            <input
              type="radio"
              name="fhaApproved"
              value="No"
              onChange={(e) => handleChange("FHAApproved", "false")}
              style={{ margin: "7px 10px auto 2em" }}
            />
            <label for="No">No</label>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        <Box sx={{ width: "50%" }}>
          <span
            style={{
              color: "#656A72",
              fontSize: "12.5px",
              fontFamily: "Poppins",
              marginBottom: "1em",
            }}
          >
            Resume
          </span>
          <Box sx={{ mt: ".8em" }}>
            <FileUploader
              showSelectedFiles={true}
              handleChange={(file) => {
                handleChange("resume", file);
                setdocuments({
                  ...documents,
                  resume: file[0],
                });
              }}
              uploaderLabel={"Upload File"}
            />
            {documents?.resume && (
              <span
                style={{
                  fontSize: 13,
                  marginTop: 4,
                }}
              >
                {documents?.resume?.name}
              </span>
            )}
          </Box>
        </Box>
        <Box sx={{ width: "50%" }}>
          <span
            style={{
              color: "#656A72",
              fontSize: "12.5px",
              fontFamily: "Poppins",
              marginBottom: "1em",
            }}
          >
            Background Check
          </span>
          <Box sx={{ mt: ".8em" }}>
            <FileUploader
              handleChange={(file) => {
                handleChange("backgroundCheck", file);
                setdocuments({
                  ...documents,
                  backgroundCheck: file[0],
                });
              }}
              uploaderLabel={"Upload File"}
            />
            {documents?.backgroundCheck && (
              <span
                style={{
                  fontSize: 13,
                  marginTop: 4,
                }}
              >
                {documents?.backgroundCheck?.name}
              </span>
            )}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          background: "#F2F2F2",
          padding: "1.6em",
          marginTop: "1em",
          borderRadius: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            borderBottom: "2px solid #E3E3E3",
            paddingBottom: "1em",
            marginBottom: "1em",
          }}
        >
          <span
            style={{
              color: "#656A72",
              fontSize: "12.5px",
              fontFamily: "Poppins",
              width: "80%",
            }}
          >
            To Your Knowledge are you or any appraiser associated with the firm
            included any government agency ,Fannie mae, Freddie mac or lender
            exclusionary List?
          </span>

          <Box sx={{ width: "40%", ml: "1em" }}>
            <Select
              style={{
                width: "100%",
                borderRadius: "8px",
                backgroundColor: "#fff",
              }}
              options={[
                { value: "true", label: "Yes" },
                { value: "false", label: "No" },
              ]}
              value={data.governmentAgencyIncludeCheck || ""}
              onChange={(option) => {
                handleChange("governmentAgencyIncludeCheck", option);
              }}
              error={errors.governmentAgencyIncludeCheck || ""}
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            borderBottom: "2px solid #E3E3E3",
            paddingBottom: "1em",
            marginBottom: "1em",
          }}
        >
          <span
            style={{
              color: "#656A72",
              fontSize: "12.5px",
              fontFamily: "Poppins",
              width: "80%",
            }}
          >
            Have you have any disciplinary actions against you or your company
            from a state or federal licensing board as it relates to your
            appraisal license or appraisals performed ?
          </span>

          <Box sx={{ width: "40%", ml: "1em" }}>
            <Select
              style={{
                width: "100%",
                borderRadius: "8px",
                backgroundColor: "#fff",
              }}
              options={[
                { value: "true", label: "Yes" },
                { value: "false", label: "No" },
              ]}
              value={data.disciplinaryActionAgainstUserCheck || ""}
              onChange={(option) => {
                handleChange("disciplinaryActionAgainstUserCheck", option);
              }}
              error={errors.disciplinaryActionAgainstUserCheck || ""}
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            borderBottom: "2px solid #E3E3E3",
            paddingBottom: "1em",
            marginBottom: "1em",
          }}
        >
          <span
            style={{
              color: "#656A72",
              fontSize: "12.5px",
              fontFamily: "Poppins",
              width: "80%",
            }}
          >
            With in the last 10 years have you been the subject of any
            disciplinary or corrective action by an appraiser organization State
            licensing board or other regulatory Body of a governmental entity as
            a result of your appraiser activities?
          </span>

          <Box sx={{ width: "40%", ml: "1em" }}>
            <Select
              style={{
                width: "100%",
                borderRadius: "8px",
                backgroundColor: "#fff",
              }}
              options={[
                { value: "true", label: "Yes" },
                { value: "false", label: "No" },
              ]}
              value={
                data.subjectOfAnyCorrectiveActionByAppraiserCheck || ""
              }
              onChange={(option) => {
                handleChange(
                  "subjectOfAnyCorrectiveActionByAppraiserCheck",
                  option
                );
              }}
              error={errors.subjectOfAnyCorrectiveActionByAppraiserCheck || ""}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            borderBottom: "2px solid #E3E3E3",
            paddingBottom: "1em",
            marginBottom: "1em",
          }}
        >
          <span
            style={{
              color: "#656A72",
              fontSize: "12.5px",
              fontFamily: "Poppins",
              width: "80%",
            }}
          >
            Have you been notified of any investigation or review open at this
            time by any appraiser Organization State likening board or other
            regulatory body of governmental entity?
          </span>

          <Box sx={{ width: "40%", ml: "1em" }}>
            <Select
              style={{
                width: "100%",
                borderRadius: "8px",
                backgroundColor: "#fff",
              }}
              options={[
                { value: "true", label: "Yes" },
                { value: "false", label: "No" },
              ]}
              value={data.notifiedOfAnyInvestigationByBoardCheck || ""}
              onChange={(option) => {
                handleChange("notifiedOfAnyInvestigationByBoardCheck", option);
              }}
              error={errors.notifiedOfAnyInvestigationByBoardCheck || ""}
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            borderBottom: "2px solid #E3E3E3",
            paddingBottom: "1em",
            marginBottom: "1em",
          }}
        >
          <span
            style={{
              color: "#656A72",
              fontSize: "12.5px",
              fontFamily: "Poppins",
              width: "80%",
            }}
          >
            Have you ever been convicted of a felony or arrested, indicated or
            changed with felonious misconduct, If yes please provide written
            narrative of events?
          </span>

          <Box sx={{ width: "40%", ml: "1em" }}>
            <Select
              style={{
                width: "100%",
                borderRadius: "8px",
                backgroundColor: "#fff",
              }}
              options={[
                { value: "true", label: "Yes" },
                { value: "false", label: "No" },
              ]}
              value={data.convictedOfFelonyCheck || ""}
              onChange={(option) => {
                handleChange("convictedOfFelonyCheck", option);
              }}
              error={errors.convictedOfFelonyCheck || ""}
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            borderBottom: "2px solid #E3E3E3",
            paddingBottom: "1em",
            marginBottom: "1em",
          }}
        >
          <span
            style={{
              color: "#656A72",
              fontSize: "12.5px",
              fontFamily: "Poppins",
              width: "80%",
            }}
          >
            In the last 10 years have any lawsuit or claim(Including notice of
            potential claim) been made or field against you this includes
            lawsuit or claim, regardless if they were tendered to to an
            insurance company of coverage.
          </span>

          <Box sx={{ width: "40%", ml: "1em" }}>
            <Select
              style={{
                width: "100%",
                borderRadius: "8px",
                backgroundColor: "#fff",
              }}
              options={[
                { value: "false", label: "No" },
                { value: "true", label: "Yes" },
              ]}
              value={data.lawsuitOrClaimFieldCheck || ""}
              onChange={(option) => {
                handleChange("lawsuitOrClaimFieldCheck", option);
              }}
              error={errors.lawsuitOrClaimFieldCheck || ""}
            />
          </Box>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <span
            style={{
              color: "#656A72",
              fontSize: "12.5px",
              fontFamily: "Poppins",
              width: "80%",
            }}
          >
            Are you aware of any circumstances that may lead to the filling of a
            lawsuit or claim against you?
          </span>

          <Box sx={{ width: "40%", ml: "1em" }}>
            <Select
              style={{
                width: "100%",
                borderRadius: "8px",
                backgroundColor: "#fff",
              }}
              options={[
                { value: "false", label: "No" },
                { value: "true", label: "Yes" },
              ]}
              value={data.awarenessOfAnyLawsuitOrClaimFieldCheck || ""}
              onChange={(option) => {
                handleChange("awarenessOfAnyLawsuitOrClaimFieldCheck", option);
              }}
              error={errors.lawsuitOrClaimFieldCheck || ""}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", mt: "1em" }}>
        {handleBack && (
          <Button
            isTransparent
            style={{
              width: "30%",
              paddingTop: ".2em",
              paddingBottom: ".2em",
              marginRight: ".5em",
            }}
            onClick={handleBack}
          >
            Back
          </Button>
        )}
        {handleNext && (
          <Button
            style={{
              width: "30%",
              paddingTop: ".1em",
              paddingBottom: ".1em",
            }}
            onClick={handleNext}
          >
            Save & Continue
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default AppraiserQualification;
