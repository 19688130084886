import { Box } from "@mui/material";
import React, { useState } from "react";
import Text from "./Core/Text";
import FourDigitInput from "./FourDigitInput";
import Button from "./Core/Button";
import { useDispatch, useSelector } from "react-redux";
import { isuserLoggedIn, otpVerification } from "../Actions/auth";
import Prompt from "./Core/Prompt";
import { Navigate } from "react-router";

const RegisterComplete = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [isLoading, setisLoading] = useState(false);
  const [otp, setotp] = useState("");
  const [makeAlert, setmakeAlert] = useState({
    open: false,
    message: "",
    type: "",
  });

  const handleSubmit = () => {
    setisLoading(true);
    const data = {
      otp,
      email: auth.user.email,
    };
    dispatch(otpVerification(data))
      .then((result) => {
        localStorage.clear();
        dispatch(isuserLoggedIn());
        setmakeAlert({
          open: true,
          message: "Otp Verified Successfully, Redirecting to login page...",
          type: "success",
        });
        setTimeout(() => {
          setisLoading(false);
          window.location.href = "/";
        }, 2000);
      })
      .catch((err) => {
        setisLoading(false);
        setmakeAlert({
          open: true,
          message: err.response.data.error || "Some error occured",
          type: "error",
        });
      });
  };

  return (
    <Box
      sx={{
        width: "66%",
        ml: "auto",
        mr: "auto",
      }}
    >
      <Text
        variant="h1"
        style={{
          color: "#E6E6E6",
          fontSize: "48px",
        }}
      >
        Registration Completed
      </Text>
      <Text
        variant="body1"
        style={{
          font: "normal normal medium 14px/21px Poppins",
          color: "#656a72",
          fontSize: "13px",
        }}
      >
        Lorem Ipsum is simply dummy text of the printing and type setting
        industry.
      </Text>
      <Box
        sx={{
          display: "flex",
          width: "60%",
          ml: "auto",
          mr: "auto",
          mt: "2em",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <p
          style={{
            fontWeight: "600",
            fontSize: "14px",
            marginBottom: "1em",
          }}
        >
          We have sent verification code to your email.
        </p>
        <FourDigitInput
          onChange={(e) => {
            setotp(e);
          }}
        />
        <Button
          isLoading={isLoading}
          onClick={handleSubmit}
          style={{ width: "60%" }}
        >
          Submit
        </Button>
        <p
          style={{
            font: " normal normal normal 12px Poppins",
            color: "#656a72",
            marginTop: "2em",
            fontSize: "12px !important",
          }}
        >
          Didn’t receive the code?
          <span
            style={{
              color: "rgba(44, 128, 255, 1)",
              cursor: "pointer",
              fontWeight: "600",
              marginLeft: ".5em",
            }}
          >
            Contact Us
          </span>
        </p>
      </Box>
      <Prompt
        open={makeAlert.open}
        message={makeAlert.message}
        type={makeAlert.type}
        handleClose={() => {
          setmakeAlert({ ...makeAlert, open: false });
        }}
      />
    </Box>
  );
};

export default RegisterComplete;
