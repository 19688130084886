import { Box } from "@mui/material";
import React from "react";
import Arc from "../Assets/Images/Arc.jpg";
import Text from "./Core/Text";
import Button from "./Core/Button";
import moment from "moment";
import {
  ClockCircle,
  CoinDollar,
  RoofHomeGraph,
  ShoppingBag,
} from "react-huge-icons/outline";

const LayoutCard = ({ item }) => {
  const { order } = item;
  const getDueStatus = () => {
    const dueDate = moment(item.dueDate);
    const today = moment();
    const daysDifference = moment(dueDate).diff(today, "days");

    if (item.status === "Completed") {
      return {
        color: "#00C165",
        text: "Completed",
      };
    } else if (daysDifference === 0) {
      return {
        color: "#f4b33f",
        text: "Due Today",
      };
    } else if (daysDifference < 0) {
      return {
        color: "#EE1616",
        text: "Late",
      };
    } else {
      return false;
    }
  };

  console.log(order)

  return (
    <Box
      sx={{
        width: "100%",
        height: "fit-content",
        maxHeight: "108px",
        background: "#FFFFFF",
        borderRadius: "15px",
        opacity: "1",
        padding: "4px",
        m: "12px",
        position: "relative",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <input
        type="checkbox"
        style={{
          width: "18px",
          height: "18px",
          borderRadius: "5px",
          margin: "auto 10px auto 10px",
        }}
      />
      <img
        src={Arc}
        alt="Arc"
        style={{
          width: "228px",
          height: "auto",
          borderRadius: "15px",
          opacity: "1",
        }}
      />

      <div
        style={{
          position: "absolute",
          width: "70px",
          backgroundColor: "#000000",
          borderRadius: "15px",
          bottom: 10,
          left: "13%",
          color: "#FFFFFF",
          padding: "7px 8px",
          textAlign: "center",
          justifyContent: "center",
          opacity: "1",
          display: "flex",
        }}
      >
        <span
          style={{
            fontSize: 11,
            fontFamily: "Poppins",
            fontWeight: 600,
          }}
        >
          {item.outing}
        </span>
      </div>
      <Box sx={{ p: "6px 16px" }}>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Text
            style={{
              color: "#000000",
              fontWeight: 600,
              fontSize: "15px",
              fontFamily: "Poppins",
            }}
          >
            {order[0].address} {order[0].zipCode}
          </Text>
          <Text
            style={{
              color: "#000000",
              fontSize: "15px",
              fontFamily: "Poppins",
              marginLeft: "1em",
              marginTop: "4px",
            }}
          >
            {order[0].city}, {order[0].state}
          </Text>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", m: " .7em" }}>
          <ShoppingBag
            color="#2593D6"
            fontSize={18}
            style={{
              marginRight: "10px",
              marginTop: "4px",
              marginBottom: "auto",
            }}
          />

          <Text
            style={{
              fontSize: "13px",
              fontFamily: "Poppins",
              color: "#000000",
            }}
          >
            Order ID: #{order[0].orderId}
          </Text>
          <RoofHomeGraph
            color="#2593D6"
            fontSize={18}
            style={{
              marginRight: "10px",
              marginTop: "4px",
              marginBottom: "auto",
              marginLeft: "1.2em",
            }}
          />
          <Text
            style={{
              fontSize: "13px",
              fontFamily: "Poppins",
              color: "#000000",
            }}
          >
            {order[0].propertyType}
          </Text>
          <CoinDollar
            color="#2593D6"
            fontSize={18}
            style={{
              marginRight: "10px",
              marginTop: "4.4px",
              marginBottom: "auto",
              marginLeft: "1.2em",
            }}
          />
          <Text
            style={{
              fontSize: "13px",
              fontFamily: "Poppins",
              color: "#000000",
            }}
          >
            {order[0].totalCost}
          </Text>
          <ClockCircle
            color="#2593D6"
            fontSize={18}
            style={{
              marginRight: "10px",
              marginTop: "4px",
              marginBottom: "auto",
              marginLeft: "1.2em",
            }}
          />

          <Text
            style={{
              fontSize: "13px",
              fontFamily: "Poppins",
              color: "#000000",
            }}
          >
            {moment(order[0].createdAt).format("DD/MM/YY")}
          </Text>
        </Box>
      </Box>
      {item.hasCounterOffer && (
        <div
          style={{
            width: "1.6px",
            height: "72px",
            backgroundColor: "#D6D6D6",
            margin: "auto .5em",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
      )}
      {item.hasCounterOffer && (
        <Box
          sx={{
            background: "#2593D617 0% 0% no-repeat padding-box",
            borderRadius: "12px",
            width: "16%",
            margin: "1em 0",
            padding: "1em",
          }}
        >
          <Text
            style={{
              fontSize: "13px",
              fontFamily: "Poppins",
              color: "#000000",
              marginTop: "-5px",
            }}
          >
            You have counter Offered for
          </Text>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <CoinDollar
              color="#2593D6"
              fontSize={18}
              style={{
                marginRight: "10px",
                marginTop: "4.4px",
                marginBottom: "auto",
              }}
            />
            <Text
              style={{
                fontSize: "13px",
                fontFamily: "Poppins",
                color: "#000000",
              }}
            >
              {item.price}
            </Text>
            <ClockCircle
              color="#2593D6"
              fontSize={18}
              style={{
                marginRight: "10px",
                marginTop: "4px",
                marginBottom: "auto",
                marginLeft: "1.6em",
              }}
            />

            <Text
              style={{
                fontSize: "13px",
                fontFamily: "Poppins",
                color: "#000000",
              }}
            >
              {item.date}
            </Text>
          </Box>
        </Box>
      )}
      <div
        style={{
          width: "1.6px",
          height: "72px",
          backgroundColor: "#D6D6D6",
          margin: "auto .5em ",
          marginLeft: "auto",
        }}
      />

      <Button
        style={{
          width: "118px",
          height: "36px",
          backgroundColor: "#00C165",
          borderRadius: "11px",
          color: "#FFFFFF",
          fontSize: "12px",
          fontFamily: "Poppins",
          fontWeight: 600,
          margin: "auto 1em",
        }}
      >
        Approve
      </Button>
      <Button
        style={{
          width: "118px",
          height: "36px",
          backgroundColor: "transparent",
          borderRadius: "11px",
          border: " 1px solid #EE1616",
          color: "#EE1616",
          fontSize: "12px",
          fontFamily: "Poppins",
          fontWeight: 600,
          margin: "auto 1em",
        }}
      >
        Decline
      </Button>
      {/* {item.dueDate || item.status === "Completed" ? (
                <div
                    style={{
                        position: "absolute",
                        width: "100px",
                        backgroundColor: getDueStatus().color,
                        bottom: 12,
                        right: -34,
                        color: "#FFFFFF",
                        padding: "7px 12px",
                        textAlign: "center",
                        justifyContent: "center",
                        opacity: "1",
                        display: "flex",
                        transform: "rotate(314deg)",
                    }}
                >
                    <span
                        style={{
                            fontSize: 12,
                            fontFamily: "Poppins",
                            fontWeight: 600,
                        }}
                    >
                        {getDueStatus().text}
                    </span>
                </div>
            ) : null} */}
    </Box>
  );
};

export default LayoutCard;
