import React, { useState } from "react";
import { Box } from "@mui/material";
import Text from "./Core/Text";
import { RemoveThin } from "react-huge-icons/outline";
import Input from "./Core/Input";
import Button from "./Core/Button";
import Select from "./Core/Select";
import { useDispatch } from "react-redux";
import Prompt from "./Core/Prompt";
import { ticketValidationSchema } from "../Validations";
import { createTicket, getTickets } from "../Actions/message";

const CreateTicket = ({ open, handleClose }) => {
  const [title, settitle] = useState("");
  const [subject, setsubject] = useState("");
  const [description, setdescription] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [makePrompt, setmakePrompt] = useState({
    open: false,
    message: "",
    type: "error",
  });
  const dispatch = useDispatch();

  const onCreateTicket = async () => {
    const data = {
      title,
      subject,
      description,
    };
    setisLoading(true);
    try {
      await ticketValidationSchema.validate(data, { abortEarly: false });
      dispatch(createTicket(data))
        .then((result) => {
          dispatch(getTickets());
          setmakePrompt({
            open: true,
            message: "Ticket created successfully",
            type: "success",
          });
          setTimeout(() => {
            setisLoading(false);
            handleClose();
            settitle("");
            setsubject("");
            setdescription("");
          }, 2000);
        })
        .catch((err) => {
          setisLoading(false);
          setmakePrompt({
            open: true,
            message: err.response.data.error || "Ticket creation failed",
            type: "error",
          });
        });
    } catch (validationError) {
      const formattedErrors = {};
      validationError.inner.forEach((error) => {
        formattedErrors[error.path] = error.message;
      });

      setmakePrompt({
        open: true,
        message:
          Object.values(formattedErrors)[0] || "Some of the fields are invalid",
        type: "error",
      });
      setisLoading(false);
    }
  };

  return (
    open && (
      <Box
        className="mainModal"
        sx={{
          zIndex: 1000,
        }}
      >
        <Box
          sx={{
            boxShadow: "0px 10px 20px #0000000D",
            borderRadius: "12px",
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            height: "fit-content",
            width: "560px",
            height: "auto",
            zIndex: 1,
            m: "auto",
            padding: "1em",
            position: "relative",
          }}
        >
          <Text
            style={{
              font: "normal normal bold 17px/26px Poppins",
              color: "#000000",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Create New Ticket
          </Text>
          <Box
            sx={{
              position: "absolute",
              top: 20,
              right: 20,
              borderRadius: "50%",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              boxShadow: `rgba(149, 157, 165, 0.2) 0px 8px 24px`,
              backgroundColor: "#fff",
            }}
          >
            <RemoveThin
              fontSize={28}
              onClick={() => {
                if (!isLoading) {
                  handleClose();
                }
              }}
            />
          </Box>
          <Input
            placeholder="Ticket Title"
            value={title}
            onChange={(e) => settitle(e.target.value)}
            maxLength={50}
          />
          <Select
            label="Select Subject"
            style={{ width: "100%", marginTop: "1em", marginBottom: "1em" }}
            options={[
              { value: "Enter value here", label: "Enter value here" },
              { value: "option2", label: "Option 2" },
              { value: "option3", label: "Option 3" },
            ]}
            value={subject || "Enter value here"}
            onChange={(option) => {
              setsubject(option);
            }}
          />
          <Input
            isMultiline={true}
            placeholder="Ticket Description"
            style={{ height: "200px" }}
            value={description}
            onChange={(e) => setdescription(e.target.value)}
          />
          <Button
            onClick={onCreateTicket}
            isLoading={isLoading}
            style={{ width: "40%", margin: "1em auto 0 auto" }}
            variant="contained"
          >
            Send Message
          </Button>
        </Box>
        {makePrompt.open && (
          <Prompt
            open={makePrompt.open}
            handleClose={() => setmakePrompt({ ...makePrompt, open: false })}
            message={makePrompt.message}
            type={makePrompt.type}
          />
        )}
      </Box>
    )
  );
};

export default CreateTicket;
