import React, { useEffect, useState } from "react";
import Text from "../../Components/Core/Text";
import License from "../../Components/License";
import PersonalInfos from "../../Components/PersonalInfos";
import CompanyInfo from "../../Components/CompanyInfo";
import CoverageArea from "../../Components/CoverageArea";
import Billings from "../../Components/Billings";
import Document from "../../Components/Document";
import DigitalSign from "../../Components/DigitalSign";
import { Box } from "@mui/material";
import logo from "../../Assets/Images/logo.png";
import { AiOutlineCheck } from "react-icons/ai";
import RegisterComplete from "../../Components/RegisterComplete";
import Agreement from "../../Components/Agreement";
import {
  billingSchema,
  companyInfoSchema,
  coverageAreaSchema,
  digitalSignatureSchema,
  documentValidationSchema,
  personalInfoSchema,
  licenseSchema,
  agreementValidationSchema,
  appraiserQualificationValidationSchema,
  insuranceValidationSchema,
} from "../../Validations";
import {
  addBilling,
  addCompany,
  addCoverageArea,
  addDigital,
  addDocument,
  addLicence,
  addPersonal,
  appraiserQualification,
  getBilling,
  getCompany,
  getCoverageArea,
  getDigital,
  getDocument,
  getInsurance,
  getLicense,
  getPersonal,
  getQualification,
  makeInsurance,
  verifyAgreement,
} from "../../Actions/auth";
import { useDispatch, useSelector } from "react-redux";
import Prompt from "../../Components/Core/Prompt";
import AppraiserQualification from "../../Components/AppraiserQualification";
import EoInsurance from "../../Components/EoInsurance";
import { getStates } from "../../Actions/utilsAction";
import { checkKeys, missingKeys } from "../../Utils";
import { licensekeys } from "../../Utils/keys";

const CompleteProfile = () => {
  const dispatch = useDispatch();
  const role = window.localStorage.getItem("role");
  const [activePage, setactivePage] = useState("State License(s)");
  const [licenseData, setlicenseData] = useState({
    isFetched: false,
    data: [
      {
        _id: Date.now(),
        licenseType: "",
        licenseState: "",
        licenseNumber: "",
        imageName: "",
        image: null,
        expiresAt: "",
      },
    ],
  });
  const [personalInfoData, setpersonalInfoData] = useState({});
  const [companyInfoData, setcompanyInfoData] = useState({});
  const [coverageAreaData, setcoverageAreaData] = useState({});
  const [digitalSignData, setdigitalSignData] = useState({});
  const [registerAgree, setregisterAgree] = useState({});
  const [qualification, setqualification] = useState({});
  const [insurance, setinsurance] = useState({});
  const [billingData, setbillingData] = useState({});
  const [documentData, setdocumentData] = useState({});
  const [complatedSigns, setcomplatedSigns] = useState([]);
  const auth = useSelector((state) => state.auth);
  const [errors, setErrors] = useState({});
  const [makePrompt, setmakePrompt] = useState({
    open: false,
    message: "",
    type: "error",
  });

  useEffect(() => {
    const completion = localStorage.getItem("completion");
    if (completion) {
      setactivePage(completion);
      const targetIndex = pageTypeData.findIndex(
        (item) => item.name === completion
      );

      const getAllCompletedPages = pageTypeData
        .slice(0, targetIndex)
        .map((item) => item.name);
      setcomplatedSigns(getAllCompletedPages);
    }
    dispatch(getStates());
  }, [auth]);
  console.log(complatedSigns);

  console.log(licenseData, "licenseData");

  useEffect(() => {
    dispatch(getLicense());
    dispatch(getPersonal());
    dispatch(getCoverageArea());
    dispatch(getDigital());
    dispatch(getCompany());
    dispatch(getBilling());
    dispatch(getDocument());
    dispatch(getInsurance());
    dispatch(getQualification());
  }, []);

  useEffect(() => {
    const { profile } = auth;

    console.log(profile, "profile");

    if (Object.keys(profile).length > 0) {
      if (profile.license) {
        setlicenseData({ data: profile.license });
      }

      if (profile.personals) {
        setpersonalInfoData(profile.personals);
        const { coverageAreaState, coverageAreaCountry, digitalSignature } =
          profile.personals;

        setcoverageAreaData({ coverageAreaState, coverageAreaCountry });
        setdigitalSignData({ digitalSignature });
        setregisterAgree({ registration: profile.personals.agreementAccepted });
      }

      if (profile.company) {
        setcompanyInfoData(profile.company);
      }
      if (profile.billings !== undefined && profile.billings.length > 0) {
        setbillingData(profile.billings[0]);
      }
      if (profile.documents !== undefined && profile.documents.length > 0) {
        setdocumentData(profile.documents[0]);
      }
    }
  }, [auth.profile]);

  const pageTypeData = [
    {
      name: "State License(s)",
      comment:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      schema: licenseSchema,
      data: licenseData,
      dispatch: addLicence,
      next: "Personal Information",
      prev: "State License(s)",
      tags: ["form"],
    },
    {
      name: "Personal Information",
      comment:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      schema: personalInfoSchema,
      data: personalInfoData,
      dispatch: addPersonal,
      next: "Company Information",
      prev: "State License(s)",
    },
    {
      name: "Company Information",
      comment:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      schema: companyInfoSchema,
      data: companyInfoData,
      dispatch: addCompany,
      next: role === "Appraiser" ? "Appraiser Qualification" : "Coverage Area",
      prev: "Personal Information",
    },
    role === "Appraiser" && {
      name: "Appraiser Qualification",
      comment:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      schema: appraiserQualificationValidationSchema,
      data: qualification,
      dispatch: appraiserQualification,
      next: "E&o Insurance",
      prev: "Company Information",
      tags: ["form"],
    },
    role === "Appraiser" && {
      name: "E&o Insurance",
      comment:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      schema: insuranceValidationSchema,
      data: insurance,
      dispatch: makeInsurance,
      next: "Coverage Area",
      prev: "Appraiser Qualification",
      tags: ["form"],
    },
    {
      name: "Coverage Area",
      comment:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      schema: coverageAreaSchema,
      data: coverageAreaData,
      dispatch: addCoverageArea,
      next: "Digital Signature",
      prev: role === "Appraiser" ? "E&o Insurance" : "Company Information",
    },
    {
      name: "Digital Signature",
      comment:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      schema: digitalSignatureSchema,
      data: digitalSignData,
      dispatch: addDigital,
      next: "Billing",
      prev: "Coverage Area",
      tags: ["form"],
    },
    {
      name: "Billing",
      comment:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      schema: billingSchema,
      data: billingData,
      dispatch: addBilling,
      next: "Document",
      prev: "Digital Signature",
    },
    {
      name: "Document",
      comment:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      schema: documentValidationSchema,
      data: documentData,
      dispatch: addDocument,
      next: "Registration Agreement",
      prev: "Billing",
      tags: ["form"],
    },
    {
      name: "Registration Agreement",
      comment:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      schema: agreementValidationSchema,
      data: registerAgree,
      dispatch: verifyAgreement,
      next: "Complete",
      prev: "Document",
    },
    {
      name: "Complete",
      comment:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      next: "Complete",
      prev: "Registration Agreement",
    },
  ];

  const handleChange = (name, value) => {
    const dataMap = {
      "State License(s)": setlicenseData,
      "Personal Information": setpersonalInfoData,
      "Company Information": setcompanyInfoData,
      "Coverage Area": setcoverageAreaData,
      "Digital Signature": setdigitalSignData,
      "Appraiser Qualification": setqualification,
      "E&o Insurance": setinsurance,
      Billing: setbillingData,
      Document: setdocumentData,
      "Registration Agreement": setregisterAgree,
      Complete: "Complete",
    };

    console.log(documentData, "documentdata");

    const setData = dataMap[activePage];
    if (setData) {
      setData((prevData) => ({ ...prevData, [name]: value }));
    } else {
      console.warn("Unsupported activePage:", activePage);
    }
  };

  const handleNext = async () => {
    try {
      const currentPage = pageTypeData.find((item) => item.name === activePage);
      console.log(currentPage, "currentPage");
      // if (!auth.fetchedItems.includes(currentPage.name)) {
      if (currentPage) {
        await currentPage.schema.validate(currentPage.data, {
          abortEarly: false,
        });
        if (
          currentPage.tags !== undefined &&
          currentPage.tags.includes("form")
        ) {
          const form = new FormData();
          if (activePage === "Digital Signature") {
            form.append("signature", currentPage.data.signature[0]);
          }

          if (activePage === "Document") {
            if (currentPage.data.length == 0) {
              setcomplatedSigns([...complatedSigns, activePage]);
            }
            const keys = [];
            const values = [];
            for (let key in currentPage.data) {
              if (currentPage.data.hasOwnProperty(key)) {
                keys.push(key);
              }
            }
            keys.forEach((key) => {
              values.push(...currentPage.data[key]);
              form.append(key, values[0]);
            });
          }

          if (activePage === "State License(s)") {
            const constructData = [];
            const images = [];
            if (
              currentPage.data?.data !== undefined &&
              currentPage.data?.data.length > 0
            ) {
              currentPage.data?.data.forEach((item) => {
                const data = {
                  licenseType: item.licenseType,
                  licenseState: item.licenseState,
                  licenseNumber: item.licenseNumber,
                  expiresAt: item.expiresAt,
                  imageName: item.imageName,
                };
                constructData.push(data);
                images.push(item.image);
              });
            }
            const jsonData = JSON.stringify(constructData);
            for (const img of images) {
              form.append("images", img);
            }
            form.append("data", jsonData);
          }

          if (activePage === "E&o Insurance") {
            form.append("companyName", currentPage.data.companyName);
            form.append("policyNumber", currentPage.data.policyNumber);
            form.append("claimLimit", currentPage.data.claimLimit);
            form.append("expiresAt", currentPage.data.expiresAt);
            form.append("document", currentPage.data.document[0]);
          }

          if (activePage === "Appraiser Qualification") {
            form.append("experience", currentPage.data.experience);
            form.append("designation", currentPage.data.designation);
            form.append(
              "commercialApproved",
              currentPage.data.commercialApproved
            );
            form.append("FHAApproved", currentPage.data.FHAApproved);
            form.append("resume", currentPage.data.resume[0]);
            form.append(
              "governmentAgencyIncludeCheck",
              currentPage.data.governmentAgencyIncludeCheck
            );
            form.append(
              "disciplinaryActionAgainstUserCheck",
              currentPage.data.disciplinaryActionAgainstUserCheck
            );
            form.append(
              "subjectOfAnyCorrectiveActionByAppraiserCheck",
              currentPage.data.subjectOfAnyCorrectiveActionByAppraiserCheck
            );
            form.append(
              "notifiedOfAnyInvestigationByBoardCheck",
              currentPage.data.notifiedOfAnyInvestigationByBoardCheck
            );
            form.append(
              "convictedOfFelonyCheck",
              currentPage.data.convictedOfFelonyCheck
            );
            form.append(
              "lawsuitOrClaimFieldCheck",
              currentPage.data.lawsuitOrClaimFieldCheck
            );
            form.append(
              "awarenessOfAnyLawsuitOrClaimFieldCheck",
              currentPage.data.awarenessOfAnyLawsuitOrClaimFieldCheck
            );
            form.append("backgroundCheck", currentPage.data.backgroundCheck[0]);
          }
          const actionToDispatch = currentPage.dispatch(form);
          dispatch(actionToDispatch)
            .then((result) => {
              setcomplatedSigns([...complatedSigns, activePage]);
              setactivePage(currentPage.next);
              setmakePrompt({
                open: true,
                message: `${activePage} has been updated successfully!`,
                type: "success",
              });
            })
            .catch((err) => {
              console.log(err, "err--405");
              setmakePrompt({
                open: true,
                message: err.response?.data?.error || "Some error occured",
                type: "error",
              });
            });
        } else {
          const actionToDispatch = currentPage.dispatch(currentPage.data);

          dispatch(actionToDispatch)
            .then((result) => {
              setcomplatedSigns([...complatedSigns, activePage]);
              setactivePage(currentPage.next);
              setmakePrompt({
                open: true,
                message: `${activePage} has been updated successfully!`,
                type: "success",
              });
            })
            .catch((err) => {
              console.log(err, "err--425");
              setmakePrompt({
                open: true,
                message: err.response?.data?.error || "Some error occured",
                type: "error",
              });
            });
        }
      } else {
        console.warn("Unsupported activePage:", activePage);
      }
      // }
      //  else {
      //   setcomplatedSigns([...complatedSigns, activePage]);
      //   setactivePage(currentPage.next);
      // }
      // } else {
      //   if (activePage === "State License(s)") {
      //     auth.profile.license.forEach((item) => {
      //       if (!validateFetchData(item, licensekeys)) {
      //         setmakePrompt({
      //           open: true,
      //           message: `Some of your license information is missing`,
      //           type: "error",
      //         });
      //       } else {
      //         setactivePage(currentPage.next);
      //         setcomplatedSigns([...complatedSigns, activePage]);
      //       }
      //     });
      //   } else if (activePage === "Personal Information") {
      //     if (!validateFetchData(auth.profile.personals, personsKeys)) {
      //       setmakePrompt({
      //         open: true,
      //         message: "Some of your personal information is missing",
      //         type: "error",
      //       });
      //     }
      //   }
      // }
    } catch (validationError) {
      const formattedErrors = {};
      validationError?.inner?.forEach((error) => {
        formattedErrors[error.path] = error.message;
      });
      console.log(formattedErrors, "formattedErrors");
      setErrors(formattedErrors);
      setmakePrompt({
        open: true,
        message: Object.values(formattedErrors)[0] || "Some error occured",
        type: "error",
      });
    }
  };

  const handleBack = () => {
    const currentPage = pageTypeData.find((item) => item.name === activePage);

    if (currentPage) {
      setactivePage(currentPage.prev);
    } else {
      console.warn("Unsupported activePage:", activePage);
    }
  };

  const getPageType = () => {
    const currentPageConfig = pageTypeData.find(
      (config) => config.name === activePage
    );
    console.log(currentPageConfig, "currentPageConfig");

    if (currentPageConfig) {
      const { schema, data, next } = currentPageConfig;

      switch (activePage) {
        case "State License(s)":
          return (
            <License
              handleChangeLicense={handleChange}
              licenseData={data}
              handleNextForLicense={handleNext}
              errors={errors}
              setlicenseData={setlicenseData}
            />
          );
        case "Personal Information":
          return (
            <PersonalInfos
              handleChange={handleChange}
              data={data}
              handleNext={handleNext}
              handleBack={handleBack}
              errors={errors}
            />
          );
        case "Company Information":
          return (
            <CompanyInfo
              handleChange={handleChange}
              data={data}
              handleNext={handleNext}
              handleBack={handleBack}
              errors={errors}
            />
          );
        case "Coverage Area":
          return (
            <CoverageArea
              handleChange={handleChange}
              data={data}
              handleNext={handleNext}
              handleBack={handleBack}
              errors={errors}
            />
          );
        case "Appraiser Qualification":
          return (
            <AppraiserQualification
              handleChange={handleChange}
              data={data}
              handleNext={handleNext}
              handleBack={handleBack}
              errors={errors}
            />
          );
        case "E&o Insurance":
          return (
            <EoInsurance
              handleChange={handleChange}
              data={data}
              handleNext={handleNext}
              handleBack={handleBack}
              errors={errors}
            />
          );
        case "Digital Signature":
          return (
            <DigitalSign
              handleChange={handleChange}
              data={data}
              handleNext={handleNext}
              handleBack={handleBack}
              errors={errors}
            />
          );
        case "Billing":
          return (
            <Billings
              handleChange={handleChange}
              data={data}
              handleNext={handleNext}
              handleBack={handleBack}
              errors={errors}
            />
          );
        case "Document":
          return (
            <Document
              handleChange={handleChange}
              data={data}
              handleNext={handleNext}
              handleBack={handleBack}
              errors={errors}
            />
          );
        case "Registration Agreement":
          return (
            <Agreement
              handleNext={handleNext}
              handleBack={handleBack}
              handleChange={handleChange}
              data={data}
              errors={errors}
            />
          );
        case "Complete":
          return <RegisterComplete />;
        default:
          return <div>Page not found</div>;
      }
    } else {
      console.warn("Unsupported activePage:", activePage);
      return <div>Unsupported Page</div>;
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      <Box
        sx={{
          padding: "3em",
          display: "flex",
          flexDirection: "column",
          width: "36%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={logo}
          alt="Logo"
          style={{
            width: "160px",
            height: "40px",
            marginBottom: "2em",
          }}
        />
        <Box sx={{ width: "90%" }}>
          {pageTypeData
            .filter((item) => item !== false)
            .map((item, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    margin: ".5em 1em",
                  }}
                  onClick={() => {
                    if (complatedSigns.includes(item.name)) {
                      setactivePage(item.name);
                    }
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <div
                      style={{
                        width: "20px",
                        height: "20px",
                        borderRadius: "50%",
                        background: complatedSigns.includes(item.name)
                          ? "#31DD67"
                          : activePage === item.name
                          ? "#2C80FF"
                          : "lightgray",
                        marginRight: ".8em",
                        marginTop: "6px",
                      }}
                    >
                      {activePage === item.name ||
                      complatedSigns.includes(item.name) ? (
                        <AiOutlineCheck
                          style={{
                            width: 16,
                            height: 16,
                            color: complatedSigns.includes(item.name)
                              ? "#fff"
                              : activePage !== item.name
                              ? "#2C80FF"
                              : "#fff",
                            marginLeft: 2,
                          }}
                        />
                      ) : null}
                    </div>

                    <Text
                      variant="body1"
                      style={{
                        color: complatedSigns.includes(item.name)
                          ? "#31DD67"
                          : activePage === item.name
                          ? "#2C80FF"
                          : "#656A72",
                        fontSize: "16px",
                        fontFamily: "Poppins",
                        fontWeight: "600",
                      }}
                    >
                      <span>{item.name}</span>
                    </Text>
                  </Box>
                  <Text
                    variant="body1"
                    style={{
                      color: "#656A72",
                      fontSize: "11px",
                      fontFamily: "Poppins",
                      fontWeight: "500",
                      opacity: "0.7",
                      marginLeft: "2.8em",
                    }}
                  >
                    {item.comment}
                  </Text>
                </Box>
              );
            })}
        </Box>
      </Box>
      <Box
        sx={{
          background: " #F8FAFB 0% 0% no-repeat padding-box",
          width: "100%",
          height: "100vh",
          padding: "5em 0",
        }}
      >
        {getPageType()}
      </Box>
      {makePrompt.open && (
        <Prompt
          open={makePrompt.open}
          message={makePrompt.message}
          type={makePrompt.type}
          handleClose={() => {
            setmakePrompt({ ...makePrompt, open: false });
          }}
        />
      )}
    </Box>
  );
};

export default CompleteProfile;
