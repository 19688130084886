import axios from "../Utils/axios";
import { utilConstants } from "./constants";

export const getStates = () => {
  return async (dispatch) => {
    dispatch({ type: utilConstants.GET_STATES_REQUEST });
    const res = await axios.get("/states");
    if (res.status === 200) {
      dispatch({
        type: utilConstants.GET_STATES_SUCCESS,
        payload: { states: res.data.data },
      });
    } else {
      dispatch({
        type: utilConstants.GET_STATES_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};
