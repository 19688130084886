import { reportConstants } from "../Actions/constants";

const initState = {
  propertyDetails: {},
  repairDetails: {},
  marketTrend: {},
  sketch: {},
  comment: {},
  reconciliation: {},
  reports: [],
  files: [],
  loading: false,
  error: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case reportConstants.GET_PROPERTY_DETAILS_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case reportConstants.GET_PROPERTY_DETAILS_SUCCESS:
      state = {
        ...state,
        propertyDetails: action.payload,
        loading: false,
      };
      break;
    case reportConstants.GET_PROPERTY_DETAILS_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;
    case reportConstants.GET_REPAIR_DETAILS_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case reportConstants.GET_REPAIR_DETAILS_SUCCESS:
      state = {
        ...state,
        repairDetails: action.payload,
        loading: false,
      };
      break;
    case reportConstants.GET_REPAIR_DETAILS_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;
    case reportConstants.GET_MARKET_TREND_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case reportConstants.GET_MARKET_TREND_SUCCESS:
      state = {
        ...state,
        marketTrend: action.payload,
        loading: false,
      };
      break;
    case reportConstants.GET_MARKET_TREND_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;
    case reportConstants.GET_SKETCH_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case reportConstants.GET_SKETCH_SUCCESS:
      state = {
        ...state,
        sketch: action.payload,
        loading: false,
      };
      break;
    case reportConstants.GET_SKETCH_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;
    case reportConstants.GET_COMMENT_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case reportConstants.GET_COMMENT_SUCCESS:
      state = {
        ...state,
        comment: action.payload,
        loading: false,
      };
      break;
    case reportConstants.GET_COMMENT_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;
    case reportConstants.GET_RECONCILIATION_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case reportConstants.GET_RECONCILIATION_SUCCESS:
      state = {
        ...state,
        reconciliation: action.payload,
        loading: false,
      };
      break;
    case reportConstants.GET_RECONCILIATION_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;

    case reportConstants.UPDATE_PROPERTY_DETAILS_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case reportConstants.UPDATE_PROPERTY_DETAILS_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case reportConstants.UPDATE_PROPERTY_DETAILS_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;
      case reportConstants.GET_FILE_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case reportConstants.GET_FILE_SUCCESS:
      state = {
        ...state,
        files: action.payload,
        loading: false,
      };
      break;
    case reportConstants.GET_FILE_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;
      
  }
  return state;
};
