import React from "react";
import { Box } from "@mui/material";
import Text from "./Core/Text";
import Select from "./Core/Select";
import Input from "./Core/Input";
import Button from "./Core/Button";

const Billings = ({
  handleChange,
  data,
  handleNext,
  handleBack,
  errors,
  isUpdate,
}) => {
  return (
    <Box
      sx={{
        width: "66%",
        ml: isUpdate ? 0 : "auto",
        mr: "auto",
      }}
    >
      <Text
        variant="h1"
        style={{
          color: "#E6E6E6",
          fontSize: "48px",
        }}
      >
        Billing
      </Text>
      <Text
        variant="body1"
        style={{
          font: "normal normal medium 14px/21px Poppins",
          color: "#656a72",
          fontSize: "13px",
        }}
      >
        Lorem Ipsum is simply dummy text of the printing and type setting
        industry.
      </Text>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          mt: "2em",
        }}
      >
        <Input
          placeholder="Routing Number"
          label="Routing Number"
          type="text"
          style={{ width: "40%" }}
          value={data.routingNumber}
          onChange={(e) => {
            if (/^\d+$/.test(e.target.value) && e.target.value) {
              handleChange("routingNumber", e.target.value);
            } else {
              handleChange("routingNumber", "");
            }
          }}
          error={errors.routingNumber || ""}
        />

        <Input
          placeholder="Account Number"
          label="Account Number"
          type="text"
          style={{ width: "40%", marginLeft: "1em" }}
          value={data.accountNumber}
          onChange={(e) => {
            if (/^\d+$/.test(e.target.value) && e.target.value) {
              handleChange("accountNumber", e.target.value);
            } else {
              handleChange("accountNumber", "");
            }
          }}
          error={errors.accountNumber || ""}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          mt: "1em",
        }}
      >
        <Input
          label="Name of the institution"
          placeholder="Name of the institution"
          type="text"
          style={{ width: "40%" }}
          value={data.institution}
          onChange={(e) => {
            if (/^[a-zA-Z]+$/.test(e.target.value) && e.target.value) {
              handleChange("institution", e.target.value);
            } else {
              handleChange("institution", "");
            }
          }}
          error={errors.institution || ""}
        />

        <Select
          label="Account Type"
          style={{ width: "40%", marginLeft: ".5em", marginRight: ".5em" }}
          options={[
            { value: "option1", label: "Option 1" },
            { value: "option2", label: "Option 2" },
            { value: "option3", label: "Option 3" },
          ]}
          value={data.accountType || ""}
          onChange={(option) => {
            handleChange("accountType", option);
          }}
          error={errors.accountType || ""}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          mt: "1em",
        }}
      >
        <Text
          variant="body1"
          style={{
            color: "#E6E6E6",
            fontSize: "22px",
          }}
        >
          Address
        </Text>
        <input
          type="checkbox"
          id={"address"}
          style={{
            marginLeft: "1em",
            width: "16px",
            height: "16px",
            borderRadius: "8px",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        />
        <label
          htmlFor="address"
          style={{
            color: "#656A72",
            fontSize: "13px",
            fontFamily: "Poppins",
            margin: "auto 0.5em",
          }}
        >
          Address same as Inspector
        </label>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          mt: "1em",
        }}
      >
        <Select
          label="Street Address / PO Box"
          style={{ width: "40%", marginRight: ".5em" }}
          options={[
            { value: "option1", label: "Option 1" },
            { value: "option2", label: "Option 2" },
            { value: "option3", label: "Option 3" },
          ]}
          value={data.address || ""}
          onChange={(option) => {
            handleChange("address", option);
          }}
          error={errors.address || ""}
        />

        <Select
          label="Zip Code"
          placeholder="Zip Code"
          style={{ width: "40%" }}
          options={[
            { value: "option1", label: "Option 1" },
            { value: "option2", label: "Option 2" },
            { value: "option3", label: "Option 3" },
          ]}
          value={data.zipCode || ""}
          onChange={(option) => {
            handleChange("zipCode", option);
          }}
          error={errors.zipCode || ""}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          mt: "1em",
        }}
      >
        <Select
          label="City / Town"
          style={{ width: "40%", marginLeft: ".5em", marginRight: ".5em" }}
          options={[
            { value: "option1", label: "Option 1" },
            { value: "option2", label: "Option 2" },
            { value: "option3", label: "Option 3" },
          ]}
          value={data.city || ""}
          onChange={(option) => {
            handleChange("city", option);
          }}
          error={errors.city || ""}
        />

        <Select
          label="State"
          placeholder="State"
          style={{ width: "40%" }}
          options={[
            { value: "option1", label: "Option 1" },
            { value: "option2", label: "Option 2" },
            { value: "option3", label: "Option 3" },
          ]}
          value={data.state || ""}
          onChange={(option) => {
            handleChange("state", option);
          }}
          error={errors.state || ""}
        />
      </Box>

      <Box sx={{ display: "flex", flexDirection: "row", mt: "1em" }}>
        {handleBack && (
          <Button
            isTransparent
            style={{
              width: "30%",
              paddingTop: ".2em",
              paddingBottom: ".2em",
              marginRight: ".5em",
            }}
            onClick={handleBack}
          >
            Back
          </Button>
        )}
        {handleNext && (
          <Button
            style={{
              width: "30%",
              paddingTop: ".1em",
              paddingBottom: ".1em",
            }}
            onClick={handleNext}
          >
            {isUpdate ? "Udpate" : "Save"} & Continue
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default Billings;
