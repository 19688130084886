import { Box } from "@mui/material";
import React, { useEffect } from "react";
import Text from "../../Components/Core/Text";
import { FileWrittenBent } from "react-huge-icons/outline";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { getFile } from "../../Actions/report";

const Attachments = () => {
  const reports = useSelector((state) => state.report);
  const dispatch = useDispatch();
  const location = useLocation();
  const report_id = location.state.id;

  useEffect(() => {
    dispatch(getFile(report_id));
  }, [dispatch, report_id]);

  const renderFiles = () => {
    if (reports.files) {
      return reports.files.map((file, index) => (
        <Box
          key={index}
          sx={{
            width: "148px",
            height: "152px",
            background: " #F9F9F9 0% 0% no-repeat padding-box",
            borderRadius: "16px",
            m: "0 .5em",
            p: ".5em",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {file.type === "image" ? (
            <img
              src={`https://dygo8x5k8m6hk.cloudfront.net/${file.file}`}
              alt={file.url}
              style={{
                width: "100%",
                height: "60%",
                borderRadius: "16px",
                objectFit: "cover",
              }}
            />
          ) : (
            <Box
              sx={{
                background: "#edf2f9 0% 0% no-repeat padding-box",
                width: "100%",
                height: "60%",
                borderRadius: "16px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FileWrittenBent
                fontSize={40}
                style={{
                  color: "#2c80ff",
                }}
              />
            </Box>
          )}

          <Text
            style={{
              fontSize: "12px",
              fontFamily: "Poppins",
              color: "#000000",
              marginTop: "8px",
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            {file.file}
          </Text>
          <Text
            style={{
              fontSize: "10.5px",
              fontFamily: "Poppins",
              color: "#000000",
              opacity: "0.5",
              marginTop: "-2px",
              font: "normal normal normal 9px/18px Poppins",
            }}
          >
            {convertSizeToKB(file.fileSize).toFixed(2)} KB
          </Text>
        </Box>
      ));
    }
  };

  const convertSizeToKB = (size) => {
    return size / 1024;
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        p: ".5em",
      }}
    >
      <Text
        style={{
          font: "normal normal 600 22px/33px Poppins",
          color: "#BFBFBF",
          fontSize: "18px",
          fontWeight: "600",
        }}
      >
        View Attachments
      </Text>
      <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        {renderFiles()}
      </Box>
    </Box>
  );
};

export default Attachments;
