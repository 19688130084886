import { orderConstants } from "../Actions/constants";

const initState = {
  newOrder: [],
  assignedOrder: [],
  scheduleOrder: [],
  completeOrder: [],
  cancelledOrder: [],
  orderDetails: {},
  activities: [],
  loading: false,
  error: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case orderConstants.NEW_ORDER_REQUEST:
    case orderConstants.CREATE_ASSIGNED_ORDER_REQUEST:
    case orderConstants.GET_SCHEDULED_ORDER_REQUEST:
    case orderConstants.GET_COMPLETE_ORDER_REQUEST:
    case orderConstants.GET_CANCELLED_ORDER_REQUEST:
    case orderConstants.GET_ORDER_DETAILS_REQUEST:
    case orderConstants.GET_ORDER_ACTIVITY_REQUEST:
    case orderConstants.GET_NEW_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case orderConstants.GET_NEW_ORDER_SUCCESS:
      return {
        ...state,
        newOrder: action.payload,
        loading: false,
      };
    case orderConstants.ASSIGNED_ORDER_SUCCESS:
      return {
        ...state,
        assignedOrder: action.payload,
        loading: false,
      };
    case orderConstants.GET_ORDER_ACTIVITY_SUCCESS:
      return {
        ...state,
        activities: action.payload,
        loading: false,
      };
    case orderConstants.GET_SCHEDULED_ORDER_SUCCESS:
      return {
        ...state,
        scheduleOrder: action.payload,
        loading: false,
      };
    case orderConstants.GET_COMPLETE_ORDER_SUCCESS:
      return {
        ...state,
        completeOrder: action.payload,
        loading: false,
      };
    case orderConstants.GET_CANCELLED_ORDER_SUCCESS:
      return {
        ...state,
        cancelledOrder: action.payload,
        loading: false,
      };
    case orderConstants.GET_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        orderDetails: action.payload,
        loading: false,
      };
    case orderConstants.NEW_ORDER_FAILURE:
    case orderConstants.GET_ORDER_ACTIVITY_FAILURE:
    case orderConstants.CREATE_ASSIGNED_ORDER_FAILURE:
    case orderConstants.GET_SCHEDULED_ORDER_FAILURE:
    case orderConstants.GET_COMPLETE_ORDER_FAILURE:
    case orderConstants.GET_CANCELLED_ORDER_FAILURE:
    case orderConstants.GET_ORDER_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};
