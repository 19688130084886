import React, { useEffect, useState } from "react";
import Template from "../../Template";
import Ellipse8 from "../../Assets/Images/Ellipse8.svg";
import { Box } from "@mui/material";
import Text from "../../Components/Core/Text";
import Chart from "react-apexcharts";
import {
  CalendarDot,
  FileDownloadBent,
  FilterEnable,
  InvoiceTorn,
  ShoppingBag,
} from "react-huge-icons/outline";
import Button from "../../Components/Core/Button";
import Input from "../../Components/Core/Input";
import { useDispatch, useSelector } from "react-redux";
import { getBillings } from "../../Actions/billing";

const generateRandomData = (count, min, max) => {
  const data = [];
  for (let i = 0; i < count; i++) {
    data.push(Math.floor(Math.random() * (max - min + 1)) + min);
  }
  return data;
};

const Billings = () => {
  const [series, setSeries] = useState([
    { data: generateRandomData(7, 1, 100) },
  ]);
  const dispatch = useDispatch();
  const billing = useSelector((state) => state.utils);

  useEffect(() => {
    dispatch(getBillings());
  }, []);

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
  };

  return (
    <Template
      showHeader
      headerProps={{
        showSearch: true,
        showNotification: true,
        showCash: true,
        showProfile: true,
        showCompleteProfile: true,
        showMessage: true,
      }}
      style={{
        paddingBottom: "2em",
      }}
      containerStyle={{
        backgroundImage: `url(${Ellipse8})`,
        height: "100vh",
      }}
    >
      <Box
        sx={{
          border: "1px solid #FFFFFF",
          background:
            "transparent linear-gradient(119deg, #FFFFFF66 0%, #FFFFFF24 100%) 0% 0% no-repeat padding-box",
          boxShadow: "15px 15px 50px #00000029",
          borderRadius: "24px",
          backgroundColor: "#F2F2F2",
          opacity: "1",
          padding: "2em",
          backdropFilter: "blur(50px)",
          WebkitBackdropFilter: "blur(50px)",
          width: "78%",
          margin: "auto",
          marginTop: "2em",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Box sx={{ mr: "auto" }}>
            <Text
              style={{
                color: "#000000",
                font: "normal normal 600 26px/36px Poppins",
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              My Billings
            </Text>
            <Text
              style={{
                color: "#000000",
                font: "normal normal normal 12px/26px Poppins",
                opacity: "0.5",
                marginTop: "-.5em",
                fontSize: "14px",
              }}
            >
              Lorem Ipsum is simply dummy text of the printing and type setting
              industry.
            </Text>
          </Box>
          <Text
            style={{
              color: "#2C80FF",
              font: "normal normal 600 17px/26px Poppins",
              margin: "auto 0px",
              fontSize: "16px",
            }}
          >
            Back
          </Text>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", mt: "1em" }}>
          <Box
            sx={{
              mr: "auto",
              width: "48%",
              background: "#fff",
              padding: "1em",
              borderRadius: "4px",
              mr: "1em",
              height: "fit-content",
            }}
          >
            <Chart options={options} series={series} type="bar" width="500" />
            <Box>
              {Array(4)
                .fill(
                  "Lorem ipsom is simple dummy text that can used for that purpose"
                )
                .map((item, index) => (
                  <Box
                    key={index}
                    sx={{
                      background: "#F9F9F9 0% 0% no-repeat padding-box",
                      borderRadius: "11px",
                      padding: ".8em",
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: ".7em",
                      cursor: "pointer",
                    }}
                  >
                    <span
                      style={{
                        color: "#000",
                        font: "normal normal normal 13px/20px Poppins",
                        fontSize: "13px",
                        fontWeight: "600",
                      }}
                    >
                      Spending on,
                    </span>
                    <span
                      style={{
                        fontSize: "12px",
                        color: "#000",
                        fontWeight: "500",
                        margin: "auto 4px auto 4px",
                      }}
                    >
                      Monday
                    </span>
                    <CalendarDot
                      color="#2C80FF"
                      fontSize={18}
                      style={{
                        marginLeft: "16px",
                        marginRight: "4px",
                        marginTop: "4px",
                        marginBottom: "auto",
                      }}
                    />
                    <span
                      style={{
                        fontSize: "12px",
                        color: "#000",
                        fontWeight: "500",
                        margin: "4px 0 auto 0",
                      }}
                    >
                      Date: Mar 9, 2022
                    </span>
                    <span
                      style={{
                        fontSize: "12px",
                        color: "#000",
                        fontWeight: "500",
                        margin: "auto 4px auto auto",
                      }}
                    >
                      Total Spend:
                    </span>
                    <span
                      style={{
                        color: "#2C80FF",
                        fontWeight: "600",
                        fontSize: "12px",
                        margin: "auto 4px",
                      }}
                    >
                      $160.00
                    </span>
                  </Box>
                ))}
            </Box>
          </Box>
          <Box sx={{ width: "50%" }}>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Input
                placeholder="Search by property id, client name…"
                label="Search"
                isTransparent
                type="text"
                style={{ width: "48%" }}
              />

              <Button
                style={{
                  width: "104px",
                  height: "46px",
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor: "#2C80FF",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                  marginLeft: "auto",
                  marginTop: "1em",
                }}
              >
                <FilterEnable
                  fontSize={18}
                  style={{
                    marginTop: "auto",
                    marginBottom: "auto",
                    position: "absolute",
                    left: "18px",
                    top: "12px",
                  }}
                />
                <span
                  style={{
                    marginLeft: "8px",
                    font: "normal normal medium 14px/18px Poppins",
                    fontSize: "14px",
                  }}
                >
                  Filter
                </span>
              </Button>
            </Box>

            <Box
              sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
            >
              {Array(9)
                .fill({
                  address: "1234 S Main St",
                  locality: "Los Angeles",
                  state: "California",
                  pincode: "90015",
                  orderID: "ORD1234",
                  familyType: "Single",
                  price: 120.5,
                  date: "2023-01-15",
                  thumbnail: "https://unsplash.com/photos/placeholder1",
                  outing: "interior",
                  status: "Delivered",
                })
                .map((item) => (
                  <Box
                    sx={{
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      borderRadius: "12px",
                      padding: "1em",
                      margin: "1em",
                      maxWidth: "calc(45% - 2em)",
                    }}
                  >
                    <Text
                      style={{
                        color: "#000000",
                        fontWeight: 600,
                        fontSize: "15px",
                        fontFamily: "Poppins",
                      }}
                    >
                      {item.address},
                    </Text>
                    <Text
                      style={{
                        color: "#000000",
                        fontSize: "13px",
                        fontFamily: "Poppins",
                        marginTop: "-4px",
                        marginBottom: ".5em",
                      }}
                    >
                      {item.locality}, {item.state}, {item.pincode}
                    </Text>
                    <Box
                      sx={{ display: "flex", flexDirection: "row", mb: "4px" }}
                    >
                      <ShoppingBag
                        color="#2593D6"
                        fontSize={18}
                        style={{
                          marginRight: "10px",
                          marginTop: "4px",
                          marginBottom: "auto",
                        }}
                      />

                      <Text
                        style={{
                          fontSize: "13px",
                          fontFamily: "Poppins",
                          color: "#000000",
                        }}
                      >
                        Order ID: #{item.orderID}
                      </Text>
                    </Box>

                    <Box
                      sx={{ display: "flex", flexDirection: "row", mb: "4px" }}
                    >
                      <InvoiceTorn
                        color="#2593D6"
                        fontSize={18}
                        style={{
                          marginRight: "10px",
                          marginTop: "4px",
                          marginBottom: "auto",
                        }}
                      />

                      <Text
                        style={{
                          fontSize: "13px",
                          fontFamily: "Poppins",
                          color: "#000000",
                        }}
                      >
                        Invoice ID: #789
                      </Text>
                    </Box>

                    <Box
                      sx={{ display: "flex", flexDirection: "row", mb: "4px" }}
                    >
                      <CalendarDot
                        color="#2593D6"
                        fontSize={18}
                        style={{
                          marginRight: "10px",
                          marginTop: "4px",
                          marginBottom: "auto",
                        }}
                      />

                      <Text
                        style={{
                          fontSize: "13px",
                          fontFamily: "Poppins",
                          color: "#000000",
                        }}
                      >
                        Invoice Date: Dec 12, 2022
                      </Text>
                    </Box>

                    <Box
                      sx={{ display: "flex", flexDirection: "row", mb: "4px" }}
                    >
                      <CalendarDot
                        color="#2593D6"
                        fontSize={18}
                        style={{
                          marginRight: "10px",
                          marginTop: "4px",
                          marginBottom: "auto",
                        }}
                      />

                      <Text
                        style={{
                          fontSize: "13px",
                          fontFamily: "Poppins",
                          color: "#000000",
                        }}
                      >
                        Paid Date: Dec 12, 2022
                      </Text>
                    </Box>
                    <Box
                      sx={{ display: "flex", flexDirection: "row", mt: ".5em" }}
                    >
                      <Text
                        style={{
                          fontSize: "15px",
                          fontFamily: "Poppins",
                          color: "#2C80FF",
                          fontWeight: "600",
                        }}
                      >
                        $410.00
                      </Text>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          ml: "auto",
                          cursor: "pointer",
                        }}
                      >
                        <FileDownloadBent
                          color="#16C672"
                          fontSize={18}
                          style={{
                            marginRight: "4px",
                            marginTop: "auto",
                            marginBottom: "auto",
                          }}
                        />

                        <Text
                          style={{
                            fontSize: "15px",
                            fontFamily: "Poppins",
                            color: "#16C672",
                            fontWeight: "600",
                          }}
                        >
                          Download
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Template>
  );
};

export default Billings;
