import { Box } from "@mui/material";
import React, { useState } from "react";
import Text from "./Core/Text";
import Input from "./Core/Input";
import Button from "./Core/Button";
import FileUploader from "./FileUploader";

const EoInsurance = ({
  handleNext,
  handleBack,
  handleChange,
  data,
  errors,
}) => {
  const [documents, setdocuments] = useState(null);

  return (
    <Box
      sx={{
        width: "66%",
        ml: "auto",
        mr: "auto",
      }}
    >
      <Text
        variant="h1"
        style={{
          color: "#E6E6E6",
          fontSize: "48px",
        }}
      >
        E&o Insurance
      </Text>
      <Text
        variant="body1"
        style={{
          font: "normal normal medium 14px/21px Poppins",
          color: "#656a72",
          fontSize: "13px",
        }}
      >
        Lorem Ipsum is simply dummy text of the printing and type setting
        industry.
      </Text>
      <Box sx={{ mt: "2em", display: "flex", flexDirection: "row" }}>
        <Input
          label="E & O Company Name"
          placeholder="Enter here"
          value={data.companyName}
          onChange={(e) => handleChange("companyName", e.target.value)}
          error={errors.companyName || ""}
          style={{ width: "48%", marginRight: "10px" }}
        />
        <Input
          label="E & O Policy Number"
          placeholder="Enter here"
          style={{ width: "48%", marginRight: "10px" }}
          value={data.policyNumber}
          onChange={(e) => handleChange("policyNumber", e.target.value)}
          error={errors.policyNumber || ""}
        />
      </Box>
      <Box sx={{ mt: "2em", display: "flex", flexDirection: "row", mb: "1em" }}>
        <Input
          label="Per Claim Limit"
          placeholder="Enter here"
          style={{ width: "48%", marginRight: "10px" }}
          value={data.claimLimit}
          onChange={(e) => handleChange("claimLimit", e.target.value)}
          error={errors.claimLimit || ""}
        />
        <Input
          label="E & O Expiration Date"
          placeholder="Enter here"
          type="date"
          style={{ width: "48%", marginRight: "10px" }}
          value={data.expiresAt}
          onChange={(e) => handleChange("expiresAt", e.target.value)}
          error={errors.expiresAt || ""}
        />
      </Box>
      <span
        style={{
          color: "#656A72",
          fontSize: "13px",
          fontFamily: "Poppins",
          marginBottom: "1em",
        }}
      >
        E & O Document
      </span>
      <FileUploader
        handleChange={(file) => {
          handleChange("document", file);
          setdocuments({
            ...documents,
            document: file[0],
          });
        }}
        uploaderLabel={"Upload File"}
      />
      {documents?.document && (
        <span
          style={{
            fontSize: 13,
            marginTop: 4,
          }}
        >
          {documents?.document?.name}
        </span>
      )}
      <Box sx={{ display: "flex", flexDirection: "row", mt: "1em" }}>
        {handleBack && (
          <Button
            isTransparent
            style={{
              width: "30%",
              paddingTop: ".2em",
              paddingBottom: ".2em",
              marginRight: ".5em",
            }}
            onClick={handleBack}
          >
            Back
          </Button>
        )}
        {handleNext && (
          <Button
            style={{
              width: "30%",
              paddingTop: ".1em",
              paddingBottom: ".1em",
            }}
            onClick={handleNext}
          >
            Save & Continue
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default EoInsurance;
