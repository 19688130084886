import React from "react";
import { Box } from "@mui/material";
import Text from "./Core/Text";
import Select from "./Core/Select";
import Input from "./Core/Input";
import Button from "./Core/Button";
import { useSelector } from "react-redux";

const PersonalInfos = ({
  handleChange,
  data,
  handleNext,
  handleBack,
  errors,
  isUpdate,
}) => {
  const utils = useSelector((state) => state.utils);
  return (
    <Box
      sx={{
        width: "66%",
        ml: isUpdate ? 0 : "auto",
        mr: "auto",
      }}
    >
      <Text
        variant="h1"
        style={{
          color: "#E6E6E6",
          fontSize: "48px",
        }}
      >
        Personal Information
      </Text>
      <Text
        variant="body1"
        style={{
          font: "normal normal medium 14px/21px Poppins",
          color: "#656a72",
          fontSize: "13px",
        }}
      >
        Lorem Ipsum is simply dummy text of the printing and type setting
        industry.
      </Text>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          mt: "2em",
        }}
      >
        <Input
          placeholder="First Name"
          label="First Name"
          type="text"
          value={data.firstName}
          onChange={(e) => handleChange("firstName", e.target.value)}
          style={{ width: "25%" }}
          error={errors.firstName || ""}
        />

        <Input
          placeholder="Last Name"
          label="Last Name"
          type="text"
          style={{ width: "25%", marginLeft: "1em" }}
          value={data.lastName}
          onChange={(e) => handleChange("lastName", e.target.value)}
          error={errors.lastName || ""}
        />

        <Input
          placeholder="Email"
          label="Email"
          type="text"
          style={{ width: "40%", marginLeft: "1em" }}
          value={data.email}
          onChange={(e) => handleChange("email", e.target.value)}
          error={errors.email || ""}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          mt: "1em",
        }}
      >
        <Input
          placeholder="Cell Phone"
          label="Cell Phone"
          type="text"
          style={{ width: "40%" }}
          value={data.cellPhone}
          onChange={(e) => handleChange("cellPhone", e.target.value)}
          error={errors.cellPhone || ""}
        />

        <Input
          placeholder="Work Phone Number"
          label="Work Phone Number"
          type="text"
          style={{ width: "40%", marginLeft: "1em" }}
          value={data.workPhone}
          onChange={(e) => handleChange("workPhone", e.target.value)}
          error={errors.workPhone || ""}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          mt: "1em",
        }}
      >
        <Select
          label="Language Spoken"
          style={{ width: "48%" }}
          options={[
            { value: "option1", label: "Option 1" },
            { value: "option2", label: "Option 2" },
            { value: "option3", label: "Option 3" },
          ]}
          value={data.language || ""}
          onChange={(option) => {
            handleChange("language", option);
          }}
          error={errors.language || ""}
        />
      </Box>

      <Text
        variant="body1"
        style={{
          color: "#E6E6E6",
          fontSize: "22px",
          marginTop: ".5em",
        }}
      >
        Address
      </Text>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          mt: "1em",
        }}
      >
        <Input
          placeholder="Street Address/PO Box"
          label="Street Address/PO Box"
          type="text"
          style={{ width: "40%" }}
          value={data.address}
          onChange={(e) => handleChange("address", e.target.value)}
          error={errors.address || ""}
        />
        <Select
          label="State"
          style={{ width: "40%", marginLeft: ".5em", marginRight: ".5em" }}
          options={utils.states.map((state) => {
            return { value: state.name, label: state.name };
          })}
          value={data.state || ""}
          onChange={(option) => {
            handleChange("state", option);
          }}
          error={errors.state || ""}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          mt: "1em",
        }}
      >
        <Select
          label="City/Town"
          placeholder="City/Town"
          style={{ width: "40%" }}
          options={[
            { value: "option1", label: "Option 1" },
            { value: "option2", label: "Option 2" },
            { value: "option3", label: "Option 3" },
          ]}
          value={data.city || ""}
          onChange={(option) => {
            handleChange("city", option);
          }}
          error={errors.city || ""}
        />
        <Select
          label="Zipcode"
          placeholder="Zipcode"
          style={{ width: "40%", marginLeft: ".5em" }}
          options={[
            { value: "option1", label: "Option 1" },
            { value: "option2", label: "Option 2" },
            { value: "option3", label: "Option 3" },
          ]}
          value={data.zipcode || ""}
          onChange={(option) => {
            handleChange("zipcode", option);
          }}
          error={errors.zipcode || ""}
        />
      </Box>

      <Text
        variant="body1"
        style={{
          color: "#E6E6E6",
          fontSize: "22px",
          marginTop: ".5em",
        }}
      >
        Demographics
      </Text>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          mt: "1em",
        }}
      >
        <Select
          label="City/Town"
          placeholder="City/Town"
          isOptional
          style={{ width: "40%" }}
          options={[
            { value: "option1", label: "Option 1" },
            { value: "option2", label: "Option 2" },
            { value: "option3", label: "Option 3" },
          ]}
          value={data.demographicsCityTown || ""}
          onChange={(option) => {
            handleChange("demographicsCityTown", option);
          }}
          error={errors.demographicsCityTown || ""}
        />
        <Select
          label="Zipcode"
          placeholder="Zipcode"
          isOptional
          style={{ width: "44%", marginLeft: ".5em" }}
          options={[
            { value: "option1", label: "Option 1" },
            { value: "option2", label: "Option 2" },
            { value: "option3", label: "Option 3" },
          ]}
          value={data.demographicsZipcode || ""}
          onChange={(option) => {
            handleChange("demographicsZipcode", option);
          }}
          error={errors.demographicsZipcode || ""}
        />
      </Box>

      <Box sx={{ display: "flex", flexDirection: "row", mt: "1em" }}>
        {handleBack && (
          <Button
            onClick={handleBack}
            isTransparent
            style={{
              width: "30%",
              paddingTop: ".2em",
              paddingBottom: ".2em",
              marginRight: ".5em",
            }}
          >
            Back
          </Button>
        )}
        {handleNext && (
          <Button
            style={{
              width: "30%",
              paddingTop: ".2em",
              paddingBottom: ".2em",
            }}
            onClick={handleNext}
          >
            {isUpdate ? "Udpate" : "Save"} & Continue
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default PersonalInfos;
