import React from "react";
import { Box } from "@mui/material";
import Text from "./Core/Text";
import Select from "./Core/Select";
import Button from "./Core/Button";

const CoverageArea = ({
  handleChange,
  data,
  handleNext,
  handleBack,
  isUpdate,
  errors,
}) => {
  return (
    <Box
      sx={{
        width: "66%",
        ml: isUpdate ? 0 : "auto",
        mr: "auto",
      }}
    >
      <Text
        variant="h1"
        style={{
          color: "#E6E6E6",
          fontSize: "48px",
        }}
      >
        Coverage Area
      </Text>
      <Text
        variant="body1"
        style={{
          font: "normal normal medium 14px/21px Poppins",
          color: "#656a72",
          fontSize: "13px",
        }}
      >
        Lorem Ipsum is simply dummy text of the printing and type setting
        industry.
      </Text>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          mt: "2em",
        }}
      >
        <Select
          label="State"
          style={{ width: "40%", marginLeft: ".5em", marginRight: ".5em" }}
          options={[
            { value: "option1", label: "Option 1" },
            { value: "option2", label: "Option 2" },
            { value: "option3", label: "Option 3" },
          ]}
          value={data.coverageAreaState || ""}
          onChange={(option) => {
            handleChange("coverageAreaState", option);
          }}
          error={errors.coverageAreaState || ""}
        />

        <Select
          label="Country"
          placeholder="Country"
          style={{ width: "34%" }}
          options={[
            { value: "option1", label: "Option 1" },
            { value: "option2", label: "Option 2" },
            { value: "option3", label: "Option 3" },
          ]}
          value={data.coverageAreaCountry || ""}
          onChange={(option) => {
            handleChange("coverageAreaCountry", option);
          }}
          error={errors.coverageAreaCountry || ""}
        />
      </Box>

      <Box sx={{ display: "flex", flexDirection: "row", mt: "1em" }}>
        {handleBack && (
          <Button
            onClick={handleBack}
            isTransparent
            style={{
              width: "30%",
              paddingTop: ".2em",
              paddingBottom: ".2em",
              marginRight: ".5em",
            }}
          >
            Back
          </Button>
        )}
        {handleNext && (
          <Button
            onClick={handleNext}
            style={{
              width: "30%",
              paddingTop: ".1em",
              paddingBottom: ".1em",
            }}
          >
             {isUpdate ? "Udpate" : "Save"}  & Continue
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default CoverageArea;
