import React from "react";
import { Box } from "@mui/material";
import Text from "./Core/Text";
import Select from "./Core/Select";
import Input from "./Core/Input";
import Button from "./Core/Button";
import { useSelector } from "react-redux";

const CompanyInfo = ({
  handleChange,
  data,
  handleNext,
  handleBack,
  errors,
  isUpdate,
}) => {
  const utils = useSelector((state) => state.utils);

  return (
    <Box
      sx={{
        width: "66%",
        ml: isUpdate ? 0 : "auto",
        mr: "auto",
      }}
    >
      <Text
        variant="h1"
        style={{
          color: "#E6E6E6",
          fontSize: "48px",
        }}
      >
        Company Information
      </Text>
      <Text
        variant="body1"
        style={{
          font: "normal normal medium 14px/21px Poppins",
          color: "#656a72",
          fontSize: "13px",
        }}
      >
        Lorem Ipsum is simply dummy text of the printing and type setting
        industry.
      </Text>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          mt: "2em",
        }}
      >
        <Input
          placeholder="Company Name"
          label="Company Name"
          type="text"
          style={{ width: "40%" }}
          value={data.companyName}
          onChange={(e) => {
            if (/^[a-zA-Z]+$/.test(e.target.value)) {
              handleChange("companyName", e.target.value);
            }
          }}
          error={errors.companyName || ""}
        />

        <Input
          placeholder="Company Phone Number"
          label="Company Phone Number"
          type="text"
          style={{ width: "40%", marginLeft: "1em" }}
          value={data.companyPhoneNumber}
          onChange={(e) => handleChange("companyPhoneNumber", e.target.value)}
          error={errors.companyPhoneNumber || ""}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          mt: "1em",
        }}
      >
        <Text
          variant="body1"
          style={{
            color: "#E6E6E6",
            fontSize: "22px",
          }}
        >
          Address
        </Text>
        <input
          type="checkbox"
          id={"address"}
          checked={data.sameAsInspectorAddress}
          onClick={() => {
            handleChange(
              "sameAsInspectorAddress",
              !data.sameAsInspectorAddress
            );
          }}
          style={{
            marginLeft: "1em",
            width: "16px",
            height: "16px",
            borderRadius: "8px",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        />
        <label
          htmlFor="address"
          style={{
            color: "#656A72",
            fontSize: "13px",
            fontFamily: "Poppins",
            margin: "auto 0.5em",
          }}
        >
          Same as Inspector Address
        </label>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          mt: "1em",
        }}
      >
        <Input
          label="Company Address 1"
          placeholder="Street Address / PO Box"
          type="text"
          style={{ width: "40%" }}
          value={data.companyAddress1}
          onChange={(e) => handleChange("companyAddress1", e.target.value)}
          error={errors.companyAddress1 || ""}
        />

        <Input
          placeholder="Street Address / PO Box"
          label="Company Address 2"
          type="text"
          style={{ width: "40%", marginLeft: "1em" }}
          value={data.companyAddress2}
          onChange={(e) => handleChange("companyAddress2", e.target.value)}
          error={errors.companyAddress2 || ""}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          mt: "1em",
        }}
      >
        <Select
          label="State"
          style={{ width: "40%", marginRight: "1em" }}
          options={utils.states.map((state) => {
            return { value: state.name, label: state.name };
          })}
          value={data.state || ""}
          onChange={(option) => {
            handleChange("state", option);
          }}
          error={errors.state || ""}
        />

        <Select
          label="City/Town"
          placeholder="City/Town"
          style={{ width: "40%" }}
          options={[
            { value: "option1", label: "Option 1" },
            { value: "option2", label: "Option 2" },
            { value: "option3", label: "Option 3" },
          ]}
          value={data.cityTown || ""}
          onChange={(option) => {
            handleChange("cityTown", option);
          }}
          error={errors.cityTown || ""}
        />
      </Box>

      <Select
        label="Zipcode"
        placeholder="Zipcode"
        style={{ width: "40%", marginTop: "1em" }}
        options={[
          { value: "option1", label: "Option 1" },
          { value: "option2", label: "Option 2" },
          { value: "option3", label: "Option 3" },
        ]}
        value={data.zipcode || ""}
        onChange={(option) => {
          handleChange("zipcode", option);
        }}
        error={errors.zipcode || ""}
      />

      <Box sx={{ display: "flex", flexDirection: "row", mt: "1em" }}>
        {handleBack && (
          <Button
            onClick={handleBack}
            isTransparent
            style={{
              width: "30%",
              paddingTop: ".2em",
              paddingBottom: ".2em",
              marginRight: ".5em",
            }}
          >
            Back
          </Button>
        )}
        {handleNext && (
          <Button
            onClick={handleNext}
            style={{
              width: "30%",
              paddingTop: ".1em",
              paddingBottom: ".1em",
            }}
          >
            {isUpdate ? "Udpate" : "Save"} & Continue
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default CompanyInfo;
