import { Box } from "@mui/material";
import React from "react";
import {
  CalendarDot,
  ClockCircle,
  FileWrittenBent,
  ShoppingBag,
} from "react-huge-icons/outline";
import Text from "./Core/Text";
import moment from "moment";

const ActivityCard = ({ item }) => {
  console.log(item.type);

  const getIcon = () => {
    if (item.type === "Accepted Order") {
      return (
        <ShoppingBag
          color="#2C80FF"
          fontSize={20}
          style={{
            marginRight: "10px",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        />
      );
    } else if (item.type === "Order Completed") {
      return (
        <ShoppingBag
          color="#2C80FF"
          fontSize={20}
          style={{
            marginRight: "10px",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        />
      );
    } else if (item.type === "Scheduled Order") {
      return (
        <ClockCircle
          color="#2C80FF"
          fontSize={20}
          style={{
            marginRight: "10px",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        />
      );
    } else if (item.name === "Message") {
      return (
        <FileWrittenBent
          color="#2C80FF"
          fontSize={20}
          style={{
            marginRight: "10px",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        />
      );
    } else {
      return (
        <CalendarDot
          color="#2C80FF"
          fontSize={20}
          style={{
            marginRight: "10px",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        />
      );
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        background: " #F9F9F9 0% 0% no-repeat padding-box",
        borderRadius: "16px",
        padding: "10px 14px",
        mb: "1em",
      }}
    >
      {getIcon()}
      <Box>
        <Text>
          <span
            style={{
              fontSize: "13.5px",
              fontFamily: "Poppins",
              color: "#000000",
              marginTop: "8px",
              fontWeight: 600,
            }}
          >
            {item.type},
          </span>
          <span
            style={{
              fontSize: "13px",
              fontFamily: "Poppins",
              color: "#000000",
              marginTop: "8px",
              opacity: ".7",
              marginLeft: ".5em",
            }}
          >
            {item.user?.firstName} {item.user?.lastName}
          </span>
        </Text>
        <Text
          style={{
            fontSize: "12px",
            fontFamily: "Poppins",
            color: "#000000",
            opacity: "0.5",
            marginTop: "-2px",
            font: "normal normal normal 9px/18px Poppins",
          }}
        >
          {item.message}
        </Text>
      </Box>

      <CalendarDot
        color="#2C80FF"
        fontSize={20}
        style={{
          marginLeft: "auto",
          marginTop: "auto",
          marginBottom: "auto",
        }}
      />
      <Text
        style={{
          width: "140px",
          fontSize: "12px",
          fontFamily: "Poppins",
          color: "#000000",
          opacity: "1",
          marginTop: "auto",
          marginBottom: "auto",
          marginLeft: "8px",
          font: "normal normal normal 9px/18px Poppins",
        }}
      >
        {moment(item.timestamp).format("Do MMM, h:mm A")}
      </Text>
    </Box>
  );
};

export default ActivityCard;
