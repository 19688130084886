import axios from "../Utils/axios";
import { messageConstants } from "./constants";

export const makeOrderMessage = (id, data) => {
  return async (dispatch) => {
    dispatch({ type: messageConstants.CREATE_ORDER_MESSAGE_REQUEST });
    const res = await axios.post(`/message/order/${id}`, data);
    if (res.status === 200) {
      dispatch({
        type: messageConstants.CREATE_ORDER_MESSAGE_SUCCESS,
        payload: res.data.data,
      });
    } else if (res.status === 400) {
      dispatch({
        type: messageConstants.CREATE_ORDER_MESSAGE_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

export const getOrderMessage = (id) => {
  return async (dispatch) => {
    dispatch({ type: messageConstants.GET_ORDER_MESSAGE_REQUEST });
    const res = await axios.get(`/message/order/${id}`);
    if (res.status === 200) {
      dispatch({
        type: messageConstants.GET_ORDER_MESSAGE_SUCCESS,
        payload: res.data.data,
      });
    } else if (res.status === 400) {
      dispatch({
        type: messageConstants.GET_ORDER_MESSAGE_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

//New Support ticket creation
export const createTicket = (data) => {
  return async (dispatch) => {
    dispatch({ type: messageConstants.CREATE_TICKET_REQUEST });
    const res = await axios.post(`/support`, data);
    if (res.status === 200) {
      dispatch({
        type: messageConstants.CREATE_TICKET_SUCCESS,
        payload: res.data,
      });
    } else if (res.status === 400) {
      dispatch({
        type: messageConstants.CREATE_TICKET_FAILURE,
        payload: res.data,
      });
      return res.data;
    }
  };
};

//GET list of support tickets
export const getTickets = () => {
  return async (dispatch) => {
    dispatch({ type: messageConstants.GET_TICKET_REQUEST });
    try {
      const res = await axios.get(`/support`);
      dispatch({
        type: messageConstants.GET_TICKET_SUCCESS,
        payload: res.data.data,
      });
    } catch (err) {
      dispatch({
        type: messageConstants.GET_TICKET_FAILURE,
        payload: err,
      });
    }
  };
};

//GET support ticket by id
export const getMsgByToken = (id) => {
  return async (dispatch) => {
    dispatch({ type: messageConstants.GET_SUPPORT_By_ID_REQUEST });
    const res = await axios.get(`/support/${id}`);
    if (res.status === 200) {
      dispatch({
        type: messageConstants.GET_SUPPORT_By_ID_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else if (res.status === 400) {
      dispatch({
        type: messageConstants.GET_SUPPORT_By_ID_FAILURE,
        payload: res.data,
      });
      return res.data;
    }
  };
};

//GET messages by support ticket id
export const getMessageByTicket = (id) => {
  return async (dispatch) => {
    dispatch({ type: messageConstants.GET_MESSAGE_BY_TICKET_REQUEST });
    const res = await axios.get(`/message/token/${id}`);
    if (res.status === 200) {
      dispatch({
        type: messageConstants.GET_MESSAGE_BY_TICKET_SUCCESS,
        payload: res.data.data,
      });
      return res.data;
    } else if (res.status === 400) {
      dispatch({
        type: messageConstants.GET_MESSAGE_BY_TICKET_FAILURE,
        payload: res.data,
      });
    }
  };
};

//Create message for support ticket
export const sendMessageByTicket = (id, data) => {
  return async (dispatch) => {
    dispatch({ type: messageConstants.POST_MESSAGE_BY_TICKET_REQUEST });
    const res = await axios.post(`/message/token/${id}`, data);
    if (res.status === 200) {
      dispatch({
        type: messageConstants.POST_MESSAGE_BY_TICKET_SUCCESS,
        payload: res.data,
      });
    } else if (res.status === 400) {
      dispatch({
        type: messageConstants.POST_MESSAGE_BY_TICKET_SUCCESS,
        payload: res.data,
      });
      return res.data;
    }
  };
};

export const createNewMsg = (data) => {
  return async (dispatch) => {
    dispatch({ type: messageConstants.CREATE_NEW_MESSAGE_REQUEST });
    try {
      const res = await axios.post(`/message`, data);
      dispatch({
        type: messageConstants.CREATE_NEW_MESSAGE_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: messageConstants.CREATE_NEW_MESSAGE_FAILURE,
        payload: err,
      });
    }
  };
};

export const getMsg = () => {
  return async (dispatch) => {
    dispatch({ type: messageConstants.GET_MESSAGE_REQUEST });
    const res = await axios.get("/message");
    if (res.status === 200) {
      dispatch({
        type: messageConstants.GET_MESSAGE_SUCCESS,
        payload: res.data,
      });
    } else if (res.status === 400) {
      dispatch({
        type: messageConstants.GET_MESSAGE_FAILURE,
        payload: res.data,
      });
    }
  };
};

export const createAdminMsg = (data) => {
  return async (dispatch) => {
    dispatch({ type: messageConstants.CREATE_ADMIN_MESSAGE_REQUEST });
    try {
      const res = await axios.post(`/admin/message`, data);
      dispatch({
        type: messageConstants.CREATE_ADMIN_MESSAGE_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: messageConstants.CREATE_ADMIN_MESSAGE_FAILURE,
        payload: err,
      });
    }
  };
};

export const getAdminMsg = () => {
  return async (dispatch) => {
    dispatch({ type: messageConstants.GET_ADMIN_MESSAGE_REQUEST });
    try {
      const res = await axios.get(`/admin/message`);
      dispatch({
        type: messageConstants.GET_ADMIN_MESSAGE_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: messageConstants.GET_ADMIN_MESSAGE_FAILURE,
        payload: err,
      });
    }
  };
};
