import { applyMiddleware, createStore } from "redux";
import {thunk} from "redux-thunk";
import RootReducer from '../Reducers/rootReducer'
// import { composeWithDevTools } from "redux-devtools-extension";

// const store = createStore(
//   RootReducer,
//   composeWithDevTools(applyMiddleware(thunk))
// );

const store = createStore(RootReducer, applyMiddleware(thunk));

export default store;