import React from "react";
import PropTypes from "prop-types";
//Icons importing from /Assets/Icons
import MailNegative from "../Assets/Icons/Huge-icon-communication-outline-mail-negative.svg";
import Shield from "../Assets/Icons/Huge-icon-ecommerce-outline-shield.svg";
import OutlineBook from "../Assets/Icons/huge-icon-education-outline-book.svg";
import OutlineStack from "../Assets/Icons/huge-icon-education-outline-cube-stack.svg";
import OutlineUser from "../Assets/Icons/Huge-icon-user-outline-user.svg";
import MenuUserOutline from "../Assets/Icons/Huge-icon-menu-outline-menu-user.svg";
import SmartHomeOutline from "../Assets/Icons/Huge-icon-smart-house-outline-home-shield.svg";
import FolderOutline from "../Assets/Icons/Huge-icon-files-and-folder-outline-archive-upload.svg";
import HomeGraph from "../Assets/Icons/Huge-icon-smart-house-outline-home-graph-01.svg";
import ClockCircle from "../Assets/Icons/Huge-icon-interface-outline-clock-circle.svg";
import RoundedDollar from "../Assets/Icons/dollar-rounded.svg";
import BagOutline from "../Assets/Icons/Cart.svg";
import HomeRefresh from "../Assets/Icons/Group-78817.svg";
import HomeVector from "../Assets/Icons/Vector-1.svg";
import HomeChecked from "../Assets/Icons/Vector.svg";
import HomeLocation from "../Assets/Icons/Huge-icon-smart-house-solid-home-location-01.svg";
import HomeNotify from "../Assets/Icons/Huge-icon-smart-house-outline-home-notification01.svg";
import ArrowUp from "../Assets/Icons/Huge-icon-smart-house-outline-home-arrow-up1.svg";
import OutlineSearch from "../Assets/Icons/Huge-icon-interface-outline-search.svg";
import Message from "../Assets/Icons/Message.svg";
import Bell_Icon from "../Assets/Icons/Bell_Icon.svg";
import Diamond from "../Assets/Icons/diamond.svg";
import CoinDollar from "../Assets/Icons/coin_dollar.svg";
import WalletOutline from "../Assets/Icons/WalletOutline.svg";
import LockOutline from "../Assets/Icons/LockOutline.svg";
import LifebuoyOutline from "../Assets/Icons/LifebuoyOutline.svg";
import UserPolygon from "../Assets/Icons/UserPolygon.svg";
import OutlineLogout from "../Assets/Icons/Huge-icon-interface-outline-logout.svg";
import OutlineGrid from "../Assets/Icons/Huge-icon-grid-outline-grid.svg";
import GrayOutlineGrid from "../Assets/Icons/Huge-icon-grid-outline-grid-G.svg";
import OutlineLayout from "../Assets/Icons/Huge-icon-grid-outline-layout.svg";
import WhiteOutlineLayout from "../Assets/Icons/Huge-icon-grid-outline-layout-W.svg";
import OutlineSort from "../Assets/Icons/Huge-icon-interface-outline-sort-rectangle.svg";
import OutlineFilter from "../Assets/Icons/Huge-icon-interface-outline-filter.svg";
import Calendar from "../Assets/Icons/Calendar.svg";

const icons = {
  MailNegative,
  Shield,
  OutlineBook,
  OutlineStack,
  OutlineUser,
  MenuUserOutline,
  SmartHomeOutline,
  FolderOutline,
  HomeGraph,
  BagOutline,
  RoundedDollar,
  ClockCircle,
  HomeRefresh,
  HomeVector,
  HomeChecked,
  HomeLocation,
  HomeNotify,
  ArrowUp,
  OutlineSearch,
  Bell_Icon,
  Message,
  CoinDollar,
  Diamond,
  WalletOutline,
  LockOutline,
  LifebuoyOutline,
  UserPolygon,
  OutlineLogout,
  OutlineGrid,
  OutlineLayout,
  OutlineSort,
  WhiteOutlineLayout,
  OutlineFilter,
  GrayOutlineGrid,
  Calendar
};

const Icon = ({ name, ...props }) => {
  const IconComponent = icons[name];

  if (!IconComponent) {
    return null;
  }

  return <img src={IconComponent} alt={name} {...props} />;
};

Icon.propTypes = {
  name: PropTypes.oneOf([
    "MailNegative",
    "Shield",
    "OutlineBook",
    "OutlineStack",
    "OutlineUser",
    "MenuUserOutline",
    "SmartHomeOutline",
    "FolderOutline",
    "HomeGraph",
    "BagOutline",
    "RoundedDollar",
    "ClockCircle",
    "HomeRefresh",
    "HomeVector",
    "HomeChecked",
    "HomeLocation",
    "HomeNotify",
    "ArrowUp",
    "OutlineSearch",
    "Bell_Icon",
    "Message",
    "CoinDollar",
    "Diamond",
    "WalletOutline",
    "LockOutline",
    "LifebuoyOutline",
    "UserPolygon",
    "OutlineLogout",
    "OutlineGrid",
    "OutlineLayout",
    "OutlineSort",
    "OutlineFilter",
    "Calendar"
  ]).isRequired,
};

export default Icon;
