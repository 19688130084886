import axios from "../Utils/axios";
import { reportConstants } from "./constants";

export const getPropertyDetails = (id) => {
  return async (dispatch) => {
    dispatch({ type: reportConstants.GET_PROPERTY_DETAILS_REQUEST });
    try {
      const res = await axios.get(`/report/propertyDetails/${id}`);
      dispatch({
        type: reportConstants.GET_PROPERTY_DETAILS_SUCCESS,
        payload: res.data.data,
      });
    } catch (err) {
      dispatch({
        type: reportConstants.GET_PROPERTY_DETAILS_FAILURE,
        payload: err,
      });
    }
  };
};

export const updatePropertyDetails = (id, data) => {
  return async (dispatch) => {
    dispatch({ type: reportConstants.UPDATE_PROPERTY_DETAILS_REQUEST });
    const res = await axios.post(`/report/propertyDetails/${id}`, data);
    if (res.status === 200) {
      dispatch({
        type: reportConstants.UPDATE_PROPERTY_DETAILS_SUCCESS,
        payload: res.data,
      });
    } else if (res.status === 400) {
      dispatch({
        type: reportConstants.UPDATE_PROPERTY_DETAILS_FAILURE,
        payload: res.data,
      });
      return res.data;
    }
  };
};

export const getRepairDetails = (id) => {
  return async (dispatch) => {
    dispatch({ type: reportConstants.GET_REPAIR_DETAILS_REQUEST });
    try {
      const res = await axios.get(`/report/repairs/${id}`);
      dispatch({
        type: reportConstants.GET_REPAIR_DETAILS_SUCCESS,
        payload: res.data.data,
      });
    } catch (err) {
      dispatch({
        type: reportConstants.GET_REPAIR_DETAILS_FAILURE,
        payload: err,
      });
    }
  };
};

export const updateRepairDetails = (data, id) => {
  return async (dispatch) => {
    dispatch({ type: reportConstants.UPDATE_REPAIR_DETAILS_REQUEST });
    const res = await axios.post(`/report/repairs/${id}`, data);
    try {
      dispatch({
        type: reportConstants.UPDATE_REPAIR_DETAILS_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: reportConstants.UPDATE_REPAIR_DETAILS_FAILURE,
        payload: err,
      });
    }
  };
};

export const getMarketTrend = (id) => {
  return async (dispatch) => {
    dispatch({ type: reportConstants.GET_MARKET_TREND_REQUEST });
    try {
      const res = await axios.get(`/report/market/${id}`);
      dispatch({
        type: reportConstants.GET_MARKET_TREND_SUCCESS,
        payload: res.data.data,
      });
    } catch (err) {
      dispatch({
        type: reportConstants.GET_MARKET_TREND_FAILURE,
        payload: err,
      });
    }
  };
};

export const updateMarketTrend = (id, data) => {
  return async (dispatch) => {
    dispatch({ type: reportConstants.UPDATE_MARKET_TREND_REQUEST });
    try {
      const res = await axios.post(`/report/market/${id}`, data);
      dispatch({
        type: reportConstants.UPDATE_MARKET_TREND_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: reportConstants.UPDATE_MARKET_TREND_FAILURE,
        payload: err,
      });
    }
  };
};

export const getComment = (data) => {
  return async (dispatch) => {
    dispatch({ type: reportConstants.GET_COMMENT_REQUEST });
    try {
      const res = await axios.get(`/comment/${data}`);
      dispatch({
        type: reportConstants.GET_COMMENT_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: reportConstants.GET_COMMENT_FAILURE,
        payload: err,
      });
    }
  };
};

export const getReconciliation = (data) => {
  return async (dispatch) => {
    dispatch({ type: reportConstants.GET_RECONCILIATION_REQUEST });
    try {
      const res = await axios.get(`/reconciliation/${data}`);
      dispatch({
        type: reportConstants.GET_RECONCILIATION_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: reportConstants.GET_RECONCILIATION_FAILURE,
        payload: err,
      });
    }
  };
};

export const updateReconciliation = (data) => {
  return async (dispatch) => {
    dispatch({ type: reportConstants.UPDATE_RECONCILIATION_REQUEST });
    try {
      const res = await axios.put(`/reconciliation/${data.id}`, data);
      dispatch({
        type: reportConstants.UPDATE_RECONCILIATION_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: reportConstants.UPDATE_RECONCILIATION_FAILURE,
        payload: err,
      });
    }
  };
};

export const getReport = (data) => {
  return async (dispatch) => {
    dispatch({ type: reportConstants.GET_REPORT_REQUEST });
    try {
      const res = await axios.get(`/report/${data}`);
      dispatch({
        type: reportConstants.GET_REPORT_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: reportConstants.GET_REPORT_FAILURE,
        payload: err,
      });
    }
  };
};

export const submitReport = (data) => {
  return async (dispatch) => {
    dispatch({ type: reportConstants.SUBMIT_REPORT_REQUEST });
    try {
      const res = await axios.post(`/report`, data);
      dispatch({
        type: reportConstants.SUBMIT_REPORT_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: reportConstants.SUBMIT_REPORT_FAILURE,
        payload: err,
      });
    }
  };
};

export const getRevisions = (data) => {
  return async (dispatch) => {
    dispatch({ type: reportConstants.GET_REVISIONS_REQUEST });
    try {
      const res = await axios.get(`/revision/${data}`);
      dispatch({
        type: reportConstants.GET_REVISIONS_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: reportConstants.GET_REVISIONS_FAILURE,
        payload: err,
      });
    }
  };
};

export const getFile = (id, type) => {
  return async (dispatch) => {
    dispatch({ type: reportConstants.GET_FILE_REQUEST });
    try {
      const res = await axios.get(
        type ? `/report/file/${id}?type=${type}` : `/report/file/${id}`
      );
      dispatch({
        type: reportConstants.GET_FILE_SUCCESS,
        payload: res.data.data,
      });
    } catch (err) {
      dispatch({
        type: reportConstants.GET_FILE_FAILURE,
        payload: err,
      });
    }
  };
};

export const updateFile = (id, type, data) => {
  return async (dispatch) => {
    dispatch({ type: reportConstants.UPDATE_FILE_REQUEST });
    const res = await axios.post(`/report/file/${id}?type=${type}`, data);
    if (res.status === 200) {
      dispatch({
        type: reportConstants.UPDATE_FILE_SUCCESS,
        payload: res.data,
      });
    } else if (res.status === 400) {
      dispatch({
        type: reportConstants.UPDATE_FILE_FAILURE,
        payload: res.data,
      });
      return res.data;
    }
  };
};

export const deleteFile = (data) => {
  return async (dispatch) => {
    dispatch({ type: reportConstants.DELETE_FILE_REQUEST });
    try {
      const res = await axios.delete(`/file/${data}`);
      dispatch({
        type: reportConstants.DELETE_FILE_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: reportConstants.DELETE_FILE_FAILURE,
        payload: err,
      });
    }
  };
};

export const getSketches = (id) => {
  return async (dispatch) => {
    dispatch({ type: reportConstants.GET_SKETCH_REQUEST });
    try {
      const res = await axios.get(`/report/sketch/${id}`);
      dispatch({
        type: reportConstants.GET_SKETCH_SUCCESS,
        payload: res.data.data,
      });
    } catch (err) {
      dispatch({
        type: reportConstants.GET_SKETCH_FAILURE,
        payload: err,
      });
    }
  };
};
