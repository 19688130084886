import React, { useEffect, useState } from "react";
import Template from "../../Template";
import { Box } from "@mui/material";
import Text from "../../Components/Core/Text";
import {
  Assignment,
  Attachment,
  CalendarDot,
  ChatWritten,
  DirectionRight,
  DocumentText,
  FileAddBent,
  FileReload,
  FileWrittenContinued,
  Grid,
  ImageRectangle,
  MapLocationMiddle,
  MoneyDollar,
  RoofHomeCelsius,
  RoofHomeGraph,
  ShoppingBag,
  ShoppingBasketHorizontal,
  SpringNotes,
  Table,
  User,
} from "react-huge-icons/outline";
import { Information } from "react-huge-icons/solid";
import Input from "../../Components/Core/Input";
import Sketch from "../../Components/Sketch";
import MoreInfo from "../../Components/MoreInfo";
import LinearProgress from "@mui/material/LinearProgress";
import Button from "../../Components/Core/Button";
import MarketTrend from "./MarketTrend";
import Photos from "./Photos";
import CommentAddenda from "./CommentAddenda";
import OpinionOfValue from "./OpinionOfValue";
import Attachments from "./Attachments";
import ProprtyDetails from "./ProprtyDetails";
import History from "./History";
import { useDispatch, useSelector } from "react-redux";
import {
  getFile,
  getMarketTrend,
  getPropertyDetails,
  getRepairDetails,
  getSketches,
  updateRepairDetails,
} from "../../Actions/report";
import { useLocation } from "react-router";
import Prompt from "../../Components/Core/Prompt";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import ReportView from "../../Components/ReportView";
import Repairs from "./Repairs";

const Report = () => {
  const reports = useSelector((state) => state.report);

 console.log(reports)
  const [activePage, setactivePage] = useState("Details");
  const [showReadMore, setshowReadMore] = useState(false);
  const [repairsData, setrepairsData] = useState([]);
  const [propertyDetails, setpropertyDetails] = useState({});
  const [repairDetails, setrepairDetails] = useState({
    repairs: [
      {
        _id: 1,
        repairType: "Roof",
        comment: "Roof is leaking",
        estimateCost: 5000,
        isEmergency: true,
      },
    ],
  });
  const [makePrompt, setmakePrompt] = useState({
    open: false,
    message: "",
    type: "error",
  });
  const [marketDetails, setmarketDetails] = useState({});
  const dispatch = useDispatch();
  const location = useLocation();
  const report_id = location.state.id;

  useEffect(() => {
    dispatch(getPropertyDetails(report_id));
    dispatch(getRepairDetails(report_id));
    dispatch(getMarketTrend(report_id));
  }, []);

  useEffect(() => {
    if (reports.propertyDetails) {
      setpropertyDetails(reports.propertyDetails[0]);
    }
    if (reports.repairDetails) {
      setrepairDetails(reports.repairDetails[0]);
    }
    if (reports.marketTrend) {
      setmarketDetails(reports.marketTrend[0]);
    }

    dispatch(getSketches(report_id));
  }, [reports.propertyDetails]);

  const details = [
    {
      title: "Order ID: #154DF45",
      icon: <ShoppingBag fontSize={18} />,
    },
    {
      title: "Client Name: Joe willson",
      icon: <User fontSize={18} />,
    },
    {
      title: "Client File No: 78599",
      icon: <DocumentText fontSize={18} />,
    },
    {
      title: "Product Type: Lorem ipsom is",
      icon: <RoofHomeGraph fontSize={18} />,
    },
    {
      title: "Inspector Fee: $2,350",
      icon: <MoneyDollar fontSize={18} />,
    },
    {
      title: "Inspection Date: Dec 12, 2022",
      icon: <CalendarDot fontSize={18} />,
    },
    {
      title: "Effective Date: Dec 12, 2022",
      icon: <CalendarDot fontSize={18} />,
    },
  ];

  const report = [
    {
      title: "Proprty Details",
      icon: <SpringNotes fontSize={18} />,
      completed: 2,
      total: 3,
    },
    {
      title: "Repairs",
      icon: <RoofHomeCelsius fontSize={18} />,
      completed: 2,
      total: 3,
    },
    {
      title: "Neighborhood / Market",
      icon: <ShoppingBasketHorizontal fontSize={18} />,
      completed: 2,
      total: 3,
    },
    {
      title: "Select Comparables",
      icon: <FileAddBent fontSize={18} />,
      completed: 2,
      total: 3,
    },
    {
      title: "Comparable Grid",
      icon: <Table fontSize={18} />,
      completed: 2,
      total: 3,
    },
    {
      title: "History",
      icon: <FileReload fontSize={18} />,
      completed: 2,
      total: 3,
    },
    {
      title: "Map",
      icon: <MapLocationMiddle fontSize={18} />,
      completed: 2,
      total: 3,
    },
    {
      title: "Photos",
      icon: <ImageRectangle fontSize={18} />,
      completed: 2,
      total: 3,
    },
    {
      title: "Sketch",
      icon: <Assignment fontSize={18} />,
      completed: 2,
      total: 3,
    },
    {
      title: "Attachments",
      icon: <Attachment fontSize={18} />,
      completed: 2,
      total: 3,
    },

    {
      title: "Comment Addenda",
      icon: <ChatWritten fontSize={18} />,
      completed: 2,
      total: 3,
    },
    {
      title: "Opinion Of Value",
      icon: <FileWrittenContinued fontSize={18} />,
      completed: 2,
      total: 3,
    },
  ];
  const pageTypeData = activePage === "Details" ? details : report;

  const getPageType = () => {
    switch (activePage) {
      case "Details":
        return (
          <ProprtyDetails
            report_id={report_id}
            propertyDetails={propertyDetails}
            setpropertyDetails={setpropertyDetails}
          />
        );
      case "Report":
        return (
          <ProprtyDetails
            report_id={report_id}
            propertyDetails={propertyDetails}
            setpropertyDetails={setpropertyDetails}
          />
        );
      case "Proprty Details":
        return (
          <ProprtyDetails
            report_id={report_id}
            propertyDetails={propertyDetails}
            setpropertyDetails={setpropertyDetails}
          />
        );
      case "Repairs":
        return (
          <Repairs
            repairDetails={repairDetails}
            repairsData={repairsData}
            setrepairDetails={setrepairDetails}
          />
        );
      case "Neighborhood / Market":
        return (
          <MarketTrend
            marketDetails={marketDetails}
            setmarketDetails={setmarketDetails}
          />
        );
      case "Photos":
        return <Photos />;
      case "Sketch":
        return <Sketch />;
      case "Attachments":
        return <Attachments />;
      case "Map":
        return (
          <LoadScript googleMapsApiKey="AIzaSyDsl-qxnn1erCHcXCJFl4_JgqkDmggaQus">
            <GoogleMap
              mapContainerStyle={{ width: "100%", height: "600px" }}
              center={{ lat: -34.397, lng: 150.644 }}
              zoom={8}
            >
              {/* Add additional components or overlays here if needed */}
            </GoogleMap>
          </LoadScript>
        );
      case "Comment Addenda":
        return <CommentAddenda />;
      case "Opinion Of Value":
        return <OpinionOfValue />;
      case "History":
        return <History />;

      default:
        break;
    }
  };

  return (
    <Template
      showHeader
      headerProps={{
        showProfile: true,
      }}
      containerStyle={{
        backgroundImage: true,
        height: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "98%",
          ml: "auto",
          mr: "auto",
        }}
      >
        <Box sx={{ width: "15%" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              ml: "auto",
              width: "fit-content",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                p: "12px 20px",
                bgcolor: activePage !== "Details" ? "#F3F3F3" : "#FFFFFF",
                width: "48%",
                borderRadius: "20px 20px 0px 0px",
                mr: "2%",
                ml: "1em",
                cursor: "pointer",
              }}
              onClick={() => setactivePage("Details")}
            >
              <SpringNotes
                color={activePage === "Details" ? "#2593D6" : "#000000"}
                style={{
                  marginTop: "auto",
                  marginBottom: "auto",
                  marginRight: "10px",
                }}
              />
              <Text
                variant="body1"
                style={{
                  color: activePage === "Details" ? "#2593D6" : "#000000",
                }}
              >
                Details
              </Text>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                p: "12px 20px",
                bgcolor: activePage !== "Report" ? "#F3F3F3" : "#FFFFFF",
                width: "40%",
                borderRadius: "20px 20px 0px 0px",
                cursor: "pointer",
                ml: "2%",
              }}
              onClick={() => setactivePage("Report")}
            >
              <Grid
                color={activePage === "Details" ? "#000000" : "#2593D6"}
                style={{
                  marginTop: "auto",
                  marginBottom: "auto",
                  marginRight: "10px",
                }}
              />
              <Text
                variant="body1"
                style={{
                  color: activePage === "Details" ? "#000000 " : "#2593D6",
                }}
              >
                Report
              </Text>
            </Box>
          </Box>
          <Box
            sx={{
              background:
                " transparent linear-gradient(150deg, #2593D6 0%, #134A6B 100%) 0% 0% no-repeat padding-box",
              padding: "28px 20px",
              borderRadius: "24px",
              boxShadow: "15px 15px 50px #00000029",
              backdropFilter: "blur(50px)",
              height: "70vh",
              width: "100%",
            }}
          >
            {pageTypeData.map((item, index) => (
              <Box
                key={index}
                onClick={() => setactivePage(item.title)}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  p: 1,
                  cursor: activePage !== "Details" ? "pointer" : "default",
                  border:
                    activePage !== "Details" && activePage === item.title
                      ? "1px solid #FFFFFF"
                      : "none",
                  borderRadius: "16px",
                  height:
                    activePage !== "Details" && activePage === item.title
                      ? "48px"
                      : "auto",
                  mb:
                    activePage !== "Details" && activePage === item.title
                      ? "1em"
                      : "0",
                  boxShadow:
                    activePage !== "Details" && activePage === item.title
                      ? "15px 15px 50px #00000029"
                      : "none",
                  background:
                    "transparent url('../../Assets/Images/RectangleMenu.svg') 0% 0% no-repeat padding-box",
                  backdropFilter:
                    activePage !== "Details" && activePage === item.title
                      ? "blur(50px)"
                      : "none",
                  WebkitBackdropFilter:
                    activePage !== "Details" && activePage === item.title
                      ? "blur(50px)"
                      : "none",
                }}
              >
                <Box sx={{ m: "auto 0", mr: "10px", color: "#ffff" }}>
                  {item.icon}
                </Box>
                <Text
                  variant="body2"
                  con
                  style={{
                    color: "white",
                    font: "normal normal normal 14px/21px Poppins",
                    fontSize:
                      activePage === item.title && item.title.length >= 18
                        ? "12px"
                        : "13.5px",
                    fontWeight: "normal !important",
                    fontFamily: "Poppins",
                    opacity: 0.8,
                    margin:
                      activePage !== "Details" && activePage === item.title
                        ? "10px auto auto 0"
                        : "-2px auto auto 0",
                  }}
                >
                  {item.title}{" "}
                  {activePage === item.title &&
                    `(${item.completed}/${item.total})`}
                </Text>
                {activePage !== "Details" && activePage !== item.title && (
                  <DirectionRight
                    fontSize={20}
                    style={{
                      color: "white",
                      margin: "auto 0",
                    }}
                  />
                )}
                {activePage !== "Details" && activePage === item.title && (
                  <LinearProgress
                    value={(item.completed / item.total) * 100}
                    disableShrink
                    variant="determinate"
                    sx={{
                      position: "absolute",
                      bottom: 10,
                      left: 0,
                      right: 0,
                      width: "88%",
                      margin: "auto",
                      borderRadius: "24px",
                      "& .MuiLinearProgress-bar": {
                        backgroundImage:
                          "transparent linear-gradient(180deg, #0477BE 0%, #2593D6 100%)",
                        backgroundPosition: "0% 0%",
                        backgroundRepeat: "no-repeat",
                        padding: "box",
                      },
                    }}
                  />
                )}
              </Box>
            ))}
          </Box>
        </Box>
        <Box sx={{ width: "76%", ml: "auto", mr: "auto" }}>
          <Box sx={{ display: "flex", flexDirection: "row", mb: "2%" }}>
            <Box
              sx={{
                backgroundColor: "#F8E7E8",
                display: "flex",
                flexDirection: "row",
                p: "12px",
                width: "80%",
                borderRadius: "18px",
                mr: "auto",
                height: "fit-content",
              }}
            >
              <Information
                color="#EE1616"
                fontSize={20}
                style={{
                  marginTop: "auto",
                  marginBottom: "auto",
                  marginRight: "10px",
                }}
              />
              <Box>
                <Text
                  variant="body2"
                  style={{
                    color: "#000000",
                    font: "normal normal 600 14px/21px Poppins",
                    fontSize: "14px",
                    fontWeight: "normal !important",
                  }}
                >
                  Revise Report{" "}
                </Text>
                <Text
                  style={{
                    font: "normal normal normal 12px/20px Poppins",
                    color: "#6D6D6D",
                    marginTop: "-5px",
                    fontSize: "12px",
                  }}
                >
                  Lorem Ipsum is simply dummy text of the printing that can
                  simply dummy text of the printing that can simply dummy text
                  <span
                    onClick={() => setshowReadMore(!showReadMore)}
                    style={{
                      color: "#EE1616",
                      cursor: "pointer",
                      fontWeight: "bold",
                      marginLeft: "6px",
                    }}
                  >
                    Read More
                  </span>
                </Text>
              </Box>
            </Box>
            <Text
              style={{
                fontSize: "13px",
                color: "#2C80FF",
                marginTop: "auto",
                marginBottom: "auto",
                marginRight: "10px",
              }}
            >
              Back
            </Text>
          </Box>
          <Box
            sx={{
              background: "#FFFFFF 0% 0% no-repeat padding-box",
              borderRadius: "24px",
              p: 1,
              height: "fit-content",
            }}
          >
            {/* <Sketch /> */}
            {getPageType()}
          </Box>
        </Box>
      </Box>
      {showReadMore && (
        <MoreInfo
          handleClose={() => setshowReadMore(false)}
          title={"Revise Report"}
          description={
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500"
          }
        />
      )}
      <Prompt
        open={makePrompt.open}
        message={makePrompt.message}
        type={makePrompt.type}
        handleClose={() =>
          setmakePrompt({ open: false, message: "", type: "error" })
        }
      />
      {/* <ReportView
        property={{
          price: 500000, 
          address: "123 Main Street, Anytown, CA 12345",
          orderId: "ABC123",
          inspectionDate: "2024-03-25",
          type: "Single Family Home",
          gla: "2500 sq ft",
          additionalNotes:
            "This property is in a great location with a spacious backyard. It has been well-maintained and is move-in ready.",
          amenities: [
            "Central A/C",
            "Granite Countertops",
            "Stainless Steel Appliances",
          ],
        }}
      /> */}
    </Template>
  );
};

export default Report;
