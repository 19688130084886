import React from "react";

const Text = ({
  variant = "body1",
  children,
  style,
  className,
  onClick,
  id,
}) => {
  const getStyles = () => {
    const baseFont = {
      font: "normal normal",
      margin: "4px 0",
    };

    switch (variant) {
      case "h1":
      case "h2":
        return {
          ...baseFont,
          fontWeight: 600,
          fontSize: "15px",
          fontFamily: "Poppins",
          color: "#191A1C",
        };
      case "body1":
      case "body2":
        return {
          ...baseFont,
          fontSize: "14px",
          fontWeight: "normal",
          fontFamily: "Poppins",
          color: "#474C4E",
        };
      default:
        return {};
    }
  };

  const mergedStyles = {
    ...getStyles(),
    ...style,
    color: style?.color || getStyles()?.color,
  };

  return (
    <div
      className={`root-text ${className || ""}`}
      style={mergedStyles}
      onClick={onClick}
      id={id}
    >
      {children}
    </div>
  );
};

export default Text;
