import React from "react";
import { Box, CircularProgress } from "@mui/material";
import "./index.css";

const Button = ({ style, children, isLoading, isTransparent, ...props }) => {
  const mergedStyle = {
    background: isTransparent
      ? "transparent"
      : "rgba(44, 128, 255, 1) 0% 0% no-repeat padding-box",
    height: "40px",
    borderRadius: "11px",
    opacity: isLoading || props.disabled ? 0.6 : 1,
    ...style,
  };

  const buttonStyle = {
    cursor: "pointer",
    border: isTransparent ? "2px solid #2D81FF" : "none",
    outline: "none",
    backgroundColor: "transparent",
    color:
      style && style.color !== undefined
        ? style.color
        : isTransparent
        ? "#2D81FF"
        : "rgba(255, 255, 255, 1)",
    font: "normal normal medium 14px/21px Poppins",
    fontWeight: "500",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    borderRadius: "11px",
    color: style?.color || "#fff",
    ...props.buttonStyle,
  };

  return (
    <Box sx={mergedStyle}>
      <button
        {...props}
        disabled={isLoading || props.disabled}
        style={buttonStyle}
      >
        {isLoading && (
          <CircularProgress
            size={16}
            sx={{
              color: "#fff",
              marginRight: "6px",
            }}
          />
        )}
        {!isLoading && (
          <div
            style={{
              marginTop: "auto",
              marginBottom: "auto",
              display: "flex",
              flexDirection: "row",
              color:
                style && style.color !== undefined
                  ? style.color
                  : isTransparent
                  ? "#2D81FF"
                  : "rgba(255, 255, 255, 1)",
            }}
          >
            {children}
          </div>
        )}
      </button>
    </Box>
  );
};

export default Button;
