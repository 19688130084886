import { checkKeys } from "../Utils";
import axios from "../Utils/axios";
import {
  billingInfoKeys,
  companyInfoKeys,
  coverageAreaKeys,
  documentKeys,
  insuranceKeys,
  licensekeys,
  personalInfoKeys,
  qualificationKeys,
} from "../Utils/keys";
import { authConstants, completeProfileConstants } from "./constants";

export const registerUser = (data) => {
  return async (dispatch) => {
    dispatch({ type: authConstants.REGISTER_REQUEST });
    const res = await axios.post("/auth/signup", data);
    if (res.status === 200) {
      dispatch({
        type: authConstants.REGISTER_SUCESS,
        payload: {
          user: res.data.status,
          message: "User Saved Sucessfully",
        },
      });
    } else {
      if (res.status === 400) {
        dispatch({
          type: authConstants.REGISTER_FAILURE,
          payload: { error: res.data },
        });
        return res.data;
      }
    }
  };
};

export const otpRequest = (data) => {
  return async (dispatch) => {
    dispatch({ type: authConstants.OTP_REQUEST });
    const res = await axios.post("/auth/sendOtp", data);
    if (res.status === 200) {
      dispatch({
        type: authConstants.OTP_SUCCESS,
        payload: {
          user: res.data.data,
          role: data.type,
          message: "OTP Send Sucessfully",
        },
      });
    } else {
      if (res.status === 400) {
        dispatch({
          type: authConstants.OTP_FAILURE,
          payload: { error: res.data },
        });
        return res.data;
      }
    }
  };
};

export const verifyOtp = (data) => {
  return async (dispatch) => {
    dispatch({ type: authConstants.LOGIN_REQUEST });
    const res = await axios.post("/auth/verifyOtp", data);
    if (res.status === 200) {
      dispatch({
        type: authConstants.VERIFY_OTP_SUCCESS,
        payload: {
          user: res.data.data,
          message: "OTP Verified Sucessfully",
        },
      });
    } else {
      if (res.status === 400) {
        dispatch({
          type: authConstants.LOGIN_FAILURE,
          payload: { error: res.data },
        });
        return res.data;
      }
    }
  };
};

export const resetPassword = (data) => {
  return async (dispatch) => {
    dispatch({ type: authConstants.RESET_PASSWORD_REQUEST });
    const res = await axios.post("/auth/resetPassword", data);
    if (res.status === 200) {
      dispatch({
        type: authConstants.RESET_PASSWORD_SUCCESS,
        payload: {
          message: "Password Reset Sucessfully",
        },
      });
    } else {
      if (res.status === 400) {
        dispatch({
          type: authConstants.RESET_PASSWORD_FAILURE,
          payload: { error: res.data },
        });
        return res.data;
      }
    }
  };
};

export const loginUser = (data) => {
  return async (dispatch) => {
    dispatch({ type: authConstants.LOGIN_REQUEST });
    const res = await axios.post("/auth/login", data);
    if (res.status === 200) {
      const { token } = res.data.data;
      localStorage.setItem("token", token);
      localStorage.setItem("user", JSON.stringify(res.data.data));
      localStorage.setItem("role", res.data.data.role);
      if (res.data.data.agreementAccepted === false) {
        localStorage.setItem("completion", "State License(s)");
      }
      dispatch({
        type: authConstants.LOGIN_SUCCESS,
        payload: {
          token,
          user: res.data.data,
          message: "Login Sucessfully",
        },
      });
      return res.data.data
    } else {
      if (res.status === 400) {
        dispatch({
          type: authConstants.LOGIN_FAILURE,
          payload: { error: res.data },
        });
        return res.data;
      }
    }
  };
};

export const isuserLoggedIn = () => {
  return async (dispatch) => {
    dispatch({ type: authConstants.LOGIN_REQUEST });
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");
    const decryptedUser = user ? JSON.parse(user) : {};
    if (token) {
      dispatch({
        type: authConstants.LOGIN_SUCCESS,
        payload: {
          token,
          user: decryptedUser,
          message: "Login Sucessfully",
        },
      });
    } else {
      dispatch({
        type: authConstants.LOGOUT_FAILURE,
        payload: { error: "Failed to login" },
      });
    }
  };
};

export const logout = () => {
  return async (dispatch) => {
    dispatch({
      type: authConstants.LOGOUT_REQUEST,
    });
    localStorage.clear();
    dispatch({
      type: authConstants.LOGOUT_SUCESS,
    });
  };
};

export const addLicence = (data, isUpdating) => {
  return async (dispatch) => {
    dispatch({ type: completeProfileConstants.ADD_LICENCE_REQUEST });
    const res = await axios.post("/license", data);
    if (res.status === 200) {
      if (!isUpdating) {
        window.localStorage.setItem("completion", "Personal Information");
      }
      dispatch({
        type: completeProfileConstants.ADD_LICENCE_SUCCESS,
        payload: {
          data: res.data,
          message: "Licence Added Sucessfully",
        },
      });
    } else {
      if (res.status === 400) {
        dispatch({
          type: completeProfileConstants.ADD_LICENCE_FAILURE,
          payload: { error: res.data },
        });
        return res.data;
      }
    }
  };
};

export const addCompany = (data, isUpdating) => {
  return async (dispatch) => {
    dispatch({ type: completeProfileConstants.ADD_COMPANY_REQUEST });
    const res = await axios.put("/users/", data);

    if (res.status === 200) {
      dispatch({
        type: completeProfileConstants.ADD_COMPANY_SUCCESS,
        payload: {
          user: res.data.data,
          message: "Company Added Sucessfully",
        },
      });
      if (!isUpdating) {
        localStorage.setItem(
          "completion",
          res.data?.data?.role === "Appraiser"
            ? "Appraiser Qualification"
            : "Coverage Area"
        );
      }
    } else {
      if (res.status === 400) {
        dispatch({
          type: completeProfileConstants.ADD_COMPANY_FAILURE,
          payload: { error: res.data },
        });
        return res.data;
      }
    }
  };
};

export const addPersonal = (data, isUpdating) => {
  return async (dispatch) => {
    dispatch({ type: completeProfileConstants.ADD_PERSONAL_REQUEST });
    const res = await axios.put("/users", data);
    if (res.status === 200) {
      if (!isUpdating) {
        localStorage.setItem("completion", "Company Information");
      }
      dispatch({
        type: completeProfileConstants.ADD_PERSONAL_SUCCESS,
        payload: {
          user: res.data.data,
          message: "Personal Added Sucessfully",
        },
      });
    } else {
      if (res.status === 400) {
        dispatch({
          type: completeProfileConstants.ADD_PERSONAL_FAILURE,
          payload: { error: res.data },
        });
        return res.data;
      }
    }
  };
};

export const addCoverageArea = (data, isUpdating) => {
  return async (dispatch) => {
    dispatch({ type: completeProfileConstants.ADD_COVERAGE_AREA_REQUEST });
    const res = await axios.post("/users/coverageArea", data);
    if (res.status === 200) {
      if (!isUpdating) {
        window.localStorage.setItem("completion", "Digital Signature");
      }
      dispatch({
        type: completeProfileConstants.ADD_COVERAGE_AREA_SUCCESS,
        payload: {
          user: res.data.data,
          message: "Coverage Area Added Sucessfully",
        },
      });
    } else {
      if (res.status === 400) {
        dispatch({
          type: completeProfileConstants.ADD_COVERAGE_AREA_FAILURE,
          payload: { error: res.data },
        });
        return res.data;
      }
    }
  };
};

export const addDigital = (data, isUpdating) => {
  return async (dispatch) => {
    dispatch({ type: completeProfileConstants.ADD_DIGITAL_REQUEST });
    const res = await axios.post("/users/signature", data);
    if (res.status === 200) {
      if (!isUpdating) {
        window.localStorage.setItem("completion", "Billing");
      }
      dispatch({
        type: completeProfileConstants.ADD_DIGITAL_SUCCESS,
        payload: {
          user: res.data.data,
          message: "Digital Added Sucessfully",
        },
      });
    } else {
      if (res.status === 400) {
        dispatch({
          type: completeProfileConstants.ADD_DIGITAL_FAILURE,
          payload: { error: res.data },
        });
        return res.data;
      }
    }
  };
};

export const addBilling = (data, isUpdating) => {
  return async (dispatch) => {
    dispatch({ type: completeProfileConstants.ADD_BILLING_REQUEST });
    const res = await axios.post("/billing", data);
    if (res.status === 200) {
      if (!isUpdating) {
        window.localStorage.setItem("completion", "Document");
      }
      dispatch({
        type: completeProfileConstants.ADD_BILLING_SUCCESS,
        payload: {
          user: res.data.data,
          message: "Billing Added Sucessfully",
        },
      });
    } else {
      if (res.status === 400) {
        dispatch({
          type: completeProfileConstants.ADD_BILLING_FAILURE,
          payload: { error: res.data },
        });
        return res.data;
      }
    }
  };
};

export const addDocument = (data, isUpdating) => {
  return async (dispatch) => {
    dispatch({ type: completeProfileConstants.ADD_DOCUMENT_REQUEST });
    const res = await axios.post("/documents", data);
    if (res.status === 200) {
      if (!isUpdating) {
        window.localStorage.setItem("completion", "Registration Agreement");
      }
      dispatch({
        type: completeProfileConstants.ADD_DOCUMENT_SUCCESS,
        payload: {
          data: res.data.data,
          message: "Document Added Sucessfully",
        },
      });
    } else if (res.status === 400 || res.status === 204) {
      dispatch({
        type: completeProfileConstants.ADD_DOCUMENT_FAILURE,
        payload: { error: res.data },
      });
      return res.data;
    }
  };
};

export const verifyAgreement = (data, isUpdating) => {
  return async (dispatch) => {
    dispatch({ type: completeProfileConstants.VERIFY_AGREEMENT_REQUEST });
    const res = await axios.post("/users/registration", data);
    if (res.status === 200) {
      if (!isUpdating) {
        window.localStorage.setItem("completion", "Registration Agreement");
      }
      dispatch({
        type: completeProfileConstants.VERIFY_AGREEMENT_SUCCESS,
        payload: {
          data: res.data.data,
          message: "Agreement Verified Sucessfully",
        },
      });
    } else if (res.status === 400) {
      dispatch({
        type: completeProfileConstants.VERIFY_AGREEMENT_FAILURE,
        payload: { error: res.data },
      });
      return res.data;
    }
  };
};

export const otpVerification = (data) => {
  return async (dispatch) => {
    dispatch({ type: completeProfileConstants.OTP_VERIFICATION_REQUEST });
    const res = await axios.post("/users/verifyRegistration", data);
    if (res.status === 200) {
      dispatch({
        type: completeProfileConstants.OTP_VERIFICATION_SUCCESS,
        payload: {
          data: res.data.data,
          message: "OTP Verified Sucessfully",
        },
      });
    } else if (res.status === 400) {
      dispatch({
        type: completeProfileConstants.OTP_VERIFICATION_FAILURE,
        payload: { error: res.data },
      });
      return res.data;
    }
  };
};

export const appraiserQualification = (data, isUpdating) => {
  return async (dispatch) => {
    dispatch({
      type: completeProfileConstants.APPRAISER_QUALIFICATION_REQUEST,
    });
    const res = await axios.post("/qualification", data);
    if (res.status === 200) {
      if (!isUpdating) {
        localStorage.setItem("completion", "E&o Insurance");
      }
      dispatch({
        type: completeProfileConstants.APPRAISER_QUALIFICATION_SUCCESS,
        payload: {
          data: res.data.data,
          message: "Qualification Added Sucessfully",
        },
      });
    } else if (res.status === 400) {
      dispatch({
        type: completeProfileConstants.APPRAISER_QUALIFICATION_FAILURE,
        payload: { error: res.data },
      });
      return res.data;
    }
  };
};

export const makeInsurance = (data, isUpdating) => {
  return async (dispatch) => {
    dispatch({
      type: completeProfileConstants.MAKE_INSURANCE_REQUEST,
    });
    const res = await axios.post("/eoInsurance", data);
    if (res.status === 200) {
      if (!isUpdating) {
        localStorage.setItem("completion", "Coverage Area");
      }
      dispatch({
        type: completeProfileConstants.MAKE_INSURANCE_SUCCESS,
        payload: {
          data: res.data.data,
          message: "Insurance Added Sucessfully",
        },
      });
    } else if (res.status === 400) {
      dispatch({
        type: completeProfileConstants.MAKE_INSURANCE_FAILURE,
        payload: { error: res.data },
      });
      return res.data;
    }
  };
};

export const getLicense = () => {
  return async (dispatch) => {
    dispatch({ type: completeProfileConstants.GET_LICENCE_REQUEST });
    const res = await axios.get("/license");
    if (res.status === 200) {
      dispatch({
        type: completeProfileConstants.GET_LICENCE_SUCCESS,
        payload: {
          data: res.data.data,
          fetchedItems: checkKeys(res.data.data, licensekeys)
            ? "State License(s)"
            : "",
          message: "Licence Get Sucessfully",
        },
      });
    } else if (res.status === 400) {
      dispatch({
        type: completeProfileConstants.GET_LICENCE_FAILURE,
        payload: { error: res.data },
      });
      return res.data;
    }
  };
};

export const getCompany = () => {
  return async (dispatch) => {
    dispatch({ type: completeProfileConstants.GET_COMPANY_REQUEST });
    const res = await axios.get("/users/detail");
    if (res.status === 200) {
      dispatch({
        type: completeProfileConstants.GET_COMPANY_SUCCESS,
        payload: {
          data: res.data.data,
          fetchedItems: checkKeys(res.data.data, companyInfoKeys)
            ? "Company Information"
            : "",
          message: "Company Get Sucessfully",
        },
      });
    } else if (res.status === 400) {
      dispatch({
        type: completeProfileConstants.GET_COMPANY_FAILURE,
        payload: { error: res.data },
      });
      return res.data;
    }
  };
};

export const getPersonal = () => {
  return async (dispatch) => {
    dispatch({ type: completeProfileConstants.GET_PERSONAL_REQUEST });
    const res = await axios.get("/users/detail");
    if (res.status === 200) {
      dispatch({
        type: completeProfileConstants.GET_PERSONAL_SUCCESS,
        payload: {
          data: res.data.data,
          message: "Personal Get Sucessfully",
          fetchedItems: checkKeys(res.data.data, personalInfoKeys)
            ? "Personal Information"
            : "",
        },
      });
    } else if (res.status === 400) {
      dispatch({
        type: completeProfileConstants.GET_PERSONAL_FAILURE,
        payload: { error: res.data },
      });
    }
  };
};

export const getCoverageArea = () => {
  return async (dispatch) => {
    dispatch({ type: completeProfileConstants.GET_COVERAGE_AREA_REQUEST });
    const res = await axios.get("/users/detail");
    if (res.status === 200) {
      dispatch({
        type: completeProfileConstants.GET_COVERAGE_AREA_SUCCESS,
        payload: {
          data: res.data.data,
          message: "Coverage Area Get Sucessfully",
          fetchedItems: checkKeys(res.data.data, coverageAreaKeys)
            ? "Coverage Area"
            : "",
        },
      });
    } else if (res.status === 400) {
      dispatch({
        type: completeProfileConstants.GET_COVERAGE_AREA_FAILURE,
        payload: { error: res.data },
      });
    }
  };
};

export const getDigital = () => {
  return async (dispatch) => {
    dispatch({ type: completeProfileConstants.GET_DIGITAL_REQUEST });
    const res = await axios.get("/users/detail");
    if (res.status === 200) {
      dispatch({
        type: completeProfileConstants.GET_DIGITAL_SUCCESS,
        payload: {
          data: res.data.data,
          fetchedItems: checkKeys(res.data.data, ["digitalSignature"])
            ? "Digital Signature"
            : "",
          message: "Digital Get Sucessfully",
        },
      });
    } else if (res.status === 400) {
      dispatch({
        type: completeProfileConstants.GET_DIGITAL_FAILURE,
        payload: { error: res.data },
      });
    }
  };
};

export const getBilling = () => {
  return async (dispatch) => {
    dispatch({ type: completeProfileConstants.GET_BILLING_REQUEST });
    const res = await axios.get("/billing");
    if (res.status === 200) {
      dispatch({
        type: completeProfileConstants.GET_BILLING_SUCCESS,
        payload: {
          data: res.data.data,
          fetchedItems: checkKeys(res.data.data, billingInfoKeys)
            ? "Billing"
            : "",
          message: "Billing Get Sucessfully",
        },
      });
    } else if (res.status === 400) {
      dispatch({
        type: completeProfileConstants.GET_BILLING_FAILURE,
        payload: { error: res.data },
      });
    }
  };
};

export const getDocument = () => {
  return async (dispatch) => {
    dispatch({ type: completeProfileConstants.GET_DOCUMENT_REQUEST });
    const res = await axios.get("/documents");
    if (res.status === 200) {
      dispatch({
        type: completeProfileConstants.GET_DOCUMENT_SUCCESS,
        payload: {
          data: res.data.data,
          message: "Document Get Sucessfully",
          fetchedItems: checkKeys(res.data.data, documentKeys)
            ? "Document"
            : "",
        },
      });
    } else if (res.status === 400) {
      dispatch({
        type: completeProfileConstants.GET_DOCUMENT_FAILURE,
        payload: { error: res.data },
      });
    }
  };
};

export const getAgreement = () => {
  return async (dispatch) => {
    dispatch({ type: completeProfileConstants.GET_AGREEMENT_REQUEST });
    const res = await axios.get("/users/registration");
    if (res.status === 200) {
      dispatch({
        type: completeProfileConstants.GET_AGREEMENT_SUCCESS,
        payload: {
          data: res.data.data,
          message: "Agreement Get Sucessfully",
        },
      });
    } else if (res.status === 400) {
      dispatch({
        type: completeProfileConstants.GET_AGREEMENT_FAILURE,
        payload: { error: res.data },
      });
    }
  };
};

export const getQualification = () => {
  return async (dispatch) => {
    dispatch({ type: completeProfileConstants.GET_QUALIFICATION_REQUEST });
    const res = await axios.get("/qualification");
    if (res.status === 200) {
      dispatch({
        type: completeProfileConstants.GET_QUALIFICATION_SUCCESS,
        payload: {
          data: res.data.data,
          message: "Qualification Get Sucessfully",
          fetchedItems: checkKeys(res.data.data, qualificationKeys)
            ? "Appraiser Qualification"
            : "",
        },
      });
    } else if (res.status === 400) {
      dispatch({
        type: completeProfileConstants.GET_QUALIFICATION_FAILURE,
        payload: { error: res.data },
      });
    }
  };
};

export const getInsurance = () => {
  return async (dispatch) => {
    dispatch({ type: completeProfileConstants.GET_INSURANCE_REQUEST });
    const res = await axios.get("/eoInsurance");
    if (res.status === 200) {
      dispatch({
        type: completeProfileConstants.GET_INSURANCE_SUCCESS,
        payload: {
          data: res.data.data,
          message: "Insurance Get Sucessfully",
          fetchedItems: checkKeys(res.data.data, insuranceKeys)
            ? "E&o Insurance"
            : "",
        },
      });
    } else if (res.status === 400) {
      dispatch({
        type: completeProfileConstants.GET_INSURANCE_FAILURE,
        payload: { error: res.data },
      });
    }
  };
};
