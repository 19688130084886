import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Text from "./Core/Text";
import Select from "./Core/Select";
import FileUploader from "./FileUploader";
import Input from "./Core/Input";
import Button from "./Core/Button";
import Icon from "../Helpers/Icon";

const License = ({
  handleChangeLicense,
  handleNextForLicense,
  licenseData,
  errors,
  setlicenseData,
  isUpdate,
}) => {
  const [data, setdata] = useState([licenseData?.data || []]);

  useEffect(() => {
    setlicenseData({
      data: licenseData?.data,
    });
    setdata(licenseData?.data);
  }, [licenseData?.data?.length, licenseData?.data]);

  const addNewLicense = () => {
    setdata([
      ...data,
      {
        _id: Date.now(),
        licenseType: "",
        licenseState: "",
        licenseNumber: "",
        imageName: "",
        image: null,
        expiresAt: "",
      },
    ]);
    setlicenseData({
      data: [
        ...data,
        {
          _id: Date.now(),
          licenseType: "",
          licenseState: "",
          licenseNumber: "",
          imageName: "",
          image: null,
          expiresAt: "",
        },
      ],
    });
  };

  const handleUpdateData = (_id, key, value) => {
    const updatedData = data.map((element) => {
      if (element._id === _id) {
        if (key === "image") {
          return {
            ...element,
            image: value,
            imageName: value.name,
          };
        } else {
          return {
            ...element,
            [key]: value,
          };
        }
      }
      return element;
    });

    setdata(updatedData);
  };

  const handleAddToLicense = () => {
    handleNextForLicense();
    console.log(data, "data");
    setlicenseData({
      data: data,
    });
  };
  return (
    <Box
      sx={{
        width: "64%",
        ml: isUpdate ? 0 : "auto",
        mr: "auto",
        height: isUpdate ? "auto" : "110vh",
        overflowY: isUpdate ? "auto" : "scroll",
        "&::-webkit-scrollbar": {
          width: "4px",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#F0F0F0",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#2C80FF",
          borderRadius: "4px",
        },
        scrollbarColor: "#2C80FF #F0F0F0",
        scrollbarWidth: "thin",
      }}
    >
      <Text
        variant="h1"
        style={{
          color: "#E6E6E6",
          fontSize: "48px",
        }}
      >
        State License(s)
      </Text>
      <Text
        variant="body1"
        style={{
          font: "normal normal medium 14px/21px Poppins",
          color: "#656a72",
          fontSize: "13px",
        }}
      >
        Select Your License Number To Pre-Populate Form.
        <span
          style={{
            color: "#2c80ff",
            cursor: "pointer",
          }}
        >
          {" "}
          Click Here
        </span>{" "}
        to verify your License Number
      </Text>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          mt: "2.2em",
        }}
      >
        <Select
          label="License State"
          name="licenseState"
          style={{ width: "40%" }}
          value={licenseData.licenseState || ""}
          options={[
            { value: "option1", label: "Option 1" },
            { value: "option2", label: "Option 2" },
            { value: "option3", label: "Option 3" },
          ]}
          onChange={(option) => {
            handleChangeLicense("licenseState", option);
          }}
          error={errors?.licenseState || ""}
        />

        <Input
          placeholder="License Number"
          label="License Number"
          type="text"
          name="licenseNumber"
          style={{ width: "40%", marginLeft: ".5em" }}
          value={licenseData.licenseNumber}
          onChange={(e) => {
            handleChangeLicense("licenseNumber", e.target.value);
          }}
          error={errors?.licenseNumber || ""}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          mt: "1em",
        }}
      >
        <Select
          label="License Type"
          style={{ width: "40%" }}
          options={[
            { value: "option1", label: "Option 1" },
            { value: "option2", label: "Option 2" },
            { value: "option3", label: "Option 3" },
          ]}
          name={"licenseType"}
          onChange={(option) => {
            handleChangeLicense("licenseType", option);
          }}
          error={errors?.licenseType || ""}
        />

        <Button style={{ width: "36%", marginTop: "1.7em", marginLeft: "1em" }}>
          Search
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          border: "1px solid #2B80FF",
          borderRadius: "15px",
          padding: "5px 1.5em",
          width: "fit-content",
          mt: "1.5em",
        }}
      >
        <Text
          style={{
            font: "normal normal 600 15px/21px Poppins",
            color: "#000000",
            fontSize: "15px",
            marginTop: ".4em",
          }}
        >
          Jane Doe
        </Text>
        <Icon
          name="SmartHomeOutline"
          style={{
            width: "24px",
            height: "17px",
            fill: "blue",
            margin: "auto .2em auto 1em",
          }}
        />
        <Text
          style={{
            font: "normal normal normal 12px/21px Poppins",
            color: "#000000",
            fontSize: "12px",
            marginTop: ".5em",
          }}
        >
          22888
        </Text>
        <Icon
          name="MenuUserOutline"
          style={{
            width: "20px",
            height: "17px",
            fill: "blue",
            margin: "auto .5em auto 1em",
          }}
        />
        <Text
          style={{
            font: "normal normal normal 12px/21px Poppins",
            color: "#000000",
            fontSize: "12px",
            marginTop: ".5em",
          }}
        >
          License Type
        </Text>
        <input
          type="checkbox"
          checked
          style={{
            marginLeft: "1em",
            width: "18px",
            height: "18px",
            borderRadius: "8px",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        />
      </Box>
      <Text
        variant="body1"
        style={{
          color: "#E6E6E6",
          fontSize: "28px",
          marginTop: ".5em",
        }}
      >
        Or
      </Text>
      {Object.keys(data).length > 0 &&
        data.map((item) => (
          <Box key={item._id}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                mt: "1em",
              }}
            >
              <Select
                label="State"
                style={{ width: "40%" }}
                options={[
                  { value: "option1", label: "Option 1" },
                  { value: "option2", label: "Option 2" },
                  { value: "option3", label: "Option 3" },
                ]}
                value={item.licenseState || ""}
                name={"state"}
                onChange={(option) => {
                  // handleChangeLicense("licenseState", option);
                  handleUpdateData(item._id, "licenseState", option);
                }}
                error={errors?.licenseState || ""}
              />

              <Input
                placeholder="License Number"
                label="License Number"
                type="text"
                style={{ width: "40%", marginLeft: "1em" }}
                name={"licenseNumber"}
                value={item.licenseNumber || ""}
                onChange={(e) => {
                  // handleChangeLicense("licenseNumber", e.target.value);
                  handleUpdateData(item._id, "licenseNumber", e.target.value);
                }}
                error={errors?.licenseNumber || ""}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                mt: "1em",
              }}
            >
              <Select
                label="License Type"
                style={{ width: "40%" }}
                options={[
                  { value: "option1", label: "Option 1" },
                  { value: "option2", label: "Option 2" },
                  { value: "option3", label: "Option 3" },
                ]}
                name={"licenseType"}
                value={item.licenseType || ""}
                onChange={(option) => {
                  // handleChangeLicense("licenseType", option);
                  handleUpdateData(item._id, "licenseType", option);
                }}
                error={errors?.licenseType || ""}
              />

              <Input
                placeholder="Exp. Date #"
                label="Exp. Date #"
                type="date"
                style={{ width: "40%", marginLeft: "1em" }}
                name={"expiresAt"}
                value={item.expiresAt || ""}
                onChange={(e) => {
                  handleUpdateData(item._id, "expiresAt", e.target.value);
                  // handleChangeLicense("expiresAt", e.target.value);
                }}
                error={errors?.expiresAt || ""}
              />
            </Box>

            <Box
              sx={{
                mt: "1.8em",
                mb: "1.2em",
              }}
            >
              <FileUploader
                handleChange={(file) => {
                  if (file && file.length >= 0) {
                    handleUpdateData(item._id, "image", file[0]);
                  }
                }}
                uploaderLabel={"Upload License"}
              />
              <span
                style={{
                  fontSize: 13,
                  marginTop: 4,
                }}
              >
                {item.imageName ? item.imageName : item.licenseImage}
              </span>
            </Box>
          </Box>
        ))}
      <Box
        sx={{
          mt: "1.8em",
          cursor: "pointer",
        }}
        onClick={addNewLicense}
      >
        <Text
          style={{
            color: "#2C80FF",
          }}
        >
          + Add License
        </Text>
        <Text
          style={{
            color: "#656A72",
            fontSize: "11px",
            font: "normal normal medium 14px/21px Poppins",
          }}
        >
          If you have license from multiple state you can add them.
        </Text>
      </Box>

      <Button
        onClick={() => {
          handleAddToLicense();
          // handleNextForLicense();
        }}
        style={{
          width: "30%",
          marginTop: "1em",
          paddingTop: ".2em",
          paddingBottom: ".2em",
        }}
      >
        {isUpdate ? "Udpate" : "Save"} & Continue
      </Button>
    </Box>
  );
};

export default License;
