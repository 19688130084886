import { Box } from "@mui/material";
import React, { useState } from "react";
import "./index.css";
import Shield from "../Assets/Icons/Huge-icon-ecommerce-outline-shield.svg";
import Input from "./Core/Input";
import Button from "./Core/Button";
import { FaTimes } from "react-icons/fa";

const ChangePassword = ({ handleClose }) => {
  const [password, setpassword] = useState("");
  const [showPassword, setshowPassword] = useState(false);

  return (
    <Box className="mainModal">
      <Box
        sx={{
          width: "670px",
          height: "300px",
          background:
            "var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box, #FFFFFF 0% 0% no-repeat padding-box",
          boxShadow: "0px 20px 20px #0000000B",
          borderRadius: "15px",
          opacity: "1",
          margin: "auto",
          backgroundColor: "#fff",
          padding: "1em",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Box>
          <span
            style={{
              font: "normal normal 600 17px/26px Poppins",
              color: "#000000",
              textAlign: "center",
              marginBottom: "1em",
            }}
          >
            Change Password
          </span>
          <Box
            sx={{
              position: "absolute",
              top: 20,
              right: 40,
              boxShadow: `rgba(149, 157, 165, 0.2) 0px 8px 24px`,
              backgroundColor: "#fff",
            }}
          >
            <FaTimes
              style={{
                cursor: "pointer",
              }}
              onClick={handleClose}
            />
          </Box>
        </Box>

        <Input
          icon={<img src={Shield} style={{ width: "18px", height: "18px" }} />}
          value={password}
          onChange={(e) => setpassword(e.target.value)}
          placeholder="Your password"
          type={showPassword ? "text" : "password"}
          style={{ margin: ".5em 0", width: "86%" }}
        />
        <Input
          icon={<img src={Shield} style={{ width: "18px", height: "18px" }} />}
          value={password}
          onChange={(e) => setpassword(e.target.value)}
          placeholder="Your password"
          type={showPassword ? "text" : "password"}
          style={{ margin: ".5em 0", width: "86%" }}
        />
        <Input
          icon={<img src={Shield} style={{ width: "18px", height: "18px" }} />}
          value={password}
          onChange={(e) => setpassword(e.target.value)}
          placeholder="Your password"
          type={showPassword ? "text" : "password"}
          style={{ margin: ".5em 0", width: "86%" }}
        />
        <Button
          onClick={handleClose}
          style={{
            width: "86%",
            marginTop: ".5em",
          }}
        >
          Update Password
        </Button>
      </Box>
    </Box>
  );
};

export default ChangePassword;
