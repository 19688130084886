import { Box } from "@mui/material";
import React, { useState } from "react";
import Template from "../../Template";
import mokcup1 from "../..//Assets/Images/mokcup1.png";
import "./index.css";
import Input from "../../Components/Core/Input";
import Button from "../../Components/Core/Button";
import MailNegative from "../../Assets/Icons/Huge-icon-communication-outline-mail-negative.svg";
import Shield from "../../Assets/Icons/Huge-icon-ecommerce-outline-shield.svg";
import OutlineBook from "../../Assets/Icons/huge-icon-education-outline-book.svg";
import OutlineStack from "../../Assets/Icons/huge-icon-education-outline-cube-stack.svg";
import backgroundImage from "../..//Assets/Images/shutterstock_1569440923.png";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { loginUser } from "../../Actions/auth";
import { Navigate } from "react-router";
import Prompt from "../../Components/Core/Prompt";

const validationSchema = yup.object({
  email: yup.string().email("Email is invalid").required("Email is required"),
  password: yup
    .string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
});

const Login = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [addUserLocaly, setaddUserLocaly] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [Errors, setErrors] = useState(null);
  const [makePrompt, setmakePrompt] = useState({
    open: false,
    message: "",
    type: "error",
  });

  if (auth.authenticate) {
    if (auth.user?.agreementAccepted) {
      return <Navigate to={"/"} />;
    } else {
      return <Navigate to={"/complete-profile"} />;
    }
  }

  const handleLogin = async () => {
    setisLoading(true);

    const data = {
      email,
      password,
    };

    try {
      await validationSchema.validate(data, {
        abortEarly: false,
      });

      dispatch(loginUser(data))
        .then((result) => {
          console.log(result);
          setisLoading(false);
          if (!result?.agreementAccepted) {
            window.location.href = "/complete-profile";
          } else {
            window.location.href = "/";
          }
        })
        .catch((err) => {
          setisLoading(false);
          setmakePrompt({
            open: true,
            message: err.response.data.error || "Some error occured",
            type: "error",
          });
        });
    } catch (validationError) {
      const formattedErrors = {};
      validationError.inner.forEach((error) => {
        formattedErrors[error.path] = error.message;
      });

      setErrors(formattedErrors);
      setmakePrompt({
        open: true,
        message:
          Object.values(formattedErrors)[0] || "Some of the fields are invalid",
        type: "error",
      });
      setisLoading(false);
    }
  };

  return (
    <Template
      showHeader={true}
      showFooter={true}
      headerProps={{
        transparent: true,
        backgroundImage: backgroundImage,
      }}
    >
      <Box
        sx={{
          mt: "-6em",
          width: "88%",
          ml: "auto",
          mr: "auto",
          display: "flex",
          flexDirection: "row",
          position: "relative",
          pb: "5em",
        }}
      >
        <img
          src={mokcup1}
          style={{
            marginTop: "3em",
            width: "480px",
            height: "320px",
          }}
        />
        <Box
          sx={{
            widht: "200px !important",
            mr: "auto",
            ml: "auto",
            mt: "7.5em",
            position: "absolute",
            left: "510px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: 300,
            }}
          >
            <Box
              sx={{
                width: 56,
                height: 36,
                backgroundColor: "#2C80FF",
                borderRadius: "6px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mr: ".75em",
                mt: ".5em",
              }}
            >
              <img
                src={OutlineBook}
                style={{
                  width: "18px",
                  height: "18px",
                }}
              />
            </Box>
            <Box>
              <h3
                style={{
                  color: "#191A1C",
                  font: "normal normal 600 15px Poppins",
                  margin: "4px 0",
                }}
              >
                Lorem Ipsum is simply dummy text of the printing
              </h3>
              <p
                style={{
                  font: "normal normal normal 10px/18px Poppins",
                  color: "#474C4E",
                }}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: 300,
              mt: 2,
            }}
          >
            <Box
              sx={{
                width: 56,
                height: 36,
                backgroundColor: "#2C80FF",
                borderRadius: "6px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mr: ".75em",
                mt: ".5em",
              }}
            >
              <img
                src={OutlineStack}
                style={{
                  width: "18px",
                  height: "18px",
                }}
              />
            </Box>
            <Box>
              <h3
                style={{
                  color: "#191A1C",
                  font: "normal normal 600 15px Poppins",
                  margin: "4px 0",
                }}
              >
                Lorem Ipsum is simply dummy text of the printing
              </h3>
              <p
                style={{
                  font: "normal normal normal 10px/18px Poppins",
                  color: "#474C4E",
                }}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
            </Box>
          </Box>
        </Box>
        <Box className="login_Box">
          <h3>Sign in</h3>
          <p>Sign in to access your account.</p>
          <Input
            icon={
              <img
                src={MailNegative}
                style={{ width: "18px", height: "18px" }}
              />
            }
            value={email}
            onChange={(e) => setemail(e.target.value)}
            placeholder="Your email"
            type="email"
            style={{ marginTop: "1em" }}
            error={Errors?.email || ""}
          />
          <Input
            icon={
              <img src={Shield} style={{ width: "18px", height: "18px" }} />
            }
            value={password}
            onChange={(e) => setpassword(e.target.value)}
            placeholder="Password"
            type={"password"}
            style={{ margin: ".5em 0" }}
            error={Errors?.password || ""}
          />
          {/* <Select
              value={role}
              onChange={(e) => {
                setrole(e.target.value);
                window.localStorage.setItem("role", e.target.value);
              }}
              options={[
                { value: "Inspector", label: "Inspector" },
                { value: "Appraiser", label: "Appraiser" },
                { value: "Admin", label: "Admin" },
                { value: "Client", label: "Client" },
              ]}
              placeholder="Select Role"
              style={{ width: "100%" }}
            /> */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              margin: ".7em 0",
            }}
          >
            <input
              type="checkbox"
              checked={addUserLocaly}
              onChange={() => setaddUserLocaly(!addUserLocaly)}
              id="vehicle1"
              name="vehicle1"
              value="Bike"
            />
            <label
              for="vehicle1"
              style={{
                marginLeft: ".5em",
                font: " normal normal normal 12px/18px Poppins",
                color: "#656a72",
              }}
            >
              Remember me
            </label>
            <p
              style={{
                marginLeft: "auto",
                color: "#2B80FF",
                cursor: "pointer",
                fontSize: "12px !important",
                font: "normal normal normal 12px/18px Poppins",
              }}
              onClick={() => {
                window.location.href = "/forget-password";
              }}
            >
              Forgot password?
            </p>
          </Box>
          <Button isLoading={isLoading} onClick={handleLogin}>
            Login
          </Button>
          <p
            style={{
              font: " normal normal normal 12px Poppins",
              color: "#656a72",
              marginTop: "2em",
              fontSize: "12px !important",
            }}
          >
            Don’t have an account?{" "}
            <span
              style={{
                color: "rgba(44, 128, 255, 1)",
                cursor: "pointer",
                fontWeight: "600",
              }}
              onClick={() => {
                window.location.href = "/register";
              }}
            >
              Sign up
            </span>
          </p>
        </Box>
        {makePrompt.open && (
          <Prompt
            open={makePrompt.open}
            message={makePrompt.message}
            type={makePrompt.type}
            handleClose={() =>
              setmakePrompt({ open: false, message: "", type: "error" })
            }
          />
        )}
      </Box>
    </Template>
  );
};

export default Login;
