import * as yup from "yup";

export const licenseSchema = yup.object().shape({
  data: yup.array().of(
    yup.object().shape({
      licenseType: yup.string().required("License Type is required"),
      licenseState: yup.string().required("License State is required"),
      licenseNumber: yup.string().required("License Number is required"),
      expiresAt: yup
        .string()
        .required("Expiration Date is required")
        .nullable(),
      // image: yup
      //   .mixed()
      //   .required("Upload license image")
      //   .test("fileSize", "Image size is too large", (value) => {
      //     return value && value.size <= 1000000;
      //   })
      //   .test("fileType", "Unsupported file type", (value) => {
      //     return (
      //       value && (value.type === "image/jpeg" || value.type === "image/png")
      //     );
      //   }),

      image: yup.lazy((value) =>
        value ? imageSchema : yup.mixed().notRequired()
      ),
      licenseImage: yup.lazy((value) =>
        value ? licenseImageSchema : yup.string().notRequired()
      ),
    })
  ),
});

const imageSchema = yup
  .mixed()
  .nullable()
  .required("Upload license image")
  .test("fileSize", "Image size is too large", (value) => {
    return value && value.size <= 1000000;
  })
  .test("fileType", "Unsupported file type", (value) => {
    return value && (value.type === "image/jpeg" || value.type === "image/png");
  });

const licenseImageSchema = yup
  .string()
  .nullable()
  .required("Upload license image");

export const personalInfoSchema = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
  cellPhone: yup.string().required("Cell Phone is required"),
  workPhone: yup.string().required("Work Phone Number is required"),
  language: yup.string().required("Language Spoken is required"),
  address: yup.string().required("Street Address/PO Box is required"),
  state: yup.string().required("State is required"),
  city: yup.string().required("City/Town is required"),
  zipcode: yup.string().required("Zipcode is required"),
  demographicsCityTown: yup.string().optional(),
  demographicsZipcode: yup.string().optional(),
});

export const companyInfoSchema = yup.object().shape({
  companyName: yup.string().required("Company Name is required"),
  companyPhoneNumber: yup.string().required("Company Phone Number is required"),
  companyAddress1: yup.string().when("sameAsInspectorAddress", {
    is: false,
    then: yup.string().required("Company Address 1 is required"),
  }),
  companyAddress2: yup.string(),
  state: yup.string().required("State is required"),
  cityTown: yup.string().required("City/Town is required"),
  zipcode: yup.string().required("Zipcode is required"),
});

export const coverageAreaSchema = yup.object().shape({
  coverageAreaState: yup.string().required("State is required"),
  coverageAreaCountry: yup.string().required("Country is required"),
});

export const digitalSignatureSchema = yup.object().shape({
  signature: yup.mixed().required("Signature file is required"),
});

export const billingSchema = yup.object().shape({
  routingNumber: yup
    .string()
    .required("Routing number is required")
    .matches(/^\d{9}$/, "Invalid routing number"),
  accountNumber: yup
    .string()
    .required("Account number is required")
    .matches(/^\d{6,12}$/, "Invalid account number"),
  institution: yup.string().required("Name of the institution is required"),
  accountType: yup.string().required("Account type is required"),
  address: yup.string().required("Street address is required"),
  zipCode: yup.string().required("Zip code is required"),
  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),
});

export const documentValidationSchema = yup.object().shape({
  insurance: yup.mixed().nullable(),
  w9: yup.mixed().nullable(),
  resume: yup.mixed().nullable(),
  certificate: yup.mixed().nullable(),
});

export const agreementValidationSchema = yup.object().shape({
  registration: yup.string().required("Registration is required"),
});

export const appraiserQualificationValidationSchema = yup.object().shape({
  experience: yup.string().required("Experience is required"),
  designation: yup.string().required("Designation is required"),
  commercialApproved: yup.string().required("Commercial is required"),
  FHAApproved: yup.string().required("FHA is required"),
  resume: yup.mixed().nullable(),
  backgroundCheck: yup.mixed().nullable(),
  governmentAgencyIncludeCheck: yup
    .string()
    .required("Government agency is required"),
  disciplinaryActionAgainstUserCheck: yup
    .string()
    .required("Action is required"),
  subjectOfAnyCorrectiveActionByAppraiserCheck: yup
    .string()
    .required("Subject of corrective action is required"),
  notifiedOfAnyInvestigationByBoardCheck: yup
    .string()
    .required("Notification of investigation is required"),
  convictedOfFelonyCheck: yup
    .string()
    .required("Felony conviction is required"),
  lawsuitOrClaimFieldCheck: yup
    .string()
    .required("Lawsuit or claim field is required"),
  awarenessOfAnyLawsuitOrClaimFieldCheck: yup
    .string()
    .required("Awareness of lawsuit or claim field is required"),
});

export const insuranceValidationSchema = yup.object().shape({
  companyName: yup.string().required("Company name is required"),
  policyNumber: yup.string().required("Policy number is required"),
  claimLimit: yup.string().required("Per claim is required"),
  expiresAt: yup.date().required("Expiration date is required").nullable(),
  document: yup.mixed().nullable(),
});

export const ticketValidationSchema = yup.object().shape({
  title: yup.string().required("Title is required"),
  subject: yup.string().required("Subject is required"),
  description: yup.string().required("Description is required"),
});
