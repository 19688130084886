
export const licensekeys = [
    "licenseType",
    "licenseState",
    "licenseNumber",
    "expiresAt",
    "licenseImage",
  ];

  export const personalInfoKeys = [
    "firstName",
    "lastName",
    "email",
    "cellPhone",
    "state",
  ]

  export const companyInfoKeys = [
    "companyName",
    "companyPhoneNumber",
    "companyAddress1",
    "companyAddress2",
    "state",
  ]

  export const coverageAreaKeys = [
    "coverageAreaState",
    "coverageAreaCountry",
  ]

  export const documentKeys = [
    "insurance",
    "w9",
    "resume",
    "certificate"
  ]
  export const billingInfoKeys = [
    "routingNumber",
    "accountNumber",
    "institution",
    "accountType",
    "address",
    "zipCode",
    "city",
    "state"
  ]

  export const qualificationKeys = [
    "experience",
    "designation",
    "commercialApproved",
    "FHAApproved",
    "resume",
    "governmentAgencyIncludeCheck",
    "disciplinaryActionAgainstUserCheck",
    "subjectOfAnyCorrectiveActionByAppraiserCheck",
    "notifiedOfAnyInvestigationByBoardCheck",
    "convictedOfFelonyCheck",
    "lawsuitOrClaimFieldCheck",
    "awarenessOfAnyLawsuitOrClaimFieldCheck"
  ]
  
  export const insuranceKeys = [
    "companyName",
    "policyNumber",
    "claimLimit",
    "expiresAt",
    "document"
  ]
  