import axios from "../Utils/axios";
import { orderConstants } from "./constants";

export const newOrder = (data) => {
  return async (dispatch) => {
    dispatch({ type: orderConstants.NEW_ORDER_REQUEST });
    try {
      const res = await axios.post(`/order`, data);
      dispatch({
        type: orderConstants.NEW_ORDER_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: orderConstants.NEW_ORDER_FAILURE,
        payload: err,
      });
    }
  };
};

export const createAssignedOrder = (data) => {
  return async (dispatch) => {
    dispatch({ type: orderConstants.CREATE_ASSIGNED_ORDER_REQUEST });
    try {
      const res = await axios.post(`/order/assigned`, data);
      dispatch({
        type: orderConstants.CREATE_ASSIGNED_ORDER_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: orderConstants.CREATE_ASSIGNED_ORDER_FAILURE,
        payload: err,
      });
    }
  };
};

export const getNewOrders = () => {
  return async (dispatch) => {
    dispatch({ type: orderConstants.GET_NEW_ORDER_REQUEST });
    const res = await axios.get(`/order/orders/new`);
    try {
      dispatch({
        type: orderConstants.GET_NEW_ORDER_SUCCESS,
        payload: res.data.data,
      });
    } catch (err) {
      dispatch({
        type: orderConstants.GET_NEW_ORDER_FAILURE,
        payload: err,
      });
    }
  };
};

export const getAssignedOrder = () => {
  return async (dispatch) => {
    dispatch({ type: orderConstants.ASSIGNED_ORDER_REQUEST });
    const res = await axios.get(`/order/orders/assigned`);
    try {
      dispatch({
        type: orderConstants.ASSIGNED_ORDER_SUCCESS,
        payload: res.data.data,
      });
    } catch (err) {
      dispatch({
        type: orderConstants.ASSIGNED_ORDER_FAILURE,
        payload: err,
      });
    }
  };
};

export const getScheduledOrder = () => {
  return async (dispatch) => {
    dispatch({ type: orderConstants.GET_SCHEDULED_ORDER_REQUEST });
    const res = await axios.get(`/order/orders/scheduled`);

    if (res.status === 200) {
      dispatch({
        type: orderConstants.GET_SCHEDULED_ORDER_SUCCESS,
        payload: res.data.data,
      });
    } else if (res.status === 400) {
      dispatch({
        type: orderConstants.GET_SCHEDULED_ORDER_FAILURE,
        payload: res.data,
      });
      return res.data;
    }
  };
};

export const completeOrder = (data) => {
  return async (dispatch) => {
    dispatch({ type: orderConstants.COMPLETE_ORDER_REQUEST });
    const res = await axios.post(`/order/complete`, data);
    try {
      dispatch({
        type: orderConstants.COMPLETE_ORDER_SUCCESS,
        payload: res.data.data,
      });
    } catch (err) {
      dispatch({
        type: orderConstants.COMPLETE_ORDER_FAILURE,
        payload: err,
      });
    }
  };
};

export const getCompleteOrder = () => {
  return async (dispatch) => {
    dispatch({ type: orderConstants.GET_COMPLETE_ORDER_REQUEST });
    const res = await axios.get(`/order/orders/completed`);
    try {
      dispatch({
        type: orderConstants.GET_COMPLETE_ORDER_SUCCESS,
        payload: res.data.data,
      });
    } catch (err) {
      dispatch({
        type: orderConstants.GET_COMPLETE_ORDER_FAILURE,
        payload: err,
      });
    }
  };
};

export const getCancelledOrder = () => {
  return async (dispatch) => {
    dispatch({ type: orderConstants.GET_CANCELLED_ORDER_REQUEST });
    try {
      const res = await axios.get(`/order/orders/cancelled`);
      dispatch({
        type: orderConstants.GET_CANCELLED_ORDER_SUCCESS,
        payload: res.data.data,
      });
    } catch (err) {
      dispatch({
        type: orderConstants.GET_CANCELLED_ORDER_FAILURE,
        payload: err,
      });
    }
  };
};

export const approveOrder = (id) => {
  return async (dispatch) => {
    dispatch({ type: orderConstants.APPROVE_ORDER_REQUEST });
    const res = await axios.post(`/order/accept/${id}`);

    if (res.status === 200) {
      dispatch({
        type: orderConstants.APPROVE_ORDER_SUCCESS,
        payload: res.data,
      });
    } else if (res.status === 400) {
      dispatch({
        type: orderConstants.APPROVE_ORDER_FAILURE,
        payload: res.data,
      });
      return res.data;
    }
  };
};

export const declineOrder = (id, data) => {
  return async (dispatch) => {
    dispatch({ type: orderConstants.DECLINE_ORDER_REQUEST });
    const res = await axios.post(`/order/decline/${id}`, data);

    if (res.status === 200) {
      dispatch({
        type: orderConstants.DECLINE_ORDER_SUCCESS,
        payload: res.data,
      });
    } else if (res.status === 400) {
      dispatch({
        type: orderConstants.DECLINE_ORDER_FAILURE,
        payload: res.data,
      });
      return res.data;
    }
  };
};

export const counterOffer = (id, data) => {
  return async (dispatch) => {
    dispatch({ type: orderConstants.COUNTER_OFFER_REQUEST });
    const res = await axios.post(`/order/counter/${id}`, data);
    if (res.status === 200) {
      dispatch({
        type: orderConstants.COUNTER_OFFER_SUCCESS,
        payload: res.data,
      });
    } else if (res.status === 400) {
      dispatch({
        type: orderConstants.COUNTER_OFFER_FAILURE,
        payload: res.data,
      });
      return res.data;
    }
  };
};

export const OrderDetails = (id) => {
  return async (dispatch) => {
    dispatch({ type: orderConstants.GET_ORDER_DETAILS_REQUEST });
    const res = await axios.get(`/order/${id}`);
    try {
      dispatch({
        type: orderConstants.GET_ORDER_DETAILS_SUCCESS,
        payload: res.data?.data[0],
      });
    } catch (err) {
      dispatch({
        type: orderConstants.GET_ORDER_DETAILS_FAILURE,
        payload: err,
      });
    }
  };
};

export const scheduleOrder = (id, data) => {
  return async (dispatch) => {
    dispatch({ type: orderConstants.SCHEDULE_ORDER_REQUEST });
    const res = await axios.post(`/order/schedule/${id}`, data);
    try {
      dispatch({
        type: orderConstants.SCHEDULE_ORDER_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: orderConstants.SCHEDULE_ORDER_FAILURE,
        payload: err,
      });
    }
  };
};

export const getOrderActivity = (id) => {
  return async (dispatch) => {
    dispatch({ type: orderConstants.GET_ORDER_ACTIVITY_REQUEST });
    try {
      const res = await axios.get(`/activity/${id}`);
      dispatch({
        type: orderConstants.GET_ORDER_ACTIVITY_SUCCESS,
        payload: res.data.data,
      });
    } catch (err) {
      dispatch({
        type: orderConstants.GET_ORDER_ACTIVITY_FAILURE,
        payload: err,
      });
    }
  };
};
