import { messageConstants } from "../Actions/constants";

const initState = {
  supportMsg: [],
  newMsg: [],
  adminMsg: [],
  tickets: [],
  ticketMessages: [],
  loading: false,
  error: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case messageConstants.GET_SUPPORT_MESSAGE_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case messageConstants.GET_SUPPORT_MESSAGE_SUCCESS:
      state = {
        ...state,
        supportMsg: action.payload,
        loading: false,
      };
      break;
    case messageConstants.GET_SUPPORT_MESSAGE_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;
    case messageConstants.CREATE_SUPPORT_MESSAGE_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case messageConstants.CREATE_SUPPORT_MESSAGE_SUCCESS:
      state = {
        ...state,
        supportMsg: action.payload,
        loading: false,
      };
      break;
    case messageConstants.CREATE_SUPPORT_MESSAGE_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;
    case messageConstants.CREATE_NEW_MESSAGE_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case messageConstants.CREATE_NEW_MESSAGE_SUCCESS:
      state = {
        ...state,
        newMsg: action.payload,
        loading: false,
      };
      break;
    case messageConstants.CREATE_NEW_MESSAGE_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;
    case messageConstants.GET_MESSAGE_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case messageConstants.GET_MESSAGE_SUCCESS:
      state = {
        ...state,
        adminMsg: action.payload,
        loading: false,
      };
      break;
    case messageConstants.GET_MESSAGE_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;
    case messageConstants.GET_TICKET_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case messageConstants.GET_TICKET_SUCCESS:
      state = {
        ...state,
        tickets: action.payload,
        loading: false,
      };
      break;
    case messageConstants.GET_TICKET_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;

    case messageConstants.GET_MESSAGE_BY_TICKET_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case messageConstants.GET_MESSAGE_BY_TICKET_SUCCESS:
      state = {
        ...state,
        ticketMessages: action.payload,
        loading: false,
      };
      break;

    case messageConstants.GET_MESSAGE_BY_TICKET_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;
  }
  return state;
};
