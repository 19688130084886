import { Box } from "@mui/material";
import React, { useState } from "react";
import Text from "../../Components/Core/Text";
import { BarChart, ListViewCircle, RemoveThin } from "react-huge-icons/outline";
import Chart from "react-apexcharts";
import Select from "../../Components/Core/Select";
import Input from "../../Components/Core/Input";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import Button from "../../Components/Core/Button";
import Prompt from "../../Components/Core/Prompt";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { getMarketTrend, updateMarketTrend } from "../../Actions/report";

const generateRandomData = (count, min, max) => {
  const data = [];
  for (let i = 0; i < count; i++) {
    data.push([
      Math.floor(Math.random() * (max - min + 1)) + min,
      Math.floor(Math.random() * (max - min + 1)) + min,
    ]);
  }
  return data;
};
const MarketTrend = ({ setmarketDetails, marketDetails }) => {
  const [isLoading, setisLoading] = useState(false);
  const [makePrompt, setmakePrompt] = useState({
    open: false,
    message: "",
    type: "error",
  });
  const dispatch = useDispatch();
  const location = useLocation();

  const data = [
    {
      name: "Series 1",
      data: generateRandomData(7, 1, 10, 100),
    },
    {
      name: "Series 2",
      data: generateRandomData(7, 1, 10, 100),
    },
  ];
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        columnWidth: "50%",
        horizontal: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#73C8FC", "#0477BE"],
  };

  const saveMarketDetails = () => {
    setisLoading(true);
    dispatch(updateMarketTrend(location.state.id, marketDetails))
      .then((result) => {
        dispatch(getMarketTrend(location.state.id));
        setmakePrompt({
          open: true,
          message: "Market details saved successfully",
          type: "success",
        });
        setisLoading(false);
      })
      .catch((err) => {
        setmakePrompt({
          open: true,
          message: "Error saving market details",
          type: "error",
        });
      });
  };
  const handleInputChange = (e, fieldName) => {
    const { value } = e.target;
    setmarketDetails({
      ...marketDetails,
      [fieldName]: value,
    });
  };

  return (
    <Box>
      <Text
        style={{
          font: "normal normal 600 22px/33px Poppins",
          color: "#BFBFBF",
          fontSize: "18px",
          fontWeight: "600",
          marginTop: ".5em",
          marginLeft: ".5em",
          marginBottom: ".5em",
        }}
      >
        Market Trends
      </Text>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <LoadScript googleMapsApiKey="AIzaSyDsl-qxnn1erCHcXCJFl4_JgqkDmggaQus">
          <GoogleMap
            mapContainerStyle={{ width: "100%", height: "350px" }}
            center={{ lat: -34.397, lng: 150.644 }}
            zoom={8}
          >
            {/* Add additional components or overlays here if needed */}
          </GoogleMap>
        </LoadScript>
        <Chart
          options={options}
          series={data}
          type="bar"
          width={520}
          height={350}
        />
      </Box>
      <Text
        style={{
          font: "normal normal 600 22px/33px Poppins",
          color: "#BFBFBF",
          fontSize: "18px",
          fontWeight: "600",
          marginTop: ".5em",
          marginLeft: ".5em",
          marginBottom: ".5em",
        }}
      >
        Housing Trends
      </Text>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          mt: "1em",
          mb: "1em",
        }}
      >
        <Select
          label={"Demand/Supply"}
          value={marketDetails?.demand || "Demand"}
          style={{ width: "15%", mr: "2%" }}
          onChange={(option) =>
            setmarketDetails({ ...marketDetails, demand: option })
          }
          options={[
            { value: "Demand", label: "Demand" },
            { value: "Supply", label: "Supply" },
          ]}
        />
        <Select
          label={"Property Values"}
          value={marketDetails?.propertyValue || "Owner"}
          style={{ width: "15%", mr: "2%" }}
          options={[
            { value: "Owner", label: "Owner" },
            { value: "Tenant", label: "Tenant" },
            { value: "Vacant", label: "Vacant" },
            { value: "Other", label: "Other" },
          ]}
          onChange={(option) => {
            setmarketDetails({ ...marketDetails, propertyValue: option });
          }}
        />
        <Select
          label={"Marketing Times"}
          value={marketDetails?.marketingTimes || "Single"}
          onChange={(option) =>
            setmarketDetails({ ...marketDetails, marketingTimes: option })
          }
          style={{ width: "15%", mr: "2%" }}
          options={[
            { value: "Single", label: "Single" },
            { value: "Multi", label: "Multi" },
            { value: "Condo/Townhouse", label: "Condo/Townhouse" },
            { value: "Manufactured", label: "Manufactured" },
          ]}
        />
        <Select
          label={"Location"}
          value={marketDetails?.location || "Waterfront"}
          style={{ width: "15%", mr: "2%" }}
          onChange={(option) =>
            setmarketDetails({ ...marketDetails, location: option })
          }
          options={[
            { value: "Waterfront", label: "Waterfront" },
            { value: "Golfcourse Lot", label: "Golfcourse Lot" },
            { value: "Hillside Lot", label: "Hillside Lot" },
            { value: "Cul-de-Sac", label: "Cul-de-Sac" },
            { value: "Borders pres/Pub land", label: "Borders pres/Pub land" },
          ]}
        />
        <Select
          label={"Built-Up"}
          value={marketDetails?.buildUp || "Residential"}
          onChange={(option) =>
            setmarketDetails({ ...marketDetails, buildUp: option })
          }
          style={{ width: "15%", mr: "2%" }}
          options={[
            { value: "Residential", label: "Residential" },
            { value: "Mountain", label: "Mountain" },
            { value: "Water", label: "Water" },
            { value: "Golf View", label: "Golf View" },
            { value: "Park", label: "Park" },
            { value: "Commercial", label: "Commercial" },
            { value: "Power Lines", label: "Power Lines" },
            { value: "City Lights", label: "City Lights" },
            { value: "Other", label: "Other" },
          ]}
        />
        <Select
          label={"Growth"}
          value={marketDetails?.growth || "Residential"}
          onChange={(option) =>
            setmarketDetails({ ...marketDetails, growth: option })
          }
          style={{ width: "15%", mr: "2%" }}
          options={[
            { value: "Residential", label: "Residential" },
            { value: "Mountain", label: "Mountain" },
            { value: "Water", label: "Water" },
            { value: "Golf View", label: "Golf View" },
            { value: "Park", label: "Park" },
            { value: "Commercial", label: "Commercial" },
            { value: "Power Lines", label: "Power Lines" },
            { value: "City Lights", label: "City Lights" },
            { value: "Other", label: "Other" },
          ]}
        />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        <Select
          label={"Adverse Site Conditions/Negative External Influences?"}
          value={marketDetails?.condition || "Adverse Site Conditions"}
          onChange={(option) =>
            setmarketDetails({ ...marketDetails, condition: option })
          }
          style={{ width: "auto", mr: "2%" }}
          options={[
            {
              value: "Adverse Site Conditions",
              label: "Adverse Site Conditions",
            },
            {
              value: "Negative External Influences?",
              label: "Negative External Influences?",
            },
          ]}
        />
        <Input
          label={"Off site Improvement"}
          placeholder={"Enter here"}
          style={{ marginRight: "1.5em" }}
          value={marketDetails?.offSiteImprovement}
          onChange={(e) =>
            setmarketDetails({
              ...marketDetails,
              offSiteImprovement: e.target.value,
            })
          }
        />
        <Box sx={{ width: "40%" }}>
          <Text
            style={{
              font: "normal normal 600 22px/33px Poppins",
              color: "#BFBFBF",
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            Subject conforms to neighborhood?
          </Text>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            <input
              type="radio"
              name="conformsToNeighborhood"
              value={true}
              checked={marketDetails?.conformsToNeighborhood}
              style={{ margin: "7px 10px auto 0" }}
              onChange={(e) => {
                setmarketDetails({
                  ...marketDetails,
                  conformsToNeighborhood: e.target.value,
                });
              }}
            />
            <label htmlFor="conformsToNeighborhood">Yes</label>
            <input
              type="radio"
              name="conformsToNeighborhood"
              value={false}
              checked={marketDetails?.conformsToNeighborhood}
              style={{ margin: "7px 10px auto 2em" }}
              onChange={(e) => {
                setmarketDetails({
                  ...marketDetails,
                  conformsToNeighborhood: e.target.value,
                });
              }}
            />
            <label htmlFor="conformsToNeighborhood">No</label>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          m: "1em 0",
        }}
      >
        <Box sx={{ width: "20%" }}>
          <Text
            style={{
              font: "normal normal 600 22px/33px Poppins",
              color: "#BFBFBF",
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            Utilities typical?
          </Text>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            <input
              type="radio"
              name="utilitiesTypical"
              value={true}
              checked={marketDetails?.utilitiesTypical === true}
              style={{ margin: "7px 10px auto 0" }}
              onChange={(e) => {
                setmarketDetails({
                  ...marketDetails,
                  utilitiesTypical: true,
                });
              }}
            />
            <label htmlFor="utilitiesTypical">Yes</label>
            <input
              type="radio"
              name="utilitiesTypical"
              value={false}
              checked={marketDetails?.utilitiesTypical === false}
              onChange={(e) => {
                handleInputChange(e, "utilitiesTypical");
              }}
              style={{ margin: "7px 10px auto 2em" }}
            />
            <label htmlFor="utilitiesTypical">No</label>
          </Box>
        </Box>
        <Input
          label={"Electricity"}
          placeholder={"Enter here"}
          value={marketDetails?.electricity}
          onChange={(e) => handleInputChange(e, "electricity")}
          style={{ marginRight: "1.5em" }}
        />
        <Input
          label={"Gas"}
          placeholder={"Enter here"}
          value={marketDetails.gas}
          onChange={(e) => handleInputChange(e, "gas")}
          style={{ marginRight: "1.5em" }}
        />
        <Input
          label={"Water"}
          placeholder={"Enter here"}
          value={marketDetails.water}
          onChange={(e) => handleInputChange(e, "water")}
          style={{ marginRight: "1.5em" }}
        />
        <Input
          label={"Sewer"}
          placeholder={"Enter here"}
          value={marketDetails.sewer}
          onChange={(e) => handleInputChange(e, "sewer")}
          style={{ marginRight: "1.5em" }}
        />
        <Box sx={{ width: "20%", m: "1em 0 0 0" }}>
          <Text
            style={{
              font: "normal normal 600 22px/33px Poppins",
              color: "#BFBFBF",
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            Street Type
          </Text>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            <input
              type="radio"
              name="streetTypePublic"
              value={true}
              checked={marketDetails?.streetTypePublic === true}
              onChange={(e) => {
                handleInputChange(e, "streetTypePublic");
              }}
              style={{ margin: "7px 10px auto 0" }}
            />
            <label for="streetTypePublic">Yes</label>
            <input
              type="radio"
              name="streetTypePublic"
              value={false}
              checked={marketDetails?.streetTypePublic === false}
              onChange={(e) => {
                handleInputChange(e, "streetTypePublic");
              }}
              style={{ margin: "7px 10px auto 2em" }}
            />
            <label for="streetTypePublic">No</label>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        <Input
          label={"Neighbourhood Comment"}
          placeholder={"Enter Neighbour Comment here"}
          style={{ marginRight: "1.5em", width: "35%" }}
          value={marketDetails.neighbourhoodComment}
          onChange={(e) => handleInputChange(e, "neighbourhoodComment")}
        />
        <Input
          label={"Market Comment"}
          placeholder={"Enter Market Comment here"}
          style={{ marginRight: "1.5em", width: "35%" }}
          value={marketDetails.marketComment}
          onChange={(e) => handleInputChange(e, "marketComment")}
        />
      </Box>
      <Button
        style={{ width: "140px", margin: "1em 0" }}
        isLoading={isLoading}
        onClick={saveMarketDetails}
      >
        Save
      </Button>
      <Prompt
        open={makePrompt.open}
        message={makePrompt.message}
        type={makePrompt.type}
        handleClose={() =>
          setmakePrompt({ open: false, message: "", type: "error" })
        }
      />
    </Box>
  );
};

export default MarketTrend;
