import { Box } from "@mui/material";
import React, { useState } from "react";
import Input from "../../Components/Core/Input";
import Select from "../../Components/Core/Select";
import Text from "../../Components/Core/Text";
import Button from "../../Components/Core/Button";
import { useDispatch } from "react-redux";
import { updatePropertyDetails } from "../../Actions/report";
import Prompt from "../../Components/Core/Prompt";

const ProprtyDetails = ({ report_id, setpropertyDetails, propertyDetails }) => {
  const dispatch = useDispatch();
  const [makePrompt, setmakePrompt] = useState({
    open: false,
    message: "",
    type: "error",
  });
  const [isLoading, setisLoading] = useState(false);

  const handleSave = () => {
    setisLoading(true);
    dispatch(updatePropertyDetails(report_id, propertyDetails))
      .then((result) => {
        setisLoading(false);
        setmakePrompt({
          open: true,
          message: "Property details updated successfully",
          type: "success",
        });
      })
      .catch((err) => {
        setisLoading(false);
        setmakePrompt({
          open: true,
          message: err.response?.data?.error || "Some error occured",
          type: "error",
        });
      });

  };
  console.log(propertyDetails,"propertydetail")
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        p: ".5em",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        <Input
          label={"Owner of record"}
          placeholder={"Enter here"}
          style={{ marginRight: ".5em" }}
          value={propertyDetails?.ownerOfRecord}
          onChange={(e) =>
            setpropertyDetails({
              ...propertyDetails,
              ownerOfRecord: e.target.value,
            })
          }
        />
        <Input
          label={"APN"}
          placeholder={"Enter Smith"}
          style={{ marginRight: ".5em" }}
          value={propertyDetails?.apn}
          onChange={(e) =>
            setpropertyDetails({ ...propertyDetails, apn: e.target.value })
          }
        />

        <Input
          label={"Legal Description"}
          placeholder={"chrisjackson@gmai.com"}
          style={{ marginRight: ".5em", width: "24%" }}
          value={propertyDetails?.legalDescription}
          onChange={(e) =>
            setpropertyDetails({
              ...propertyDetails,
              legalDescription: e.target.value,
            })
          }
        />
        <Select
          label={"Property Interest"}
          style={{ width: "15%", mr: ".5em" }}
          value={propertyDetails?.propertyInterest || "option1"}
          onChange={(option) =>
            setpropertyDetails({ ...propertyDetails, propertyInterest: option })
          }
          options={[
            { value: "Desktop", label: "Desktop" },
            { value: "Desktop with Exterior", label: "Desktop with Exterior" },
            {
              value: "MIT/Full Interior Inspection",
              label: "MIT/Full Interior Inspection",
            },
            { value: "PDR", label: "PDR" },
            {
              value: "Desk Review/Appraisal Risk Review",
              label: "Desk Review/Appraisal Risk Review",
            },
            { value: "BPO/ARBPO", label: "BPO/ARBPO" },
          ]}
        />
        <Select
          label={"Tax Year"}
          style={{ width: "15%", mr: ".5em" }}
          options={[
            { value: "2004", label: "2004" },
            { value: "2005", label: "2005" },
            { value: "2006", label: "2006" },
            { value: "2007", label: "2007" },
            { value: "2008", label: "2008" },
            { value: "2009", label: "2009" },
            { value: "2010", label: "2010" },
            { value: "2011", label: "2011" },
            { value: "2012", label: "2012" },
            { value: "2013", label: "2013" },
          ]}
          value={propertyDetails?.taxYear || "2004"}
          onChange={(option) =>
            setpropertyDetails({ ...propertyDetails, taxYear: option })
          }
        />
        <Input
          label={"Tax Amount"}
          placeholder={"Enter here"}
          style={{ margin: ".5em 0" }}
          value={propertyDetails?.taxAmount}
          onChange={(e) =>
            setpropertyDetails({
              ...propertyDetails,
              taxAmount: e.target.value,
            })
          }
        />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        <Input
          label={"Lot"}
          placeholder={"Enter here"}
          style={{ marginRight: ".5em" }}
          value={propertyDetails?.lot}
          onChange={(e) =>
            setpropertyDetails({ ...propertyDetails, lot: e.target.value })
          }
        />
        <Input
          label={"Zoning"}
          placeholder={"Enter here"}
          style={{ marginRight: ".5em" }}
          value={propertyDetails?.zoning}
          onChange={(e) =>
            setpropertyDetails({ ...propertyDetails, zoning: e.target.value })
          }
        />
        <Select
          label={"Occupancy"}
          style={{ width: "15%" }}
          options={[
            { value: "Owner", label: "Owner" },
            { value: "Tenant", label: "Tenant" },
            { value: "Vacant", label: "Vacant" },
            { value: "Other", label: "Other" },
          ]}
          value={propertyDetails?.occupancy || "option1"}
          onChange={(option) =>
            setpropertyDetails({ ...propertyDetails, occupancy: option })
          }
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          mt: "1em",
        }}
      >
        <Select
          label={"Property Type"}
          style={{ width: "15%", mr: "2%" }}
          options={[
            { value: "Single", label: "Single" },
            { value: "Multi", label: "Multi" },
            { value: "Condo/Townhouse", label: "Condo/Townhouse" },
            { value: "Manufactured", label: "Manufactured" },
          ]}
          value={propertyDetails?.propertyType || "option1"}
          onChange={(option) =>
            setpropertyDetails({ ...propertyDetails, propertyType: option })
          }
        />
        <Select
          label={"Style"}
          style={{ width: "15%", mr: "2%" }}
          options={[
            { value: "Ranch", label: "Ranch" },
            { value: "Contemporary", label: "Contemporary" },
            { value: "Bungalow", label: "Bungalow" },
            { value: "Cape", label: "Cape" },
            { value: "Cabin", label: "Cabin" },
            { value: "Colonial", label: "Colonial" },
            { value: "Cottage", label: "Cottage" },
            { value: "Townhome", label: "Townhome" },
            { value: "One Level", label: "One Level" },
            { value: "Two Level", label: "Two Level" },
            { value: "Tri Level", label: "Tri Level" },
            { value: "Split Level", label: "Split Level" },
            { value: "Other", label: "Other" },
          ]}
          value={propertyDetails?.style || "option1"}
          onChange={(option) =>
            setpropertyDetails({ ...propertyDetails, style: option })
          }
        />
        <Select
          label={"Location"}
          style={{ width: "15%", mr: "2%" }}
          options={[
            { value: "Waterfront", label: "Waterfront" },
            { value: "Golfcourse Lot", label: "Golfcourse Lot" },
            { value: "Hillside Lot", label: "Hillside Lot" },
            { value: "Cul-de-Sac", label: "Cul-de-Sac" },
            { value: "Borders pres/Pub land", label: "Borders pres/Pub land" },
          ]}
          value={propertyDetails?.location || "Waterfront"}
          onChange={(option) =>
            setpropertyDetails({ ...propertyDetails, location: option })
          }
        />
        <Select
          label={"View"}
          style={{ width: "15%", mr: "2%" }}
          options={[
            { value: "Residential", label: "Residential" },
            { value: "Mountain", label: "Mountain" },
            { value: "Water", label: "Water" },
            { value: "Golf View", label: "Golf View" },
            { value: "Park", label: "Park" },
            { value: "Commercial", label: "Commercial" },
            { value: "Power Lines", label: "Power Lines" },
            { value: "City Lights", label: "City Lights" },
            { value: "Other", label: "Other" },
          ]}
          value={propertyDetails?.view || "option1"}
          onChange={(option) =>
            setpropertyDetails({ ...propertyDetails, view: option })
          }
        />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        <Input
          label={"GLA"}
          placeholder={"Enter here"}
          style={{ marginRight: ".5em" }}
          value={propertyDetails?.gla}
          onChange={(e) =>
            setpropertyDetails({ ...propertyDetails, gla: e.target.value })
          }
        />
        <Input
          label={"Age"}
          placeholder={"Enter here"}
          style={{ marginRight: ".5em" }}
          value={propertyDetails?.age}
          onChange={(e) =>
            setpropertyDetails({ ...propertyDetails, age: e.target.value })
          }
        />
        <Input
          label={"Beds"}
          placeholder={"Enter here"}
          style={{ marginRight: ".5em" }}
          value={propertyDetails?.beds}
          onChange={(e) =>
            setpropertyDetails({ ...propertyDetails, beds: e.target.value })
          }
        />
        <Input
          label={"Baths"}
          placeholder={"Enter here"}
          style={{ marginRight: ".5em" }}
          value={propertyDetails?.baths}
          onChange={(e) =>
            setpropertyDetails({ ...propertyDetails, baths: e.target.value })
          }
        />
        <Select
          label={"Kitchen Updated?"}
          style={{ width: "15%", mr: "2%" }}
          options={[
            { value: "< 1 Year", label: "< 1 Year" },
            { value: "1 - 5 Year", label: "1 - 5 Year" },
            { value: "6 - 10 Year", label: "6 - 10 Year" },
            { value: "11+ Year", label: "11+ Year" },
          ]}
          value={propertyDetails?.kitchenUpdated || "option1"}
          onChange={(option) =>
            setpropertyDetails({ ...propertyDetails, kitchenUpdated: option })
          }
        />
        <Select
          label={"Baths Updated?"}
          style={{ width: "15%", mr: "2%", mt: "1em" }}
          options={[
            { value: "< 1 Year", label: "< 1 Year" },
            { value: "1 - 5 Year", label: "1 - 5 Year" },
            { value: "6 - 10 Year", label: "6 - 10 Year" },
            { value: "11+ Year", label: "11+ Year" },
          ]}
          value={propertyDetails?.bathsUpdated || "option1"}
          onChange={(option) =>
            setpropertyDetails({ ...propertyDetails, bathsUpdated: option })
          }
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          mt: "1em",
        }}
      >
        <Select
          label={"Heating"}
          style={{ width: "15%", mr: "2%" }}
          options={[
            { value: "Force Warm Air", label: "Force Warm Air" },
            { value: "Hot Water Baseboard", label: "Hot Water Baseboard" },
            { value: "Radiant", label: "Radiant" },
            { value: "Geothermal", label: "Geothermal" },
            { value: "None", label: "None" },
          ]}
          value={propertyDetails?.heating || "option1"}
          onChange={(option) =>
            setpropertyDetails({ ...propertyDetails, heating: option })
          }
        />
        <Select
          label={"Cooling"}
          style={{ width: "15%", mr: "2%" }}
          options={[
            { value: "Central", label: "Central" },
            { value: "Evaporate", label: "Evaporate" },
            { value: "Geothermal", label: "Geothermal" },
            { value: "Individual", label: "Individual" },
            { value: "Other", label: "Other" },
          ]}
          value={propertyDetails?.cooling || "option1"}
          onChange={(option) =>
            setpropertyDetails({ ...propertyDetails, cooling: option })
          }
        />
        <Select
          label={"Amenities"}
          style={{ width: "15%", mr: "2%" }}
          options={[
            { value: "Pool", label: "Pool" },
            { value: "Spa", label: "Spa" },
            { value: "Fence", label: "Fence" },
            { value: "Out building", label: "Out building" },
            { value: "Fire place", label: "Fire place" },
            { value: "Guest house", label: "Guest house" },
          ]}
          value={propertyDetails?.amenities || "option1"}
          onChange={(option) =>
            setpropertyDetails({ ...propertyDetails, amenities: option })
          }
        />
        <Input
          label={"Outbuildings"}
          placeholder={"Enter here"}
          value={propertyDetails?.outBuilding}
          onChange={(e) =>
            setpropertyDetails({
              ...propertyDetails,
              outBuilding: e.target.value,
            })
          }
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          m: "1em 0",
        }}
      >
        <Box sx={{ width: "50%" }}>
          <Text
            style={{
              font: "normal normal 600 22px/33px Poppins",
              color: "#BFBFBF",
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            Basement
          </Text>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            <input
              type="radio"
              name="basement"
              value="Yes"
              onChange={(e) =>
                setpropertyDetails({
                  ...propertyDetails,
                  basement: true,
                  parking: false,
                })
              }
              style={{ margin: "7px 10px auto 0" }}
            />
            <label for="Yes">Yes</label>
            <input
              type="radio"
              name="basement"
              value="No"
              onChange={(e) =>
                setpropertyDetails({
                  ...propertyDetails,
                  basement: false,
                  parking: false,
                })
              }
              style={{ margin: "7px 10px auto 2em" }}
            />
            <label for="No">No</label>
          </Box>
        </Box>
        <Box sx={{ width: "50%" }}>
          <Text
            style={{
              font: "normal normal 600 22px/33px Poppins",
              color: "#BFBFBF",
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            Parking
          </Text>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            <input
              type="radio"
              name="basement"
              value="Yes"
              onChange={(e) =>
                setpropertyDetails({
                  ...propertyDetails,
                  parking: true,
                  basement: false,

                })
              }
              style={{ margin: "7px 10px auto 0" }}
            />
            <label for="Yes">Yes</label>
            <input
              type="radio"
              name="basement"
              value="No"
              onChange={(e) =>
                setpropertyDetails({
                  ...propertyDetails,
                  parking: false,
                  basement: false,

                })
              }
              style={{ margin: "7px 10px auto 2em" }}
            />
            <label for="No">No</label>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        <Input
          label={"SF"}
          placeholder={"Enter here"}
          style={{ marginRight: ".5em" }}
          value={propertyDetails?.sf}
          onChange={(e) =>
            setpropertyDetails({ ...propertyDetails, sf: e.target.value })
          }
        />
        <Input
          label={"% Finished"}
          placeholder={"Enter % Finished"}
          style={{ marginRight: ".5em" }}
          value={propertyDetails?.finished}
          onChange={(e) =>
            setpropertyDetails({ ...propertyDetails, finished: e.target.value })
          }
        />
        <Input
          label={"Total | Beds | Baths"}
          placeholder={"Enter no."}
          style={{ marginRight: ".5em" }}
          value={propertyDetails?.total}
          onChange={(e) =>
            setpropertyDetails({
              ...propertyDetails,
              total: e.target.value,
            })
          }
        />
        <Input
          label={"Garage"}
          placeholder={"Enter here"}
          style={{ marginRight: ".5em" }}
          value={propertyDetails?.garage}
          onChange={(e) =>
            setpropertyDetails({ ...propertyDetails, garage: e.target.value })
          }
        />
        <Input
          label={"Carpet"}
          placeholder={"Enter % Finished"}
          style={{ marginRight: ".5em" }}
          value={propertyDetails?.carpet}
          onChange={(e) =>
            setpropertyDetails({ ...propertyDetails, carpet: e.target.value })
          }
        />
        <Input
          label={"Driveway"}
          placeholder={"Enter no."}
          style={{ marginRight: ".5em" }}
          value={propertyDetails?.driveway}
          onChange={(e) =>
            setpropertyDetails({ ...propertyDetails, driveway: e.target.value })
          }
        />
      </Box>
      <Input
        label={"Comments"}
        placeholder={"Enter here"}
        isMultiline
        value={propertyDetails?.comments}
        onChange={(e) =>
          setpropertyDetails({ ...propertyDetails, comments: e.target.value })
        }
        style={{
          width: "70%",
          height: "10vh",
          marginTop: "1em",
          marginBottom: "1.6em",
        }}
      />
      <Button
        style={{ width: "25%", marginTop: "1em" }}
        onClick={handleSave}
        isLoading={isLoading}
      >
        Save
      </Button>
      <Prompt
        open={makePrompt.open}
        message={makePrompt.message}
        type={makePrompt.type}
        handleClose={() => {
          setmakePrompt({ ...makePrompt, open: false });
        }}
      />
    </Box>
  );
};

export default ProprtyDetails;
