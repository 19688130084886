import { Box } from "@mui/material";
import React, { useState } from "react";
import "./index.css";
import Button from "./Core/Button";
import { CalendarDot, RemoveThin, SendFast } from "react-huge-icons/outline";
import Arc from "../Assets/Images/Arc.jpg";
import Text from "./Core/Text";
import { useDispatch } from "react-redux";
import { makeOrderMessage } from "../Actions/message";
import moment from "moment";
import Prompt from "./Core/Prompt";

const MessageModal = ({ item, handleClose }) => {
  const [activekey, setactivekey] = useState();
  const [isLoading, setisLoading] = useState(false);
  const [message, setmessage] = useState("");
  const [makePrompt, setmakePrompt] = useState({
    open: false,
    message: "",
    type: "",
  });
  const dispatch = useDispatch();

  const handleMakePrompt = (message, type) => {
    setmakePrompt({ open: true, message, type });
    setTimeout(() => {
      setmakePrompt({ open: false, message: "", type: "" });
    }, 2000);
  };

  const handleMakeMessage = () => {
    setisLoading(true);
    dispatch(makeOrderMessage(item?.order[0]?._id, { message }))
      .then((result) => {
        handleMakePrompt("Message Sent", "success");
        setTimeout(() => {
          setisLoading(false);
          handleClose();
        }, 2000);
      })
      .catch((err) => {
        setisLoading(false);
        handleMakePrompt(err.response.data.error, "error");
      });
  };

  return (
    <Box className="mainModal">
      <Box
        sx={{
          width: "540px",
          height: "fit-content",
          background:
            "var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box, #FFFFFF 0% 0% no-repeat padding-box",
          boxShadow: "0px 20px 20px #0000000B",
          borderRadius: "15px",
          opacity: "1",
          margin: "auto 1em 2em auto",
          backgroundColor: "#fff",
          padding: "1em",
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <span
            style={{
              font: "normal normal 600 15px/26px Poppins",
              color: "#000000",
              textAlign: "center",
              marginBottom: "auto",
              marginRight: "auto",
              marginTop: "auto",
            }}
          >
            Send Message
          </span>
          <RemoveThin
            onClick={() => {
              if (isLoading) return;
              handleClose();
            }}
            fontSize={28}
            style={{
              cursor: "pointer",
              marginLeft: "auto !important",
              boxShadow: `rgba(149, 157, 165, 0.2) 0px 8px 24px`,
              backgroundColor: "#fff",
              borderRadius: "50%",
              padding: "2px",
            }}
          />
        </Box>
        <Box
          sx={{
            background: "#FAFAFA 0% 0% no-repeat padding-box",
            borderRadius: "15px",
            padding: ".5em",
            m: ".5em auto",
            width: "97%",
            position: "relative",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <img
            src={Arc}
            alt="Arc"
            style={{
              width: "90px",
              height: "78px",
              borderRadius: "15px",
              opacity: "1",
            }}
          />

          <div
            style={{
              position: "absolute",
              width: "70px",
              backgroundColor: "#000000",
              borderRadius: "15px",
              bottom: 12,
              left: "2%",
              color: "#FFFFFF",
              padding: "6px 6px",
              textAlign: "center",
              justifyContent: "center",
              display: "flex",
              opacity: ".4",
            }}
          >
            <span
              style={{
                fontSize: "10px",
                fontFamily: "Poppins",
                fontWeight: 600,
              }}
            >
              {item.property?.propertyType}
            </span>
          </div>
          <Box sx={{ p: "6px 16px" }}>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Text
                style={{
                  color: "#000000",
                  fontWeight: 600,
                  fontSize: "15px",
                  fontFamily: "Poppins",
                }}
              >
                {item.address} {item.zipCode},
              </Text>
              <Text
                style={{
                  color: "#000000",
                  fontSize: "15px",
                  fontFamily: "Poppins",
                  marginLeft: ".2em",
                  marginTop: "4px",
                }}
              >
                {item.state}
              </Text>
            </Box>

            <Box sx={{ display: "flex", flexDirection: "row", mt: ".5em" }}>
              {item.dueDate && (
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <CalendarDot
                    color="#f4b33f"
                    fontSize={18}
                    style={{
                      marginRight: "10px",
                      marginTop: "4px",
                      marginBottom: "auto",
                    }}
                  />

                  <Text
                    style={{
                      fontSize: "12px",
                      fontFamily: "Poppins",
                      color: "#f4b33f",
                    }}
                  >
                    {moment(item.dueDate).format("DD/MM/YY")}
                  </Text>
                </Box>
              )}
              <CalendarDot
                color="#2593D6"
                fontSize={18}
                style={{
                  marginRight: "10px",
                  marginTop: "4px",
                  marginBottom: "auto",
                  marginLeft: "1.2em",
                }}
              />

              <Text
                style={{
                  fontSize: "12px",
                  fontFamily: "Poppins",
                  color: "#000000",
                }}
              >
                {moment(item.createdAt).format("DD/MM/YY")}
              </Text>
            </Box>
          </Box>
        </Box>
        <Box>
          {Array(4)
            .fill(
              "Lorem ipsom is simple dummy text that can used for that purpose"
            )
            .map((item, index) => (
              <Box
                onClick={() => {
                  setactivekey(index);
                  setmessage(item);
                }}
                key={index}
                sx={{
                  background:
                    activekey === index
                      ? "#2593D624 0% 0% no-repeat padding-box"
                      : " #F7F7F7 0% 0% no-repeat padding-box",
                  border:
                    activekey === index
                      ? "1px solid #2593D6"
                      : "1px solid #F7F7F7",
                  borderRadius: "11px",
                  padding: ".8em",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: ".7em",
                  cursor: "pointer",
                }}
              >
                <span
                  style={{
                    color: activekey === index ? "#2593D6" : "#000",
                    opacity: activekey === index ? 1 : 0.5,
                    font: "normal normal normal 13px/20px Poppins",
                  }}
                >
                  {item}
                </span>
              </Box>
            ))}
        </Box>
        <Button
          style={{
            width: "35%",
            marginTop: ".5em",
            marginLeft: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            position: "relative",
          }}
          isLoading={isLoading}
          disabled={!message || isLoading}
          onClick={handleMakeMessage}
        >
          <span
            style={{
              marginLeft: "-14px",
            }}
          >
            Send Message
          </span>
          <SendFast
            fontSize={18}
            style={{
              position: "absolute",
              right: "18%",
            }}
          />
        </Button>
      </Box>
      <Prompt
        open={makePrompt.open}
        message={makePrompt.message}
        type={makePrompt.type}
        onClose={() => setmakePrompt({ ...makePrompt, open: false })}
      />
    </Box>
  );
};

export default MessageModal;
