import { Box } from '@mui/material'
import React from 'react'

const Report = ({
    handleClose
}) => {
  return (
    <Box sx={{display:"none"}}>
        {/* <h1>Report</h1> */}

    </Box>
  )
}

export default Report