import axios from "../Utils/axios";
import { utilConstants } from "./constants";

export const getBillings = () => {
  return async (dispatch) => {
    dispatch({ type: utilConstants.GET_BILLING_REQUEST });
    const res = await axios.get("/billings");
    try {
      if (res.status === 200) {
        dispatch({
          type: utilConstants.GET_BILLING_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: utilConstants.GET_BILLING_FAILURE,
          payload: res.data,
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: utilConstants.GET_BILLING_FAILURE,
        payload: error,
      });
    }
  };
};
