import { Box } from "@mui/material";
import React, { useState } from "react";
import Template from "../../Template";
import mokcup1 from "../..//Assets/Images/mokcup1.png";
import Input from "../../Components/Core/Input";
import Button from "../../Components/Core/Button";
import MailNegative from "../../Assets/Icons/Huge-icon-communication-outline-mail-negative.svg";
import Shield from "../../Assets/Icons/Huge-icon-ecommerce-outline-shield.svg";
import OutlineBook from "../../Assets/Icons/huge-icon-education-outline-book.svg";
import OutlineStack from "../../Assets/Icons/huge-icon-education-outline-cube-stack.svg";
import backgroundImage from "../..//Assets/Images/shutterstock_317730017.png";
import OutlineUser from "../../Assets/Icons/Huge-icon-user-outline-user.svg";
import FourDigitInput from "../../Components/FourDigitInput";
import Select from "../../Components/Core/Select";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { registerUser, verifyOtp } from "../../Actions/auth";
import Prompt from "../../Components/Core/Prompt";

const validationSchema = yup.object({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  email: yup.string().email("Email is invalid").required("Email is required"),
  role: yup.string().required("Role is required"),
  password: yup
    .string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
});

const Register = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [role, setrole] = useState("Inspector");
  const [showPassword, setshowPassword] = useState(false);
  const [showOtp, setshowOtp] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [otp, setotp] = useState("");
  const [Errors, setErrors] = useState(null);
  const [makePrompt, setmakePrompt] = useState({
    open: false,
    message: "",
    type: "error",
  });

  const handleSubmit = async (e) => {
    setisLoading(true);

    const data = {
      email,
      password,
      firstName,
      lastName,
      role,
    };

    try {
      await validationSchema.validate(data, {
        abortEarly: false,
      });

      if (!showOtp) {
        if (password !== confirmPassword) {
          return setmakePrompt({
            open: true,
            message: "Password And Confirm Password Havn't Match",
            type: "error",
          });
        }
        dispatch(registerUser(data))
          .then((result) => {
            setshowOtp(true);
            setisLoading(false);
            setmakePrompt({
              open: true,
              message: "Verification code sent to your email.",
              type: "success",
            });
          })
          .catch((err) => {
            setisLoading(false);
            setmakePrompt({
              open: true,
              message: err.response.data.error || "Some error occured",
              type: "error",
            });
          });
      } else {
        if (otp) {
          let data = {
            otp,
            email,
          };
          dispatch(verifyOtp(data))
            .then((result) => {
              setisLoading(false);
              setmakePrompt({
                open: true,
                message: "Account created successfully, Redirecting to login",
                type: "success",
              });
              setTimeout(() => {
                window.location.href = "/login";
              }, 2000);
            })
            .catch((err) => {
              setisLoading(false);
              setmakePrompt({
                open: true,
                message: err.response.data.error || "Some error occured",
                type: "error",
              });
            });
        }
      }
    } catch (validationError) {
      const formattedErrors = {};
      validationError.inner.forEach((error) => {
        formattedErrors[error.path] = error.message;
      });

      setErrors(formattedErrors);
      setmakePrompt({
        open: true,
        message:
          Object.values(formattedErrors)[0] || "Some of the fields are invalid",
        type: "error",
      });
      setisLoading(false);
    }
  };

  return (
    <Template
      showHeader={true}
      showFooter={true}
      headerProps={{
        transparent: true,
        backgroundImage: backgroundImage,
      }}
    >
      <Box
        sx={{
          width: "88%",
          ml: "auto",
          mr: "auto",
          display: "flex",
          flexDirection: "row",
          position: "relative",
          mt: "-8em",
          pb: "5em",
        }}
      >
        <img
          src={mokcup1}
          style={{
            width: "480px",
            height: "320px",
            marginTop: "3em",
          }}
        />
        <Box
          sx={{
            widht: "200px !important",
            mr: "auto",
            ml: "auto",
            mt: "9.5em",
            position: "absolute",
            left: "510px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: 300,
            }}
          >
            <Box
              sx={{
                width: 56,
                height: 36,
                backgroundColor: "#2C80FF",
                borderRadius: "6px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mr: ".75em",
                mt: ".5em",
              }}
            >
              <img
                src={OutlineBook}
                style={{
                  width: "18px",
                  height: "18px",
                }}
              />
            </Box>
            <Box>
              <h3
                style={{
                  color: "#191A1C",
                  font: "normal normal 600 15px Poppins",
                  margin: "4px 0",
                }}
              >
                Lorem Ipsum is simply dummy text of the printing
              </h3>
              <p
                style={{
                  font: "normal normal normal 10px/18px Poppins",
                  color: "#474C4E",
                }}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: 300,
              mt: 2,
            }}
          >
            <Box
              sx={{
                width: 56,
                height: 36,
                backgroundColor: "#2C80FF",
                borderRadius: "6px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mr: ".75em",
                mt: ".5em",
              }}
            >
              <img
                src={OutlineStack}
                style={{
                  width: "18px",
                  height: "18px",
                }}
              />
            </Box>
            <Box>
              <h3
                style={{
                  color: "#191A1C",
                  font: "normal normal 600 15px Poppins",
                  margin: "4px 0",
                }}
              >
                Lorem Ipsum is simply dummy text of the printing
              </h3>
              <p
                style={{
                  font: "normal normal normal 10px/18px Poppins",
                  color: "#474C4E",
                }}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
            </Box>
          </Box>
        </Box>
        {showOtp ? (
          <Box className="login_Box" sx={{ height: "fit-content" }}>
            <h3>Verification </h3>
            <p>We have sent verification code to your email.</p>
            <FourDigitInput
              onChange={(e) => {
                setotp(e);
              }}
            />

            <Button onClick={handleSubmit} isLoading={isLoading}>
              Submit
            </Button>
            <p
              style={{
                font: " normal normal normal 12px Poppins",
                color: "#656a72",
                marginTop: "2em",
                fontSize: "12px !important",
              }}
            >
              Didn’t receive the code?
              <span
                style={{
                  color: "rgba(44, 128, 255, 1)",
                  cursor: "pointer",
                  fontWeight: "600",
                }}
              >
                {" "}
                Resend
              </span>
            </p>
          </Box>
        ) : (
          <Box className="login_Box">
            <h3>Sign up</h3>
            <p>Hi, let’s get familiar.</p>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                mb: 0,
              }}
            >
              <Input
                icon={
                  <img
                    src={OutlineUser}
                    style={{ width: "18px", height: "18px" }}
                  />
                }
                value={firstName}
                onChange={(e) => setfirstName(e.target.value)}
                placeholder="First Name"
                type="text"
                style={{ marginTop: "1em", width: "48%", mr: "auto" }}
              />
              <Input
                icon={
                  <img
                    src={OutlineUser}
                    style={{ width: "18px", height: "18px" }}
                  />
                }
                value={lastName}
                onChange={(e) => setlastName(e.target.value)}
                placeholder="Last Name"
                type="text"
                style={{ marginTop: "1em", width: "48%", ml: "auto" }}
              />
            </Box>
            <Input
              icon={
                <img
                  src={MailNegative}
                  style={{ width: "18px", height: "18px" }}
                />
              }
              value={email}
              onChange={(e) => setemail(e.target.value)}
              placeholder="Your email"
              type="email"
              style={{ marginTop: ".5em" }}
            />
            <Input
              icon={
                <img src={Shield} style={{ width: "18px", height: "18px" }} />
              }
              value={password}
              onChange={(e) => setpassword(e.target.value)}
              placeholder="Password"
              type={"password"}
              style={{ margin: ".5em 0" }}
            />
            <Input
              icon={
                <img src={Shield} style={{ width: "18px", height: "18px" }} />
              }
              value={confirmPassword}
              onChange={(e) => setconfirmPassword(e.target.value)}
              placeholder="Confirm Password"
              type={"password"}
              style={{ margin: ".5em 0" }}
            />
            <Select
              value={role}
              onChange={(value) => {
                setrole(value);
                window.localStorage.setItem("role", value);
              }}
              options={[
                { value: "Inspector", label: "Inspector" },
                { value: "Appraiser", label: "Appraiser" },
              ]}
              placeholder="Select Role"
              style={{ width: "100%" }}
            />
            <Button
              onClick={handleSubmit}
              style={{ marginTop: "1em" }}
              isLoading={isLoading}
            >
              Submit
            </Button>
            <p
              style={{
                font: " normal normal normal 12px Poppins",
                color: "#656a72",
                marginTop: "2em",
                fontSize: "12px !important",
              }}
            >
              I have an account?
              <span
                style={{
                  color: "rgba(44, 128, 255, 1)",
                  cursor: "pointer",
                  fontWeight: "600",
                  marginLeft: "5px",
                }}
                onClick={() => {
                  window.location.href = "/login";
                }}
              >
                Login
              </span>
            </p>
          </Box>
        )}
        {makePrompt.open && (
          <Prompt
            open={makePrompt.open}
            handleClose={() => setmakePrompt({ ...makePrompt, open: false })}
            message={makePrompt.message}
            type={makePrompt.type}
          />
        )}
      </Box>
    </Template>
  );
};

export default Register;
