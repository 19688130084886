import { Box } from "@mui/material";
import React, { useState } from "react";
import {
  FileWrittenContinued,
  SpringNotesList,
} from "react-huge-icons/outline";
import {
  SpringNotesList as SpringNotesListFill,
  FileWrittenContinued as FileWrittenContinuedFill,
} from "react-huge-icons/outline";
import Text from "../../Components/Core/Text";
import Input from "../../Components/Core/Input";

const History = () => {
  const [activePage, setactivePage] = useState("Subject History");

  const iconStyle = {
    margin: "auto .5em auto 0",
  };
  const activePages = [
    {
      title: "Subject History",
      icon: <SpringNotesList fontSize={20} style={iconStyle} />,
      filled: <SpringNotesListFill fontSize={20} style={iconStyle} />,
    },
    {
      title: "Comparable History",
      icon: <FileWrittenContinued fontSize={20} style={iconStyle} />,
      filled: <FileWrittenContinuedFill fontSize={20} style={iconStyle} />,
    },
  ];

  const getActivePage = () => {
    switch (activePage) {
      case "Subject History":
        return (
          <Box>
            <Box sx={{ width: "50%" }}>
              <Text
                style={{
                  font: "normal normal 600 22px/33px Poppins",
                  color: "#BFBFBF",
                  fontSize: "18px",
                  fontWeight: "600",
                }}
              >
                Transferred within 3 years?
              </Text>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                <input
                  type="radio"
                  name="basement"
                  value="Yes"
                  style={{ margin: "7px 10px auto 0" }}
                />
                <label for="Yes">Yes</label>
                <input
                  type="radio"
                  name="basement"
                  value="No"
                  style={{ margin: "7px 10px auto 2em" }}
                />
                <label for="No">No</label>
              </Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        <Input
          label={"Sale Price"}
          placeholder={"Enter here"}
          style={{ marginRight: ".5em" }}
        />
        <Input
          label={"Date"}
          placeholder={"Enter here"}
          style={{ marginRight: ".5em" }}
        />
      
      </Box>
          </Box>
        );
      case "Comparable History":
        return <Box></Box>;

      default:
        break;
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        p: ".5em",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          width: "fit-content",
          ml: "2em",
        }}
      >
        {activePages.map((page, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "row",
              p: "10px 20px",
              bgcolor: "#F3F3F3",
              width: "auto",
              borderRadius: "20px 20px 0px 0px",
              mr: ".5em",
              cursor: "pointer",
              color: activePage !== page.title ? "#28303F" : "#2593D6",
              opacity: activePage !== page.title ? 0.6 : 1,
            }}
            onClick={() => setactivePage(page.title)}
          >
            {activePage === page.title ? page.filled : page.icon}
            <Text
              variant="body1"
              style={{
                color: activePage === page.title ? "#2593D6" : "#28303F",
                fontSize: "14px",
              }}
            >
              {page.title}
            </Text>
          </Box>
        ))}
      </Box>
      <Box sx={{ bgcolor: "#F3F3F3", padding: "1em 1.6em", borderRadius: "24px" }}>
        {getActivePage()}
      </Box>
    </Box>
  );
};

export default History;
