import { Box } from "@mui/material";
import React, { useState } from "react";
import Text from "../../Components/Core/Text";
import Input from "../../Components/Core/Input";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { getRepairDetails, updateRepairDetails } from "../../Actions/report";
import Button from "../../Components/Core/Button";
import { DataGrid } from "@mui/x-data-grid";

const columns = [
  { field: "repairType", headerName: "Repair Type", width: 150 },
  { field: "comment", headerName: "Comment", width: 200 },
  {
    field: "estimateCost",
    headerName: "Estimate Cost",
    type: "number",
    width: 150,
  },
  {
    field: "isEmergency",
    headerName: "Is Emergency",
    type: "boolean",
    width: 150,
  },
  { field: "agree", headerName: "Agree/Disagree", type: "boolean", width: 120 },
];

const Repairs = ({ repairDetails, setrepairDetails, repairsData }) => {
  const role = window.localStorage.getItem("role");
  const [isRepairLoading, setisRepairLoading] = useState(false);
  const [makePrompt, setmakePrompt] = useState({
    open: false,
    message: "",
    type: "error",
  });
  const dispatch = useDispatch();
  const location = useLocation();
  const report_id = location.state.id;
  const rows = repairDetails.repairs.map((item) => {
    return {
      id: item._id,
      _id: item._id,
      repairType: item.repairType,
      comment: item.comment,
      estimateCost: item.estimateCost,
      isEmergency: item.isEmergency,
      agree: true,
    };
  });

  const getRowClassName = (params) => {
    return "custom-row";
  };

  const handleChangeRepairLists = (id, key, data) => {
    repairDetails.repairs.map((item) => {
      if (item._id === id) {
        item[key] = data;
      }
    });
    setrepairDetails({ ...repairDetails });
  };

  const saveRepairDetails = () => {
    const data = {
      ...repairsData,
    };
    const repairData = [];
    repairDetails.repairs.map((item) => {
      repairData.push({
        repairType: item.repairType,
        comment: item.comment,
        estimateCost: item.estimateCost,
        isEmergency: item.isEmergency,
      });
    });
    if (repairData.length > 0) {
      data.repairs = JSON.stringify(repairData);
    }
    setisRepairLoading(true);
    dispatch(updateRepairDetails(data, report_id))
      .then((result) => {
        setisRepairLoading(false);
        dispatch(getRepairDetails(report_id));
        setmakePrompt({
          open: true,
          message: "Repair details updated successfully",
          type: "success",
        });
      })
      .catch((err) => {
        setisRepairLoading(false);
        setmakePrompt({
          open: true,
          message: "Error updating repair details",
          type: "error",
        });
      });
  };

  console.log(
    "repairDetails",
    repairDetails.inspectorRepairsRecommendations?.recommended
  );
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        p: ".5em",
      }}
    >
      {role === "Appraiser" && (
        <Text
          style={{
            font: "normal normal 600 14px/33px Poppins",
            color: "#28303F",
            fontWeight: "600",
          }}
        >
          Inspector
        </Text>
      )}
      {role === "Appraiser" &&
        Object.keys(repairDetails.inspectorRepairsRecommendations).length >
          0 && (
          <Box
            sx={{
              boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
              padding: "1em",
              m: "1em 0",
              borderRadius: "12px",
            }}
          >
            <Box
              sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
            >
              <Box sx={{ m: "0 1em 0 0" }}>
                <Text
                  style={{
                    font: "normal normal 600 14px/33px Poppins",
                    color: "#132843",
                    fontWeight: "600",
                  }}
                >
                  Repair Recommended?
                </Text>
                <Text
                  style={{
                    font: "normal normal 600 14px/33px Poppins",
                    color: "#000000",
                    fontWeight: "normal",
                  }}
                >
                  {repairDetails.inspectorRepairsRecommendations?.recommended
                    ? "Yes"
                    : "No"}
                </Text>
              </Box>

              <Box sx={{ m: "0 1em 0 1em" }}>
                <Text
                  style={{
                    font: "normal normal 600 14px/33px Poppins",
                    color: "#132843",
                    fontWeight: "600",
                  }}
                >
                  Emergency Repairs Required?
                </Text>
                <Text
                  style={{
                    font: "normal normal 600 14px/33px Poppins",
                    color: "#000000",
                    fontWeight: "normal",
                  }}
                >
                  {repairDetails.inspectorRepairsRecommendations?.emergency
                    ? "Yes"
                    : "No"}
                </Text>
              </Box>

              <Box sx={{ m: "0 1em 0 1em" }}>
                <Text
                  style={{
                    font: "normal normal 600 14px/33px Poppins",
                    color: "#132843",
                    fontWeight: "600",
                  }}
                >
                  Repair Estimate
                </Text>
                <Text
                  style={{
                    font: "normal normal 600 14px/33px Poppins",
                    color: "#000000",
                    fontWeight: "normal",
                  }}
                >
                  ${repairDetails.inspectorRepairsRecommendations?.estimateCost}
                </Text>
              </Box>
              <Box sx={{ m: "0 1em 0 1em" }}>
                <Text
                  style={{
                    font: "normal normal 600 14px/33px Poppins",
                    color: "#132843",
                    fontWeight: "600",
                  }}
                >
                  Days to Complete
                </Text>
                <Text
                  style={{
                    font: "normal normal 600 14px/33px Poppins",
                    color: "#000000",
                    fontWeight: "normal",
                  }}
                >
                  {
                    repairDetails.inspectorRepairsRecommendations
                      ?.daysToComplete
                  }
                </Text>
              </Box>
            </Box>

            <Box sx={{ m: "0 1em 0 0" }}>
              <Text
                style={{
                  font: "normal normal 600 14px/33px Poppins",
                  color: "#132843",
                  fontWeight: "600",
                }}
              >
                Inspector Comment
              </Text>
              <Text
                style={{
                  color: "#000000",
                  fontWeight: "normal",
                  fontSize: "13.5px",
                  lineHeight: "20px",
                  fontFamily: "Poppins",
                }}
              >
                {repairDetails.inspectorRepairsRecommendations?.comment}
              </Text>
            </Box>
          </Box>
        )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          m: "1em 0",
        }}
      >
        <Box sx={{ width: "25%" }}>
          <Text
            style={{
              font: "normal normal 600 22px/33px Poppins",
              color: "#BFBFBF",
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            Repair Recommended?
          </Text>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            <input
              type="radio"
              name="recommended"
              checked={
                repairDetails.inspectorRepairsRecommendations?.recommended
              }
              onChange={(e) =>
                setrepairDetails({
                  ...repairDetails,
                  inspectorRepairsRecommendations: {
                    recommended: true,
                  },
                })
              }
              style={{ margin: "7px 10px auto 0" }}
            />
            <label htmlFor="recommended">Yes</label>
            <input
              type="radio"
              name="recommended"
              checked={
                repairDetails.inspectorRepairsRecommendations?.recommended
              }
              onChange={(e) =>
                setrepairDetails({
                  ...repairDetails,
                  inspectorRepairsRecommendations: {
                    recommended: false,
                  },
                })
              }
              style={{ margin: "7px 10px auto 2em" }}
            />
            <label htmlFor="recommended">No</label>
          </Box>
        </Box>
        <Box sx={{ width: "25%" }}>
          <Text
            style={{
              font: "normal normal 600 22px/33px Poppins",
              color: "#BFBFBF",
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            Emergency Repairs Required?
          </Text>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            <input
              type="radio"
              name="emergency"
              checked={repairDetails.inspectorRepairsRecommendations?.emergency}
              onChange={(e) =>
                setrepairDetails({
                  ...repairDetails,
                  inspectorRepairsRecommendations: {
                    emergency: true,
                  },
                })
              }
              style={{ margin: "7px 10px auto 0" }}
            />
            <label htmlFor="emergency">Yes</label>
            <input
              type="radio"
              name="emergency"
              onChange={(e) =>
                setrepairDetails({
                  ...repairDetails,
                  inspectorRepairsRecommendations: {
                    emergency: false,
                  },
                })
              }
              checked={repairDetails.inspectorRepairsRecommendations?.emergency}
              style={{ margin: "7px 10px auto 2em" }}
            />
            <label htmlFor="emergency">No</label>
          </Box>
        </Box>
        <Input
          label={"Repair Estimate"}
          placeholder={"Enter here"}
          style={{
            marginRight: ".5em",
            width: "20%",
            marginLeft: "auto",
          }}
          value={repairDetails.inspectorRepairsRecommendations?.estimateCost}
          onChange={(e) =>
            setrepairDetails({
              ...repairDetails,
              inspectorRepairsRecommendations: {
                estimateCost: e.target.value,
              },
            })
          }
        />
        <Input
          label={"Days to Complete"}
          placeholder={"Enter here"}
          style={{ marginLeft: ".5em", width: "20%" }}
          value={repairDetails.inspectorRepairsRecommendations?.daysToComplete}
          onChange={(e) =>
            setrepairDetails({
              ...repairDetails,
              inspectorRepairsRecommendations: {
                daysToComplete: e.target.value,
              },
            })
          }
        />
      </Box>
      <Input
        label={"Repair Comment"}
        placeholder={"Add Comments"}
        isMultiline
        value={repairDetails.inspectorRepairsRecommendations?.comment}
        onChange={(e) =>
          setrepairDetails({
            ...repairDetails,
            inspectorRepairsRecommendations: {
              comment: e.target.value,
            },
          })
        }
        style={{
          width: "70%",
          height: "12vh",
          marginTop: ".5em",
          marginBottom: "1em",
        }}
      />
      {role === "Inspector" && (
        <Text
          style={{
            font: "normal normal 600 22px/33px Poppins",
            color: "#BFBFBF",
            fontSize: "18px",
            fontWeight: "600",
            marginTop: "1.5em",
            marginBottom: ".5em",
          }}
        >
          Repairs
        </Text>
      )}

      {role === "Inspector"
        ? repairDetails.repairs?.map((item, index) => (
            <Box
              key={index}
              sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
            >
              <Input
                label={"Repair Type"}
                placeholder={"Enter here"}
                style={{ marginRight: ".5em", width: "20%" }}
                value={item.repairType}
                onChange={(e) =>
                  handleChangeRepairLists(
                    item._id,
                    "repairType",
                    e.target.value
                  )
                }
              />
              <Input
                label={"Comments"}
                placeholder={"Enter here"}
                style={{ marginLeft: ".5em", width: "20%" }}
                value={item.comment}
                onChange={(e) =>
                  handleChangeRepairLists(item._id, "comment", e.target.value)
                }
              />
              <Input
                label={"Estimated Cost"}
                placeholder={"Enter here"}
                style={{ marginLeft: ".5em", width: "20%" }}
                value={item.estimateCost}
                onChange={(e) =>
                  handleChangeRepairLists(
                    item._id,
                    "estimateCost",
                    e.target.value
                  )
                }
              />
              <Input
                label={"Emergency Repair"}
                isTransparent={true}
                type="checkbox"
                placeholder={"Select here"}
                value={item.isEmergency}
                onChange={(e) => {
                  handleChangeRepairLists(
                    item._id,
                    "isEmergency",
                    e.target.checked
                  );
                }}
                style={{ marginLeft: ".5em", width: "20%", height: "40px" }}
              />
            </Box>
          ))
        : repairDetails?.repairs?.length > 0 && (
            <Box>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                getRowHeight={() => {
                  return 54;
                }}
                hideFooter
                disableColumnFilter
                disableColumnMenu
                disableSelectionOnClick
                autoHeight
                disableColumnSelector
                disableDensitySelector
                density="compact"
                getRowClassName={getRowClassName}
                disableColumnReorder
                style={{ border: "none" }}
              />
            </Box>
          )}
      {role === "Inspector" && (
        <Button
          isTransparent={true}
          style={{
            backgroundColor: "#EEF5FF",
            color: "#2593D6",
            width: "140px",
            marginTop: "1em",
          }}
          onClick={() => {
            setrepairDetails({
              ...repairDetails,
              repairs: [
                ...repairDetails.repairs,
                {
                  _id: Date.now(),
                  repairType: "",
                  comment: "",
                  estimatedCost: 0,
                  isEmergency: false,
                },
              ],
            });
          }}
        >
          Add More
        </Button>
      )}
      <Button
        isLoading={isRepairLoading}
        style={{
          width: "140px",
          marginTop: "1em",
        }}
        onClick={() => saveRepairDetails()}
      >
        Save Now
      </Button>
    </Box>
  );
};

export default Repairs;
