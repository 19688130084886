import { Box } from "@mui/material";
import React, { useState } from "react";
import Text from "./Core/Text";
import Button from "./Core/Button";

const Agreement = ({ handleNext, handleBack, handleChange, data }) => {
  const [isAgree, setisAgree] = useState(false);
  return (
    <Box
      sx={{
        width: "66%",
        ml: "auto",
        mr: "auto",
      }}
    >
      <Text
        variant="h1"
        style={{
          color: "#E6E6E6",
          fontSize: "48px",
        }}
      >
        Registration Agreement
      </Text>
      <Text
        variant="body1"
        style={{
          font: "normal normal medium 14px/21px Poppins",
          color: "#656a72",
          fontSize: "13px",
        }}
      >
        Lorem Ipsum is simply dummy text of the printing and type setting
        industry.
      </Text>
      <Box
        sx={{
          height: "400px",
          overflowY: "scroll",
          mt: "2em",
          pr: "4em",
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#F0F0F0",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#2C80FF",
            borderRadius: "4px",
          },
          scrollbarColor: "#2C80FF #F0F0F0",
          scrollbarWidth: "thin",
        }}
      >
        <Text
          style={{
            font: "normal normal normal 12px/18px Poppins",
            color: "#474C4E",
            opacity: "0.7",
          }}
        >
          Lorem Ipsum is Lorem Ipsum is simply dummy text of the printing and
          typesetting industry. Lorem Ipsum has been the industry’s standard
          dummy text ever since the 1500s,Lorem Ipsum is Lorem Ipsum is simply
          dummy text of the printing and typesetting industry. Lorem Ipsum has
          been the industry’s standard dummy text ever since the 1500s,Lorem
          Ipsum is Lorem Ipsum is simply dummy text of the printing and
          typesetting industry. Lorem Ipsum has been the industry’s standard
          dummy text ever since the 1500s,Lorem Ipsum is Lorem Ipsum is simply
          dummy text of the printing and typesetting industry. Lorem Ipsum has
          been the industry’s standard dummy text ever since the 1500s,Lorem
          Ipsum is Lorem Ipsum is simply dummy text of the printing and
          typesetting industry. Lorem Ipsum has been the industry’s standard
          dummy text ever since the 1500s,
        </Text>
        <Text
          style={{
            color: "#191919",
            font: "normal normal 600 14px/21px Poppins",
          }}
        >
          2. Lorem Ipsum is Lorem Ipsum is simply dummy text
        </Text>
        <Text
          style={{
            font: "normal normal normal 12px/18px Poppins",
            color: "#474C4E",
            opacity: "0.7",
          }}
        >
          Lorem Ipsum is Lorem Ipsum is simply dummy text of the printing and
          typesetting industry. Lorem Ipsum has been the industry’s standard
          dummy text ever since the 1500s,Lorem Ipsum is Lorem Ipsum is simply
          dummy text of the printing and typesetting industry. Lorem Ipsum has
          been the industry’s standard dummy text ever since the 1500s,Lorem
          Ipsum is Lorem Ipsum is simply dummy text of the printing and
          typesetting industry. Lorem Ipsum has been the industry’s standard
          dummy text ever since the 1500s,Lorem Ipsum is Lorem Ipsum is simply
          dummy text of the printing and typesetting industry. Lorem Ipsum has
          been the industry’s standard dummy text ever since the 1500s,Lorem
          Ipsum is Lorem Ipsum is simply dummy text of the printing and
          typesetting industry. Lorem Ipsum has been the industry’s standard
          dummy text ever since the 1500s,
        </Text>
        <Text
          style={{
            color: "#191919",
            font: "normal normal 600 14px/21px Poppins",
          }}
        >
          2. Lorem Ipsum is Lorem Ipsum is simply dummy text
        </Text>
        <Text
          style={{
            font: "normal normal normal 12px/18px Poppins",
            color: "#474C4E",
            opacity: "0.7",
          }}
        >
          Lorem Ipsum is Lorem Ipsum is simply dummy text of the printing and
          typesetting industry. Lorem Ipsum has been the industry’s standard
          dummy text ever since the 1500s,Lorem Ipsum is Lorem Ipsum is simply
          dummy text of the printing and typesetting industry. Lorem Ipsum has
          been the industry’s standard dummy text ever since the 1500s,Lorem
          Ipsum is Lorem Ipsum is simply dummy text of the printing and
          typesetting industry. Lorem Ipsum has been the industry’s standard
          dummy text ever since the 1500s,Lorem Ipsum is Lorem Ipsum is simply
          dummy text of the printing and typesetting industry. Lorem Ipsum has
          been the industry’s standard dummy text ever since the 1500s,Lorem
          Ipsum is Lorem Ipsum is simply dummy text of the printing and
          typesetting industry. Lorem Ipsum has been the industry’s standard
          dummy text ever since the 1500s,
        </Text>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", m: "1em 0" }}>
        <input
          type="radio"
          id={"agree"}
          checked={data.registration}
          style={{
            width: "16px",
            height: "16px",
            borderRadius: "8px",
            marginTop: "auto",
            marginBottom: "auto",
          }}
          onChange={() => {
            handleChange("registration", true);
          }}
        />
        <label
          htmlFor="agree"
          style={{
            color: "#656A72",
            fontSize: "13px",
            fontFamily: "Poppins",
            margin: "auto 0.5em",
          }}
        >
          Lorem Ipsum is Lorem Ipsum is simply dummy text of the printing and
          typesetting industry.{" "}
        </label>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", mt: "1em" }}>
        {handleBack && (
          <Button
            isTransparent
            style={{
              width: "30%",
              paddingTop: ".2em",
              paddingBottom: ".2em",
              marginRight: ".5em",
            }}
            onClick={handleBack}
          >
            Back
          </Button>
        )}
        {handleNext && (
          <Button
            style={{
              width: "30%",
              paddingTop: ".1em",
              paddingBottom: ".1em",
            }}
            onClick={handleNext}
          >
            Save & Continue
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default Agreement;
