import React, { useEffect, useState } from "react";
import Template from "../../Template";
import { Box } from "@mui/material";
import Ellipse8 from "../../Assets/Images/Ellipse8.svg";
import Text from "../../Components/Core/Text";
import { AiOutlineRight } from "react-icons/ai";
import License from "../../Components/License";
import PersonalInfos from "../../Components/PersonalInfos";
import CompanyInfo from "../../Components/CompanyInfo";
import CoverageArea from "../../Components/CoverageArea";
import Billings from "../../Components/Billings";
import Document from "../../Components/Document";
import DigitalSign from "../../Components/DigitalSign";
import Agreement from "../../Components/Agreement";
import RegisterComplete from "../../Components/RegisterComplete";
import {
  billingSchema,
  companyInfoSchema,
  coverageAreaSchema,
  digitalSignatureSchema,
  documentValidationSchema,
  personalInfoSchema,
  licenseSchema,
  agreementValidationSchema,
} from "../../Validations";
import {
  addBilling,
  addCompany,
  addCoverageArea,
  addDigital,
  addDocument,
  addLicence,
  addPersonal,
  appraiserQualification,
  getBilling,
  getCompany,
  getCoverageArea,
  getDigital,
  getDocument,
  getInsurance,
  getLicense,
  getPersonal,
  getQualification,
  makeInsurance,
  verifyAgreement,
} from "../../Actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { InformationCircle } from "react-huge-icons/solid";
import Prompt from "../../Components/Core/Prompt";

function MyProfile() {
  const auth = useSelector((state) => state.auth);
  const [activePage, setactivePage] = useState("State License(s)");
  const [licenseData, setlicenseData] = useState({
    data: [
      {
        _id: Date.now(),
        licenseType: "",
        licenseState: "",
        licenseNumber: "",
        imageName: "",
        image: null,
        expDate: "",
      },
    ],
  });
  console.log(licenseData, "licenseData");
  const [personalInfoData, setpersonalInfoData] = useState({});
  const [companyInfoData, setcompanyInfoData] = useState({});
  const [coverageAreaData, setcoverageAreaData] = useState({});
  const [digitalSignData, setdigitalSignData] = useState({});
  const [registerAgree, setregisterAgree] = useState({});
  const [showReadMore, setshowReadMore] = useState(false);
  const [billingData, setbillingData] = useState({});
  const [documentData, setdocumentData] = useState({});
  const [complatedSigns, setcomplatedSigns] = useState([]);
  const [errors, setErrors] = useState({});
  const [makePrompt, setmakePrompt] = useState({
    open: false,
    message: "",
    type: "error",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLicense());
    dispatch(getPersonal());
    dispatch(getCoverageArea());
    dispatch(getDigital());
    dispatch(getCompany());
    dispatch(getBilling());
    dispatch(getDocument());
    dispatch(getInsurance());
    dispatch(getQualification());
  }, []);

  useEffect(() => {
    const { profile } = auth;

    if (Object.keys(profile).length > 0) {
      if (profile.license) {
        setlicenseData({ data: profile.license });
      }

      if (profile.personals) {
        setpersonalInfoData(profile.personals);
        const { coverageAreaState, coverageAreaCountry, digitalSignature } =
          profile.personals;

        setcoverageAreaData({ coverageAreaState, coverageAreaCountry });
        setdigitalSignData({ digitalSignature });
        setregisterAgree({ registration: profile.personals.agreementAccepted });
      }

      if (profile.company) {
        setcompanyInfoData(profile.company);
      }
      if (profile.billings !== undefined && profile.billings.length > 0) {
        setbillingData(profile.billings[0]);
      }
      if (profile.documents !== undefined && profile.documents.length > 0) {
        setdocumentData(profile.documents[0]);
      }
    }
  }, [auth.profile]);

  const pageTypeData = [
    {
      name: "State License(s)",
      comment:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      schema: licenseSchema,
      data: licenseData,
      dispatch: addLicence,
      next: "Personal Information",
      prev: "State License(s)",
      tags: ["form"],
    },
    {
      name: "Personal Information",
      comment:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      schema: personalInfoSchema,
      data: personalInfoData,
      dispatch: addCompany,
      next: "Company Information",
      prev: "State License(s)",
    },
    {
      name: "Company Information",
      comment:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      schema: companyInfoSchema,
      data: companyInfoData,
      dispatch: addPersonal,
      next: "Coverage Area",
      prev: "Personal Information",
    },
    {
      name: "Coverage Area",
      comment:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      schema: coverageAreaSchema,
      data: coverageAreaData,
      dispatch: addCoverageArea,
      next: "Digital Signature",
      prev: "Company Information",
    },
    {
      name: "Digital Signature",
      comment:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      schema: digitalSignatureSchema,
      data: digitalSignData,
      dispatch: addDigital,
      next: "Billing",
      prev: "Coverage Area",
      tags: ["form"],
    },
    {
      name: "Billing",
      comment:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      schema: billingSchema,
      data: billingData,
      dispatch: addBilling,
      next: "Document",
      prev: "Digital Signature",
    },
    {
      name: "Document",
      comment:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      schema: documentValidationSchema,
      data: documentData,
      dispatch: addDocument,
      next: "State License(s)",
      prev: "Billing",
      tags: ["form"],
    },
  ];

  const handleChange = (name, value) => {
    const dataMap = {
      "State License(s)": setlicenseData,
      "Personal Information": setpersonalInfoData,
      "Company Information": setcompanyInfoData,
      "Coverage Area": setcoverageAreaData,
      "Digital Signature": setdigitalSignData,
      // "Appraiser Qualification": setqualification,
      // "E&o Insurance": setinsurance,
      Billing: setbillingData,
      Document: setdocumentData,
      "Registration Agreement": setregisterAgree,
      Complete: "Complete",
    };

    const setData = dataMap[activePage];
    if (setData) {
      setData((prevData) => ({ ...prevData, [name]: value }));
    } else {
      console.warn("Unsupported activePage:", activePage);
    }
  };

  console.log(activePage, "currentPage");

  const handleNext = async () => {
    console.log("hellooo");
    // try {
    //   const currentPage = pageTypeData.find((item) => item.name === activePage);
    //   if (!auth.fetchedItems.includes(activePage)) {
    //     await currentPage.schema.validate(currentPage.data, {
    //       abortEarly: false,
    //     });
    //     if (currentPage.tags?.includes("form")) {
    //       const form = new FormData();
    //       if (activePage === "Digital Signature") {
    //         form.append("signature", currentPage.data.signature[0]);
    //       }

    //       if (activePage === "Document") {
    //         const keys = [];
    //         const values = [];
    //         for (let key in currentPage.data) {
    //           if (currentPage.data.hasOwnProperty(key)) {
    //             keys.push(key);
    //           }
    //         }
    //         keys.forEach((key) => {
    //           values.push(...currentPage.data[key]);
    //           form.append(key, values[0]);
    //         });
    //       }

    //       if (activePage === "State License(s)") {
    //         const constructData = [];
    //         const images = [];
    //         if (
    //           currentPage.data?.data !== undefined &&
    //           currentPage.data?.data.length > 0
    //         ) {
    //           currentPage.data?.data.forEach((item) => {
    //             const data = {
    //               licenseType: item.licenseType,
    //               licenseState: item.licenseState,
    //               licenseNumber: item.licenseNumber,
    //               expiresAt: item.expiresAt,
    //               imageName: item.imageName,
    //             };
    //             constructData.push(data);
    //             images.push(item.image);
    //           });
    //         }
    //         const jsonData = JSON.stringify(constructData);
    //         for (const img of images) {
    //           form.append("images", img);
    //         }
    //         form.append("data", jsonData);
    //       }

    //       if (activePage === "E&o Insurance") {
    //         form.append("companyName", currentPage.data.companyName);
    //         form.append("policyNumber", currentPage.data.policyNumber);
    //         form.append("claimLimit", currentPage.data.claimLimit);
    //         form.append("expiresAt", currentPage.data.expiresAt);
    //         form.append("document", currentPage.data.document[0]);
    //       }

    //       if (activePage === "Appraiser Qualification") {
    //         form.append("experience", currentPage.data.experience);
    //         form.append("designation", currentPage.data.designation);
    //         form.append(
    //           "commercialApproved",
    //           currentPage.data.commercialApproved
    //         );
    //         form.append("FHAApproved", currentPage.data.FHAApproved);
    //         form.append("resume", currentPage.data.resume[0]);
    //         form.append(
    //           "governmentAgencyIncludeCheck",
    //           currentPage.data.governmentAgencyIncludeCheck
    //         );
    //         form.append(
    //           "disciplinaryActionAgainstUserCheck",
    //           currentPage.data.disciplinaryActionAgainstUserCheck
    //         );
    //         form.append(
    //           "subjectOfAnyCorrectiveActionByAppraiserCheck",
    //           currentPage.data.subjectOfAnyCorrectiveActionByAppraiserCheck
    //         );
    //         form.append(
    //           "notifiedOfAnyInvestigationByBoardCheck",
    //           currentPage.data.notifiedOfAnyInvestigationByBoardCheck
    //         );
    //         form.append(
    //           "convictedOfFelonyCheck",
    //           currentPage.data.convictedOfFelonyCheck
    //         );
    //         form.append(
    //           "lawsuitOrClaimFieldCheck",
    //           currentPage.data.lawsuitOrClaimFieldCheck
    //         );
    //         form.append(
    //           "awarenessOfAnyLawsuitOrClaimFieldCheck",
    //           currentPage.data.awarenessOfAnyLawsuitOrClaimFieldCheck
    //         );
    //         form.append("backgroundCheck", currentPage.data.backgroundCheck[0]);
    //       }
    //       const actionToDispatch = currentPage.dispatch(form, true);
    //       dispatch(actionToDispatch)
    //         .then((result) => {
    //           setcomplatedSigns([...complatedSigns, activePage]);
    //           setactivePage(currentPage.next);
    //           setmakePrompt({
    //             open: true,
    //             message: `${activePage} has been updated successfully!`,
    //             type: "success",
    //           });
    //         })
    //         .catch((err) => {
    //           setmakePrompt({
    //             open: true,
    //             message: err.response?.data?.error || "Some error occured",
    //             type: "error",
    //           });
    //         });
    //     } else {
    //       const actionToDispatch = currentPage.dispatch(currentPage.data, true);

    //       dispatch(actionToDispatch)
    //         .then((result) => {
    //           setcomplatedSigns([...complatedSigns, activePage]);
    //           setactivePage(currentPage.next);
    //           setmakePrompt({
    //             open: true,
    //             message: `${activePage} has been updated successfully!`,
    //             type: "success",
    //           });
    //         })
    //         .catch((err) => {
    //           setmakePrompt({
    //             open: true,
    //             message: err.response?.data?.error || "Some error occured",
    //             type: "error",
    //           });
    //         });
    //     }
    //   } else {
    //     setmakePrompt({
    //       open: true,
    //       message: `${activePage} has been updated successfully!`,
    //       type: "success",
    //     });
    //     setactivePage(currentPage.next);
    //   }
    // } catch (validationError) {
    //   const formattedErrors = {};
    //   validationError?.inner?.forEach((error) => {
    //     formattedErrors[error.path] = error.message;
    //   });
    //   setErrors(formattedErrors);
    //   setmakePrompt({
    //     open: true,
    //     message: Object.values(formattedErrors)[0] || "Some error occured",
    //     type: "error",
    //   });
    // }
  };
  const handleBack = () => {
    const currentPage = pageTypeData.find((item) => item.name === activePage);

    if (currentPage) {
      setactivePage(currentPage.prev);
    } else {
      console.warn("Unsupported activePage:", activePage);
    }
  };

  const getPageType = () => {
    const currentPageConfig = pageTypeData.find(
      (config) => config.name === activePage
    );

    if (currentPageConfig) {
      const { schema, data, next } = currentPageConfig;
      console.log(activePage, "activePage");

      switch (activePage) {
        case "State License(s)":
          return (
            <License
              handleChangeLicense={handleChange}
              licenseData={data}
              handleNextForLicense={handleNext}
              errors={errors}
              setlicenseData={setlicenseData}
              isUpdate={auth.fetchedItems.includes(activePage)}
            />
          );
        case "Personal Information":
          return (
            <PersonalInfos
              handleChange={handleChange}
              data={data}
              handleNext={handleNext}
              handleBack={handleBack}
              errors={errors}
              isUpdate={true}
            />
          );
        case "Company Information":
          return (
            <CompanyInfo
              handleChange={handleChange}
              data={data}
              handleNext={handleNext}
              handleBack={handleBack}
              errors={errors}
              isUpdate={true}
            />
          );
        case "Coverage Area":
          return (
            <CoverageArea
              handleChange={handleChange}
              data={data}
              handleNext={handleNext}
              handleBack={handleBack}
              errors={errors}
              isUpdate={true}
            />
          );
        case "Digital Signature":
          return (
            <DigitalSign
              handleChange={handleChange}
              data={data}
              handleNext={handleNext}
              handleBack={handleBack}
              errors={errors}
              isUpdate={true}
            />
          );
        case "Billing":
          return (
            <Billings
              handleChange={handleChange}
              data={data}
              handleNext={handleNext}
              handleBack={handleBack}
              errors={errors}
              isUpdate={true}
            />
          );
        case "Document":
          return (
            <Document
              handleChange={handleChange}
              data={data}
              handleNext={handleNext}
              handleBack={handleBack}
              errors={errors}
              isUpdate={true}
            />
          );
        default:
          return <div>Page not found</div>;
      }
    } else {
      console.warn("Unsupported activePage:", activePage);
      return <div>Unsupported Page</div>;
    }
  };

  return (
    <Template
      showHeader
      headerProps={{
        showNotification: true,
        showProfile: true,
        showSearch: true,
        showMessage: true,
        showCash: true,
        showCompleteProfile: true,
      }}
      containerStyle={{
        backgroundImage: `url(${Ellipse8})`,
        height: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "95%",
          ml: "auto",
          mr: "auto",
        }}
      >
        <Box
          sx={{
            background:
              " transparent linear-gradient(150deg, #2593D6 0%, #134A6B 100%) 0% 0% no-repeat padding-box",
            padding: "28px 20px",
            borderRadius: "24px",
            boxShadow: "15px 15px 50px #00000029",
            backdropFilter: "blur(50px)",
            width: "15%",
            height: "fit-content",
          }}
        >
          <Text
            variant="h4"
            style={{
              color: "white",
              paddingLeft: "6px",
              font: " normal normal 600 22px/38px Poppins",
            }}
          >
            Manage Profile
          </Text>
          <Box
            sx={{
              height: "2px",
              width: "100%",
              background: "#FFFFFF 0% 0% no-repeat padding-box",
              opacity: "0.2",
              margin: ".7em 0px",
            }}
          />
          {pageTypeData.map((item, index) => (
            <Box
              onClick={() => setactivePage(item.name)}
              sx={{ display: "flex", flexDirection: "row", p: 1 }}
            >
              <Text
                variant="body1"
                style={{
                  color: "white",
                  opacity: activePage === item.name ? 1 : 0.5,
                }}
              >
                {item.name}
              </Text>
              <AiOutlineRight
                style={{
                  margin: "auto 0 auto auto",
                  color: "white",
                  opacity: activePage === item.name ? 1 : 0.5,
                }}
              />
            </Box>
          ))}
        </Box>
        <Box sx={{ width: "77%", ml: "3%" }}>
          <Box sx={{ display: "flex", flexDirection: "row", mb: "2%" }}>
            <Box
              sx={{
                backgroundColor: "#F8E7E8",
                display: "flex",
                flexDirection: "row",
                p: "12px",
                width: "80%",
                borderRadius: "18px",
                mr: "auto",
                height: "fit-content",
              }}
            >
              <InformationCircle
                color="#EE1616"
                fontSize={20}
                style={{
                  marginTop: "auto",
                  marginBottom: "auto",
                  marginRight: "10px",
                }}
              />
              <Box>
                <Text
                  variant="body2"
                  style={{
                    color: "#000000",
                    font: "normal normal 600 14px/21px Poppins",
                    fontSize: "13.5px",
                    fontWeight: 600,
                  }}
                >
                  Instructions
                </Text>
                <Text
                  style={{
                    font: "normal normal normal 12px/20px Poppins",
                    color: "#6D6D6D",
                    marginTop: "-5px",
                    fontSize: "11.5px",
                  }}
                >
                  Lorem Ipsum is simply dummy text of the printing that can
                  simply dummy text of the printing that can simply dummy text
                  <span
                    onClick={() => setshowReadMore(!showReadMore)}
                    style={{
                      color: "#EE1616",
                      cursor: "pointer",
                      fontWeight: "bold",
                      marginLeft: "6px",
                      fontSize: "12.5px",
                    }}
                  >
                    Read More
                  </span>
                </Text>
              </Box>
            </Box>
            <Text
              style={{
                fontSize: "13px",
                color: "#2C80FF",
                marginTop: "auto",
                marginBottom: "auto",
                marginRight: "10px",
              }}
            >
              Back
            </Text>
          </Box>
          {getPageType()}
        </Box>
        <Prompt
          open={makePrompt.open}
          message={makePrompt.message}
          type={makePrompt.type}
          handleClose={() => {
            setmakePrompt({ ...makePrompt, open: false });
          }}
        />
      </Box>
    </Template>
  );
}

export default MyProfile;
