import React, { useState } from "react";
import logo from "../Assets/Images/logo.png";
import { Box } from "@mui/material";
import "./index.css";
import Icon from "../Helpers/Icon";
import { AiOutlineMore } from "react-icons/ai";
import PopMenu from "../Components/PopMenu";

const Header = ({
  transparent = false,
  backgroundImage = "",
  showLogin = false,
  showSearch = false,
  showNotification = false,
  showProfile = false,
  showMessage = false,
  showCash = false,
  showCompleteProfile = false,
}) => {
  const [showMenu, setshowMenu] = useState(false);

  return (
    <Box
      sx={{
        height: backgroundImage ? "408px" : "auto",
        background: transparent
          ? `transparent url(${backgroundImage}) 0% 0% no-repeat padding-box`
          : "none",
        marginTop: backgroundImage ? "-3.2em" : "0",
        display: "flex",
        flexDirection: "row",
        padding: backgroundImage ? "5.3em 5em 0 5em" : "1.5em 5em 1.5em 5em",
      }}
    >
      <img
        src={logo}
        alt="Logo"
        style={{
          width: "160px",
          height: "40px",
          marginRight: "auto",
          cursor: "pointer",
        }}
      />
      {showSearch && (
        <Icon
          name="OutlineSearch"
          style={{
            width: "18px",
            height: "18px",
            marginRight: "1em",
            cursor: "pointer",
          }}
        />
      )}
      {showMessage && (
        <Icon
          name="Message"
          style={{
            width: "18px",
            height: "18px",
            marginRight: "1em",
            cursor: "pointer",
          }}
        />
      )}
      {showNotification && (
        <Icon
          name="Bell_Icon"
          style={{
            width: "20px",
            height: "20px",
            marginRight: "1em",
            cursor: "pointer",
          }}
        />
      )}

      {showCash ||
        showCompleteProfile ||
        (showProfile && (
          <div
            style={{
              width: "1.6px",
              height: "30px",
              backgroundColor: "#F2F2F2",
              marginRight: "1em",
              marginTop: "-4px",
            }}
          />
        ))}
      {showCompleteProfile && (
        <Box
          sx={{
            boxShadow: "0px 10px 20px #0000000B",
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            mt: "-8px",
            padding: "0.5em 1em",
            height: "fit-content",
            borderRadius: "16px",
            display: "flex",
            flexDirection: "row",
            cursor: "pointer",
          }}
        >
          <Icon
            name={"Diamond"}
            style={{
              width: "20px",
              height: "20px",
            }}
          />
          <span
            style={{
              font: " normal normal 600 15px/23px Poppins",
              color: "#000000",
              marginLeft: "0.5em",
            }}
          >
            90%
          </span>
        </Box>
      )}

      {showCash && (
        <Box
          sx={{
            boxShadow: "0px 10px 20px #0000000B",
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            mt: "-8px",
            padding: "0.5em 1em",
            height: "fit-content",
            borderRadius: "16px",
            display: "flex",
            flexDirection: "row",
            ml: ".8em",
            mr: ".8em",
            cursor: "pointer",
          }}
        >
          <Icon
            name={"CoinDollar"}
            style={{
              width: "20px",
              height: "20px",
            }}
          />
          <span
            style={{
              font: " normal normal 600 15px/23px Poppins",
              color: "#000000",
              marginLeft: "0.5em",
            }}
          >
            $3,156
          </span>
        </Box>
      )}
      {showProfile && (
        <Box
          sx={{
            boxShadow: "0px 3px 6px #00000029",
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            borderRadius: "50%",
            padding: ".5em",
            height: "20px",
            mt: "-7px",
            width: "20px",
            display: "flex",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={() => setshowMenu(!showMenu)}
        >
          <span
            style={{
              font: " normal normal 600 15px/23px Poppins",
              color: "#2C80FF",
            }}
          >
            JD
          </span>
        </Box>
      )}
      {showProfile && (
        <AiOutlineMore
          size={20}
          style={{
            cursor: "pointer",
            marginTop: "1px",
            color: "#2C80FF",
          }}
          onClick={() => setshowMenu(!showMenu)}
        />
      )}
      {showMenu && <PopMenu handleClose={() => setshowMenu(!showMenu)} />}
    </Box>
  );
};

export default Header;
