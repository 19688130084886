export const checkKeys = (file, keys) => {
  if (Array.isArray(file)) {
    if (file.length > 0) {
      return file.every((obj) => checkKeys(obj, keys));
    } else {
      return false;
    }
  } else {
    const fileKeys = Object.keys(file);
    return keys.every((key) => fileKeys.includes(key));
  }
};

export const missingKeys = (file, keys) => {
  const fileKeys = Object.keys(file);
  return keys.filter((key) => !fileKeys.includes(key));
};
