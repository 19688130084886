import React, { useState } from "react";
import { Box } from "@mui/material";
import { CalendarAdd, RemoveThin } from "react-huge-icons/outline";
import Button from "./Core/Button";
import Input from "./Core/Input";
import Arc from "../Assets/Images/Arc.jpg";
import Text from "./Core/Text";
import { useDispatch } from "react-redux";
import { getScheduledOrder, scheduleOrder } from "../Actions/order";
import Prompt from "./Core/Prompt";

const ScheduleNow = ({ handleClose, item }) => {
  const [scheduledDate, setscheduledDate] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [makePrompt, setmakePrompt] = useState({
    open: false,
    message: "",
    type: "",
  });
  const dispatch = useDispatch();

  const handleMakePrompt = (message, type) => {
    setmakePrompt({ open: true, message, type });
    setTimeout(() => {
      setmakePrompt({ open: false, message: "", type: "" });
    }, 2000);
  };

  const makeSchedule = () => {
    setisLoading(true);
    const _id = item?.order === undefined ? item._id : item?.order[0]?._id;

    dispatch(scheduleOrder(_id, { scheduledDate }))
      .then((result) => {
        handleMakePrompt("Scheduled Successfully", "success");
        dispatch(getScheduledOrder());
        setTimeout(() => {
          handleClose();
        }, 2000);
      })
      .catch((err) => {
        setisLoading(false);
        handleMakePrompt(err.response.data.error, "error");
      });
  };

  return (
    <Box className="mainModal">
      <Box
        sx={{
          boxShadow: "0px 10px 20px #0000000D",
          borderRadius: "12px",
          background: "#FFFFFF 0% 0% no-repeat padding-box",
          height: "fit-content",
          width: "920px",
          height: "560px",
          zIndex: 1,
          m: "auto",
          padding: "1em",
          position: "relative",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <img
          src={Arc}
          style={{
            width: "48%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "12px",
            marginRight: "1em",
          }}
        />
        <Box
          sx={{
            width: "50%",
            height: "100%",
            p: "1.5em 0",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Text
              style={{
                color: "#000000",
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "Poppins",
              }}
            >
              {item.address} {item.pincode},
            </Text>
            <Text
              style={{
                color: "#000000",
                fontSize: "13px",
                fontFamily: "Poppins",
                marginLeft: ".4em",
                marginTop: "4px",
                marginRight: "auto",
                opacity: "0.6",
              }}
            >
              {item.locality} {item.state}
            </Text>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Text
              style={{
                color: "#000000",
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "Poppins",
              }}
            >
              MLSI Number:
            </Text>
            <Text
              style={{
                color: "#000000",
                fontSize: "13px",
                fontFamily: "Poppins",
                marginLeft: ".4em",
                marginTop: "4px",
                marginRight: "auto",
                opacity: "0.6",
              }}
            >
              14586
            </Text>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            <Text
              style={{
                color: "#000000",
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "Poppins",
              }}
            >
              Public Remarks:
            </Text>
            <Text
              style={{
                color: "#000000",
                fontSize: "12.5px",
                fontFamily: "Poppins",
                opacity: "0.6",
              }}
            >
              Lorem Ipsum is simply dummy text of the printing and type setting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled Ipsum is simply dummy text of the printing and
              type setting industry.
            </Text>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              mt: "4px",
            }}
          >
            <Text
              style={{
                color: "#000000",
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "Poppins",
              }}
            >
              Property Type:
            </Text>
            <Text
              style={{
                color: "#000000",
                fontSize: "13px",
                fontFamily: "Poppins",
                marginLeft: "4px",
                marginTop: "auto",
                marginRight: "1em",
              }}
            >
              {item.property?.propertyType}
            </Text>

            <Text
              style={{
                color: "#000000",
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "Poppins",
              }}
            >
              GLA:
            </Text>
            <Text
              style={{
                color: "#000000",
                fontSize: "13px",
                fontFamily: "Poppins",
                marginLeft: "4px",
                marginTop: "auto",
                marginRight: "1em",
              }}
            >
              125
            </Text>

            <Text
              style={{
                color: "#000000",
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "Poppins",
              }}
            >
              Year Built:
            </Text>
            <Text
              style={{
                color: "#000000",
                fontSize: "13px",
                fontFamily: "Poppins",
                marginLeft: "4px",
                marginTop: "auto",
                marginRight: "1em",
              }}
            >
              2008
            </Text>
            <Text
              style={{
                color: "#000000",
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "Poppins",
              }}
            >
              Lot:{" "}
            </Text>
            <Text
              style={{
                color: "#000000",
                fontSize: "13px",
                fontFamily: "Poppins",
                marginLeft: "4px",
                marginTop: "auto",
                marginRight: "1em",
              }}
            >
              1356 Sq. Ft.
            </Text>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              mt: "4px",
              mb: "4px",
            }}
          >
            <Text
              style={{
                color: "#000000",
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "Poppins",
              }}
            >
              Beds:
            </Text>
            <Text
              style={{
                color: "#000000",
                fontSize: "13px",
                fontFamily: "Poppins",
                marginLeft: "4px",
                marginTop: "auto",
                marginRight: "1em",
              }}
            >
              2
            </Text>

            <Text
              style={{
                color: "#000000",
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "Poppins",
              }}
            >
              Baths:
            </Text>
            <Text
              style={{
                color: "#000000",
                fontSize: "13px",
                fontFamily: "Poppins",
                marginLeft: "4px",
                marginTop: "auto",
                marginRight: "1em",
              }}
            >
              4
            </Text>

            <Text
              style={{
                color: "#000000",
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "Poppins",
              }}
            >
              Lot SqFt:
            </Text>
            <Text
              style={{
                color: "#000000",
                fontSize: "13px",
                fontFamily: "Poppins",
                marginLeft: "4px",
                marginTop: "auto",
                marginRight: "1em",
              }}
            >
              400 sq ft
            </Text>
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: 20,
              right: 20,
              borderRadius: "50%",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              boxShadow: `rgba(149, 157, 165, 0.2) 0px 8px 24px`,
              backgroundColor: "#fff",
              width: "36px",
              height: "36px",
              alignItems: "center",
            }}
            onClick={handleClose}
          >
            <RemoveThin fontSize={28} />
          </Box>
          <Box
            sx={{
              backgroundColor: "#FEF2D4",
              position: "absolute",
              top: 60,
              right: 20,
              padding: "10px 16px",
              borderRadius: "30px",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span
              style={{
                color: "#EBA900",
                font: "normal normal medium 14px/21px Poppins",
                fontSize: "14px",
                fontWeight: "600",
              }}
            >
              Status: {item.status}
            </span>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Input
              placeholder="Enter here"
              label="Proposed Due Date"
              onChange={(e) => setscheduledDate(e.target.value)}
              value={scheduledDate}
              type="date"
              style={{
                width: "97%",
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: ".7em",
              }}
            />
          </Box>

          <Button
            isLoading={isLoading}
            disabled={!scheduledDate || isLoading}
            onClick={makeSchedule}
            style={{
              width: "50%",
              height: "42px",
              borderRadius: "11px",
              color: "#fff",
              fontSize: "14px",
              margin: "0 auto",
              fontFamily: "Poppins",
              fontWeight: 600,
              display: "flex",
              flexDirection: "row",
              position: "relative",
              marginTop: "2.7em",
            }}
          >
            <CalendarAdd
              fontSize={20}
              style={{
                position: "absolute",
                left: "15%",
                top: "25%",
              }}
            />
            <span style={{ marginLeft: "1.5em" }}>Schedule Now</span>
          </Button>
        </Box>
      </Box>
      {makePrompt.open && (
        <Prompt
          open={makePrompt.open}
          message={makePrompt.message}
          type={makePrompt.type}
          handleClose={() =>
            setmakePrompt({ open: false, message: "", type: "" })
          }
        />
      )}
    </Box>
  );
};

export default ScheduleNow;
