import { Box } from "@mui/material";
import React, { useState } from "react";
import Text from "./Core/Text";
import { RemoveThin } from "react-huge-icons/outline";
import Input from "./Core/Input";
import Button from "./Core/Button";

const Decline = ({handleClose,onDecline,onCreateCounter}) => {
  const [reason, setreason] = useState('')
  return (
    <Box className="mainModal">
      <Box
        sx={{
          boxShadow: "0px 10px 20px #0000000D",
          borderRadius: "12px",
          background: "#FFFFFF 0% 0% no-repeat padding-box",
          height: "fit-content",
          width: "560px",
          height: "400px",
          zIndex: 1,
          m: "auto",
          padding: "1em",
          position: "relative",
        }}
      >
        <Text
          style={{
            font: "normal normal bold 17px/26px Poppins",
            color: "#000000",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Decline Offer
        </Text>
        <Text
          style={{
            font: "normal normal normal 14px/22px Poppins",
            color: "#000000",
            textAlign: "center",
            marginBottom: "1em",
            width: "85%",
            marginLeft: "auto",
            marginRight: "auto",
            opacity: "0.6",
          }}
        >
          Are you sure you want to remove this property from the listing? You
          can’t undo this action.
        </Text>
        <Box
          sx={{
            position: "absolute",
            top: 20,
            right: 20,
            borderRadius: "50%",
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            boxShadow: `rgba(149, 157, 165, 0.2) 0px 8px 24px`,
            backgroundColor: "#fff",
          }}
        >
          <RemoveThin
            fontSize={28}
              onClick={handleClose}
          />
        </Box>
        <Input
          label={"Add Reason"}
          value={reason}
          onChange={(e) => setreason(e.target.value)}
          style={{
            height: "200px",
            width: "95%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          placeholder={"Enter reason for declining"}
          isMultiline
        />
        <Box sx={{ display: "flex", flexDirection: "row", mt: "3em" }}>
          <Button
            style={{
              width: "45%",
              paddingTop: ".2em",
              paddingBottom: ".2em",
              marginRight: "auto",
              marginLeft: "auto",
              background: "#2C80FF 0% 0% no-repeat padding-box",
            }}
            onClick={onCreateCounter}
          >
            Counter
          </Button>
          <Button
            disabled={!reason}
            style={{
              width: "45%",
              paddingTop: ".2em",
              paddingBottom: ".2em",
              marginRight: "auto",
              marginLeft: "auto",
              backgroundColor: "#EE1616",
            }}
            onClick={()=>{
              onDecline(reason)
            }}
          >
            Yes, Decline
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Decline;
