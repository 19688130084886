import { Box } from "@mui/material";
import React from "react";
import Text from "../../Components/Core/Text";
import {
  FileReload,
  FileWrittenContinued,
  ShoppingBasketHorizontal,
} from "react-huge-icons/outline";

const CommentAddenda = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        p: ".5em",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        <FileWrittenContinued
          fontSize={20}
          style={{ margin: "auto .5em auto 0", color: "#2593D6" }}
        />
        <Text
          style={{
            font: "normal normal 600 22px/33px Poppins",
            color: "#2593D6",
            fontSize: "17px",
            fontWeight: "600",
          }}
        >
          Property Details
        </Text>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        {Array(3)
          .fill({
            title: "Lipsum 1",
            desc: "Lorem ipsum dolor sit amet consectetur. Lorem maecenas id sed enim aliquam sit non curabitur mauris. Nisi that can vestibulum eget vivamus pretium integer turpis justo. Sed praesent tellus lectus ipsum dolor sit amet consectetur. Lorem maecenas id sed enim aliquam sit non curabitur maecenas id sed enim. Dsafdsf 2",
          })
          .map((item, index) => (
            <Box
              key={index}
              sx={{
                width: "320px",
                height: "170px",
                boxShadow: "0px 3px 15px #0000001A",
                borderRadius: "20px",
                p: ".8em",
                m: ".5em",
                position: "relative",
                display: "flex",
                flexDirection: "column",
                // overflow: "hidden",
                borderTopRightRadius: "40px",
              }}
            >
              <Text
                style={{
                  fontSize: "16.5px",
                  fontFamily: "Poppins",
                  color: "#2593D6",
                }}
              >
                {item.title}
              </Text>
              <Text
                style={{
                  fontSize: "11.5px",
                  fontFamily: "Poppins",
                  color: "#000000",
                  marginBottom: "auto",
                  opacity: 0.6,
                }}
              >
                {item.desc}
              </Text>
              <Box
                style={{
                  height: "1.6px",
                  marginTop: "auto",
                  width: "100%",
                  backgroundColor: "#2593D6",
                }}
              />
              {/* <div style={{
        position: 'absolute',
        top: 0,
        right: 0,
        width: 0,
        height: 0,
        borderLeft: '40px solid transparent',
        borderBottom: '40px solid #BABABA',
        transform: 'rotate(90deg)',
      }} /> */}
            </Box>
          ))}
      </Box>
      <Box
        style={{
          height: "1.6px",
          margin: "1em 0",
          width: "100%",
          opacity: 0.1,
          backgroundColor: "#28303F",
        }}
      />

      <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        <ShoppingBasketHorizontal
          fontSize={20}
          style={{ margin: "auto .5em auto 0", color: "#2593D6" }}
        />
        <Text
          style={{
            font: "normal normal 600 22px/33px Poppins",
            color: "#2593D6",
            fontSize: "17px",
            fontWeight: "600",
          }}
        >
          Neighborhood / Market
        </Text>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        {Array(3)
          .fill({
            title: "Lipsum 1",
            desc: "Lorem ipsum dolor sit amet consectetur. Lorem maecenas id sed enim aliquam sit non curabitur mauris. Nisi that can vestibulum eget vivamus pretium integer turpis justo. Sed praesent tellus lectus ipsum dolor sit amet consectetur. Lorem maecenas id sed enim aliquam sit non curabitur maecenas id sed enim. Dsafdsf 2",
          })
          .map((item, index) => (
            <Box
              key={index}
              sx={{
                width: "320px",
                height: "170px",
                boxShadow: "0px 3px 15px #0000001A",
                borderRadius: "20px",
                p: ".8em",
                m: ".5em",
                position: "relative",
                display: "flex",
                flexDirection: "column",
                // overflow: "hidden",
                borderTopRightRadius: "40px",
              }}
            >
              <Text
                style={{
                  fontSize: "16.5px",
                  fontFamily: "Poppins",
                  color: "#2593D6",
                }}
              >
                {item.title}
              </Text>
              <Text
                style={{
                  fontSize: "11.5px",
                  fontFamily: "Poppins",
                  color: "#000000",
                  marginBottom: "auto",
                  opacity: 0.6,
                }}
              >
                {item.desc}
              </Text>
              <Box
                style={{
                  height: "1.6px",
                  marginTop: "auto",
                  width: "100%",
                  backgroundColor: "#2593D6",
                }}
              />
              {/* <div style={{
        position: 'absolute',
        top: 0,
        right: 0,
        width: 0,
        height: 0,
        borderLeft: '40px solid transparent',
        borderBottom: '40px solid #BABABA',
        transform: 'rotate(90deg)',
      }} /> */}
            </Box>
          ))}
      </Box>
      <Box
        style={{
          height: "1.6px",
          margin: "1em 0",
          width: "100%",
          opacity: 0.1,
          backgroundColor: "#28303F",
        }}
      />

      <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        <FileReload
          fontSize={20}
          style={{ margin: "auto .5em auto 0", color: "#2593D6" }}
        />
        <Text
          style={{
            font: "normal normal 600 22px/33px Poppins",
            color: "#2593D6",
            fontSize: "17px",
            fontWeight: "600",
          }}
        >
          History
        </Text>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        {Array(3)
          .fill({
            title: "Lipsum 1",
            desc: "Lorem ipsum dolor sit amet consectetur. Lorem maecenas id sed enim aliquam sit non curabitur mauris. Nisi that can vestibulum eget vivamus pretium integer turpis justo. Sed praesent tellus lectus ipsum dolor sit amet consectetur. Lorem maecenas id sed enim aliquam sit non curabitur maecenas id sed enim. Dsafdsf 2",
          })
          .map((item, index) => (
            <Box
              key={index}
              sx={{
                width: "320px",
                height: "170px",
                boxShadow: "0px 3px 15px #0000001A",
                borderRadius: "20px",
                p: ".8em",
                m: ".5em",
                position: "relative",
                display: "flex",
                flexDirection: "column",
                // overflow: "hidden",
                borderTopRightRadius: "40px",
              }}
            >
              <Text
                style={{
                  fontSize: "16.5px",
                  fontFamily: "Poppins",
                  color: "#2593D6",
                }}
              >
                {item.title}
              </Text>
              <Text
                style={{
                  fontSize: "11.5px",
                  fontFamily: "Poppins",
                  color: "#000000",
                  marginBottom: "auto",
                  opacity: 0.6,
                }}
              >
                {item.desc}
              </Text>
              <Box
                style={{
                  height: "1.6px",
                  marginTop: "auto",
                  width: "100%",
                  backgroundColor: "#2593D6",
                }}
              />
              {/* <div style={{
        position: 'absolute',
        top: 0,
        right: 0,
        width: 0,
        height: 0,
        borderLeft: '40px solid transparent',
        borderBottom: '40px solid #BABABA',
        transform: 'rotate(90deg)',
      }} /> */}
            </Box>
          ))}
      </Box>
      <Box
        style={{
          height: "1.6px",
          margin: "1em 0",
          width: "100%",
          opacity: 0.1,
          backgroundColor: "#28303F",
        }}
      />
    </Box>
  );
};

export default CommentAddenda;
