import React, { useEffect, useRef, useState } from "react";
import Template from "../../Template";
import Ellipse8 from "../../Assets/Images/Ellipse8.svg";
import { Box } from "@mui/material";
import Text from "../../Components/Core/Text";
import Input from "../../Components/Core/Input";
import {
  ChatPlus,
  MenuCircleVertical,
  PlusThin,
  Search,
  Send,
} from "react-huge-icons/outline";
import Button from "../../Components/Core/Button";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  getMessageByTicket,
  getTickets,
  sendMessageByTicket,
} from "../../Actions/message";
import CreateTicket from "../../Components/CreateTicket";
import Prompt from "../../Components/Core/Prompt";

function Support() {
  const [selected, setselected] = useState(1);
  const [showMenu, setshowMenu] = useState(false);
  const [showMakeTicket, setshowMakeTicket] = useState(false);
  const [makePrompt, setmakePrompt] = useState({
    open: false,
    message: "",
    type: "error",
  });
  const [file, setfile] = useState(null);
  const [msg, setmsg] = useState("");
  const message = useSelector((state) => state.message);
  const auth = useSelector((state) => state.auth);
  const boxRef = useRef(null);
  const fileRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTickets());
  }, []);

  useEffect(() => {
    const scrollToBottom = () => {
      if (boxRef.current) {
        boxRef.current.scrollTop = boxRef.current.scrollHeight;
      }
    };

    scrollToBottom();
    setselected(message?.tickets[0]?._id);
    if (message?.tickets[0]?._id) {
      dispatch(getMessageByTicket(message?.tickets[0]?._id));
    }
  }, [message.tickets.length]);

  useEffect(() => {
    if (selected) {
      const intervalId = setInterval(() => {
        dispatch(getMessageByTicket(selected));
      }, 3000);

      return () => clearInterval(intervalId);
    }
  }, [selected]);

  const currentTicket = message.tickets.find((item) => item._id === selected);

  const handleChangeSelected = (_id) => {
    setselected(_id);
    dispatch(getMessageByTicket(_id));
  };

  const handleSendMsg = () => {
    if (msg.length < 1) {
      setmakePrompt({
        open: true,
        message: "Please enter a message",
        type: "error",
      });
      return;
    }

    const data = new FormData();
    data.append("message", msg);
    data.append("file", file);
    dispatch(sendMessageByTicket(selected, data))
      .then((result) => {
        setmsg("");
        setfile(null);
        dispatch(getMessageByTicket(selected));
      })
      .catch((err) => {
        setmakePrompt({
          open: true,
          message: err.response.data.error || "Some error occured",
          type: "error",
        });
      });
  };

  return (
    <Template
      showHeader
      headerProps={{
        showNotification: true,
        showProfile: true,
        showSearch: true,
        showMessage: true,
        showCash: true,
        showCompleteProfile: true,
      }}
      containerStyle={{
        backgroundImage: `url(${Ellipse8})`,
        height: "100vh",
      }}
    >
      <Box
        sx={{
          border: "1px solid #FFFFFF",
          background:
            "transparent linear-gradient(119deg, #FFFFFF66 0%, #FFFFFF24 100%) 0% 0% no-repeat padding-box",
          boxShadow: "15px 15px 50px #00000029",
          borderRadius: "24px",
          backgroundColor: "#F2F2F2",
          opacity: "1",
          padding: "1.5em",
          backdropFilter: "blur(50px)",
          WebkitBackdropFilter: "blur(50px)",
          width: "90%",
          margin: "auto",
        }}
      >
        <Box style={{ display: "flex", flexDirection: "row" }}>
          <Box sx={{ mr: "auto" }}>
            <Text variant="h2">Support Chat</Text>
            <Text
              variant="body2"
              style={{
                fontSize: "12px",
                color: "gray",
              }}
            >
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </Text>
          </Box>
          <Text
            style={{
              fontSize: "12px",
              color: "#2C80FF",
            }}
          >
            Back
          </Text>
        </Box>
        <Box
          sx={{
            border: "1px solid #FFFFFF",
            background:
              "transparent linear-gradient(119deg, #FFFFFF66 0%, #FFFFFF24 100%) 0% 0% no-repeat padding-box",
            boxShadow: "15px 15px 50px #00000029",
            borderRadius: "24px",
            backgroundColor: "#F2F2F2",
            display: "flex",
            flexDirection: "row",
            opacity: 1,
            padding: "1.5em",
            backdropFilter: "blur(50px)",
            WebkitBackdropFilter: "blur(50px)",
            width: "90%",
            margin: "auto",
            backgroundImage: `url(${Ellipse8})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            height: "100vh",
            mt: "1em",
            backgroundPosition: "left -500px top -600px",
          }}
        >
          <Box
            sx={{
              mr: "auto",
              width: "30%",
              borderRight: "1.6px solid #ccc",
              paddingRight: "1.6em",
              mr: "1em",
            }}
          >
            <Input
              placeholder="Search"
              style={{
                width: "100%",
                background: "#fff",
                borderRadius: 12,
                height: 56,
              }}
              icon={<Search style={{ color: "#A8A8A8", marginTop: -12 }} />}
            />
            <Button
              style={{
                display: "flex",
                marginTop: "1em",
                flexDirection: "row",
              }}
              onClick={() => setshowMakeTicket(!showMakeTicket)}
            >
              <ChatPlus fontSize={15} style={{ marginRight: ".3em" }} />
              <span>Creat New Ticket</span>
            </Button>
            <Box
              sx={{
                height: "80vh",
                overflowY: "scroll",
                "&::-webkit-scrollbar": {
                  width: "4px",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#F0F0F0",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#2C80FF",
                  borderRadius: "4px",
                },
                scrollbarColor: "#2C80FF #F0F0F0",
                scrollbarWidth: "thin",
              }}
            >
              {message?.tickets?.length > 0 &&
                message?.tickets.map((item) => (
                  <Box
                    key={item._id}
                    style={{
                      background:
                        selected === item._id ? "#fff" : "transparent",
                      borderRadius: selected === item._id ? 24 : 0,
                      padding: "1em",
                      width: "90%",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: "1em",
                      cursor: "pointer",
                      borderBottom:
                        selected === item._id ? "none" : "1px solid #ccc",
                    }}
                    onClick={() => handleChangeSelected(item._id)}
                  >
                    <Box style={{ display: "flex", flexDirection: "row" }}>
                      <Text
                        style={{
                          color: "#011502",
                          font: "normal normal 600 15px/28px Poppins",
                          fontSize: "15px",
                          fontWeight: "600",
                        }}
                      >
                        {item.title.length > 20
                          ? item.title.slice(0, 20) + "..."
                          : item.title}
                      </Text>
                      <Text
                        style={{
                          color: "#8D8D8D",
                          marginLeft: "auto",
                          font: "normal normal normal 11px Poppins",
                          fontSize: "11px",
                        }}
                      >
                        {moment(item.createdAt).fromNow()}
                      </Text>
                    </Box>
                    <Text
                      style={{
                        color: "#818181",
                        font: "normal normal normal 14px Poppins",
                        fontSize: "12.5px",
                        marginTop: "-.2em",
                      }}
                    >
                      {item.description.length > 80
                        ? item.description.slice(0, 80) + "..."
                        : item.description}
                    </Text>
                  </Box>
                ))}
            </Box>
          </Box>
          <Box sx={{ width: "66%", paddingX: "1em" }}>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                position: "relative",
              }}
            >
              <Text
                style={{
                  color: "#011502",
                  font: "normal normal 600 15px/28px Poppins",
                  fontSize: "18px",
                  fontWeight: "600",
                }}
              >
                {message?.tickets?.length > 0
                  ? currentTicket?.title?.length > 40
                    ? `${currentTicket?.title?.slice(0, 40)}...`
                    : currentTicket?.title
                  : "Loading..."}
              </Text>
              <MenuCircleVertical
                fontSize={32}
                onClick={() => setshowMenu(!showMenu)}
                color="#d6d848"
                style={{ marginLeft: "auto", cursor: "pointer" }}
              />
              {showMenu && (
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    boxShadow: "0px 10px 20px #0000000D",
                    borderRadius: "12px !important",
                    padding: "1em",
                    zIndex: 1,
                    backgroundColor: "#fff",
                    mt: "2em",
                  }}
                >
                  {["Ticket Info", "Report", "Mark as Resolved"].map(
                    (item, index) => (
                      <Box
                        key={index}
                        sx={{
                          cursor: "pointer",
                          mb: index === 2 ? 0 : "1em",
                        }}
                      >
                        <Text
                          style={{
                            color: item === "Report" ? "#FF0000" : "#4C5056",
                            font: "normal normal normal 14px/21px Poppins",
                            fontSize: "13.5px",
                          }}
                        >
                          {item}
                        </Text>
                      </Box>
                    )
                  )}
                </Box>
              )}
            </Box>
            <Box
              sx={{
                height: "80vh",
                overflowY: "scroll",
                pr: "1em",
                "&::-webkit-scrollbar": {
                  width: "4px",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#F0F0F0",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#2C80FF",
                  borderRadius: "4px",
                },
                scrollbarColor: "#2C80FF #F0F0F0",
                scrollbarWidth: "thin",
              }}
              ref={boxRef}
            >
              {message.ticketMessages.length > 0 &&
                message.ticketMessages
                  ?.slice()
                  .reverse()
                  .map((item) => (
                    <Box
                      key={item._id}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1em",
                      }}
                    >
                      <Box
                        style={{
                          background:
                            item.fromUser?._id === auth.user?._id
                              ? "#2593D6"
                              : "#fff",
                          borderRadius: 24,
                          padding: "1em",
                          width: "auto",
                          marginLeft:
                            item.fromUser?._id === auth.user?._id ? "auto" : 0,
                          marginRight:
                            item.fromUser?._id === auth.user?._id ? 0 : "auto",
                          marginTop: "1em",
                          borderRadius:
                            item.fromUser?._id === auth.user?._id
                              ? "24px 24px 0px 24px"
                              : "24px 24px 24px 0px",
                        }}
                      >
                        <Text
                          style={{
                            color:
                              item.fromUser?._id === auth.user?._id
                                ? "#fff"
                                : "#818181",
                            font: "normal normal normal 14px Poppins",
                            fontSize: "13px",
                          }}
                        >
                          {item.message}
                        </Text>
                      </Box>
                      <Text
                        style={{
                          color: "#818181",
                          font: "normal normal normal 14px Poppins",
                          fontSize: "10.5px",
                          marginLeft:
                            item.fromUser?._id === auth.user?._id ? "auto" : 0,
                        }}
                      >
                        {moment(item.timestamp).fromNow()}
                      </Text>
                    </Box>
                  ))}
            </Box>
            {file && (
              <Box
                style={{
                  fontSize: "13px",
                  marginLeft: "1em",
                }}
              >
                {file?.name}
                <span
                  style={{
                    color: "red",
                    cursor: "pointer",
                    margin: "auto 0 auto 6px",
                  }}
                  onClick={() => setfile(null)}
                >
                  X
                </span>
              </Box>
            )}
            <Box
              sx={{
                background: "#2593D6",
                display: "flex",
                flexDirection: "row",
                padding: ".7em",
                mt: file ? ".5em" : "1em",
                height: 44,
                borderRadius: 24,
              }}
            >
              <input
                type="file"
                style={{ display: "none" }}
                ref={fileRef}
                onChange={(e) => setfile(e.target.files[0])}
              />
              <Box
                sx={{
                  background: "#fff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                  width: 46,
                  height: 42,
                  cursor: "pointer",
                  mt: "auto",
                  mb: "auto",
                }}
                onClick={() => fileRef.current.click()}
              >
                <PlusThin fontSize={32} />
              </Box>
              <Box
                sx={{
                  ml: ".7em",
                  width: "100%",
                  background: "#fff",
                  borderRadius: "24px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Input
                  placeholder="Type a message"
                  value={msg}
                  onChange={(e) => setmsg(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleSendMsg();
                    }
                  }}
                  style={{
                    width: "95%",
                    background: "#fff",
                    borderRadius: 18,
                    paddingLeft: ".5em",
                  }}
                />
                <Box
                  sx={{
                    background: "#00476a",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                    width: 32,
                    height: 32,
                    cursor: "pointer",
                    mt: ".4em",
                    mr: ".5em",
                  }}
                  onClick={handleSendMsg}
                >
                  <Send
                    fontSize={18}
                    color="#fff"
                    style={{ transform: "rotate(375deg)", marginTop: ".1em" }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <CreateTicket
        open={showMakeTicket}
        handleClose={() => setshowMakeTicket(false)}
      />
      <Prompt
        open={makePrompt.open}
        handleClose={() => setmakePrompt({ ...makePrompt, open: false })}
        message={makePrompt.message}
        type={makePrompt.type}
      />
    </Template>
  );
}

export default Support;
