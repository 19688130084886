import { Box } from "@mui/material";
import React, { useState } from "react";
import Text from "./Core/Text";
import { PlusThin, MinusThin } from "react-huge-icons/outline";

function FaqCard({ question, isLast, isOpened }) {
  const [isOpen, setisOpen] = useState(isOpened);
  
  const commonIconStyle = {
    marginLeft: "auto",
    cursor: "pointer",
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        marginTop: "1em",
        borderBottom: isLast ? "none" : "1px solid #E5E5E5",
        paddingBottom: "1em",
      }}
    >
      <Box
        sx={{
          width: "6px",
          height: "6px",
          borderRadius: "100%",
          backgroundColor: "#000000",
          marginTop: ".8em",
          marginRight: ".8em",
        }}
      />
      <Box>
        <Text
          variant="h2"
          style={{
            fontSize: "15px",
            fontWeight: "bold",
          }}
        >
          {question.question}
        </Text>
        {isOpen && (
          <Text
            variant="h2"
            style={{
              marginTop: "1em",
              font: "normal normal medium 14px/21px Poppins",
              fontSize: "12px",
              color: "#71706F",
              textAlign: "left",
            }}
          >
            {question.answer}
          </Text>
        )}
      </Box>
      {!isOpen ? (
        <PlusThin
          fontSize={20}
          onClick={() => setisOpen(!isOpen)}
          style={commonIconStyle}
        />
      ) : (
        <MinusThin
          fontSize={20}
          onClick={() => setisOpen(!isOpen)}
          style={commonIconStyle}
        />
      )}
    </Box>
  );
}

export default FaqCard;
