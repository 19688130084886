import { Box } from "@mui/material";
import React from "react";
import Text from "./Core/Text";
import { RemoveThin } from "react-huge-icons/outline";
import { Information } from "react-huge-icons/solid";

const MoreInfo = ({ handleClose, title, description }) => {
  return (
    <Box className="mainModal">
      <Box
        sx={{
          boxShadow: "0px 10px 20px #0000000D",
          borderRadius: "12px",
          background: "#FFFFFF 0% 0% no-repeat padding-box",
          height: "fit-content",
          width: "560px",
          height: "auto",
          zIndex: 1,
          m: "auto",
          padding: "1em",
          position: "relative",
        }}
      >
        <Text
          style={{
            font: "normal normal bold 17px/26px Poppins",
            color: "#000000",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          More Information
        </Text>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Information
            color="#EE1616"
            fontSize={20}
            style={{
              marginTop: "auto",
              marginBottom: "auto",
              marginRight: "10px",
            }}
          />
          <Text
            variant="body2"
            style={{
              color: "#000000",
              font: "normal normal 600 14px/21px Poppins",
              fontSize: "14px",
              fontWeight: "normal !important",
            }}
          >
            Revise Report{" "}
          </Text>
        </Box>
        <Text
          style={{
            font: "normal normal normal 14px/22px Poppins",
            color: "#000000",
            textAlign: "left",
            marginBottom: "1em",
            opacity: "0.6",
          }}
        >
          <span style={{ fontSize: "12px" }}>{description}</span>
        </Text>
        <Box
          sx={{
            position: "absolute",
            top: 20,
            right: 20,
            borderRadius: "50%",
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            boxShadow: `rgba(149, 157, 165, 0.2) 0px 8px 24px`,
            backgroundColor: "#fff",
          }}
        >
          <RemoveThin fontSize={28} onClick={handleClose} />
        </Box>
      </Box>
    </Box>
  );
};

export default MoreInfo;
