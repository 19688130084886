import { Box } from "@mui/material";
import React from "react";

const Footer = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <p
        style={{
          font: "normal normal normal 12px/18px Poppins",
          color: "#656A72",
          mt: "1em",
          width: "60%",
          textAlign: "center",
          marginRight: "auto",
        }}
      >
        Nextday Desktop Valuations. all rights reserved
      </p>

      <a
        style={{
          font: "normal normal normal 12px/18px Poppins",
          color: "#656A72",
          mt: "1em",
          width: "20%",
          textAlign: "right",
          marginRight: "2em",
        }}
      >
        Terms of Service.
      </a>
      <a
        style={{
          font: "normal normal normal 12px/18px Poppins",
          color: "#656A72",
          mt: "1em",
          width: "15%",
          paddingLeft: "2em",
        }}
      >
        Privacy Policy
      </a>
    </Box>
  );
};

export default Footer;
