import { Box } from "@mui/material";
import React from "react";
import Text from "../../Components/Core/Text";
import Select from "../../Components/Core/Select";
import Input from "../../Components/Core/Input";
import Button from "../../Components/Core/Button";

const OpinionOfValue = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        p: ".5em",
        height: "70vh",
      }}
    >
      <Text
        style={{
          font: "normal normal 600 22px/33px Poppins",
          color: "#BFBFBF",
          fontSize: "18px",
          fontWeight: "600",
        }}
      >
        Reconciliation Detail
      </Text>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          mt: "1em",
        }}
      >
        <Select
          label={"Source Used For Subject Inspection"}
          value={"option1"}
          style={{ width: "35%", mr: "2%" }}
          options={[
            { value: "option1", label: "Option 1" },
            { value: "option2", label: "Option 2" },
            { value: "option3", label: "Option 3" },
          ]}
        />
        <Select
          label={"Marketing Time"}
          value={"option1"}
          style={{ width: "35%", mr: "2%" }}
          options={[
            { value: "option1", label: "Option 1" },
            { value: "option2", label: "Option 2" },
            { value: "option3", label: "Option 3" },
          ]}
        />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        <Input
          label={"Effective Date"}
          placeholder={"Enter here"}
          style={{ marginRight: ".5em" }}
        />
        <Input
          label={"As is"}
          placeholder={"Enter % Finished"}
          style={{ marginRight: ".5em" }}
        />
        <Input
          label={"As Repaired Value"}
          placeholder={"Enter no."}
          style={{ marginRight: ".5em" }}
        />
        <Input
          label={"Market Rent"}
          placeholder={"Enter here"}
          style={{ marginRight: ".5em" }}
        />
      </Box>
      <Input
        label={"Rec. Comment"}
        placeholder={"Enter here"}
        isMultiline
        style={{ width: "70%", height: "10vh", marginBottom: "1em" }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          mt: "auto",
        }}
      >
        <Button
          style={{ marginRight: "1em", width: "15%", color: "#2C80FF" }}
          isTransparent={true}
        >
          View Report
        </Button>
        <Button style={{ width: "15%" }}>Sign & Submit</Button>
      </Box>
    </Box>
  );
};

export default OpinionOfValue;
