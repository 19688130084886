import { Box } from "@mui/material";
import React, { useState } from "react";
import Text from "./Core/Text";
import { RemoveThin } from "react-huge-icons/outline";
import Input from "./Core/Input";
import Button from "./Core/Button";

const CreateCounter = ({ handleClose, onSubmit }) => {
  const [additionalInformation, setadditionalInformation] = useState("");
  const [proposedDueDate, setproposedDueDate] = useState("");
  const [proposedFee, setproposedFee] = useState("");

  return (
    <Box className="mainModal" sx={{ zIndex: 10001 }}>
      <Box
        sx={{
          boxShadow: "0px 10px 20px #0000000D",
          borderRadius: "12px",
          background: "#FFFFFF 0% 0% no-repeat padding-box",
          height: "fit-content",
          width: "560px",
          height: "auto",
          zIndex: 1,
          m: "auto",
          padding: "1em",
          position: "relative",
        }}
      >
        <Text
          style={{
            font: "normal normal bold 17px/26px Poppins",
            color: "#000000",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Create Counter
        </Text>
        <Text
          style={{
            font: "normal normal normal 14px/22px Poppins",
            color: "#000000",
            textAlign: "center",
            marginBottom: "1em",
            width: "85%",
            marginLeft: "auto",
            marginRight: "auto",
            opacity: "0.6",
          }}
        >
          Lorem Ipsum is simply dummy text of the printing that can be help to
          all kind people on that help so on people can do
        </Text>
        <Box
          sx={{
            position: "absolute",
            top: 20,
            right: 20,
            borderRadius: "50%",
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            boxShadow: `rgba(149, 157, 165, 0.2) 0px 8px 24px`,
            backgroundColor: "#fff",
          }}
        >
          <RemoveThin fontSize={28} onClick={handleClose} />
        </Box>
        <Input
          placeholder="Enter here"
          label="Proposed Fee"
          value={proposedFee}
          onChange={(e) => setproposedFee(e.target.value)}
          type="text"
          style={{
            width: "95%",
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: ".7em",
          }}
        />
        <Input
          placeholder="Enter here"
          label="Proposed Due Date"
          value={proposedDueDate}
          onChange={(e) => setproposedDueDate(e.target.value)}
          type="date"
          style={{
            width: "95%",
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: ".7em",
          }}
        />
        <Input
          label={"Additional Note"}
          value={additionalInformation}
          onChange={(e) => setadditionalInformation(e.target.value)}
          style={{
            height: "200px",
            width: "95%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          placeholder={"Enter additional note"}
          isMultiline
        />
        <Button
          disabled={!additionalInformation || !proposedDueDate || !proposedFee}
          style={{
            width: "75%",
            paddingTop: ".2em",
            paddingBottom: ".2em",
            marginRight: "auto",
            marginLeft: "auto",
            backgroundColor: "#2C80FF",
            marginTop: "2.7em",
          }}
          onClick={() => {
            onSubmit({
              additionalInformation,
              proposedDueDate,
              proposedFee,
            });
          }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default CreateCounter;
