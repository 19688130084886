import React, { useRef, useState } from "react";
import { Box } from "@mui/material";
import Icon from "../Helpers/Icon";
import SignatureCanvas from "react-signature-canvas";

const FileUploader = ({
  label,
  isOptional = false,
  isMultiple = false,
  allowedFileTypes = [],
  maxFileSize = 5242880,
  handleChange,
  handleUpload,
  type,
  name,
  uploaderLabel,
  showSelectedFiles = false,
  style
}) => {
  const fileInputRef = useRef(null);
  const signatureCanvasRef = useRef({});
  const [signatureFile, setSignatureFile] = useState(null);

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);
    handleFiles(droppedFiles);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleFileInputChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    handleFiles(selectedFiles);
  };

  // const handleFiles = (selectedFiles) => {
  //     const filteredFiles = selectedFiles.filter((file) => {
  //         const isFileTypeAllowed =
  //             allowedFileTypes.length === 0 || allowedFileTypes.includes(file.type);
  //         const isFileSizeValid = file.size <= maxFileSize;
  //         return isFileTypeAllowed && isFileSizeValid;
  //     });

  //     if (isMultiple) {
  //         handleChange(filteredFiles)
  //     } else {
  //         handleChange([filteredFiles[0]]);
  //     }
  // };

  const handleFiles = (selectedFiles) => {
    const filteredFiles = selectedFiles.filter((file) => {
      const isFileTypeAllowed =
        allowedFileTypes.length === 0 || allowedFileTypes.includes(file.type);
      const isFileSizeValid = file.size <= maxFileSize;
      return isFileTypeAllowed && isFileSizeValid;
    });

    if (isMultiple) {
      handleChange(filteredFiles);
    } else {
      handleChange([filteredFiles[0]]);
    }
  };
  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const openFileDialog = () => {
    fileInputRef.current.click();
  };

  const fileInputStyle = {
    display: "none",
  };

  const getPageType = () => {
    switch (type) {
      case "file-uploader":
        return (
          <Box
            sx={[{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              color: "#E8E8E8",
              border: " 2px dashed #E8E8E8",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "11px",
              height: "200px",
            },
          { ...style}
          ]}
            onClick={() => {
              openFileDialog();
            }}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileInputChange}
              style={fileInputStyle}
              multiple={isMultiple}
            />
            <Icon name="FolderOutline" style={{}} />
            <span style={{ marginTop: 8 }}>
              {uploaderLabel || "Upload here"}
            </span>

          </Box>
        );
      case "signature":
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              color: "#E8E8E8",
              backgroundColor: "#fff",
              border: " 2px solid #E8E8E8",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "11px",
              height: "200px",
            }}
          >
            <SignatureCanvas
              ref={signatureCanvasRef}
              penColor="black"
              onEnd={() => {
                const image = signatureCanvasRef.current.toDataURL("image/png");
                const file = dataURLtoFile(image, "signature.png");
                handleFiles([file]);
                setSignatureFile(file);
              }}
              canvasProps={{
                width: 444,
                height: 200,
                className: "sigCanvas",
              }}
            />
          </Box>
        );
      default:
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "35%",
              color: "#2C80FF",
              border: "2px dashed #2C80FF",
              justifyContent: "center",
              padding: ".8em 1em",
              borderRadius: "11px",
              fontWeight: "600",
            }}
            onClick={() => {
              openFileDialog();
            }}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileInputChange}
              style={fileInputStyle}
              multiple={isMultiple}
            />
            {uploaderLabel || "Upload"}
          </Box>
        );
    }
  };

  return (
    <div style={{ width: "500px" }}>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        {label && (
          <label
            style={{
              color: "#656A72",
              fontSize: "13px",
              marginBottom: ".4em",
              fontFamily: "Poppins",
            }}
          >
            {label}
          </label>
        )}
        {isOptional && (
          <label
            style={{
              color: "#B8B8B8",
              fontSize: "12px",
              marginBottom: ".4em",
              fontFamily: "Poppins",
              marginLeft: ".5em",
            }}
          >
            (Optional)
          </label>
        )}
      </Box>
      {getPageType()}
      {/* {files.length > 0 && showSelectedFiles && (
        <div>
          <p>Selected File(s):</p>
          <ul>
            {files.map((file, index) => (
              <li key={index}>
                {file.type.startsWith("image/") && (
                  <img
                    src={URL.createObjectURL(file)}
                    alt={file.name}
                    style={{
                      maxWidth: "100px",
                      maxHeight: "100px",
                      marginRight: "10px",
                    }}
                  />
                )}
                {file.name}
              </li>
            ))}
          </ul>
          <button onClick={handleUpload}>Upload</button>
        </div>
      )} */}
    </div>
  );
};

export default FileUploader;
