import { Navigate, Route, Routes } from "react-router";
import "./App.css";
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import CompleteProfile from "./Pages/CompleteProfile";
import Feed from "./Pages/Feed";
import OrderDetail from "./Pages/OrderDetail/OrderDetail";
import MyProfile from "./Pages/MyProfile/MyProfile";
import { useDispatch, useSelector } from "react-redux";
import { isuserLoggedIn } from "./Actions/auth";
import { useEffect, useState } from "react";
import Support from "./Pages/Support";
import Help from "./Pages/Help/Help";
import Report from "./Pages/Report/Report";
import Billings from "./Pages/Billings";
import ForgetPassword from "./Pages/ForgetPassword";

function App() {
  const auth = useSelector((state) => state.auth);
  const [checkProfileCompletion, setcheckProfileCompletion] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!auth.authenticate) {
      dispatch(isuserLoggedIn());
    }
    setcheckProfileCompletion(
      localStorage.getItem("completion") ? true : false
    );
  }, [auth.authenticate && auth.user]);

  return checkProfileCompletion ? (
    <Routes>
      <Route path="/complete-profile" element={<CompleteProfile />} />
      <Route path="*" element={<Navigate to="complete-profile" />} />
    </Routes>
  ) : (
    <Routes>
      <Route
        path="/"
        element={auth.authenticate ? <Feed /> : <Navigate to="/login" />}
      />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/complete-profile" element={<CompleteProfile />} />
      <Route path="/feed" element={<Feed />} />
      <Route path="/detail/:type/:id" element={<OrderDetail />} />
      <Route path="/profile" element={<MyProfile />} />
      <Route path="/support" element={<Support />} />
      <Route path="/forget-password" element={<ForgetPassword />} />
      <Route path="/help" element={<Help />} />
      <Route path="/report" element={<Report />} />
      <Route path="/billings" element={<Billings />} />
      <Route
        path="*"
        element={
          <div style={{ position: "absolute", top: "50%", left: "50%" }}>
            <h1>Not Found</h1>
          </div>
        }
      />
    </Routes>
  );
}

export default App;
