import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { HomeLink, HomeSmile, RoofHomeUpload } from "react-huge-icons/outline";
import {
  HomeLink as HomeLinkFilled,
  HomeSmile as HomeSmileFilled,
  RoofHomeUpload as RoofHomeUploadFilled,
} from "react-huge-icons/outline";
import Text from "../../Components/Core/Text";
import FileUploader from "../../Components/FileUploader";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { getFile, updateFile } from "../../Actions/report";
import Button from "../../Components/Core/Button";
import Prompt from "../../Components/Core/Prompt";

const Photos = () => {
  const reports = useSelector((state) => state.report);
  const [activePage, setactivePage] = useState("Interior");
  const [labels, setlabels] = useState("");
  const [selectedFile, setselectedFile] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [makePrompt, setmakePrompt] = useState({
    open: false,
    message: "",
    type: "error",
  });
  const dispatch = useDispatch();
  const location = useLocation();
  const report_id = location.state.id;

  useEffect(() => {
    dispatch(getFile(report_id, activePage.toLowerCase()));
  }, [activePage]);

  const iconStyle = {
    margin: "auto .5em auto 0",
  };
  const activePages = [
    {
      title: "Interior",
      icon: <HomeSmile fontSize={20} style={iconStyle} />,
      filled: <HomeSmileFilled fontSize={20} style={iconStyle} />,
    },
    {
      title: "Exterior",
      icon: <RoofHomeUpload fontSize={20} style={iconStyle} />,
      filled: <RoofHomeUploadFilled fontSize={20} style={iconStyle} />,
    },
    {
      title: "Repairs",
      icon: <HomeLink fontSize={20} style={iconStyle} />,
      filled: <HomeLinkFilled fontSize={20} style={iconStyle} />,
    },
  ];

  const uploadImage = () => {
    if (labels && selectedFile) {
      const data = new FormData();
      data.append("file", selectedFile);
      data.append("label", labels);
      data.append("type", "image");
      data.append("propertyType", activePage.toLowerCase());
      dispatch(updateFile(report_id, activePage.toLowerCase(), data))
        .then((result) => {
          setisLoading(false);
          dispatch(getFile(report_id, activePage.toLowerCase()));
          setmakePrompt({
            open: true,
            message: "Image uploaded successfully",
            type: "success",
          });
          setlabels("");
          setselectedFile(null);
        })
        .catch((err) => {
          setisLoading(false);
          setmakePrompt({
            open: true,
            message: err.response.data.error || "Some error occured",
            type: "error",
          });
        });
    } else {
      setmakePrompt({
        open: true,
        message: "Please provide a label and select an image",
        type: "error",
      });
    }
  };

  const filterImages = () => {
    return reports.files?.filter(
      (report) => report.propertyType === activePage.toLowerCase()
    );
  };

  const getActivePage = () => {
    switch (activePage) {
      case "Interior":
        return (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "200px",
              }}
            >
              <input
                type="text"
                placeholder="Enter label"
                value={labels}
                onChange={(e) => setlabels(e.target.value)}
                style={{
                  border: "none",
                  outline: "none",
                  backgroundColor: "#F3F3F3",
                  padding: "6px",
                  margin: "0 auto",
                  textAlign: "center",
                }}
              />
              <FileUploader
                type="file-uploader"
                style={{
                  width: "200px",
                  height: "200px",
                  backgroundColor: "white",
                  borderRadius: "20px",
                }}
                handleChange={(file) => {
                  if (file && file.length >= 0) {
                    setselectedFile(file[0]);
                  }
                }}
              />
              {selectedFile && (
                <span
                  style={{
                    textAlign: "center",
                    margin: "4px auto",
                    fontSize: "12px",
                    color: "#2593D6",
                    cursor: "pointer",
                  }}
                >
                  {selectedFile.name}
                </span>
              )}
              {labels && selectedFile ? (
                <Button
                  style={{
                    margin: ".6em auto 1em auto",
                    width: "100%",
                  }}
                  onClick={uploadImage}
                  isLoading={isLoading}
                >
                  Uplaod
                </Button>
              ) : null}
            </div>
            {filterImages().map((image, index) => (
              <div key={index}>
                <Text
                  style={{
                    textAlign: "center",
                    margin: "0 1em",
                    fontSize: "12px",
                    cursor: "pointer",
                  }}
                >
                  {image.label}
                </Text>
                <img
                  src={`https://dygo8x5k8m6hk.cloudfront.net/${image.file}`}
                  alt={image.label}
                  style={{
                    width: "200px",
                    height: "200px",
                    margin: ".5em 1em",
                    borderRadius: "20px",
                  }}
                />
              </div>
            ))}
          </div>
        );
      case "Exterior":
        return (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "200px",
              }}
            >
              <input
                type="text"
                placeholder="Enter label"
                value={labels}
                onChange={(e) => setlabels(e.target.value)}
                style={{
                  border: "none",
                  outline: "none",
                  backgroundColor: "#F3F3F3",
                  padding: "6px",
                  margin: "0 auto",
                  textAlign: "center",
                }}
              />
              <FileUploader
                type="file-uploader"
                style={{
                  width: "200px",
                  height: "200px",
                  backgroundColor: "white",
                  borderRadius: "20px",
                }}
                handleChange={(file) => {
                  if (file && file.length >= 0) {
                    setselectedFile(file[0]);
                  }
                }}
              />
              {selectedFile && (
                <span
                  style={{
                    textAlign: "center",
                    margin: "4px auto",
                    fontSize: "12px",
                    color: "#2593D6",
                    cursor: "pointer",
                  }}
                >
                  {selectedFile.name}
                </span>
              )}
              {labels && selectedFile ? (
                <Button
                  style={{
                    margin: ".6em auto 1em auto",
                    width: "100%",
                  }}
                  onClick={uploadImage}
                  isLoading={isLoading}
                >
                  Uplaod
                </Button>
              ) : null}
            </div>
            {filterImages().map((image, index) => (
              <div key={index}>
                <Text
                  style={{
                    textAlign: "center",
                    margin: "0 1em",
                    fontSize: "12px",
                    cursor: "pointer",
                  }}
                >
                  {image.label}
                </Text>
                <img
                  src={`https://dygo8x5k8m6hk.cloudfront.net/${image.file}`}
                  alt={image.label}
                  style={{
                    width: "200px",
                    height: "200px",
                    margin: ".5em 1em",
                    borderRadius: "20px",
                  }}
                />
              </div>
            ))}
          </div>
        );
      case "Repairs":
        return (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "200px",
              }}
            >
              <input
                type="text"
                placeholder="Enter label"
                value={labels}
                onChange={(e) => setlabels(e.target.value)}
                style={{
                  border: "none",
                  outline: "none",
                  backgroundColor: "#F3F3F3",
                  padding: "6px",
                  margin: "0 auto",
                  textAlign: "center",
                }}
              />
              <FileUploader
                type="file-uploader"
                style={{
                  width: "200px",
                  height: "200px",
                  backgroundColor: "white",
                  borderRadius: "20px",
                }}
                handleChange={(file) => {
                  if (file && file.length >= 0) {
                    setselectedFile(file[0]);
                  }
                }}
              />
              {selectedFile && (
                <span
                  style={{
                    textAlign: "center",
                    margin: "4px auto",
                    fontSize: "12px",
                    color: "#2593D6",
                    cursor: "pointer",
                  }}
                >
                  {selectedFile.name}
                </span>
              )}
              {labels && selectedFile ? (
                <Button
                  style={{
                    margin: ".6em auto 1em auto",
                    width: "100%",
                  }}
                  onClick={uploadImage}
                  isLoading={isLoading}
                >
                  Uplaod
                </Button>
              ) : null}
            </div>
            {filterImages().map((image, index) => (
              <div key={index}>
                <Text
                  style={{
                    textAlign: "center",
                    margin: "0 1em",
                    fontSize: "12px",
                    cursor: "pointer",
                  }}
                >
                  {image.label}
                </Text>
                <img
                  src={`https://dygo8x5k8m6hk.cloudfront.net/${image.file}`}
                  alt={image.label}
                  style={{
                    width: "200px",
                    height: "200px",
                    margin: ".5em 1em",
                    borderRadius: "20px",
                  }}
                />
              </div>
            ))}
          </div>
        );
      default:
        return <span>No page found</span>;
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        p: ".5em",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          width: "fit-content",
          ml: "2em",
        }}
      >
        {activePages.map((page, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "row",
              p: "10px 20px",
              bgcolor: "#F3F3F3",
              width: "48%",
              borderRadius: "20px 20px 0px 0px",
              mr: ".5em",
              cursor: "pointer",
              color: activePage !== page.title ? "#28303F" : "#2593D6",
              opacity: activePage !== page.title ? "0.7" : "1",
            }}
            onClick={() => {
              setactivePage(page.title);
              setlabels("");
              setselectedFile(null);
            }}
          >
            {activePage === page.title ? page.filled : page.icon}
            <Text
              variant="body1"
              style={{
                color: activePage === page.title ? "#2593D6" : "#28303F",
                fontSize: "14px",
              }}
            >
              {page.title}
            </Text>
          </Box>
        ))}
      </Box>
      <Box sx={{ bgcolor: "#F3F3F3", padding: "1em", borderRadius: "24px" }}>
        {getActivePage()}
      </Box>
      <Prompt
        open={makePrompt.open}
        message={makePrompt.message}
        type={makePrompt.type}
        handleClose={() =>
          setmakePrompt({ open: false, message: "", type: "error" })
        }
      />
    </Box>
  );
};

export default Photos;
