import { Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import Template from "../../Template";
import Ellipse8 from "../../Assets/Images/Ellipse8.svg";
import GridCard from "../../Components/GridCard";
import Icon from "../../Helpers/Icon";
import Input from "../../Components/Core/Input";
import SelectInput from "../../Components/Core/Select";
import Button from "../../Components/Core/Button";
import Text from "../../Components/Core/Text";
import LayoutCard from "../../Components/LayoutCard";
import {
  FilterEnable,
  HomeArrowUp,
  HomeLocation,
  RoofHomeCheck,
  RoofHomeLock,
  RoofHomeNotification,
  RoofHomeShare,
  SortRectangle,
} from "react-huge-icons/outline";
import Decline from "../../Components/Decline";
import CreateCounter from "../../Components/CreateCounter";
import { useDispatch, useSelector } from "react-redux";
import {
  approveOrder,
  counterOffer,
  declineOrder,
  getAssignedOrder,
  getCancelledOrder,
  getCompleteOrder,
  getNewOrders,
  getScheduledOrder,
} from "../../Actions/order";
import Prompt from "../../Components/Core/Prompt";
import MessageModal from "../../Components/MessageModal";
import ScheduleNow from "../../Components/ScheduleNow";
import Report from "../../Components/Report";
import { Navigate, useNavigate } from "react-router";

const Feed = () => {
  const auth = useSelector((state) => state.auth);
  const order = useSelector((state) => state.order);
  const [pageType, setpageType] = useState("new");
  const [showFilter, setshowFilter] = useState(false);
  const [layoutType, setlayoutType] = useState("grid");
  const [currentSlide, setcurrentSlide] = useState(0);
  const [showDecline, setshowDecline] = useState(false);
  const [showCreateCounter, setshowCreateCounter] = useState(false);
  const [currentItem, setcurrentItem] = useState({});
  const [showMessage, setshowMessage] = useState(false);
  const [showReport, setshowReport] = useState(false);
  const [showReviseReport, setshowReviseReport] = useState(false);
  const [showSchedule, setshowSchedule] = useState(false);
  const [makePrompt, setmakePrompt] = useState({
    open: false,
    message: "",
    type: "error",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getNewOrders());
    dispatch(getAssignedOrder());
    dispatch(getScheduledOrder());
    dispatch(getCompleteOrder());
    dispatch(getCancelledOrder());
  }, []);

  const headerOptions = [
    {
      label: `New (${order.newOrder.length})`,
      dispatch: getNewOrders,
      caption: "New Request",
      key: "new",
      comment:
        "Lorem Ipsum is simply dummy text of the printing that can be help Lorem ipsum dolor sit amet consectetur. Consequat nec phasellus amet sodales. Quis ut enim etiam arcu a. Imperdiet neque aliquam porta gravida integer bibendum sapien. to all kind people",
      icon: (
        <HomeLocation
          fontSize={20}
          style={{
            marginTop: "auto",
            marginBottom: "auto",
            marginRight: "8px",
          }}
        />
      ),
    },
    {
      label: `Assigned (${order.assignedOrder.length})`,
      dispatch: getAssignedOrder,
      caption: "Assigned",
      key: "assigned",
      comment:
        "Lorem Ipsum is simply dummy text of the printing that can be help Lorem ipsum dolor sit amet consectetur. Consequat nec phasellus amet sodales. Quis ut enim etiam arcu a. Imperdiet neque aliquam porta gravida integer bibendum sapien. to all kind people",
      icon: (
        <HomeArrowUp
          fontSize={20}
          style={{
            marginTop: "auto",
            marginBottom: "auto",
            marginRight: "8px",
          }}
        />
      ),
    },
    {
      label: `Scheduled (${order.scheduleOrder.length})`,
      dispatch: getScheduledOrder,
      caption: "Scheduled",
      key: "scheduled",
      comment:
        "Lorem Ipsum is simply dummy text of the printing that can be help Lorem ipsum dolor sit amet consectetur. Consequat nec phasellus amet sodales. Quis ut enim etiam arcu a. Imperdiet neque aliquam porta gravida integer bibendum sapien. to all kind people",
      icon: (
        <RoofHomeNotification
          fontSize={20}
          style={{
            marginTop: "auto",
            marginBottom: "auto",
            marginRight: "8px",
          }}
        />
      ),
    },
    {
      label: `Completed (${order.completeOrder.length})`,
      dispatch: getCompleteOrder,
      caption: "Completed",
      key: "completed",
      comment:
        "Lorem Ipsum is simply dummy text of the printing that can be help Lorem ipsum dolor sit amet consectetur. Consequat nec phasellus amet sodales. Quis ut enim etiam arcu a. Imperdiet neque aliquam porta gravida integer bibendum sapien. to all kind people",
      icon: (
        <RoofHomeCheck
          fontSize={20}
          style={{
            marginTop: "auto",
            marginBottom: "auto",
            marginRight: "8px",
          }}
        />
      ),
    },
    {
      label: "Revision",
      // dispatch: ,
      caption: "Revision",
      key: "revision",
      comment:
        "Lorem Ipsum is simply dummy text of the printing that can be help Lorem ipsum dolor sit amet consectetur. Consequat nec phasellus amet sodales. Quis ut enim etiam arcu a. Imperdiet neque aliquam porta gravida integer bibendum sapien. to all kind people",
      icon: (
        <RoofHomeShare
          fontSize={20}
          style={{
            marginTop: "auto",
            marginBottom: "auto",
            marginRight: "8px",
          }}
        />
      ),
    },
    {
      label: `On Hold / Cancelled (${order.cancelledOrder.length})`,
      caption: "On Hold / Cancelled",
      dispatch: getCancelledOrder,
      key: "cancelled",
      comment:
        "Lorem Ipsum is simply dummy text of the printing that can be help Lorem ipsum dolor sit amet consectetur. Consequat nec phasellus amet sodales. Quis ut enim etiam arcu a. Imperdiet neque aliquam porta gravida integer bibendum sapien. to all kind people",
      icon: (
        <RoofHomeLock
          fontSize={20}
          style={{
            marginTop: "auto",
            marginBottom: "auto",
            marginRight: "8px",
          }}
        />
      ),
    },
  ];

  const showTutorial = () => {
    if (localStorage.getItem("tutorialStatus") === "done") {
      return false;
    } else if (localStorage.getItem("tutorialStatus") === "skip") {
      return false;
    }
    return true;
  };

  const getData = () => {
    switch (pageType) {
      case "new":
        return order.newOrder;
      case "assigned":
        return order.assignedOrder;
      case "scheduled":
        return order.scheduleOrder;
      case "completed":
        return order.completeOrder;
      case "revision":
        return [];
      case "cancelled":
        return order.cancelledOrder;
      default:
        return [];
    }
  };

  const handleMakePrompt = (message, type) => {
    setmakePrompt({ open: true, message, type });
    setTimeout(() => {
      setmakePrompt({ open: false, message: "", type: "" });
    }, 2000);
  };

  const onApprove = (id) => {
    dispatch(approveOrder(id))
      .then((result) => {
        handleMakePrompt("Order Approved", "success");
        dispatch(getNewOrders());
      })
      .catch((err) => {
        handleMakePrompt(err.response.data.error, "error");
      });
  };

  const onDecline = (reason) => {
    dispatch(declineOrder(currentItem.order[0]._id, { reason }))
      .then((result) => {
        setshowDecline(false);
        dispatch(getNewOrders());
        handleMakePrompt("Order Declined", "success");
      })
      .catch((err) => {
        setshowDecline(false);
        handleMakePrompt(err.response.data.error, "error");
      });
  };

  const onCreateCounter = (data) => {
    dispatch(counterOffer(currentItem.order[0]._id, data))
      .then((result) => {
        setshowCreateCounter(false);
        handleMakePrompt("Counter Offer Created", "success");
      })
      .catch((err) => {
        setshowCreateCounter(false);
        handleMakePrompt(err.response.data.error, "error");
      });
  };

  return (
    <Template
      showHeader
      headerProps={{
        showSearch: true,
        showNotification: true,
        showCash: true,
        showProfile: true,
        showCompleteProfile: true,
        showMessage: true,
      }}
      containerStyle={{
        backgroundImage: `url(${Ellipse8})`,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          mt: "40px",
        }}
      >
        {headerOptions.map((item, index) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              background:
                pageType === item.key
                  ? "#FFFFFF 0% 0% no-repeat padding-box"
                  : "#F2F2F2 0% 0% no-repeat padding-box",
              borderRadius: "20px 20px 0px 0px",
              height: "44px",
              width: "fit-content",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              color: pageType === item.key ? "#2D81FF" : "#000000",
              margin: "0px 4px",
              padding: "2px 15px",
              zIndex: currentSlide === index && showTutorial() ? 1005 : 0,
              position: "relative",
            }}
          >
            <Box
              onClick={() => {
                setpageType(item.key);
                dispatch(item.dispatch());
              }}
              sx={{
                display: "flex",
                flexDirection: "row",
                height: "100%",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  color:
                    pageType === item.key ? "#2593D6 !important" : "#000000",
                }}
              >
                {item.icon}
              </Box>
              <Box
                sx={{
                  font: "normal normal normal 13.5px/21px Poppins",
                  color: pageType === item.key ? "#2593D6" : "#000000",
                }}
              >
                {item.label}
              </Box>
            </Box>
            {showTutorial() && (
              <Box
                sx={{
                  position: "absolute",
                  bottom: "-224px",
                  width: "320px",
                  backgroundColor: "#FFFFFF",
                  height: "fit-content",
                  maxHeight: "180px",
                  ml: "120px",
                  zIndex: 1500,
                  background: " #FFFFFF 0% 0% no-repeat padding-box",
                  boxShadow: "0px 20px 20px #0000000B",
                  borderRadius: "26px",
                  padding: "1em 1.5em",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    width: 0,
                    height: 0,
                    mt: "-1.6em",
                    ml: "1.5em",
                    mr: "auto",
                    borderLeft: "8px solid transparent",
                    borderRight: "8px solid transparent",
                    borderBottom: "12px solid #ffff",
                    borderTop: "none",
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: ".5em",
                  }}
                >
                  <Text
                    style={{
                      font: "normal normal 600 15px/27px Poppins",
                      color: "#000000",
                      fontSize: "15px",
                    }}
                  >
                    {item.caption}
                  </Text>
                  <Text
                    style={{
                      color: "#2C80FF",
                      marginLeft: "auto",
                      font: "normal normal normal 11px/20px Poppins",
                      fontSize: "11px",
                    }}
                  >
                    ({index + 1}/{headerOptions.length})
                  </Text>
                </Box>
                <Text
                  style={{
                    color: "#000000",
                    opacity: "0.5",
                    marginTop: "-.4em",
                    font: "normal normal normal 10px/20px Poppins",
                    fontSize: "10px",
                  }}
                >
                  {item.comment}
                </Text>
                {index === headerOptions.length - 1 ? (
                  <Button
                    style={{
                      width: "48%",
                      marginTop: "auto",
                      marginBottom: "auto",
                      marginLeft: "auto",
                      marginRight: "auto",
                      height: "40px",
                    }}
                    onClick={() => {
                      localStorage.setItem("tutorialStatus", "done");
                      setcurrentSlide(null);
                    }}
                  >
                    Done
                  </Button>
                ) : (
                  <Box
                    sx={{ display: "flex", flexDirection: "row", m: "auto 0" }}
                  >
                    <Button
                      isTransparent
                      style={{
                        width: "48%",
                        paddingTop: ".1em",
                        paddingBottom: ".1em",
                        marginRight: ".5em",
                      }}
                      onClick={() => {
                        localStorage.setItem("tutorialStatus", "skip");
                        setcurrentSlide(null);
                      }}
                    >
                      Skip
                    </Button>
                    <Button
                      style={{
                        width: "48%",
                        marginTop: "auto",
                        marginBottom: "auto",
                      }}
                      onClick={() => {
                        if (currentSlide < headerOptions.length - 1) {
                          setcurrentSlide(currentSlide + 1);
                        }
                      }}
                    >
                      Next
                    </Button>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          border: "1px solid #FFFFFF",
          background:
            "transparent linear-gradient(119deg, #FFFFFF66 0%, #FFFFFF24 100%) 0% 0% no-repeat padding-box",
          boxShadow: "15px 15px 50px #00000029",
          borderRadius: "24px",
          backgroundColor: "#F2F2F2",
          opacity: "1",
          padding: "1.5em",
          backdropFilter: "blur(50px)",
          WebkitBackdropFilter: "blur(50px)",
          width: "90%",
          margin: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            mb: "1em",
          }}
        >
          <Button
            style={{
              background: layoutType === "layout" ? "#2C80FF" : "none",
              marginLeft: "auto",
              marginRight: "1em",
              marginTop: layoutType === "layout" ? "4px" : "7px",
              height: "40px",
              width: "40px",
            }}
            onClick={() => setlayoutType("layout")}
          >
            <Icon
              name={
                layoutType === "layout" ? "WhiteOutlineLayout" : "OutlineLayout"
              }
              style={{
                width: layoutType === "layout" ? "20px" : "28px",
                height: layoutType === "layout" ? "20px" : "28px",
                marginTop: "4px",
              }}
            />
          </Button>
          <Button
            style={{
              background: layoutType === "grid" ? "#2C80FF" : "none",
              height: "40px",
              width: "40px",
              display: "flex",
              marginTop: "4px",
            }}
            onClick={() => setlayoutType("grid")}
          >
            <Icon
              name={layoutType !== "grid" ? "GrayOutlineGrid" : "OutlineGrid"}
              style={{
                marginTop: "4px",
                width: layoutType === "grid" ? "20px" : "28px",
                height: layoutType === "grid" ? "20px" : "28px",
              }}
            />
          </Button>
          <Button
            style={{
              width: "104px",
              height: "46px",
              display: "flex",
              flexDirection: "row",
              margin: "0 1em",
              position: "relative",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SortRectangle
              fontSize={18}
              style={{
                marginTop: "auto",
                marginBottom: "auto",
                position: "absolute",
                left: "20px",
                top: "13px",
              }}
            />
            <span
              style={{
                marginLeft: "8px",
                font: "normal normal medium 12px/18px Poppins",
                fontSize: "13px",
              }}
            >
              Sort
            </span>
          </Button>
          <Button
            onClick={() => setshowFilter(!showFilter)}
            style={{
              width: "104px",
              height: "46px",
              display: "flex",
              flexDirection: "row",
              backgroundColor: showFilter ? "#EE1616" : "#2C80FF",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            {!showFilter && (
              <FilterEnable
                fontSize={18}
                style={{
                  marginTop: "auto",
                  marginBottom: "auto",
                  position: "absolute",
                  left: "18px",
                  top: "12px",
                }}
              />
            )}
            <span
              style={{
                marginLeft: "8px",
                font: "normal normal medium 14px/18px Poppins",
                fontSize: "14px",
              }}
            >
              {showFilter ? "Close Filter" : "Filter"}
            </span>
          </Button>
        </Box>
        {showFilter && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Input
              placeholder="Search by property id, client name…"
              label="Search"
              isTransparent
              type="text"
              style={{ width: "48%", marginLeft: "1em" }}
            />

            <Input
              placeholder="Search by property id, client name…"
              label="Select Date"
              type="date"
              isTransparent
              style={{ width: "48%", marginLeft: "1em" }}
            />
            <span
              style={{
                color: "#656A72",
                fontSize: "13px",
                fontFamily: "Poppins",
                margin: "2.8em .7em 0 1.5em",
              }}
            >
              to
            </span>
            <Input
              placeholder="Search by property id, client name…"
              label="Select Date"
              type="date"
              isTransparent
              style={{ width: "48%", marginLeft: "1em" }}
            />

            <SelectInput
              label="By Property Type"
              style={{ width: "48%", marginLeft: "1em", padding: "1.2px 0" }}
              isTransparent
              options={[
                { value: "option1", label: "Option 1" },
                { value: "option2", label: "Option 2" },
                { value: "option3", label: "Option 3" },
              ]}
              value={"option1"}
              onChange={(option) => {
                console.log(option);
              }}
            />

            <SelectInput
              label="By State"
              isTransparent
              style={{ width: "48%", marginLeft: "1em", padding: "1.2px 0" }}
              options={[
                { value: "option1", label: "Option 1" },
                { value: "option2", label: "Option 2" },
                { value: "option3", label: "Option 3" },
              ]}
              value={"option1"}
              onChange={(option) => {
                console.log(option);
              }}
            />

            <Button
              style={{ width: "36%", marginTop: "1.7em", marginLeft: "1em" }}
            >
              Apply Filter
            </Button>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            mt: "1em",
          }}
        >
          {getData() !== undefined && getData().length > 0 ? (
            getData().map((item, index) =>
              layoutType === "grid" ? (
                <GridCard
                  key={index}
                  item={item}
                  pageType={pageType}
                  onSchedule={() => {
                    setshowSchedule(true);
                    setcurrentItem(item);
                  }}
                  onReport={() => {
                    setshowReport(true);
                    setcurrentItem(item);
                    navigate("/report", {
                      state: { id: item.report_id },
                    });
                  }}
                  onMessage={() => {
                    setshowMessage(true);
                    setcurrentItem(item);
                  }}
                  onReviseReport={() => {
                    setshowReviseReport(true);
                    setcurrentItem(item);
                  }}
                  onApprove={() => {
                    const id = item?.order[0]?._id;
                    onApprove(id);
                  }}
                  onDecline={(data) => {
                    setshowDecline(true);
                    setcurrentItem(data);
                  }}
                />
              ) : (
                <LayoutCard key={index} item={item} />
              )
            )
          ) : (
            <CircularProgress color="primary" sx={{ m: "0 auto" }} />
          )}
        </Box>
      </Box>
      {showTutorial() && <Box className="mainModal" />}
      {showDecline && (
        <Decline
          handleClose={() => setshowDecline(false)}
          onCreateCounter={() => setshowCreateCounter(true)}
          onDecline={(reason) => {
            onDecline(reason);
          }}
        />
      )}
      {showCreateCounter && (
        <CreateCounter
          handleClose={() => setshowCreateCounter(false)}
          onSubmit={(data) => {
            onCreateCounter(data);
          }}
        />
      )}
      {showMessage && (
        <MessageModal
          handleClose={() => setshowMessage(!showMessage)}
          item={currentItem}
        />
      )}
      {showReport && (
        <Report
          handleClose={() => setshowReport(!showReport)}
          item={currentItem}
        />
      )}
      {showSchedule && (
        <ScheduleNow
          item={currentItem}
          handleClose={() => setshowSchedule(!showSchedule)}
        />
      )}
      <Prompt
        open={makePrompt.open}
        message={makePrompt.message}
        type={makePrompt.type}
        onClose={() => setmakePrompt({ ...makePrompt, open: false })}
      />
    </Template>
  );
};

export default Feed;
