import { Box } from "@mui/material";
import React, { useState } from "react";
import Arc from "../Assets/Images/Arc.jpg";
import Text from "./Core/Text";
import Button from "./Core/Button";
import moment from "moment";
import {
  Calendar,
  ChatWritten,
  ClockCircle,
  CoinDollar,
  RoofHomeGraph,
  ShoppingBag,
} from "react-huge-icons/outline";

const Card = ({
  item,
  onDecline,
  pageType,
  onApprove,
  onMessage,
  onReport,
  onSchedule,
  onReviseReport,
}) => {
  const [isHover, setisHover] = useState(false);
  const { order } = item;

  const getDueStatus = () => {
    const dueDate = moment(order[0].dueDate);
    const today = moment();
    const daysDifference = moment(dueDate).diff(today, "days");

    if (pageType === "completed") {
      return {
        color: "#00C165",
        text: "Completed",
      };
    } else if (daysDifference === 0) {
      return {
        color: "#f4b33f",
        text: "Due Today",
      };
    } else if (daysDifference < 0) {
      return {
        color: "#EE1616",
        text: "Late",
      };
    } else {
      return false;
    }
  };

  const getStatusBox = () => {
    if (pageType === "assigned") {
      return (
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Button
            style={{
              width: "240px",
              height: "44px",
              borderRadius: 0,
              color: "#fff",
              fontSize: "14px",
              margin: "0 auto",
              fontFamily: "Poppins",
              fontWeight: 600,
              display: "flex",
              flexDirection: "row",
              position: "relative",
              borderBottomLeftRadius: "15px",
              borderBottomRightRadius: "15px",
            }}
            onClick={onSchedule}
          >
            Schedule Now
          </Button>
          <Button
            style={{
              width: "240px",
              height: "44px",
              borderRadius: 0,
              color: "#fff",
              fontSize: "14px",
              margin: "0 auto",
              marginLeft: "0.5em",
              fontFamily: "Poppins",
              fontWeight: 600,
              display: "flex",
              flexDirection: "row",
              position: "relative",
              borderBottomLeftRadius: "15px",
              borderBottomRightRadius: "15px",
            }}
            onClick={onMessage}
          >
            <ChatWritten
              fontSize={18}
              style={{
                marginRight: "4px",
              }}
            />
            <span style={{ marginTop: "2px" }}>Message</span>
          </Button>
        </Box>
      );
    } else if (pageType === "scheduled") {
      return (
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Button
            style={{
              width: "240px",
              height: "44px",
              borderRadius: 0,
              color: "#fff",
              fontSize: "14px",
              margin: "0 auto",
              fontFamily: "Poppins",
              fontWeight: 600,
              display: "flex",
              flexDirection: "row",
              position: "relative",
              borderBottomLeftRadius: "15px",
              borderBottomRightRadius: "15px",
            }}
            onClick={onReport}
          >
            Complete Report
          </Button>
        </Box>
      );
    } else if (pageType === "completed") {
      return (
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Button
            style={{
              width: "240px",
              height: "44px",
              borderRadius: 0,
              color: "#fff",
              fontSize: "14px",
              margin: "0 auto",
              fontFamily: "Poppins",
              fontWeight: 600,
              display: "flex",
              flexDirection: "row",
              position: "relative",
              borderBottomLeftRadius: "15px",
              borderBottomRightRadius: "15px",
            }}
            onClick={onReport}
          >
            View Report
          </Button>
        </Box>
      );
    } else if (pageType === "revision") {
      return (
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Button
            style={{
              width: "240px",
              height: "44px",
              borderRadius: 0,
              color: "#fff",
              fontSize: "14px",
              margin: "0 auto",
              fontFamily: "Poppins",
              fontWeight: 600,
              display: "flex",
              flexDirection: "row",
              position: "relative",
              borderBottomLeftRadius: "15px",
              borderBottomRightRadius: "15px",
            }}
            onClick={onReviseReport}
          >
            Revise Report
          </Button>
        </Box>
      );
    } else if (pageType === "cancelled") {
      return (
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Button
            style={{
              width: "240px",
              height: "44px",
              borderRadius: 0,
              color: "#fff",
              fontSize: "14px",
              margin: "0 auto",
              fontFamily: "Poppins",
              fontWeight: 600,
              display: "flex",
              flexDirection: "row",
              position: "relative",
              borderBottomLeftRadius: "15px",
              borderBottomRightRadius: "15px",
            }}
            onClick={onMessage}
          >
            <ChatWritten
              fontSize={18}
              style={{
                marginRight: "4px",
              }}
            />
            <span style={{ marginTop: "2px" }}>Message</span>
          </Button>
        </Box>
      );
    }
  };

  return (
    <Box>
      <Box
        sx={{
          position: "relative",
          height: !item.dueDate && pageType !== "completed" ? "430px" : "385px",
          cursor: "pointer",
          mb: item.hasCounterOffer ? "36px !important" : "0",
        }}
        onMouseEnter={() => setisHover(true)}
        onMouseLeave={() => setisHover(false)}
      >
        <Box
          sx={{
            width: "270px",
            height: "fit-content",
            maxHeight: "400px",
            background: "#FFFFFF",
            borderRadius: "15px",
            opacity: "1",
            padding: "4px 4px 0 4px",
            m: "12px 12px 0 12px",
            position: "relative",
            overflow: "hidden",
            transition: "all 0.3s ease-in-out",
            boxShadow: isHover ? "0px 30px 20px #0000002B" : "none",
          }}
        >
          <img
            src={Arc}
            alt="Arc"
            style={{
              width: "270px",
              height: "120px",
              borderRadius: "15px",
              opacity: "1",
            }}
            onClick={() => {
              window.location.href = `/detail/${pageType}/${order[0]._id}`;
            }}
          />

          <div
            style={{
              position: "absolute",
              width: "auto",
              height: "fit-content",
              backgroundColor: "#000000 ",
              borderRadius: "15px",
              top: 90,
              right: 10,
              color: "#FFFFFF",
              padding: "7px 8px",
              textAlign: "center",
              justifyContent: "center",
              opacity: "1",
              display: "flex",
            }}
          >
            <span
              style={{
                fontSize: 11,
                fontFamily: "Poppins",
                fontWeight: 600,
              }}
            >
              {item.propertyType}
            </span>
          </div>
          <Box
            onClick={() => {
              window.location.href = `/detail/${pageType}/${order[0]._id}`;
            }}
            sx={{ p: 1 }}
          >
            <Text
              style={{
                color: "#000000",
                fontWeight: 600,
                fontSize: "15px",
                fontFamily: "Poppins",
              }}
            >
              {order[0].address},
            </Text>
            <Text
              style={{
                color: "#000000",
                fontSize: "15px",
                fontFamily: "Poppins",
                marginTop: "-4px",
              }}
            >
              {order[0].state}, {order[0].zipCode}
            </Text>
            <Box>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <ShoppingBag
                  color="#2593D6"
                  fontSize={18}
                  style={{
                    marginRight: "10px",
                    marginTop: "4px",
                    marginBottom: "auto",
                  }}
                />

                <Text
                  style={{
                    fontSize: "13px",
                    fontFamily: "Poppins",
                    color: "#000000",
                  }}
                >
                  Order ID: #{order[0].orderId}
                </Text>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <RoofHomeGraph
                  color="#2593D6"
                  fontSize={18}
                  style={{
                    marginRight: "10px",
                    marginTop: "4px",
                    marginBottom: "auto",
                  }}
                />
                <Text
                  style={{
                    fontSize: "13px",
                    fontFamily: "Poppins",
                    color: "#000000",
                  }}
                >
                  {item.propertyType}
                </Text>
                <CoinDollar
                  color="#2593D6"
                  fontSize={18}
                  style={{
                    marginRight: "10px",
                    marginTop: "4px",
                    marginBottom: "auto",
                    marginLeft: "1.6em",
                  }}
                />
                <Text
                  style={{
                    fontSize: "13px",
                    fontFamily: "Poppins",
                    color: "#000000",
                  }}
                >
                  {order[0].totalCost}
                </Text>
              </Box>

              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <ClockCircle
                  color="#2593D6"
                  fontSize={18}
                  style={{
                    marginRight: "10px",
                    marginTop: "4px",
                    marginBottom: "auto",
                  }}
                />

                <Text
                  style={{
                    fontSize: "13px",
                    fontFamily: "Poppins",
                    color: "#000000",
                  }}
                >
                  {moment(order[0].createdAt).format("DD/MM/YY hh:mm A")}
                </Text>
              </Box>
              {item.dueDate && pageType !== "new" && (
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Calendar
                    color={getDueStatus().color}
                    fontSize={18}
                    style={{
                      marginLeft: -2,
                      marginRight: "10px",
                      marginTop: "4px",
                      marginBottom: "auto",
                    }}
                  />

                  <Text
                    style={{
                      fontSize: "13px",
                      fontFamily: "Poppins",
                      color: getDueStatus().color,
                    }}
                  >
                    {moment(item.dueDate).format("DD/MM/YY")}
                  </Text>
                </Box>
              )}

              {item?.completed && pageType !== "new" && (
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Calendar
                    color={getDueStatus().color}
                    fontSize={18}
                    style={{
                      marginLeft: -2,
                      marginRight: "10px",
                      marginTop: "4px",
                      marginBottom: "auto",
                    }}
                  />

                  <Text
                    style={{
                      fontSize: "13px",
                      fontFamily: "Poppins",
                      color: getDueStatus().color,
                    }}
                  >
                    {moment(item?.completed).format("DD/MM/YY")}
                  </Text>
                </Box>
              )}

              {item.hasCounterOffer && (
                <Box
                  sx={{
                    background: "#2593D617 0% 0% no-repeat padding-box",
                    borderRadius: "12px",
                    width: "auto",
                    height: "auto",
                    margin: ".3em 0 .5em 0",
                    padding: ".5em",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "13px",
                      fontFamily: "Poppins",
                      color: "#000000",
                    }}
                  >
                    You have counter Offered for
                  </Text>
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <CoinDollar
                      color="#2593D6"
                      fontSize={18}
                      style={{
                        marginRight: "10px",
                        marginTop: "4.4px",
                        marginBottom: "auto",
                      }}
                    />
                    <Text
                      style={{
                        fontSize: "13px",
                        fontFamily: "Poppins",
                        color: "#000000",
                      }}
                    >
                      {item.price}
                    </Text>
                    <ClockCircle
                      color="#2593D6"
                      fontSize={18}
                      style={{
                        marginRight: "10px",
                        marginTop: "4px",
                        marginBottom: "auto",
                        marginLeft: "1.6em",
                      }}
                    />

                    <Text
                      style={{
                        fontSize: "13px",
                        fontFamily: "Poppins",
                        color: "#000000",
                      }}
                    >
                      {moment(item.dueDate).format("DD/MM/YY")}
                    </Text>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>

          {pageType === "new" ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: "4px 4px 10px 4px",
              }}
            >
              <Button
                style={{
                  width: "113px",
                  height: "40px",
                  backgroundColor: "#00C165",
                  borderRadius: "11px",
                  color: "#FFFFFF",
                  fontSize: "12px",
                  fontFamily: "Poppins",
                  fontWeight: 600,
                }}
                onClick={onApprove}
              >
                Approve
              </Button>
              <Button
                onClick={() => onDecline(item)}
                style={{
                  width: "113px",
                  height: "40px",
                  backgroundColor: "transparent",
                  borderRadius: "11px",
                  border: " 1px solid #EE1616",
                  color: "#EE1616",
                  fontSize: "12px",
                  fontFamily: "Poppins",
                  fontWeight: 600,
                }}
              >
                Decline
              </Button>
            </Box>
          ) : null}
          {pageType !== "new" ? (
            <div
              style={{
                position: "absolute",
                width: "100px",
                backgroundColor: getDueStatus().color,
                bottom: 12,
                right: -34,
                color: "#FFFFFF",
                padding: "7px 12px",
                textAlign: "center",
                justifyContent: "center",
                opacity: "1",
                display: "flex",
                transform: "rotate(314deg)",
              }}
            >
              <span
                style={{
                  fontSize: 12,
                  fontFamily: "Poppins",
                  fontWeight: 600,
                }}
              >
                {getDueStatus().text}
              </span>
            </div>
          ) : null}
        </Box>
        {isHover && (
          <Box
            sx={{
              position: "absolute",
              // bottom:
              //   (!item.hasCounterOffer && item.dueDate) ||
              //   pageType === "Completed"
              //     ? 54
              //     : 5,
              left: 0,
              right: 0,
              width: "260px",
              m: "0 auto",
            }}
          >
            {getStatusBox()}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Card;
