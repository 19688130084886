import { Box } from "@mui/material";
import React, { useState } from "react";
import Template from "../../Template";
import mokcup1 from "../..//Assets/Images/mokcup1.png";
import Input from "../../Components/Core/Input";
import Button from "../../Components/Core/Button";
import MailNegative from "../../Assets/Icons/Huge-icon-communication-outline-mail-negative.svg";
import Shield from "../../Assets/Icons/Huge-icon-ecommerce-outline-shield.svg";
import OutlineBook from "../../Assets/Icons/huge-icon-education-outline-book.svg";
import OutlineStack from "../../Assets/Icons/huge-icon-education-outline-cube-stack.svg";
import backgroundImage from "../..//Assets/Images/shutterstock_2116750028.png";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import {
  loginUser,
  otpRequest,
  resetPassword,
  verifyOtp,
} from "../../Actions/auth";
import FourDigitInput from "../../Components/FourDigitInput";
import Select from "../../Components/Core/Select";
import Prompt from "../../Components/Core/Prompt";

const ForgetPassword = () => {
  const [password, setpassword] = useState("");
  const [activePage, setactivePage] = useState("email");
  const [email, setemail] = useState("");
  const [type, settype] = useState("Inspector");
  const [otp, setotp] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [Errors, setErrors] = useState(null);
  const [makePrompt, setmakePrompt] = useState({
    open: false,
    message: "",
    type: "error",
  });
  const dispatch = useDispatch();

  const handleChangePassword = () => {
    if (activePage === "email") {
      handleSendOtp();
    } else if (activePage === "otp") {
      handleVerifyOtp();
    } else if (activePage === "password") {
      handleResetPassword();
    }
  };

  const handleSendOtp = () => {
    if (!email) {
      setErrors({ email: "Email is required" });
      setmakePrompt({
        open: true,
        message: "Email is required",
        type: "error",
      });
      return;
    } else if (!type) {
      setErrors({ type: "Type is required" });
      setmakePrompt({
        open: true,
        message: "Role is required",
        type: "error",
      });
      return;
    } else {
      setisLoading(true);
      dispatch(otpRequest({ email, type }))
        .then((result) => {
          setactivePage("otp");
          setisLoading(false);
          setmakePrompt({
            open: true,
            message: "Otp sent to your email",
            type: "success",
          });
        })
        .catch((err) => {
          setmakePrompt({
            open: true,
            message: err.response.data.error || "Some error occured",
            type: "error",
          });
        });
    }
  };

  const handleVerifyOtp = () => {
    if (!otp) {
      setErrors({ otp: "Otp is required" });
      setmakePrompt({
        open: true,
        message: "Otp is required",
        type: "error",
      });
      return;
    } else {
      setisLoading(true);
      dispatch(verifyOtp({ email, otp }))
        .then((result) => {
          setisLoading(false);
          setactivePage("password");
          setmakePrompt({
            open: true,
            message: "Otp verified, now you can reset your password",
            type: "success",
          });
        })
        .catch((err) => {
          setisLoading(false);
          setmakePrompt({
            open: true,
            message: err.response.data.error || "Some error occured",
            type: "error",
          });
        });
    }
  };

  const handleResetPassword = () => {
    if (!password) {
      setmakePrompt({
        open: true,
        message: "Password is required",
        type: "error",
      });
      setErrors({ password: "Password is required" });
      return;
    } else if (!confirmPassword) {
      setmakePrompt({
        open: true,
        message: "Confirm Password is required",
        type: "error",
      });
      setErrors({ confirmPassword: "Confirm Password is required" });
      return;
    } else if (password !== confirmPassword) {
      setmakePrompt({
        open: true,
        message: "Password does not match",
        type: "error",
      });
      setErrors({ confirmPassword: "Password does not match" });
      return;
    } else {
      setisLoading(true);
      dispatch(resetPassword({ email, newPassword: password }))
        .then((result) => {
          setmakePrompt({
            open: true,
            message: "Password changed successfully, logging you in.",
            type: "success",
          });
          setTimeout(() => {
            setisLoading(false);
            window.location.href = "/login";
          }, 2000);
        })
        .catch((err) => {
          setisLoading(false);
          setmakePrompt({
            open: true,
            message: err.response.data.error || "Some error occured",
            type: "error",
          });
        });
    }
  };
  return (
    <Template
      showHeader={true}
      showFooter={true}
      headerProps={{
        transparent: true,
        backgroundImage: backgroundImage,
      }}
    >
      <Box
        sx={{
          mt: "-6em",
          width: "88%",
          ml: "auto",
          mr: "auto",
          display: "flex",
          flexDirection: "row",
          position: "relative",
          pb: "5em",
        }}
      >
        <img
          src={mokcup1}
          style={{
            marginTop: "3em",
            width: "480px",
            height: "320px",
          }}
        />
        <Box
          sx={{
            widht: "200px !important",
            mr: "auto",
            ml: "auto",
            mt: "7.5em",
            position: "absolute",
            left: "510px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: 300,
            }}
          >
            <Box
              sx={{
                width: 56,
                height: 36,
                backgroundColor: "#2C80FF",
                borderRadius: "6px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mr: ".75em",
                mt: ".5em",
              }}
            >
              <img
                src={OutlineBook}
                style={{
                  width: "18px",
                  height: "18px",
                }}
              />
            </Box>
            <Box>
              <h3
                style={{
                  color: "#191A1C",
                  font: "normal normal 600 15px Poppins",
                  margin: "4px 0",
                }}
              >
                Lorem Ipsum is simply dummy text of the printing
              </h3>
              <p
                style={{
                  font: "normal normal normal 10px/18px Poppins",
                  color: "#474C4E",
                }}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: 300,
              mt: 2,
            }}
          >
            <Box
              sx={{
                width: 56,
                height: 36,
                backgroundColor: "#2C80FF",
                borderRadius: "6px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mr: ".75em",
                mt: ".5em",
              }}
            >
              <img
                src={OutlineStack}
                style={{
                  width: "18px",
                  height: "18px",
                }}
              />
            </Box>
            <Box>
              <h3
                style={{
                  color: "#191A1C",
                  font: "normal normal 600 15px Poppins",
                  margin: "4px 0",
                }}
              >
                Lorem Ipsum is simply dummy text of the printing
              </h3>
              <p
                style={{
                  font: "normal normal normal 10px/18px Poppins",
                  color: "#474C4E",
                }}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
            </Box>
          </Box>
        </Box>
        <Box className="login_Box">
          <h3>Password</h3>
          <p
            style={{
              marginBottom: ".5em",
            }}
          >
            Set a new password.
          </p>
          {activePage === "email" && (
            <Box>
              <Input
                icon={
                  <img
                    src={MailNegative}
                    style={{ width: "18px", height: "18px" }}
                  />
                }
                value={email}
                onChange={(e) => setemail(e.target.value)}
                placeholder="Your email"
                type="email"
                style={{ marginTop: ".5em" }}
              />
              <Select
                value={type}
                onChange={(value) => {
                  settype(value);
                }}
                options={[
                  { value: "Inspector", label: "Inspector" },
                  { value: "Appraiser", label: "Appraiser" },
                ]}
                placeholder="Select Role"
                style={{
                  width: "100%",
                  marginTop: ".5em",
                  marginBottom: ".5em",
                }}
              />
            </Box>
          )}

          {activePage === "otp" && (
            <FourDigitInput
              onChange={(e) => {
                setotp(e);
              }}
            />
          )}

          {activePage === "password" && (
            <Box>
              <Input
                icon={
                  <img src={Shield} style={{ width: "18px", height: "18px" }} />
                }
                value={password}
                onChange={(e) => setpassword(e.target.value)}
                placeholder="Password"
                type={"password"}
                style={{ margin: ".5em 0" }}
                error={Errors?.password || ""}
              />
              <Input
                icon={
                  <img src={Shield} style={{ width: "18px", height: "18px" }} />
                }
                value={confirmPassword}
                onChange={(e) => setconfirmPassword(e.target.value)}
                placeholder="Confirm password"
                type={"password"}
                style={{ margin: ".5em 0" }}
                error={Errors?.password || ""}
              />
            </Box>
          )}
          <Button
            isLoading={isLoading}
            disabled={isLoading}
            onClick={handleChangePassword}
          >
            {activePage === "email" && "Send OTP"}
            {activePage === "otp" && "Verify OTP"}
            {activePage === "password" && "Reset Password"}
          </Button>
        </Box>
        {makePrompt.open && (
          <Prompt
            open={makePrompt.open}
            message={makePrompt.message}
            type={makePrompt.type}
            handleClose={() =>
              setmakePrompt({ open: false, message: "", type: "error" })
            }
          />
        )}
      </Box>
    </Template>
  );
};

export default ForgetPassword;
