import React, { useState } from "react";
import Template from "../../Template";
import Ellipse8 from "../../Assets/Images/Ellipse8.svg";
import { Box } from "@mui/material";
import Text from "../../Components/Core/Text";
import Input from "../../Components/Core/Input";
import Button from "../../Components/Core/Button";
import FaqCard from "../../Components/FaqCard";
const faqs = [
  {
    category: "My Properties",
    questions: [
      {
        question: "How do I delete a property?",
        answer:
          "To delete a property, navigate to the 'My Properties' section and select the property you want to delete. Click on the 'Delete' option and confirm your choice.",
      },
      {
        question: "Can I add multiple images to a property listing?",
        answer:
          "Yes, you can add multiple images to showcase your property. While adding or editing a property, use the 'Upload Image' feature to include additional pictures.",
      },
      {
        question: "Is there a limit to the number of properties I can list?",
        answer:
          "Currently, there is no limit to the number of properties you can list on our platform. Feel free to add as many properties as you need.",
      },
      {
        question: "How do I mark a property as 'Sold'?",
        answer:
          "To mark a property as 'Sold,' go to the 'My Properties' section, find the property in question, and use the 'Mark as Sold' option.",
      },
    ],
  },
  {
    category: "My Orders",
    questions: [
      {
        question: "Can I cancel my order after placing it?",
        answer:
          "The ability to cancel an order depends on its current status. Please check the 'My Orders' page for the specific order's status and follow the provided instructions for cancellations.",
      },
      {
        question: "What do I do if there is an issue with my delivered order?",
        answer:
          "If you encounter any issues with your delivered order, such as missing items or damages, contact our customer support immediately with your order details for assistance.",
      },
    ],
  },
  {
    category: "Billing",
    questions: [
      {
        question: "How can I view my billing history?",
        answer:
          "To view your billing history, go to the 'Billing' section in your account settings. There, you can find a detailed history of your past transactions and invoices.",
      },
      {
        question: "Do you offer automatic billing for subscriptions?",
        answer:
          "Yes, we provide automatic billing for subscription services. You can set up automatic payments in the 'Billing' section by linking a valid payment method.",
      },
      {
        question:
          "What should I do if I notice an incorrect charge on my statement?",
        answer:
          "If you identify an incorrect charge on your statement, please contact our billing department with the relevant details. We will investigate and resolve the issue promptly.",
      },
      {
        question: "Are there any hidden fees associated with my account?",
        answer:
          "No, we are transparent about our fees. You can review the fee structure in the 'Billing' section to ensure clarity on any charges associated with your account.",
      },
    ],
  },
  {
    category: "Others",
    questions: [
      {
        question:
          "How can I change my email address associated with the account?",
        answer:
          "To change your email address, go to the 'Account Settings' page and update the email field with your new address. Verify the change through the confirmation link sent to your new email.",
      },
      {
        question: "Is there a mobile app available for your services?",
        answer:
          "Yes, we offer a mobile app for a seamless experience. You can download it from the App Store or Google Play and log in using your existing account credentials.",
      },
    ],
  },
];

function Help() {
  const [selected, setselected] = useState("My Properties");

  const filterFAQ = faqs.find((faq) => faq.category === selected);

  console.log(filterFAQ);
  return (
    <Template
      showHeader
      headerProps={{
        showNotification: true,
        showProfile: true,
        showSearch: true,
        showMessage: true,
        showCash: true,
        showCompleteProfile: true,
      }}
      containerStyle={{
        backgroundImage: `url(${Ellipse8})`,
        height: "100vh",
      }}
    >
      <Box
        sx={{
          border: "1px solid #FFFFFF",
          background:
            "transparent linear-gradient(119deg, #FFFFFF66 0%, #FFFFFF24 100%) 0% 0% no-repeat padding-box",
          boxShadow: "15px 15px 50px #00000029",
          borderRadius: "24px",
          backgroundColor: "#F2F2F2",
          opacity: "1",
          padding: "1.5em",
          backdropFilter: "blur(50px)",
          WebkitBackdropFilter: "blur(50px)",
          width: "90%",
          margin: "auto",
        }}
      >
        <Box style={{ display: "flex", flexDirection: "row" }}>
          <Box sx={{ mr: "auto" }}>
            <Text variant="h2">Help & Support</Text>
            <Text
              variant="body2"
              style={{
                fontSize: "12px",
                color: "gray",
              }}
            >
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </Text>
          </Box>
          <Text
            style={{
              fontSize: "12px",
              color: "#2C80FF",
            }}
          >
            Back
          </Text>
        </Box>
        <Text
          variant="h2"
          style={{
            textAlign: "center",
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          Hello, how can we help?
        </Text>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "30%",
            marginTop: "1em",
            height: "48px",
            backgroundColor: "#FFFFFF",
            ml: "auto",
            mr: "auto",
            borderRadius: "12px",
          }}
        >
          <Input
            placeholder="Ask a question..."
            style={{ width: "70%", height: "48px", background: "transparent" }}
          />
          <Button
            style={{
              width: "30%",
              height: "42px",
              marginTop: "auto",
              marginBottom: "auto",
              marginRight: ".3em",
            }}
          >
            Search
          </Button>
        </Box>
        <Box
          sx={{
            marginTop: "2em",
            display: "flex",
            flexDirection: "row",
            width: "60%",
            ml: "auto",
            mr: "auto",
          }}
        >
          {faqs.map((faq) => (
            <Box
              sx={{ width: "30%", cursor: "pointer" }}
              onClick={() => setselected(faq.category)}
            >
              <Text
                variant="h2"
                style={{
                  marginTop: "1em",
                  font: "normal normal medium 14px/21px Poppins",
                  fontSize: "14px",
                  marginLeft: "1em",
                  color: selected === faq.category ? "#2593D6" : "gray",
                }}
              >
                {faq.category}
              </Text>
              <Box
                sx={{
                  width: "50%",
                  height: "2px",
                  ml: ".8em",
                  backgroundColor:
                    selected === faq.category ? "#2593D6" : "transparent",
                }}
              />
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            marginTop: "2em",
            width: "86%",
            ml: "auto",
            mr: "auto",
          }}
        >
          {filterFAQ.questions.map((question,index) => (
            <FaqCard
              question={question}
              isOpened={index === 0}
              isLast={
                filterFAQ.questions.indexOf(question) ===
                filterFAQ.questions.length - 1
              }
            />
          ))}
          <Text
            variant="h2"
            style={{
              textAlign: "center",
              fontSize: "18px",
              fontWeight: "bold",
              marginTop: "2em",
            }}
          >
            Still need help?
          </Text>
          <Button
            style={{
              width: "22%",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "1em",
              height: "44px",
            }}
          >
            Talk to Support
          </Button>
        </Box>
      </Box>
    </Template>
  );
}

export default Help;
